import React, { useEffect } from 'react';
import { Form, Modal, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	addBranch,
	editBranch
} from '../../stateManager/reducers/studentManagementSlice';
import { divisions } from '../../constants/constString';
import { isPhoneNoValid } from '../../constants/constFunction';

const { Option } = Select;

const AddBranchModal = props => {
	const { isVisible, isEdit, branchData } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const status = useSelector(state => state.studentsAdmin.status);

	useEffect(() => {
		if (isEdit && branchData) {
			form.setFieldsValue({
				division: branchData?.division,
				name: branchData?.name,
				phoneNo: branchData?.phone?.substr(2)
			});
		}
		// eslint-disable-next-line
	}, [branchData]);

	const handleSubmit = async values => {
		const { name, code, phoneNo, division } = values;
		let phone;
		if (!!phoneNo) {
			phone = '88' + phoneNo;
		}

		if (isEdit) {
			const data = { name, code, phone };
			const response = await dispatch(
				editBranch({ branchId: branchData?._id, data })
			);
		} else {
			// eslint-disable-next-line
			const res = await dispatch(addBranch({ division, name, code, phone }));
		}

		props.toggleAddModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			maskClosable={false}
			destroyOnClose
			onCancel={() => props.toggleAddModal()}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			okText='Add Branch'
			title='Add Branch'
		>
			<Form layout='vertical' form={form} onFinish={handleSubmit}>
				<Form.Item
					name='division'
					rules={[{ required: true }]}
					label={<strong>Select Division</strong>}
				>
					<Select>
						{divisions.map((item, index) => (
							<Option key={index} value={item}>
								{item}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='name'
					rules={[{ required: true }]}
					label={<strong>Branch Name</strong>}
				>
					<Input placeholder='Enter branch name' />
				</Form.Item>
				<Form.Item
					name='phoneNo'
					label={<strong>Phone Number</strong>}
					requiredMark='optional'
					rules={[
						() => ({
							validator(rule, value) {
								// let phone = new RegExp(/^01\d{9}$/);
								if (!value || isPhoneNoValid(value)) {
									return Promise.resolve();
								}
								return Promise.reject('The given number is not valid');
							}
						})
					]}
				>
					<Input prefix='+88' placeholder='Enter phone number' />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddBranchModal;
