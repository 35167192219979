import React from 'react';
import { Form, Modal, Upload, Input, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import {
	addCourse,
	addSubjectByCourse
} from '../../stateManager/reducers/courseSlice';
import {
	patchFileRequest,
	signedUrl as signedUrlRequest
} from '../../stateManager/reducers/questionSlice';
import { sessions } from '../../constants/constString';

const { Dragger } = Upload;
const { Option } = Select;

const AddCourseModal = props => {
	const { isVisible, isSubject, courseId } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const handleSubmit = async values => {
		const { name, description, image, session } = values;
		console.log(name, description, image, session);

		try {
			if (image) {
				const signResp = await dispatch(signedUrlRequest(image.file.type));
				var { key, signedUrl } = signResp.payload?.data;
				const patchResp = await dispatch(
					patchFileRequest({ signedUrl, file: image.file.originFileObj })
				);
			}
			if (isSubject) {
				const response = await dispatch(
					addSubjectByCourse({
						name,
						courseId,
						image: key,
						description
					})
				);
			} else {
				const res = await dispatch(
					addCourse({ name, description, image: key, session })
				);
			}
		} catch (error) {
			console.log(error);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			onOk={() => form.submit()}
			title={<strong>{isSubject ? 'Add Subject' : 'Add Course'} </strong>}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='name'
					required={[{ required: true }]}
					label={<strong>{isSubject ? 'Subject Name' : 'Course Title'}</strong>}
				>
					<Input
						placeholder={
							isSubject ? 'Enter Subject Name' : 'Enter Course Title'
						}
					/>
				</Form.Item>
				{/* {!isSubject && ( */}
				<Form.Item
					name='description'
					required={[{ required: true }]}
					label={
						<strong>
							{isSubject ? 'Subject Description' : 'Course Description'}
						</strong>
					}
				>
					<Input.TextArea
						placeholder={
							isSubject
								? 'Enter Subject Description'
								: 'Enter Course Description'
						}
					/>
				</Form.Item>
				{/* )} */}
				{!isSubject && (
					<Form.Item
						name='session'
						label={<strong>Session</strong>}
						required={[{ required: true }]}
					>
						<Select>
							{sessions.map((item, index) => (
								<Option value={item} key={index}>
									{item}
								</Option>
							))}
						</Select>
					</Form.Item>
				)}
				<Form.Item
					name='image'
					required={[{ required: true }]}
					label={<strong>Upload Image</strong>}
				>
					<Dragger>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p>Drag and drop images here or click to browse</p>
					</Dragger>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddCourseModal;
