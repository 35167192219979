import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";
import toastr from "toastr";

const initialState = {
  studentsList: [],
  branchList: [],
  searchName: "",
  searchId: "",
  searchPhone: "",
  filterBranch: null,
  filterCourse: null,
  filterSession: null,
  filterStartDate: "",
  filterEndDate: "",
  studentScoreBoardData: {},
  scoreboardExamResult: [],
  offlineResults: [],
  status: "idle",
  error: null,
};

export const getBranch = createAsyncThunk("students/getBranch", async () => {
  try {
    const response = await instance.get("/branch");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const addBranch = createAsyncThunk(
  "students/addBranch",
  async ({ name, code, phone, division }) => {
    try {
      const response = await instance.post("/branch", {
        division,
        name,
        code,
        phone,
      });
      if (response.status === 200) {
        toastr.success("Branch added successfully");
      }
      return response.data;
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
      return Promise.reject(error);
    }
  }
);

export const editBranch = createAsyncThunk(
  "students/editBranch",
  async ({ branchId, data }) => {
    try {
      const response = await instance.patch(`/branch/${branchId}`, data);
      return response.data;
    } catch (error) {
      return Promise.reject;
    }
  }
);

export const deleteBranch = createAsyncThunk(
  "students/deleteBranch",
  async ({ branchId }) => {
    try {
      const response = await instance.delete(`/branch/${branchId}`);
      if (response.status === 200) {
        toastr.success("Branch deleted successfully!");
      }
      return response.data;
    } catch (error) {
      return Promise.reject;
    }
  }
);

export const getStudentScoreRequest = createAsyncThunk(
  "students/getScore",
  async ({ studentId, lastId, limit }) => {
    try {
      const response = await instance.get(
        `/exam/scoreboard/${studentId}${lastId ? "?lastId=" + lastId : ""}${
          limit ? "&limit=" + limit : ""
        }`
      );
      return { data: response.data, lastId };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getStudent = createAsyncThunk(
  "students/getStudent",
  async (isLoadMore, { getState }) => {
    const {
      filterSession,
      filterCourse,
      filterBranch,
      searchName,
      searchId,
      searchPhone,
      studentsList,
      filterStartDate,
      filterEndDate,
    } = getState().studentsAdmin;
    const lastId =
      isLoadMore && !!studentsList && studentsList.length > 0
        ? studentsList[studentsList.length - 1]._id
        : undefined;
    try {
      const response = await instance.get(
        `/student?branch=${filterBranch || ""}${
          lastId ? "&lastId=" + lastId : ""
        }&session=${
          filterSession || ""
        }&name=${searchName}&username=${searchPhone}&courseId=${
          filterCourse || ""
        }&startDate=${filterStartDate}&endDate=${filterEndDate}&sid=${searchId}`
      );
      return { data: response.data?.data, isLoadMore };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getAlternateExamById = createAsyncThunk(
  "students/getAlternateExamById",
  async (username) => {
    console.log("username", username);
    try {
      const response = await instance.get(`/student/alternateExam/${username}`);
      return { data: response.data };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const exportStudentRequest = createAsyncThunk(
  "students/exportStudentRqeust",
  async (lastId, { getState }) => {
    const {
      filterSession,
      filterCourse,
      filterBranch,
      searchName,
      searchId,
      searchPhone,
      studentsList,
      filterEndDate,
      filterStartDate,
    } = getState().studentsAdmin;
    try {
      const response = await instance.get(
        `/student/export?branch=${filterBranch || ""}&session=${
          filterSession || ""
        }&name=${searchName}&sid=${searchId}&username=${searchPhone}&courseId=${
          filterCourse || ""
        }&startDate=${filterStartDate}&endDate=${filterEndDate}`
      );
      window.open(response.data?.data?.S3?.Location);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const addStudent = createAsyncThunk(
  "students/addStudents",
  async (data) => {
    try {
      const response = await instance.post("/student/add-student", data);
      // toastr.success('Student added successfully');
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const editStudentRequest = createAsyncThunk(
  "students/editStudents",
  async ({ studentId, data }) => {
    try {
      const response = await instance.patch(
        `/student/profile/${studentId}`,
        data
      );
      toastr.success("Student updated successfully");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const studentStatusUpdateRequest = createAsyncThunk(
  "students/studentStatusUpdateRequest",
  async ({ studentId, data }) => {
    try {
      const response = await instance.patch(
        `/student/status/${studentId}`,
        data
      );
      toastr.success("Student status successfully");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const studentStatusUpdateByBatchRequest = createAsyncThunk(
  "students/studentStatusUpdateByBatchRequest",
  async ({ data }) => {
    try {
      const response = await instance.patch(`/student/status`, data);
      toastr.success("Student status successfully");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteStudentRequest = createAsyncThunk(
  "students/deleteStudents",
  async ({ data }) => {
    try {
      const response = await instance.delete(`/student/delete`, { data });
      toastr.success("Student deleted successfully");
      return { data: response.data, usernames: data.students };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "students",
  initialState: initialState,
  reducers: {
    getStudentsAction: (state) => {
      return state.studentsList;
    },
    setSearchName: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetFilters: (state, action) => {
      state.searchId = initialState.searchId;
      state.searchName = initialState.searchName;
      state.searchPhone = initialState.searchPhone;
      state.filterSession = initialState.filterSession;
      state.filterCourse = initialState.filterCourse;
      state.filterBranch = initialState.filterBranch;
      state.filterStartDate = initialState.filterStartDate;
      state.filterEndDate = initialState.filterEndDate;
    },
    resetState: (state, action) => {
      const { key } = action.payload;
      state[key] = initialState[key];
    },
  },
  extraReducers: {
    [getBranch.pending]: (state, action) => {
      state.status = "loading";
    },
    [getBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.branchList = action.payload?.data;
    },
    [getBranch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteBranch.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const data = action.payload?.data;
      const temp = [...state.branchList];
      state.branchList = temp.filter((item) => item._id !== data?._id);
    },
    [deleteBranch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [editBranch.pending]: (state, action) => {
      state.status = "loading";
    },
    [editBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const data = action.payload?.data;
      const clonedData = [...state.branchList];
      state.branchList = clonedData.map((item) =>
        item._id === data._id ? data : item
      );
    },
    [editBranch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addBranch.pending]: (state, action) => {
      state.status = "loading";
    },
    [addBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.branchList = state.branchList.concat(action.payload?.data);
    },
    [addBranch.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addStudent.pending]: (state, action) => {
      state.status = "loading";
    },
    [addStudent.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsList = [action.payload?.data, ...state.studentsList];
    },
    [addStudent.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getStudent.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStudent.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { data, isLoadMore } = action.payload;
      state.studentsList = isLoadMore ? state.studentsList.concat(data) : data;
    },
    [getStudent.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [editStudentRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [editStudentRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsList = state.studentsList.map((item) =>
        item._id === action.payload?.data?._id ? action.payload?.data : item
      );
    },
    [editStudentRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [studentStatusUpdateRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [studentStatusUpdateRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsList = state.studentsList.map((item) =>
        item._id === action.payload?.data?._id ? action.payload?.data : item
      );
    },
    [studentStatusUpdateRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteStudentRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteStudentRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { usernames } = action.payload;
      const temp = [...state.studentsList];
      state.studentsList = temp.filter(
        (item) => usernames.indexOf(item.username) === -1
      );
      // state.studentsList = temp.filter(item => {
      // 	for (let i = 0; i < usernames.length; i++) {
      // 		if (item.username !== usernames[i]) {
      // 			return item;
      // 		}
      // 	}
      // });
    },
    [deleteStudentRequest.rejected]: (state, action) => {
      state.status = "failed";
    },
    [getStudentScoreRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStudentScoreRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { lastId, data } = action?.payload;
      state.studentScoreBoardData = lastId
        ? state.studentScoreBoardData
        : data?.data;
      const result = data?.data?.results?.examResult || [];
      state.scoreboardExamResult = [...state.scoreboardExamResult, ...result];
      state.offlineResults = data?.data?.offlineResults || [];
    },
    [getStudentScoreRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action?.error?.message;
    },
  },
});

export const allStudentsList = (state) => state.studentsAdmin.studentsList;
export const searchName = (state) => state.studentsAdmin.searchName;
export const searchId = (state) => state.studentsAdmin.searchId;
export const searchPhone = (state) => state.studentsAdmin.searchPhone;
export const filterBranch = (state) => state.studentsAdmin.filterBranch;
export const filterCourse = (state) => state.studentsAdmin.filterCourse;
export const filterSession = (state) => state.studentsAdmin.filterSession;
export const filterStartDate = (state) => state.studentsAdmin.filterStartDate;
export const filterEndDate = (state) => state.studentsAdmin.filterEndDate;
export const offlineResults = (state) => state.studentsAdmin.offlineResults;

export const filteredStudentList = (state) => {
  const {
    searchName,
    searchId,
    searchPhone,
    filterBranch,
    filterCourse,
    filterSession,
  } = state.studentsAdmin;

  return (
    state.studentsAdmin?.studentsList &&
    state.studentsAdmin?.studentsList?.filter(
      (item) =>
        item?.branch?.includes(filterBranch) &&
        (filterCourse?.length > 0
          ? item?.courses?.indexOf(filterCourse) >= 0
          : true) &&
        item?.name?.toLowerCase()?.includes(searchName) &&
        item?.sid?.toLowerCase()?.includes(searchId) &&
        item?.username?.toLowerCase()?.includes(searchPhone) &&
        item?.session?.includes(filterSession)
    )
  );
};

export const { getStudentsAction, setSearchName, resetFilters, resetState } =
  slice.actions;

export default slice.reducer;
