import React from 'react';
import { Switch } from 'react-router-dom';

import AdminModeratorNavigation from './Admin/AdminModerator';
import ContentUploaderNavigation from './Admin/ContentUploader';
import QuestionUploaderNavigation from './Admin/QuestionUploader';
import InstructorNavigation from './Admin/Instructor';
import ExamModeratorNavigation from './Admin/ExamModerator';
import ExaminerNavigation from './Admin/Examiner';
import MentorNavigation from './Admin/Mentor';


import {
	isAdminOrModerator,
	isContentUploader,
	isExamModarator,
	isExaminer,
	isInstructor,
	isQuestionUploader,
	isMentor
} from '../../constants/constFunction';
import QuestionUploader from './Admin/QuestionUploader';

const AdminNavigation = props => {

	console.log('isAdminOrModerator', isAdminOrModerator());
	console.log('isContentUploader', isContentUploader());
	console.log('isExamModarator', isExamModarator());
	console.log('isExaminer', isExaminer());
	console.log('isInstructor', isInstructor());
	console.log('isQuestionUploader', isQuestionUploader());

	if (isAdminOrModerator()) {
		return <AdminModeratorNavigation />;
	} else if (
		isExamModarator() &&
		isQuestionUploader() &&
		isContentUploader() &&
		isInstructor()
	) {
		return (
			// <Switch>
			<React.Fragment>
				<ExamModeratorNavigation />
				<QuestionUploader />
				<ContentUploaderNavigation />
				<isInstructor />
			</React.Fragment>
			// </Switch>
		);
	} else if (isExamModarator() && isQuestionUploader() && isContentUploader()) {
		return (
			// <Switch>
			<React.Fragment>
				<ExamModeratorNavigation />
				<QuestionUploader />
				<ContentUploaderNavigation />
			</React.Fragment>
			// </Switch>
		);
	} else if (isExamModarator() && isQuestionUploader()) {
		return (
			// <Switch>
			<React.Fragment>
				<ExamModeratorNavigation />
				<QuestionUploader />
			</React.Fragment>
			// </Switch>
		);
	} else if (isExamModarator() && isContentUploader()) {
		return (
			// <Switch>
			<React.Fragment>
				<ExamModeratorNavigation />
				<ContentUploaderNavigation />
			</React.Fragment>
			// </Switch>
		);
	} else if (isContentUploader() && isQuestionUploader()) {
		return (
			<React.Fragment>
				<ContentUploaderNavigation />
				<QuestionUploader />
			</React.Fragment>

			// </Switch>
		);
	} else if (isContentUploader()) {
		return (
			<Switch>
				<ContentUploaderNavigation />
			</Switch>
		);
	} else if (isQuestionUploader()) {
		return (
			<Switch>
				<QuestionUploaderNavigation />
			</Switch>
		);
	} else if (isExamModarator()) {
		return (
			<Switch>
				<ExamModeratorNavigation />
			</Switch>
		);
	} else if (isExaminer()) {
		return (
			<Switch>
				<ExaminerNavigation />
			</Switch>
		);
	} else if (isInstructor()) {
		return (
			<Switch>
				<InstructorNavigation />
			</Switch>
		);
	} else if (isMentor()) {
		return (
			<Switch>
				<MentorNavigation />
			</Switch>
		);
	}
	else{
		return <div>
			<h1>Not Found</h1>
		</div>;
	}
};

export default AdminNavigation;
