import React, { useEffect } from 'react';
import { Form, Modal, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import { addStudent } from '../../stateManager/reducers/studentManagementSlice';
import { sessions } from '../../constants/constString';
import { isPhoneNoValid } from '../../constants/constFunction';

const { Option } = Select;

const AddSingleStudentModal = props => {
	const { isVisible } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	const branchList = useSelector(state => state.studentsAdmin.branchList);
	let courseList = useSelector(state => state.courses.courseList);
	const [selectedSession, setSelectedSession] = React.useState('');
	console.log(selectedSession);
	courseList = courseList.filter(item => item.session === selectedSession);

	useEffect(() => {
		form.setFieldsValue({
			branch: undefined,
			contact: undefined,
			username: undefined,
			courses: undefined,
			SSCGPA: undefined,
			HSCGPA: undefined,
			session: undefined,
			sid: undefined,
			firstTime: undefined
		});
		
	}
	, [isVisible]);

	const handleSubmit = async values => {
		const res = await dispatch(
			addStudent({
				...values,
				contact: '88' + values.contact,
				username: '88' + values.username,
				HSCGPA: values.HSCGPA === '' ? undefined : values.HSCGPA,
				SSCGPA: values.SSCGPA === '' ? undefined : values.SSCGPA,
				courses: [values.courses]
			})
		);
		props.toggleModal();
		if (res && res.status === 200) {
			toastr.success('Student added successfully');
		} else {
			toastr.success('Student added successfully');
		}
	};

	return (
		<Modal
			visible={isVisible}
			centered
			maskClosable={false}
			destroyOnClose
			onCancel={() => props.toggleModal()}
			onOk={() => form.submit()}
			okText='Add Student'
			title={<strong>Add Student</strong>}
		>
			<Form
				layout='vertical'
				preserve={false}
				form={form}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='branch'
					rules={[{ required: true }]}
					label={<strong>Select Branch</strong>}
				>
					<Select>
						{branchList.map((item, index) => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='session'
					rules={[{ required: true, message: 'Please select a session' }]}
					label={<strong>Session</strong>}
					
				>
					<Select value={selectedSession} onChange={value => {
						setSelectedSession(value);
						form.setFieldsValue({ courses: undefined,
							session: value });
					
					}}>
						{sessions.map((item, index) => (
							<Option value={item} key={index}>
								{item}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='courses'
					rules={[{ required: true }]}
					label={<strong>Select Course</strong>}
				>
					<Select>
						{courseList.map((item, index) => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='name'
					rules={[{ required: true }]}
					label={<strong>Student Name</strong>}
				>
					<Input placeholder='Enter student name' />
				</Form.Item>
				<Form.Item
					name='sid'
					rules={[{ required: true }]}
					label={<strong>Student ID</strong>}
				>
					<Input placeholder='Enter student id' />
				</Form.Item>
				
				<Form.Item
					name='username'
					label={<strong>Phone Number</strong>}
					rules={[
						{ required: true, message: 'Please enter contact no.' },
						() => ({
							validator(rule, value) {
								let phone = new RegExp(/^01\d{9}$/);
								if (!value || value.match(phone)) {
									return Promise.resolve();
								}
								return Promise.reject('The given number is not valid');
							}
						})
					]}
				>
					<Input prefix='+88' placeholder='Enter phone number' />
				</Form.Item>
				<Form.Item
					name='contact'
					label={<strong>Parent's Phone Number</strong>}
					requiredMark='optional'
					rules={[
						{ required: true, message: "Please enter parent's contact no." },
						() => ({
							validator(rule, value) {
								// let phone = new RegExp(/^01\d{9}$/);
								if (!value || isPhoneNoValid(value)) {
									return Promise.resolve();
								}
								return Promise.reject('The given number is not valid');
							}
						})
					]}
				>
					<Input prefix='+88' placeholder='Enter phone number' />
				</Form.Item>
				<Form.Item
					name='SSCGPA'
					requiredMark='optional'
					rules={[
						// { required: true, message: 'Enter SSC GPA' },
						() => ({
							validator(rule, value) {
								// let phone = new RegExp(/^01\d{9}$/);
								if (!value || (value >= 0 && value <= 5)) {
									return Promise.resolve();
								}
								return Promise.reject('The given number is not valid');
							}
						})
					]}
					label={<strong>SSC GPA</strong>}
				>
					<Input placeholder='Enter SSC GPA' />
				</Form.Item>
				<Form.Item
					name='HSCGPA'
					requiredMark='optional'
					rules={[
						// { required: true, message: 'Enter HSC GPA' },
						() => ({
							validator(rule, value) {
								// let phone = new RegExp(/^01\d{9}$/);
								if (!value || (value >= 0 && value <= 5)) {
									return Promise.resolve();
								}
								return Promise.reject('The given number is not valid');
							}
						})
					]}
					label={<strong>HSC GPA</strong>}
				>
					<Input placeholder='Enter HSC GPA' />
				</Form.Item>
				<Form.Item
					name='firstTime'
					rules={[{ required: true, message: 'Select 1st/2nd time' }]}
					label={<strong>First Time? </strong>}
				>
					<Select placeholder='Select 1st/2nd time'>
						<Option value={true} key={'1'}>
							Yes
						</Option>
						<Option value={false} key={'2'}>
							No
						</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddSingleStudentModal;
