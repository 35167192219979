import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	addUser,
	updateUser
} from '../../stateManager/reducers/userManagementSlice';
import { userRoles } from '../../constants/constString';
import { isPhoneNoValid } from '../../constants/constFunction';

const { Option } = Select;

const AddUserModal = props => {
	const { isVisible, isEdit, user } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const userStatus = useSelector(state => state.userManagement.status);

	useEffect(() => {
		if (isEdit && user) {
			form.setFieldsValue({
				firstName: user.firstName,
				lastName: user.lastName,
				username: user.username.substr(2),
				role: user.roles,
				adminId: user.adminId,
				email: user?.email
			});
		}
		// eslint-disable-next-line
	}, [user]);

	const handleSubmit = async values => {
		const { username, firstName, lastName, password, role, email, adminId } = values;

		if (isEdit) {
			const data = {
				username: '88' + username,
				firstName,
				lastName,
				password,
				email,
				adminId,
				roles: [...role]
			};
			// eslint-disable-next-line
			const res = await dispatch(updateUser({ userId: user?._id, data }));
			props.toggleModal();
		} else {
			const data = {
				username: '88' + username,
				firstName,
				lastName,
				password,
				email,
				adminId,
				roles: [role]
			};
			const res = await dispatch(addUser(data));
			props.toggleModal();
			return res;
		}
	};

	return (
		<Modal
			visible={isVisible}
			destroyOnClose
			centered
			title={<strong>Add Users</strong>}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			okButtonProps={{ loading: userStatus === 'loading' }}
			onOk={() => form.submit()}
		>
			<Form
				form={form}
				layout='vertical'
				onFinish={handleSubmit}
				preserve={false}
			>
				<Form.Item
					name='firstName'
					label='First Name'
					rules={[{ required: true, message: 'Please enter first name' }]}
				>
					<Input placeholder='Enter first name' required />
				</Form.Item>
				<Form.Item
					name='lastName'
					label='Last Name'
					rules={[{ required: true, message: 'Please enter last name' }]}
				>
					<Input placeholder='Enter last name' required />
				</Form.Item>
				<Form.Item
					name='adminId'
					label='ID'
					rules={[{ required: true, message: 'Please enter ID' }]}
				>
					<Input placeholder='Enter ID' required />
				</Form.Item>
				<Form.Item
					name='username'
					label='Phone number'
					rules={[
						{ required: true, message: 'Please enter phone number' },
						() => ({
							validator(rule, value) {
								// let phone = new RegExp(/^01\d{9}$/);
								if (!value || isPhoneNoValid(value)) {
									return Promise.resolve();
								}
								return Promise.reject('Please enter a valid phone number');
							}
						})
					]}
				>
					<Input placeholder='Enter phone number' required prefix='+88' />
				</Form.Item>
				<Form.Item
					name='email'
					label='Email'
					rules={[{ required: true, message: 'Please enter an email' }]}
				>
					<Input placeholder='Enter email' type='email' />
				</Form.Item>
				<Form.Item
					name='role'
					label='Select Role'
					rules={[{ required: true, message: 'Please select a role' }]}
				>
					<Select
						mode={isEdit && 'multiple'}
						allowClear
						placeholder='Select role'
					>
						{userRoles.map((item, index) => (
							<Option value={item.value}>{item.name}</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='password'
					label='Password'
					rules={
						!isEdit && [
							{ required: 'true', message: 'Please input your password' },
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || value.length >= 8) {
										return Promise.resolve();
									}
									return Promise.reject('Password must be 8 characters long');
								}
							})
						]
					}
					hasFeedback
				>
					<Input.Password placeholder='Enter password' />
				</Form.Item>
				<Form.Item
					name='confirmPassword'
					label='Confirm Password'
					dependencies={['password']}
					hasFeedback
					rules={
						!isEdit && [
							{ required: true, message: 'Confirm Password' },
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject('Passwords dont match');
								}
							})
						]
					}
				>
					<Input.Password placeholder='Retype password' />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddUserModal;
