import React from 'react';
import { Bar } from '@ant-design/charts';

const BarChart = props => {
	const { data, style, xField, yField, seriesField, legendPosition } = props;

	const config = {
		data: data,
		xField: xField,
		yField: yField,
		seriesField: seriesField,
		legend: { position: !!legendPosition ? legendPosition : 'top-left' }
	};

	return (
		<React.Fragment>
			<Bar {...config} style={style} />
		</React.Fragment>
	);
};

export default BarChart;
