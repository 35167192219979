//write list of questions antd and a button to reply

import React, { useState, useRef } from "react";
import { List, Comment, Avatar, Button, Input, Layout ,Row , Col } from "antd";
import { Content } from "antd/lib/layout/layout";
import SubHeader from "../Common/SubHeader";
import Modal from "antd/lib/modal/Modal";
import QAForum from "./QAForum";
import { useParams } from 'react-router-dom';
import './QNAManagement.style.css';
import { useSelector } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Card, Collapse, Flex } from 'antd';
import { bucket_url } from '../../constants/constString';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getComments, getQuestion } from "../../stateManager/reducers/mentorSlice";
import { useDispatch } from 'react-redux';
import PostComponent from "./Post";
import './QNAManagement.style.css';
const { Panel } = Collapse;


const CommentList = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [question, setQuestion] = useState(null);
    const qnaList = useSelector(state => state.mentor.qnaList);
    const comments = useSelector(state => state.mentor.comments);
    console.log('comments', comments);
    
    const { id } = useParams();
    // const question = qnaList.find(qna => qna._id === id);

    const history = useHistory();

    const qaForumRef = useRef(); 

    const dispatch = useDispatch();

    const fetchQuestion = async (id) => {
      const res = await dispatch(getQuestion(id));
      setQuestion(res.payload.data);
    }

    React.useEffect(() => {
        const fetchComments = async () => {
            try {
               await fetchQuestion(id);
                await dispatch(getComments(id));
            } catch (error) {
                console.error('Error fetching comments:', error);
            }
        };
        fetchComments();
    }, [id]);
   
    const disableLockButton = (comment) => {
      const user = JSON.parse(localStorage.getItem('user'));

      if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
        return false;
      }
     
      if(comment.isLocked && comment.locked.userId === user.id){
        return false;
      }
      return true;
    }

  if (!question) {
  
    return <></>
  }

  return (
      <Layout className="white-bg question-main-section">
        <SubHeader headText='Manage Content' />
        <Row>
          <Col>
          <Button type="link" block  className="back-link" onClick={()=>{
            history.push('/qna-management');
          }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <path d="M16.0003 25.3332L6.66699 15.9998L16.0003 6.6665" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.3337 16H6.66699" stroke="black" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
            Back
          </Button>
          </Col>
        </Row>
        <Content className='custom-container section-padding sec-mh full-container p-0'>
          <Layout style={{
            backgroundColor: 'white',
          }} >
            <Row gutter={[40,40]} className="question-list-row with-padding">
              <Col span={24}>
                {
                  question && 
                  <PostComponent comment={{
                    ...question,
                    userId: question.studentId,
                    userType: 'Student',
                    reply: question.questionDescription,
                    
                  }} showStatus = {true} updateQuestionStatus={(status)=>{
                    console.log('Updating question status:', status);
                    setQuestion({
                      ...question,
                      status: status
                    });
                  }}

                 updateQuestion={
                    () => {
                      //getQuestion(id);
                      fetchQuestion(id);
                    }
                  }
                  
                  />
                }
              </Col>
              {
                comments.length > 0 &&  
                <>
                  {comments.map((comment) => (
                    <Col span={24}>
                      <PostComponent comment={comment} showStatus={false}/>
                    </Col>
                  ))}  
                </>
              }
            </Row>
            <Row gutter={[0,0]} className="question-list-row with-padding pt-0">
            <Col span={24} style={{padding:0}}>
              <div className=""><QAForum ref={qaForumRef} questionId={id} status = {question?.status} isLocked = {disableLockButton(question)} updateQuestion={
                    () => {
                      //getQuestion(id);
                      fetchQuestion(id);
                    }
                  }/></div>
            </Col>
          </Row>
          </Layout>
      
        </Content>

      </Layout>
  );
};

export default CommentList;
