import React from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-hls-quality-selector';
import 'videojs-contrib-quality-levels';
import "videojs-youtube";
import CustomSettingsButton from './CustomSettingsButton';
import { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import Settings from './settings';
import PauseIcon from '../../../assets/images/icons-pause.png';
import { useLocation } from 'react-router-dom';


let currentTime = new Date().getTime();
const PlayerSection = ({ open, sourceURL, sourceKey, onProgress, onEnded, setIntervalID, playerRef, accessToken }) => {
  
  console.log("Open", open);

  const location = useLocation();
  

  const videoRef = useRef(null);
  
  const [playingFirstTime, setPlayingFirstTime] = useState(false);
	let videoUrl = sourceURL;
  const typeOfVideo = sourceURL.includes("youtube") ? "video/youtube" : "application/x-mpegURL";
  const dispatch = useDispatch()
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    preload: 'auto',
    // always show control 
    sources: [
      {
        src: `${videoUrl}?bearerToken=${accessToken}`,
        //src: videoUrl,
        type: typeOfVideo, 
      },
    ],
    html5: {
      vhs: {
        overrideNative: true,
        withCredentials: true,
        // xhrSetup: function(xhr, url) {
        //   xhr.timeout = 10000; // Timeout in milliseconds (e.g., 10 seconds)
        // }
      },
      hls:{
        overrideNative: true,
        withCredentials: true,
        // xhrSetup: function(xhr, url) {
        //   xhr.timeout = 10000; // Timeout in milliseconds (e.g., 10 seconds)
        // }
      }
      // hls: {
      //   // Set the timeout for fetching video chunks
      //   xhrSetup: function(xhr, url) {
      //     xhr.timeout = 10000; // Timeout in milliseconds (e.g., 10 seconds)
      //   }
      // }
    
    },
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    controlBar: {
      children: [
        'playToggle',
        'skipBackward',
        'skipForward',
        'volumePanel',
        'currentTimeDisplay',
        'progressControl',
        'durationDisplay',
        'remainingTimeDisplay',
        'CustomSettingsButton', // Add the custom settings button here
        'fullscreenToggle'
      ],
      skipButtons: {
        forward: 10,
        backward: 10
      },
    },
    "youtube": { ytControls: 0 },
  };

  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    const domainString ="; domain=dev.streamlinevrs.com";
    const sameSite = "; SameSite=None";
    const secure = "; Secure";
    
    document.cookie = name + "=" + (value || "") + expires + "; path=/" + sameSite + secure;
}



  useEffect(()=>{

    if(typeOfVideo === "application/x-mpegURL"){
      console.log("VOD");
      if(accessToken){
        console.log("Access Token", accessToken);
        //Cookies.set('accessToken', accessToken, { path: '/', domain: ".retinalms.com", secure: true, sameSite: 'None' })
        initializePlayer();
        
      }
    }
    else{
      console.log("YOU TUBE");
      initializePlayer();
    }
    
  }, [accessToken, playerRef]);

  videojs['Vhs'].xhr.beforeRequest = function (options) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${accessToken}`;
    //var url = new URL(options.uri);
    //url.searchParams.set('bearerToken', `${accessToken}`);
    // console.log("URL", url.toString());
    // options.uri = url.toString();
    // return options;
  };

  const initializePlayer = () => {
    if(playerRef && playerRef.current){
      console.log("Player Already Initialized")
    //  playerRef.current.src({src: `${videoUrl}?bearerToken=${accessToken}`, type: typeOfVideo});
    }
    else{
      
      const videoElement = document.createElement("video-js");
      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
        videojs.log('player is ready');
      
      });

      player.hlsQualitySelector({
        displayCurrentQuality: true
    });

    
      //player.play();
      handlePlayerReady(player);
    
      
   }
  }

  // useEffect(() => {
  //   const handleKeyPress = (event) => {
  //     if (event.code === 'Space') {
  //       console.log('Space button pressed');
  //       if(playerRef.current.paused()){
  //         playerRef.current.play();
  //       }
  //       else{
  //         playerRef.current.pause();
  //       }
  //       // Add your logic here
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyPress);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyPress);
  //   };
  // }, []);

  const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  const checkIfMenuShowing = () => {
    const element = document.querySelector('.vjs-settings-menu-container');
    if (element) {
      const displayStyle = window.getComputedStyle(element).display;
      return displayStyle === 'block';
    } 
    return false;
  }

  const handlePlayerReady = (player) => {

    // You can handle player events here, for example:
    if(!player)return;

    console.log('Player Ready', player);
    player.on('loadedmetadata', () => {
     console.log('player is loaded metadata');
     player.play();
    });

    const qualityLevels = player.qualityLevels();
    
    qualityLevels.on('addqualitylevel', function(event) {
     
    });

    qualityLevels.on('change', function(event) {
     
    console.log('quality level changed', event);
    });

    

    // Listen for quality level changes and adjust accordingly
   

    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('progress', (e) => {
     
    }
    );

    // player.on('play', () => {
    //   console.log('player is playing', Settings.get('showSettings'));
    //   // if(Settings.get('showSettings')){
    //   //   Settings.set('showSettings', false);
    //   //   player.pause();
    //   // }
    //   document.querySelector('.vjs-settings-menu-container').style.display = 'none';
    //   console.log('play',document.querySelector('.vjs-settings-menu-container'));

    // }

    // );
 

    // player.on('pause', () => {
    //   videojs.log('player is paused', Settings.get('showSettings'));
    //   document.querySelector('.vjs-settings-menu-container').style.display = 'none';
    //   console.log('play',document.querySelector('.vjs-settings-menu-container'));
    //   if(Settings.get('showSettings')){
    //     //Settings.set('showSettings', false);
    //     player.play();
    //     Settings.set('showSettings', false);
    //   }
      
    // }
    // );

    player.on('play', () => {
      document.querySelector('.vjs-big-play-button').style.display = 'none';
      document.querySelector('.vjs-pause-icon').style.display = 'flex';
      document.querySelector('.vjs-forward-icon').style.display = 'block';
      document.querySelector('.vjs-backward-icon').style.display = 'block';
    });

    player.on('pause', () => {
      document.querySelector('.vjs-pause-icon').style.display = 'none';
      document.querySelector('.vjs-forward-icon').style.display = 'none';
      document.querySelector('.vjs-backward-icon').style.display = 'none';
      document.querySelector('.vjs-big-play-button').style.display = 'flex';
    });

    // Listen for user active and inactive events
    player.on('useractive', () => {
      if (!player.paused()) {
        document.querySelector('.vjs-pause-icon').style.display = 'flex'; 
        document.querySelector('.vjs-backward-icon').style.display = 'block';
        document.querySelector('.vjs-forward-icon').style.display = 'block';
      }
    });

    player.on('userinactive', () => {
      document.querySelector('.vjs-pause-icon').style.display = 'none';
      document.querySelector('.vjs-backward-icon').style.display = 'none';
      document.querySelector('.vjs-forward-icon').style.display = 'none';
    });


    player.on('ended', () => {
      videojs.log('player is ended');
      
    }
    );

    player.on('error', (error) => {
      videojs.log('player is error', error);
    }

    );

    const pauseIcon = document.querySelector('.vjs-pause-icon');
    if (pauseIcon) {
      pauseIcon.addEventListener('click', () => {
        if (!player.paused()) {
          player.pause();
        }
      });
    }

    player.on('dispose', () => {
      videojs.log('player will dispose');
      //remove all event listeners
      player.off('loadedmetadata');
      player.off('waiting');
      player.off('progress');
      player.off('play');
      player.off('pause');
      player.off('ended');
      player.off('error');
      player.off('dispose');
      console.log('player disposed', player.isDisposed);
      playerRef.current = null;
      player = null;


    });

    // document.addEventListener('keydown', (event) => {

    //   if(!player) return;
    //   if(player && player.isDisposed())return;
    //   if (event.code === 'Space') {
    //     if (player.paused()) {
    //       player.play();
    //     } else {
    //       player.pause();
    //     }
    //   }
    // });

    // // Add touch event listeners for single tap play/pause and double tap forward/backward
    // let lastTapTime = 0;
    // let tapTimeout = null;

    // const handleSingleTap = () => {
    //   if (player.paused()) {
    //     player.play();
    //   } else {
    //     player.pause();
    //   }
    // };

    // const handleDoubleTap = (event) => {
    //   const touchX = event.changedTouches[0].clientX;
    //   const videoWidth = player.el().offsetWidth;
    //   const thirdOfScreen = videoWidth / 3;

    //   if (touchX < thirdOfScreen) {
    //     player.currentTime(player.currentTime() - 10); // Backward 10 seconds
    //   } else if (touchX > 2 * thirdOfScreen) {
    //     player.currentTime(player.currentTime() + 10); // Forward 10 seconds
    //   }
    // };

    // const tapHandler = (event) => {
    //   const currentTime = new Date().getTime();
    //   const tapInterval = currentTime - lastTapTime;

    //   clearTimeout(tapTimeout);

    //   if (tapInterval < 300 && tapInterval > 0) {
    //     handleDoubleTap(event);
    //   } else {
    //     tapTimeout = setTimeout(() => {
    //       // Only handle single tap if the target is not a control element or settings menu
    //       const target = event.target;
    //       console.log('single tap', Settings.get('showSettings'));
    //       if (!target.closest('.vjs-control') && !target.closest('.vjs-settings-menu') && !target.closest('.vjs-settings-menu-item')) {
            
    //         if(!Settings.get('showSettings'))
    //           //Settings.set('showSettings', false);
    //           handleSingleTap();
    //           Settings.set('showSettings', false);
    //       }
    //     }, 300);
    //   }

    //   lastTapTime = currentTime;
    // };

    // // If on a mobile device, disable vjs-big-play-button functionality
    // if (isMobileDevice()) {
    //   const bigPlayButton = player.getChild('bigPlayButton');
    //   if (bigPlayButton) {
    //     bigPlayButton.off('click');
    //     bigPlayButton.off('touchend');
    //   }
    // }

    // player.el().addEventListener('touchend', tapHandler);

    // // Prevent clicks on the settings menu from propagating
    // document.querySelector('.vjs-settings-menu-container').addEventListener('click', (event) => {
    //   event.stopPropagation();
    // });


    document.addEventListener('keydown', (event) => {
      if (event.code === 'Space') {
        if (player.paused()) {
          player.play();
        } else {
          player.pause();
        }
      }
    });

    // Prevent clicks on the settings menu from propagating
    document.querySelector('.vjs-settings-menu-container').addEventListener('click', (event) => {
      event.stopPropagation();
    });
    document.querySelector('.vjs-forward-icon').addEventListener('click', () => {
      console.log('double clicked',currentTime);
      if(new Date().getTime() - currentTime < 300){
        player.currentTime(player.currentTime() + 10);
      }
      currentTime = new Date().getTime();
    });
    
    document.querySelector('.vjs-backward-icon').addEventListener('click', () => {
      console.log('double clicked',currentTime);
      if(new Date().getTime() - currentTime < 300){
        player.currentTime(player.currentTime() - 10);
      }
      currentTime = new Date().getTime();
    });

  };

  return (
    <div className="player-section">
     <div ref={videoRef} />
     <div className="vjs-pause-icon" id="pause-icon">
        <img src={PauseIcon} alt="Pause Icon" />
      </div>
      <div className="vjs-backward-icon" id="vjs-backward-icon"></div>
      <div className="vjs-forward-icon" id="vjs-forward-icon"></div>
    </div>
  );
};

export default PlayerSection;
