import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Upload, Divider, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bucket_url } from '../../constants/constString';

import {
	updateContentById,
	patchFileRequest
} from '../../stateManager/reducers/contentSlice';
import { instance } from '../../constants/constString';

const { Dragger } = Upload;

const EditVODMOdal = props => {
	const {
		chapters,
		courses,
		lectures,
		questionSolves,
		subjects,
		isVisible,
		contentData,
	} = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [file, setFile] = useState(null);
	const [title, setTitle] = useState(contentData?.title);
    const [uploadPercenatge, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    
    const [thumbnail, setThumbnail] = useState(null);

    
    useEffect(() => {
        form.setFieldsValue({
            title: contentData?.title,
        });

        console.log('contentData', contentData);
        setThumbnail(contentData?.thumbnail);
    }, [contentData]);
	const status = useSelector(state => state.contents.status);

    //upload interval

   


	const handleSubmit = async () => {
	
        setIsUploading(true);
        setUploadPercentage(25);
		try {
            if(thumbnail instanceof File){
            const res = await instance.get(
                `/content/signed-request?mimeType=${thumbnail.type}`
            );
            const { signedUrl, key } = res?.data?.data;
            console.log('thumbnail', thumbnail);
            const response = await patchFileRequest({ signedUrl, file: thumbnail });
            
            if (response.status === 200) {
                const data = {
                    title,
                    thumbnail: key,
                    key: contentData?.key,
                };
                try {
                    const res1 = await dispatch(updateContentById({ contentId: contentData?._id, data }));
                } catch (err) {
                    console.log(err);
                }
                setUploadPercentage(100);
                setIsUploading(false);
                props.toggleModal();
            }

            
        }
        else{
            const data = {
                title,
                thumbnail: thumbnail,
                key: contentData?.key,
          
            };
            try {
                const res1 = await dispatch(updateContentById({ contentId: contentData?._id, data }));
            } catch (err) {
                console.log(err);
            }
            setUploadPercentage(100);
            setIsUploading(false);
            props.toggleModal();
        }

        setUploadPercentage(70);
          
        } catch (error) {
            console.log(error);
            setIsUploading(false);
           
        }
	};


	return (
		<Modal
			maskClosable={false}
			visible={isVisible}
			centered
			destroyOnClose={true}
			okText={'Edit Video'}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => handleSubmit()}
			onCancel={() => props.toggleModal()}
			title={<strong>Edit Video</strong>}
		>
			<Form form={form} layout='vertical' preserve={false}>
				<Form.Item
					label={'Video Title'}
					rules={[{ required: true, message: 'Please enter video title' }]}
					name='title'
				>
					<Input
						placeholder='Enter video title'
						onChange={e => setTitle(e.target.value)}
					/>
				</Form.Item>
			</Form>

            <div>
            <Divider />
			<h2>Thumbnail</h2>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            
            }}>
                 <input type="file" accept="image/*" onChange={(e)=>{
                    setThumbnail(e.target.files[0]);
                    console.log(e.target.files[0]);
                 }} />
                {thumbnail && (
                   
                    <img src={thumbnail instanceof File ? URL.createObjectURL(thumbnail) : bucket_url+thumbnail} alt="Preview" style={{ maxWidth: '25%' }} />
                   
                )}
            </div>
               
                </div>
			
			{/* {(
                <div>
                    <Divider />
                    <video style={{
                    marginTop: 20,
                }} src={cloudUrl + contentData?.URL}  width="100%" controls></video>
                </div>
				
			)} */}

		</Modal>
	);
};

export default EditVODMOdal;
