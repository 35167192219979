import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import AddLectureModal from './AddLectureModal';
import LectureTable from './LectureTable';
import Spinner from '../Common/Spinner';
import {
	getLectureBySubject,
	status
} from '../../stateManager/reducers/courseSlice';

const { Content } = Layout;

const LectureList = props => {
	const { subjectId } = props.match.params;
	const dispatch = useDispatch();
	const lectureList = useSelector(state => state.courses.lectureList);
	const selectedCourse = JSON.parse(localStorage.getItem('selectedCourse'));

	const [isAddModalVisible, setAddModalVisible] = useState(false);

	const toggleAddModal = () => setAddModalVisible(!isAddModalVisible);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getLectureBySubject({ subjectId }));
		}
		fetchData();
	}, [subjectId, dispatch]);

	if (status === 'loading') {
		return <Spinner />;
	}

	return (
		<React.Fragment>
			<AddLectureModal
				isVisible={isAddModalVisible}
				toggleModal={toggleAddModal.bind(this)}
				type='lecture'
				subjectId={subjectId}
			/>

			<SubHeader
				headText='Lecture'
				subTitle={selectedCourse?.name}
				backIcon={true}
			/>

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>Add your courses, subjects and chapters all in one panel</p>
					<Button
						onClick={() => setAddModalVisible(!isAddModalVisible)}
						type='primary'
						size='middle'
					>
						Add
					</Button>
				</div>

				<LectureTable subjectId={subjectId} data={lectureList} type='lecture' />
			</Content>
		</React.Fragment>
	);
};

export default LectureList;
