import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import GroupList from '../../Group/GroupList';
import SingleGroup from '../../Group/SingleGroup';
import ExamHome from '../../Exam/index';
import CourseWiseExamList from '../../Exam/CourseWiseExamList';
import AddExam from '../../Exam/AddExam';
import ExamDetails from '../../pages/admin/ExamDetails/ExamDetails.page';

const ExamModeratorNavigation = props => {
	return (
		<React.Fragment>
			<Redirect exact from='/' to='/groups' />
			<Route path='/groups/:courseId/:groupId' component={SingleGroup} />
			<Route exact path='/groups' component={GroupList} />
			<Route path='/exam/:courseId' component={CourseWiseExamList} />
			<Route exact path='/exam' component={ExamHome} />
			<Route path='/add-exam/:courseId/:subjectId' component={AddExam} />
			<Route exact path='/exam-details/:examId' component={ExamDetails} />
		</React.Fragment>
	);
};

export default ExamModeratorNavigation;
