import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';

import {
	getVerificationOtp,
	setData
} from '../../stateManager/reducers/adminAuthSlice';
import { isPhoneNoValid } from '../../constants/constFunction';

const FormItem = Form.Item;

const LoginForm = props => {
	const [form] = Form.useForm();
	const [isLoading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleGetVerifyOtp = async values => {
		if (!values || !values.username || !values.password) {
			toastr.error('Please give username and password!');
			return;
		}
		setLoading(true);

		const data = {
			handle: '88' + values?.username,
			password: values?.password,
			viaEmail: values?.viaEmail
		};
		dispatch(setData({ key: 'password', value: values.password }));
		const res = await dispatch(getVerificationOtp({ data }));
		if (res?.payload?.status === '200') {
			history.push(
				{ pathname: '/verify-login' },
				{ viaEmail: values?.viaEmail }
			);
		}
		setLoading(false);
	};

	return (
		<Form className='login-form' form={form} onFinish={handleGetVerifyOtp}>
			<FormItem
				name='username'
				// style={{ padding: 1 }}
				rules={[
					{ required: true, message: 'Please input your phone no.' },
					() => ({
						validator(rule, value) {
							// let phone = new RegExp(/^01\d{9}$/);
							if (!value || isPhoneNoValid(value)) {
								return Promise.resolve();
							}
							return Promise.reject('Uername not valid');
						}
					})
				]}
			>
				<Input
					type='text'
					size='large'
					prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
					placeholder='Phone number'
				/>
			</FormItem>
			<FormItem
				name='password'
				rules={[{ required: true, message: 'Please input your password.' }]}
			>
				<Input.Password
					size='large'
					style={{ margin: '1rem 0rem' }}
					prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
					placeholder='Password'
				/>
			</FormItem>
			<Row style={{ marginBottom: '20px', marginTop: '20px' }}>
				<Col xs={24} md={14}>
					<Form.Item
						name='viaEmail'
						initialValue={false}
						valuePropName='checked'
						noStyle
					>
						<Checkbox className='form-item'>Send otp to email</Checkbox>
					</Form.Item>
				</Col>
				{/* <Col xs={24} md={10}>
					<a
						style={{
							width: '100%',
							textAlign: 'right',
							display: 'inline-block'
						}}
						className='forgotpass'
						href='/forget-password'
					>
						Forgot password
					</a>
				</Col> */}
			</Row>
			<Form.Item noStyle>
				<Button
					loading={isLoading}
					// onClick={() => handleLogin()}
					style={{ margin: '0.5rem 0rem' }}
					type='primary'
					htmlType='submit'
					className='login-form-button'
				>
					Log in
				</Button>
			</Form.Item>
		</Form>
	);
};

export default LoginForm;
