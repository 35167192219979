import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SubHeader from '../Common/SubHeader';
import StudentList from '../StudetsManagement/StudentsList';
import StudentsByGroupTable from './StudntesByGroupTable';
import ChapterAndLecturePermission from './ChapterAndLecture';
import GroupExamList from './GroupExamList';
import AssignSubject from './AssignSubject';
import { getCourseSubjects } from '../../stateManager/reducers/mentorSlice';

import {
	getStudentsByGroup,
	setSelectedGroup,
	filteredStudentList,
	resetFilters
} from '../../stateManager/reducers/groupSlice';
import { getBranch } from '../../stateManager/reducers/studentManagementSlice';
import { roles } from '../../constants/constString';
import { isAdminOrModerator } from '../../constants/constFunction';

const { TabPane } = Tabs;

const SingleGroup = props => {
	const { groupId, courseId } = props.match.params;
	const history = useHistory();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem('user'));

	const group =
		useSelector(state =>
			state.groups.groupList.find(item => item._id === groupId)
		) || JSON.parse(localStorage.getItem('selectedGroup'));

	const studentsListByGroup = useSelector(state => filteredStudentList(state));
	console.log("studentsListByGroup", studentsListByGroup);
	

	useEffect(() => {
		async function fetchData() {
			if (isAdminOrModerator()) {
				await dispatch(getBranch());
				await dispatch(getStudentsByGroup({ groupId }));
				await dispatch(getCourseSubjects());
				
				dispatch(resetFilters());
			}
			if (history.action !== 'POP') {
				dispatch(setSelectedGroup(group));
			}
			localStorage.setItem('groupId', groupId);
		}
		fetchData();
	}, [groupId]);

	return (
		<React.Fragment>
			<SubHeader headText={group.name} />

			<Tabs
				type='card'
				style={{ paddingLeft: 15, paddingRight: 15, background: 'white' }}
				defaultActiveKey={history.action === 'PUSH' ? '1' : '3'}
			>
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR)) > -1 && (
					<TabPane tab='Students' key='1'>
						<Tabs style={{ paddingLeft: 15 }}>
							<TabPane tab='Students List' key='1'>
								<StudentsByGroupTable
									groupId={groupId}
									data={studentsListByGroup}
								/>
							</TabPane>
							<TabPane tab='Add Students' key='2'>
								<StudentList
									groupId={groupId}
									courseId={courseId}
									isGroup={true}
								/>
							</TabPane>
						</Tabs>
					</TabPane>
				)}
				{(user.roles.indexOf(roles.ADMIN) > -1 ||
					user.roles.indexOf(roles.MODERATOR)) > -1 && (
					<TabPane tab='Contents' key='2'>
						<Tabs style={{ paddingLeft: 15 }}>
							<TabPane tab='Chapter' key='1'>
								<ChapterAndLecturePermission
									type='chapter'
									courseId={courseId}
									groupId={groupId}
								/>
							</TabPane>
							<TabPane tab='Lecture' key='2'>
								<ChapterAndLecturePermission
									type='lecture'
									courseId={courseId}
									groupId={groupId}
								/>
							</TabPane>
							<TabPane tab='Question Solve' key='3'>
								<ChapterAndLecturePermission
									type='questionSolve'
									courseId={courseId}
									groupId={groupId}
								/>
							</TabPane>
						</Tabs>
					</TabPane>
				)}
				<TabPane tab='Exams' key='3'>
					<Tabs style={{ paddingLeft: 15 }}>
						<TabPane tab='Live' key='1'>
							<GroupExamList
								courseId={courseId}
								groupId={groupId}
								isPracticeExam={false}
							/>
						</TabPane>
						<TabPane tab='Practice' key='2'>
							<GroupExamList
								courseId={courseId}
								groupId={groupId}
								isPracticeExam={true}
							/>
						</TabPane>
					</Tabs>
				</TabPane>
				<TabPane tab='Q&A' key='4'>
					<AssignSubject courseId={courseId} groupId={groupId}/>
				</TabPane>
			</Tabs>
		</React.Fragment>
	);
};

export default SingleGroup;
