import React, { useRef, useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Modal, Progress } from 'antd'
import DrawingCanvas from './Sketch/DrawingCanvas';

const EvaluateImageModal = ({ visible, toggleModal, image, imageKey, readLocalData }) => {

    const { progress } = useSelector(state => state.groups)

    const [img, setImg] = useState("")
    const childRef = useRef(null);

    console.log("EvaluateImageModal image", image, imageKey);

    const submitEvaluateCopy = () => {
        childRef.current.saveImageToLocal();
    };

    // console.log("props.visible", visible);
    const toDataURL = (url, callback) => {
        let xhr = new XMLHttpRequest();
        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', `${url}?`);
        xhr.responseType = 'blob';
        xhr.send();
    }


    useEffect(() => {
        // toDataURL(image, (f) => {
        //     setImg(f);
        // })
    }, []);



    return (
        <>
            <Modal
                wrapClassName="evaluate-image-modal-wrap"
                className='evaluate-image-modal'
                title="Evaluate Image"
                visible={visible}
                onCancel={() => toggleModal("")}
                width={window.innerWidth}
                centered
                onOk={submitEvaluateCopy}

            >
                {progress > 0 && <Progress percent={progress} />}
                <DrawingCanvas imageSrc={image} imageKey={imageKey} ref={childRef} toggleModal={toggleModal} readLocalData={readLocalData} />

            </Modal>
        </>
    )
}

export default EvaluateImageModal