import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import AddLectureModal from './AddLectureModal';
import LectureTable from './LectureTable';
import { getChapterBySubject } from '../../stateManager/reducers/courseSlice';

const { Content } = Layout;

const ChapterList = props => {
	const { subjectId } = props.match.params;
	const dispatch = useDispatch();
	const chapterList = useSelector(state => state.courses.chapterList);

	const [isAddModalVisible, setAddModalVisible] = useState(false);

	const toggleAddModal = () => setAddModalVisible(!isAddModalVisible);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getChapterBySubject({ subjectId }));
		}
		fetchData();
	}, [subjectId, dispatch]);

	return (
		<React.Fragment>
			<AddLectureModal
				isVisible={isAddModalVisible}
				toggleModal={toggleAddModal.bind(this)}
				type='chapter'
				subjectId={subjectId}
			/>

			<SubHeader headText='Chapter' backIcon={true} />

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>Add your courses, subjects and chapters all in one panel</p>
					<Button
						onClick={() => setAddModalVisible(!isAddModalVisible)}
						type='primary'
						size='middle'
					>
						Add
					</Button>
				</div>

				<LectureTable subjectId={subjectId} data={chapterList} type='chapter' />
			</Content>
		</React.Fragment>
	);
};

export default ChapterList;
