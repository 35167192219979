import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toastr from "toastr";
import axios from "axios";
import arrayMove from "array-move";
import { instance } from "../../constants/constString";

const initialState = {
  videoList: [],
  fileList: [],
  contentName: undefined,
  status: "idle",
  uploading: 0,
  error: null,
};

export const getVideo = createAsyncThunk(
  "content/getVideo",
  async ({ lectureId, courseId, title, subjectId, chapterId }) => {
    try {
      const response = await instance.get(
        `/content/search?${courseId ? "courseId=" + courseId : ""}${
          subjectId ? "&subjectId=" + subjectId : ""
        }${chapterId ? "&chapterId=" + chapterId : ""}${
          lectureId ? "&lectureId=" + lectureId : ""
        }${title ? "&title=" + title : ""}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
      return Promise.reject(error);
    }
  }
);

export const deleteContentById = createAsyncThunk(
  "content/deleteContent",
  async ({ contentId }) => {
    try {
      const response = await instance.delete(`/content/delete/${contentId}`);
      toastr.success("Content deleted successfully");
      return response.data;
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
    }
  }
);

export const getAccessToken = createAsyncThunk(
  "student/getAccessToken",
  async ({ key }) => {
    try {
      const response = await instance.post(`lecture/getToken`, { key });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getDownloadLink = async ({ key }) => {
  try {
    const response = await instance.post(`content/download-link`, { key });

    console.log("response", response);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const publishContentById = createAsyncThunk(
  "content/publishContent",
  async ({ contentId, data }) => {
    try {
      const response = await instance.patch(
        `/content/publish/${contentId}`,
        data
      );
      toastr.success("Content updated successfully");
      return response.data;
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
    }
  }
);

export const updateContentById = createAsyncThunk(
  "content/updateContent",
  async ({ contentId, data }) => {
    try {
      const response = await instance.patch(
        `/content/update/${contentId}`,
        data
      );
      toastr.success("Content updated successfully");
      return response.data;
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
    }
  }
);

export const addVideo = createAsyncThunk("content/addVideo", async (data) => {
  try {
    const response = await instance.post("/content", data);
    toastr.success(
      `${data?.type === "video" ? "Video" : "File"} added successfully`
    );
    return response.data;
  } catch (error) {
    toastr.error(error?.response?.data?.errors?.title);
    return Promise.reject(error);
  }
});

export const getChapterLectureById = createAsyncThunk(
  "content/getLectureById",
  async ({ type, typeId }) => {
    try {
      const response = await instance.get(`${type}/${typeId}`);
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
);

export const reorderContent = createAsyncThunk(
  "content/reorderContent",
  async ({ type, typeId, data }) => {
    try {
      const response = await instance.patch(
        `/${type}/${typeId}/content-order`,
        data
      );
    } catch (error) {
      Promise.reject(error);
    }
  }
);

export const signedUrl = createAsyncThunk(
  "question/signedUrl",
  async (fileType) => {
    try {
      const response = await instance.get(
        `/content/signed-request?mimeType=${fileType}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const patchFileRequest = async ({ signedUrl, file }) => {
  try {
    const response = await axios({
      url: signedUrl,
      method: "PUT",
      data: file,
      headers: {
        "Content-Type": `${file.type}`,
        // Authorization: sessionId
      },
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
export const patchFileRequest2 = createAsyncThunk(
  "qna/fileUpload",
  async ({ signedUrl, file }) => {
    try {
      const response = await axios({
        url: signedUrl,
        method: "PUT",
        data: file,
        headers: {
          "Content-Type": `${file.type}`,
          // Authorization: sessionId
        },
      });
      console.log("response", response);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "contents",
  initialState: initialState,
  reducers: {
    getContent: (state) => {
      return state.videoList;
    },
    setContentName: (state, action) => {
      state.contentName = action.payload;
    },
    setReorderData: (state, action) => {
      const { oldIndex, newIndex, dataSource, contentName } = action.payload;
      if (oldIndex !== newIndex) {
        const newData = arrayMove(
          [].concat(dataSource),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        state.videoList = contentName === "video" ? newData : state.videoList;
        state.fileList = contentName === "file" ? newData : state.fileList;
      }
    },
  },
  extraReducers: {
    [addVideo.pending]: (state, action) => {
      state.status = "loading";
    },
    [addVideo.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { content } = action.payload?.data;
      state.videoList = state.videoList.concat(
        !!content && content.type === "video" ? content : []
      );
      state.fileList = state.fileList.concat(
        !!content && content.type === "file" ? content : []
      );
    },
    [addVideo.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getChapterLectureById.pending]: (state, action) => {
      state.status = "loading";
    },
    [getChapterLectureById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.videoList = action.payload.data?.videoContents || [];
      state.fileList = action.payload.data?.fileContents || [];
    },
    [getChapterLectureById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [reorderContent.pending]: (state, action) => {
      state.status = "loading";
    },
    [reorderContent.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [reorderContent.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateContentById.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateContentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload?.data?.type === "video") {
        state.videoList = state.videoList.map((item) =>
          item._id === action.payload?.data?._id ? action?.payload?.data : item
        );
      } else {
        state.fileList = state.fileList.map((item) =>
          item._id === action.payload?.data?._id ? action?.payload?.data : item
        );
      }
    },
    [publishContentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload?.data?.type === "video") {
        state.videoList = state.videoList.map((item) =>
          item._id === action.payload?.data?._id ? action?.payload?.data : item
        );
      }
    },
    [updateContentById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteContentById.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteContentById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      if (action.payload?.data?.type === "video") {
        const temp = [...state.videoList];
        state.videoList = temp.filter((item) => {
          if (item._id !== action.payload?.data?._id) {
            return item;
          }
        });
      } else {
        const temp = [...state.fileList];
        state.fileList = temp.filter((item) => {
          if (item._id !== action.payload?.data?._id) {
            return item;
          }
        });
      }
    },
    [deleteContentById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { setContentName, setReorderData } = slice.actions;

const contentName = (state) => state.contents.contentName;

export { contentName };

export default slice.reducer;
