import React, { useEffect } from 'react';
import { Form, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	editChapterLecture,
	updateChapterLecture
} from '../../stateManager/reducers/courseSlice';

const EditChapterLectureModal = props => {
	const { isVisible, subjectId, type, chapterLectureData } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const status = useSelector(state => state.courses.status);

	useEffect(() => {
		form.setFieldsValue({ name: chapterLectureData?.name });
	}, [chapterLectureData]);

	const handleSubmit = async values => {
		const { name } = values;
		try {
			const res = await dispatch(
				editChapterLecture({
					subjectId,
					type,
					typeId: chapterLectureData._id,
					name
				})
			);
			dispatch(
				updateChapterLecture({ type, id: chapterLectureData?._id, name })
			);
		} catch (error) {
			console.log(error);
		}

		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => {
				props.toggleModal();
				Modal.destroyAll();
			}}
			okText='Submit'
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			title={
				<strong>
					{type === 'lecture'
						? 'Edit Lecture'
						: type === 'chapter'
						? 'Edit Chapter'
						: 'Edit Question'}{' '}
				</strong>
			}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='name'
					label={
						<strong>
							{type === 'lecture'
								? 'Lecture Name'
								: type === 'chapter'
								? 'Chapter Title'
								: 'Question Name'}
						</strong>
					}
				>
					<Input
						placeholder={
							type === 'lecture'
								? 'Enter Lecture Name'
								: type === 'chapter'
								? 'Enter Chapter Title'
								: 'Enter Question Name'
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditChapterLectureModal;
