import React from 'react';
import { Card, Image } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';

import { bucket_url } from '../../constants/constString';
import DummyImage from '../../assets/images/dummy-image.png';

const { Meta } = Card;

const GroupCard = props => {
	const { data, selectedCourse } = props;
	const match = useRouteMatch();

	return (
		<Link to={`${match.url}/${data.courseId}/${data._id}`}>
			<Card
				onClick={() =>
					localStorage.setItem('selectedGroup', JSON.stringify(data))
				}
				className='admin-group-card'
				style={{ height: '100%' }}
				cover={
					!!data && (
						<Image
							height='250px'
							alt='image'
							placeholder
							fallback={DummyImage}
							src={
								bucket_url + data?.image ||
								'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
							}
						/>
					)
				}
			>
				<Meta
					title={<strong>{data?.name || 'Title'}</strong>}
					description={`Total Students ${data?.totalStudents}`}
				/>
			</Card>
		</Link>
	);
};

export default GroupCard;
