import React, { useEffect, useState } from 'react';
import { Modal, Form, DatePicker, Input, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
	addExamToGroup,
	updateExamFromGroup
} from '../../stateManager/reducers/groupSlice';
import { examType, examStatus } from '../../constants/constString';

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 }
};

const PublishExamModal = props => {
	const {
		isVisible,
		examId,
		groupId,
		isPublished,
		examData,
		isPracticeExam,
		isAssessable
	} = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [showMultiplier, setShowMultiplier] = useState(false);
	const [isCutMarks, setIsCutMarks] = useState(false);
	const [isMultipleTimesSubmission, setIsMultipleTimesSubmission] = useState(true);
	const [isLoading, setLoading] = useState(false);
	const [cutMarks, setCutMarks] = useState(5);
	const [isInitialized, setIsInitialized] = useState(false);

	useEffect(() => {

		if (isPublished && !isInitialized) {
			form.setFieldsValue({
				addGPA: isPublished?.addGPA,
				startsAt: moment(isPublished?.startsAt),
				endsAt: moment(isPublished?.endsAt),
				multipleTimesSubmission: isPublished?.multipleTimesSubmission,
				status: isPublished?.status,
				duration: isPublished?.duration,
				sscMultiplier: isPublished?.ssc,
				hscMultiplier: isPublished?.hsc,
				cutMarks: isPublished?.cutMarks,
				isCutMarks: isPublished?.isCutMarks ?? false
				
			});

			setShowMultiplier(isPublished?.addGPA ?? false);
			setIsCutMarks(isPublished?.isCutMarks ?? false);
			setIsInitialized(true);
			console.log('isPublished', isPublished);
		}
		
		// eslint-disable-next-line
	}, [isPublished]);

	

	// useEffect(() => {
	// 	if(!isVisible) {
	// 		form.resetFields();
	// 		setShowMultiplier(false);
	// 		setIsCutMarks(false);
	// 	}
		
	// }, [isVisible]);

	const handleSubmit = async values => {
		setLoading(true);
		const startsAt = values?.startsAt?.seconds(0);
		const endsAt = values?.endsAt?.seconds(0);

		if (startsAt && endsAt) {
			let start = ((startsAt.hour()) * 60) + startsAt.minute()
			let end = ((endsAt.hour()) * 60) + endsAt.minute()

			let totalMinutes = ((endsAt.date() - startsAt.date()) * 24 * 60) + end - start

			if (values.duration > totalMinutes) {
				alert('duration must less than or equal to exam time')
				setLoading(false);
				return
			}
		}

		const data = {
			examId: !!isPublished ? undefined : examId,
			duration: parseInt(values.duration),
			addGPA: values.addGPA === undefined ? false : values.addGPA,
			// groupId,
			startsAt: values.startsAt.toISOString(),
			hsc: values.addGPA?parseInt(values.hscMultiplier):0,
			ssc: values.addGPA?parseInt(values.sscMultiplier):0,
			isCutMarks: isCutMarks,
			cutMarks: isCutMarks?parseInt(cutMarks):0,
			publishedAt:
				isPublished?.status === 'resultPublished'
					? isPublished?.publishedAt || Date.now()
					: isPublished?.publishedAt || 0,
			multipleTimesSubmission:
				values.multipleTimesSubmission === undefined
					? true
					: values?.multipleTimesSubmission,
			type: isPracticeExam ? examType.PRACTICE : examType.LIVE,
			endsAt: !isPracticeExam ? endsAt : undefined
		};

		try {
			if (isPublished) {
				const res = await dispatch(
					updateExamFromGroup({
						groupId,
						examId,
						data: {
							...data,
							status: values.status ? 'published' : 'unpublished'
						}
					})
				);
				setLoading(false);
			} else {
				const res = await dispatch(
					addExamToGroup({
						groupId,
						data,
						sscMultiplier: values?.sscMultiplier,
						hscMultiplier: values?.hscMultiplier
					})
				);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log(error.response);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			maskClosable={false}
			destroyOnClose
			okText={isAssessable ? 'Update Exam' : 'Publish Exam'}
			onOk={() => form.submit()}
			okButtonProps={{ loading: isLoading }}
			onCancel={() => props.toggleModal()}
			title={<strong>{isAssessable ? 'Update Exam' : 'Publish Exam'}</strong>}
		>
			<Form {...layout} layout='horizontal' form={form} onFinish={handleSubmit}>
				<Form.Item
					name='startsAt'
					label='Start Date'
					rules={[
						{
							required: isPublished ? false : true,
							message: 'Please give a start date and time'
						},
						() => ({
							validator(rule, value) {
								if (!value || value > new Date() || isPublished) {
									return Promise.resolve();
								}
								return Promise.reject('Please give a valid date and time');
							}
						})
					]}
				>
					<DatePicker
						disabled={isAssessable}
						style={{ width: '100%' }}
						format='YYYY-MM-DD hh:mm A'
						showTime
					/>
				</Form.Item>
				{!isPracticeExam && (
					<Form.Item
						name='endsAt'
						label='End Date'
						rules={[
							{ required: true, message: 'Please give a end date and time' },
							({ getFieldValue }) => ({
								validator(rule, value) {
									if (!value || value > getFieldValue('startsAt')) {
										return Promise.resolve();
									}
									return Promise.reject('Please give a valid date and time');
								}
							})
						]}
					>
						<DatePicker
							disabled={isAssessable}
							style={{ width: '100%' }}
							format='YYYY-MM-DD hh:mm A'
							showTime
						/>
					</Form.Item>
				)}
				<Form.Item
					label='Exam Duration'
					name='duration'
					rules={[
						{ required: true, message: 'Please enter the exam duration' }
					]}
				>
					<Input
						disabled={isAssessable}
						type='number'
						placeholder='Enter exam duration in minutes'
					/>
				</Form.Item>
				<Form.Item name='addGPA' label='Add GPA Marks'>
					<Switch
						onChange={checked => setShowMultiplier(checked)}
						disabled={isAssessable}
						unCheckedChildren='OFF'
						checkedChildren='ON'
						defaultChecked={isPublished?.addGPA}
					/>
				</Form.Item>
				<Form.Item name='isCutMarks' label='Add Cut Marks'>
					<Switch
						onChange={checked => setIsCutMarks(checked)}
						disabled={isAssessable}
						unCheckedChildren='OFF'
						checkedChildren='ON'
						defaultChecked={isPublished?.isCutMarks}
					/>
				</Form.Item>
				{isCutMarks && (
					<Form.Item label='Cut Marks' name='cutMarks'>
						<Input type='number' defaultValue={5} onChange={(e)=>{
							setCutMarks(e.target.value)
						}}/>
					</Form.Item>
				)}
				{showMultiplier && (
					<Form.Item label='SSC Multiplier' name='sscMultiplier'>
						<Input type='number' />
					</Form.Item>
				)}
				{showMultiplier && (
					<Form.Item label='HSC Multiplier' name='hscMultiplier'>
						<Input type='number' />
					</Form.Item>
				)}
				<Form.Item
					name='multipleTimesSubmission'
					label='Multiple Time Submission'
				>
					<Switch
						disabled={isAssessable}
						unCheckedChildren='False'
						checkedChildren='True'
						defaultChecked={isPublished?.multipleTimesSubmission}
						
						
					/>
				</Form.Item>
				{isPublished && (
					<Form.Item name='status' label='Change Status'>
						<Switch
							unCheckedChildren='Unpublished'
							checkedChildren='Publised'
							defaultChecked={isPublished?.status !== examStatus.UNPUBLISHED}
						/>
					</Form.Item>
				)}
			</Form>
		</Modal>
	);
};

export default PublishExamModal;
