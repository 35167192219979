import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { InboxOutlined } from '@ant-design/icons';
import toastr from 'toastr';

import {
	updateContentById,
	patchFileRequest
} from '../../stateManager/reducers/contentSlice';
import { instance } from '../../constants/constString';

const { Dragger } = Upload;

const EditContentModal = props => {
	const { isVisible, type, contentData } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const status = useSelector(state => state.contents.status);
	const [videoUrl, setVideoUrl] = useState(contentData?.URL);
	const [videoID, setVideoID] = useState(contentData?.key);
	const [file, setFile] = useState(undefined);
	console.log('contentData', contentData);

	useEffect(() => {
		form.setFieldsValue({
			title: contentData?.title,
			videoID: contentData?.key,
			videoUrl: contentData?.URL
		});
		setVideoID(contentData?.key);
		setVideoUrl(contentData?.URL);
	}, [contentData, form]);

	const handleSubmit = async values => {
		const { title } = values;
		if (type !== 'file' && !videoID && !videoUrl) {
			toastr.error('Please add videor url or id');
			return;
		}
		if (!title) {
			toastr.error('Please add title for the video/file');
			return;
		}
		if (type === 'file' && !file && !contentData?.key) {
			toastr.error('Please add a file');
			return;
		}

		if (type === 'file') {
			try {
				let response;
				let key;
				let signedUrl;
				if (!!file) {
					const res = await instance.get(
						`/content/signed-request?mimeType=${file.type}`
					);
					signedUrl = res?.data?.data?.signedUrl;
					key = res?.data?.data?.key;
					response = await patchFileRequest({ signedUrl, file });
				}
				if (!file || response.status === 200) {
					const data = {
						title,
						key: key || contentData?.key
					};
					try {
						const res1 = await dispatch(
							updateContentById({ contentId: contentData?._id, data })
						);
					} catch (err) {
						console.log(err);
					}
					props.toggleModal();
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			const data = {
				title,
				key: videoID || contentData?.key,
				URL: videoUrl || contentData?.URL
			};
			try {
				const res = await dispatch(
					updateContentById({ contentId: contentData._id, data })
				);
			} catch (error) {
				console.log(error);
			}
			props.toggleModal();
		}
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => {
				props.toggleModal();
				Modal.destroyAll();
			}}
			okText='Submit'
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			title={<strong>{`Edit ${type}`} </strong>}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='title'
					label={
						<strong>{type === 'video' ? 'Vidoe Title' : 'File Title'}</strong>
					}
				>
					<Input
						placeholder={
							type === 'video' ? 'Enter Video Title' : 'Enter File Title'
						}
					/>
				</Form.Item>
				{type !== 'file' && (
					<Form.Item label='Add Youtube Video URL' name='videoUrl'>
						<Input
							value={videoUrl}
							onChange={e => {
								setVideoUrl(e.target.value);
								setVideoID(undefined);
							}}
						/>
					</Form.Item>
				)}
				{type !== 'file' && (
					<Form.Item label='Add Youtube Video ID' name='videoID'>
						<Input
							value={videoID}
							onChange={e => {
								setVideoID(e.target.value);
								setVideoUrl(undefined);
							}}
						/>
					</Form.Item>
				)}
				{type === 'file' && (
					<Dragger onChange={e => setFile(e.file.originFileObj)}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p>Drag and drop file or click to browse</p>
					</Dragger>
				)}
			</Form>
			{type !== 'file' && <strong>Preview</strong>}
			{type !== 'file' && (
				<ReactPlayer
					width='100%'
					height='35vh'
					url={
						(videoID || videoUrl) && videoUrl
							? videoUrl
							: `https://www.youtube.com/watch?v=${videoID}`
					}
					onError={error => console.log('error', error)}
					light={false}
					controls={true}
				/>
			)}
		</Modal>
	);
};

export default EditContentModal;
