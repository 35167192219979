import React, { useState } from 'react';
import {
	Table,
	Popconfirm,
	Button,
	Divider,
	Menu,
	Dropdown,
	Typography,
	Switch
} from 'antd';
import { DeleteOutlined, DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { studentStatus } from '../../constants/constString';
import SearchStudents from '../StudetsManagement/SearchStudents';
import FilterStudents from '../StudetsManagement/FilterStudents';
import {
	removeStudentsFromGroup,
	exportStudentsByGroup
} from '../../stateManager/reducers/groupSlice';
import {
	studentStatusUpdateRequest,
} from '../../stateManager/reducers/studentManagementSlice';

const { Text } = Typography;

const StudentsByGroupTable = props => {
	const { data, groupId } = props;
	const dispatch = useDispatch();
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const courseList = useSelector(state => state.courses.courseList);
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const status = useSelector(state => state.groups.status);

	const handleCsvExport = async () => {
		await dispatch(exportStudentsByGroup({ groupId }));
	};

	const handleBatchDelete = async () => {
		const data = { usernames: [...selectedRowKeys] };
		await dispatch(removeStudentsFromGroup({ groupId, data }));
		setSelectedRowKeys([]);
	};

	const columns = [
		{
			title: <strong>ID</strong>,
			key: 'sid',
			dataIndex: 'sid'
		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: <strong>Attempt</strong>,
			key: 'firstTime',
			dataIndex: 'firstTime',
			render: text => (text === true ? '1st' : '2nd')
		},
		{
			title: <strong>Session</strong>,
			key: 'session',
			dataIndex: 'session'
		},
		{
			title: <strong>Course</strong>,
			key: 'courses',
			dataIndex: 'courses',
			render: text =>
				courseList.map(item => {
					for (let i = 0; i < text?.length; i++) {
						const element = text[i];
						if (item._id === element) {
							return item.name;
						}
					}
				})
		},
		{
			title: <strong>Branch</strong>,
			key: 'branch',
			dataIndex: 'branch',
			render: text =>
				branchList.map(item => {
					if (item._id === text) {
						return item.name;
					}
				})
		},
		{
			title: <strong>SSC</strong>,
			key: 'SSCGPA',
			dataIndex: 'SSCGPA',
			responsive: ['lg', 'md', 'sm', 'xs']
		},
		{
			title: <strong>HSC</strong>,
			key: 'HSCGPA',
			dataIndex: 'HSCGPA'
		},
		{
			title: <strong>Contact</strong>,
			key: 'username',
			dataIndex: 'username'
		},
		{
			title: <strong>Parents</strong>,
			key: 'contact',
			dataIndex: 'contact'
		},
		{
			title: <strong>Activations</strong>,
			key: 'activation',
			dataIndex: 'status',
			filters: [
				{
					text: 'Active',
					value: [studentStatus.ACTIVE]
				},
				{
					text: 'Pending',
					value: [studentStatus.PENDING]
				},
				{
					text: 'Deactive',
					value: [studentStatus.BANNED, studentStatus.DIACTIVE]
				}
			],
			filterMultiple: false,
			onFilter: (value, record) => value.indexOf(record.status) > -1,
			render: (text, record, item) => (
				<Switch
					onChange={() => {
						console.log('text', text);
						if (text === 'pending') {
							alert('Status still pending, you cannot change the status now!');
							return;
						} else if (text === studentStatus.ACTIVE) {
							const data = { status: studentStatus.DIACTIVE };
							dispatch(
								studentStatusUpdateRequest({ studentId: record._id, data })
							);
						} else if (text === studentStatus.DIACTIVE) {
							const data = { status: studentStatus.ACTIVE };
							dispatch(
								studentStatusUpdateRequest({ studentId: record._id, data })
							);
						}
					}}
					checkedChildren='ON'
					checked={text === 'active'}
					unCheckedChildren='OFF'
				/>
			)
		},
		{
			title:
				selectedRowKeys.length > 0 ? (
					<strong>
						<Dropdown
							overlay={
								<Menu>
									<Menu.Item onClick={() => handleBatchDelete()}>
										Delete
									</Menu.Item>
								</Menu>
							}
						>
							<Text>
								Actions <DownOutlined />{' '}
							</Text>
						</Dropdown>
					</strong>
				) : (
					<strong>Actions</strong>
				),
			key: 'action',
			dataIndex: 'action',
			render: (text, record, index) => (
				<Popconfirm
					title='Are you sure to delete the student?'
					okButtonProps={{ loading: status === 'loading' }}
					placement='topRight'
					onConfirm={async () => {
						const data = { usernames: [record.username] };
						await dispatch(removeStudentsFromGroup({ groupId, data }));
					}}
				>
					<Button icon={<DeleteOutlined />} type='text'></Button>
				</Popconfirm>
			)
		}
	];

	return (
		<React.Fragment>
			<SearchStudents groupFilter={true} />
			<Divider style={{ margin: '0px' }} />
			<FilterStudents
				courseList={courseList}
				branchList={branchList}
				groupFilter={true}
				isGroup={true}
				handleCsvExport={handleCsvExport.bind(this)}
			/>

			<Table
				dataSource={data}
				columns={columns}
				loading={status === 'loading'}
				rowSelection={{
					type: 'checkbox',
					selectedRowKeys,
					onChange: (keys, rows) => {
						setSelectedRowKeys(keys);
					}
				}}
				rowKey='username'
			/>
		</React.Fragment>
	);
};

export default StudentsByGroupTable;
