import React, { useEffect, useState } from 'react';
import {
	PageHeader,
	Row,
	Col,
	Button,
	Typography,
	Input,
	InputNumber,
	Affix,
	Modal
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import {
	markAnswer,
	publishExamResult
} from '../../../../stateManager/reducers/groupSlice';
import ShortAnsComponent from '../../../Questions/students/ShortAns.component';
import ParagraphComponent from '../../../Questions/students/Paragraph.component';
import { CheckCircleOutlined } from '@ant-design/icons';
import './Assessment.page.style.css';

const { Text } = Typography;
const { TextArea } = Input;

const Assessment = props => {
	const { studentId } = props.match.params;
	const dispatch = useDispatch();
	const history = useHistory();
	const [isModalVisible, setModalVisible] = useState(false);
	const [dataToAssess, setAssessData] = useState([]);
	const [groupId, setGroupId] = useState('');
	const [marks, setMarks] = useState({});
	const [notes, setNotes] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const examData = useSelector(state => state.groups.examDataForResult);
	const examResult = useSelector(state =>
		state.groups.examResult.find(item => (item?.studentId?._id || item?.studentId) === studentId)
	);

	useEffect(() => {
		
		if(isLoaded)return;
		console.log('Extra Initialized');
		localStorage.setItem('extra', JSON.stringify({}));
		setGroupId(localStorage.getItem('groupId'));
		if (examData && examData?.questions?.length > 0) {
			let temp = [];
			for (let i = 0; i < examData?.questions.length; i++) {
				const element = examData?.questions[i];
				if (
					element.question.type === 'shortAns' ||
					element.question.type === 'paragraph'
				) {
					for (let j = 0; j < examResult.answers.length; j++) {
						const element2 = examResult.answers[j];
						if (element2.questionId === element.question._id) {
							const obj = { ...element, ...element2 };
							temp.push(obj);
							
							setNotes(prev => {
								return {
									...prev,
									[element2.questionId]: element2.notes
								};
							});
							setMarks(prev => {
								return {
									...prev,
									[element2.questionId]: element2.marks
								};
							});
							console.log('temp', element2, element);
							break;
						}
						
						
					}

				}
			}

			
			setAssessData(temp);
			if (temp.length <= 0) {
				setModalVisible(!isModalVisible);
			}
		}
		setIsLoaded(true);
		// eslint-disable-next-line
	}, [examResult, examData]);
	console.log(notes, marks)

	const handleDone = async () => {
		setModalVisible(!isModalVisible);
		history.goBack();
		// try {
		// 	if (dataToAssess.length !== Object.keys(marks).length) {
		// 		toastr.error(`Please give marks for all answers`);
		// 		return
		// 	}

		// 	const res = await dispatch(
		// 		publishExamResult({ examId, studentId, groupId })
		// 	);
		
		// 	history.goBack();
		// } catch (error) {
		// 	console.log(error);
		// }
	};

	const handleSubmitMark = ({ questionId, point, setIsSubmitted }) => {
		if (
			marks[questionId] === undefined ||
			isNaN(marks[questionId]) ||
			marks[questionId] < 0
		) {
			toastr.error('Please give a valid mark');
			return;
		}
		if (
			marks[questionId] > point
		) {
			toastr.error(`Mark must not greater than ${point}`);
			return;
		}

		let data = {
			questionId,
			marks: marks[questionId],
			notes: notes[questionId],
			studentId
		};
		let extra = JSON.parse(localStorage.getItem('extra'));
		if (extra[questionId]) {
			data = {
				...data,
				extra: extra[questionId]
			}
		}
		const examId = examData._id;
		try {
			// eslint-disable-next-line
			const res = dispatch(markAnswer({ examId, data, groupId }));
			//remove extra
			setIsSubmitted(true)
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div className='main-content-wrap'>
			<Affix className='page-header-affix' style={{ margin: '0px -25px' }}>
				<PageHeader
					ghost={true}
					onBack={() => window.history.back()}
					title='Exam Title'
					// subTitle="This is a subtitle"
					extra={[
						<Button
							key='3'
							type='primary'
							onClick={() => {
								handleDone();
							}}
						>
							Done
						</Button>
					]}
				/>
			</Affix>
			{dataToAssess.map((question, index) => {
				return (
					(question.question.type === 'shortAns' ||
						question.question.type === 'paragraph') && (
						<>
							{question.question.type === 'shortAns' && (
								<ShortAnsComponent
									question={question}
									examResult={examResult}
									isAssessment={true}
									questionIndex={index}
									marks={marks}
									setMarks={setMarks}
									notes={notes}
									setNotes={setNotes}
									handleSubmitMark={handleSubmitMark}
								/>
							)}
							{question.question.type === 'paragraph' && (
								<ParagraphComponent
									question={question}
									examResult={examResult}
									isAssessment={true}
									questionIndex={index}
									marks={marks}
									setMarks={setMarks}
									notes={notes}
									setNotes={setNotes}
									handleSubmitMark={handleSubmitMark}
								/>
							)}
						</>
					)
				);
			})}
			{/* <Modal
				className='examMessageModal'
				closable={false}
				visible={isModalVisible}
				onOk={() => handleDone()}
				onCancel={() => setModalVisible(!isModalVisible)}
				maskClosable={false}
				footer={[
					<Button
						key='ok'
						type='primary'
						shape='round'
						onClick={() => handleDone()}
					>
						Ok
					</Button>
				]}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center'
					}}
				>
					<CheckCircleOutlined
						style={{ fontSize: 82, marginBottom: 15, color: '#4caf50' }}
					/>
					<Text style={{ textAlign: 'center' }}>
						No Creative question to assess. Press Ok to publish result
					</Text>
				</div>
			</Modal> */}
		</div>
	);
};

export default Assessment;
