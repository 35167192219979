import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Popover, Radio, Slider, Space } from 'antd';
import { UndoOutlined, RedoOutlined, ZoomInOutlined, ZoomOutOutlined, RotateRightOutlined, RotateLeftOutlined } from '@ant-design/icons';
import mime from 'mime-types';
import { signedUrl as signedUrlRequest, patchFileRequest } from '../../../../stateManager/reducers/fileSystem';
import { useDispatch } from 'react-redux';

const DrawingCanvas = forwardRef(({ imageSrc, imageKey, toggleModal, readLocalData }, ref) => {
    const canvasRef = useRef(null);
    const canvasBgRef = useRef(null);
    const contextRef = useRef(null);
    const contextBgRef = useRef(null);
    const dispatch = useDispatch();

    const [isDrawing, setIsDrawing] = useState(false);
    const [canvasWidth, setCanvasWidth] = useState(window.innerWidth - 80);
    const [canvasHeight, setCanvasHeight] = useState((window.innerHeight - 100));
    const [history, setHistory] = useState([]);
    const [selectedTool, setSelectedTool] = useState("draw");
    const [brushSize, setBrushSize] = useState(5);
    const [currentState, setCurrentState] = useState(0);
    const [operation, setOperation] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const [currentDataUrl, setCurrentDataUrl] = useState("");
    const [isMouseLeave, setIsMouseLeave] = useState(false);
    const [rotate, setRotate] = useState(0);
    const [scale, setScale] = useState(1);
    const [color, setColor] = useState("#c90808");
    const historyLimit = 10;

    console.log("imageSrc", imageSrc);

    useEffect(() => {
        const canvas = canvasRef.current;

        console.log("canvas", canvas);
        const context = canvas.getContext("2d");
        context.lineCap = "round";
        
        context.strokeStyle = "#c90808";
        context.lineWidth = 5;
        contextRef.current = context;
        contextBgRef.current = canvasBgRef.current.getContext("2d");
        // contextBgRef.current.lineWidth = 5;
    }, [imageSrc]);

    const downloadImage = async () => {
        try {
            console.log('downloadImage imageSrc', imageSrc);
          const response = await fetch("https://retina-dev-files.s3.ap-southeast-1.amazonaws.com/directory-dev/604724852995.jpeg");
          const blob = await response.blob();
          const imageUrl = URL.createObjectURL(blob);
          
         console.log('imageUrl', imageUrl);
        } catch (error) {
          console.error('Error downloading image:', error);
        }
      };

    useEffect(() => {

        //downloadImage()
        const canvas = canvasRef.current;
        const canvasBg = canvasBgRef.current;
        const ctx = canvas.getContext("2d");
        const ctxBg = canvasBg.getContext("2d");

        const image = new Image();
        //https://retina-dev-files.s3.ap-southeast-1.amazonaws.com/directory-dev/418650424476.png
        //image.src = 'http://localhost:3000/785614015836.jpeg';
        console.log('imageSrc', imageSrc);
        image.src = imageSrc + '?time=' + Date.now();
        image.crossOrigin = "anonymous";
        image.width = '50%';
        image.height = '50%';
        image.onload = () => {
            setImageLoaded(true);

            var ratio = image.naturalWidth / image.naturalHeight;
            var width = canvas.width;
            var height = width / ratio;
            setCanvasHeight(height)

            console.log('width', width, height);
            ctx.drawImage(image, 0, 0, width, height);
            //ctxBg.drawImage(canvas, 0, 0, width, height);
           
                // let dataUrl = canvas.toDataURL();
                // setHistory([dataUrl]);

            if(canvasRef?.current){
                let dataUrl = canvasRef.current.toDataURL();
                if (history.length === 0) {
                    setHistory([...history, dataUrl]);
                }
            }
            
        };
        image.onerror = () => {
            setImageLoaded(false);
            console.log('Could not load image at ' + imageSrc)
        };
    }, [imageSrc]);

    useEffect(() => {
        if (operation === "undo" || operation === "redo" || operation === "rotate") {
            redrawCanvas();
        }
    }, [currentState, rotate]);

    useImperativeHandle(ref, () => ({
        saveImageToLocal() {
            (async () => {
                const ctxBg = canvasBgRef.current.getContext("2d");
                ctxBg.drawImage(canvasRef.current, 0, 0);
                let image = canvasBgRef.current.toDataURL();
                const blob = dataURItoBlob(image);
                const randomNumber = Math.floor(Math.random() * 100000)
                const filename = `${randomNumber}.${mime.extension(blob.type)}`;

                const file = new File([blob], filename, { type: blob.type });

                const res = await dispatch(signedUrlRequest(file.type));
                const { signedUrl, key } = res?.payload?.data;
                console.log('File Upload', res?.payload?.data);
                await dispatch(patchFileRequest({ signedUrl, file }));

                // const { questionId, key: imgKey } = imageKey
                const questionId = imageKey.questionId
                const imgKey = imageKey.key

                let extra = JSON.parse(localStorage.getItem('extra'));
                let _extra = extra[questionId]

                let indexOfImage = _extra.findIndex(el => el.key === imgKey)
                console.log('indexOfImage', indexOfImage, _extra, imgKey, key);
                if (indexOfImage > -1) {
                   
                    _extra[indexOfImage].evaluatedKey = key
                }
                extra[questionId] = _extra

                // _extra[question.questionId] = question?.extra
                localStorage.setItem(
                    'extra',
                    JSON.stringify(extra)
                );
                readLocalData(extra)
                toggleModal('')
            })();
        }
    }));

    const dataURItoBlob = (dataURI) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
    }

    const redrawCanvas = () => {
    console.log('redrawCanvas', rotate);
        contextRef.current.globalCompositeOperation = 'source-over';
        const context = canvasRef.current.getContext('2d');
        const image = new Image();
        image.src = history[currentState];
      
        //scale up the image
        image.onload = () => {
            context.clearRect(0, 0, canvasRef.current.width*scale, canvasRef.current.height*scale);
            context.drawImage(image, 0, 0, canvasWidth*scale, canvasHeight*scale);
            //context.rotate(rotate);

        };
        setSelectedTool("draw");
    };

    const redrawCanvasZoom = (scale) => {
        contextRef.current.globalCompositeOperation = 'source-over';
        const context = canvasRef.current.getContext('2d');
        const image = new Image();
        image.src = history[currentState];
        image.crossOrigin = "anonymous";
        //scale up the image
        image.onload = () => {
            context.clearRect(0, 0, canvasRef.current.width*scale, canvasRef.current.height*scale);
            context.drawImage(image, 0, 0, canvasWidth*scale, canvasHeight*scale);
            
        };
        setSelectedTool("draw");
    }

    const handleUndo = () => {
        if (currentState > 0) {
            setOperation("undo");
            setCurrentState(currentState - 1);

        }
    };

    const handleRedo = () => {
        if (currentState < history.length - 1) {
            setOperation("redo");
            setCurrentState(currentState + 1);
        }
    };

    const startDrawing = ({ nativeEvent }) => {
        setOperation("drawing");
        const { offsetX, offsetY } = nativeEvent;
        contextBgRef.current.lineWidth = brushSize;
        contextRef.current.lineWidth = brushSize;
        contextRef.current.strokeStyle = color;
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
        setIsDrawing(true);
        nativeEvent.preventDefault();

    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) {
            return;
        }

        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY);
        contextRef.current.stroke();
        nativeEvent.preventDefault();
    };

    const stopDrawing = (onMouseLeave = false) => {
        contextRef.current.closePath();
        let dataUrl = canvasRef.current.toDataURL();
        setCurrentDataUrl(dataUrl);
        setIsMouseLeave(onMouseLeave)
        setIsDrawing(false);
    };

    useEffect(() => {
        if (imageLoaded && operation !== "reset" && history[history.length - 1] !== currentDataUrl && !history.includes(currentDataUrl)) {
            if ((operation !== "undo" && (!isMouseLeave && operation !== "drawing")) || (currentState > 0 && currentState < (history.length - 1))) {
                setHistory((prevHistory) => {
                    let newItems = prevHistory.slice(0, (currentState + 1));
                    return [...newItems, currentDataUrl]
                })
            } else {
                setHistory([...history, currentDataUrl]);
            }
            setCurrentState(currentState + 1);
        }

        setOperation("")
    }, [currentDataUrl, isMouseLeave]);

    const changeColor = (e) => {
        console.log("e.target.value", e.target.value);
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");
        context.strokeStyle = e.target.value;
        setColor(e.target.value);
        context.lineCap = "round";
        context.lineWidth = 5;
        contextRef.current = context;
    };

    const changeTool = (e) => {
        if (e.target.value === "draw") {
            setSelectedTool(e.target.value);
            contextRef.current.globalCompositeOperation = 'source-over';
        } else if (e.target.value === "erase") {
            setSelectedTool(e.target.value);
            contextRef.current.globalCompositeOperation = 'destination-out';
        }
    }

    const resetCanvas = (e) => {

        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        setOperation("reset");
        if (history.length > 0) {
            setHistory([history[0]]);
        }
        setCurrentState(0);

        const image = new Image();

        image.src = imageSrc;
        console.log("imageSrc", imageSrc);
        image.onload = () => {
            setImageLoaded(true);
            var ratio = image.naturalWidth / image.naturalHeight;
            var width = canvasRef.current.width;
            var height = width / ratio;
            setCanvasHeight(height)
            contextRef.current.drawImage(image, 0, 0, width, height);
            contextBgRef.current.drawImage(canvasRef.current, 0, 0, width, height);
        };
        image.onerror = () => {
            setImageLoaded(false);
            console.log('Could not load image at ' + imageSrc)
        };
    }

    return (
        <div>
            {/* <HistoryGrid history={history} currentState={currentState} /> */}

            <Space className='d-flex justify-space-between flex-wrap mb-5'>
                <Space>
                    <Radio.Group
                        options={[
                            {
                                label: 'Draw',
                                value: 'draw',
                            },
                            {
                                label: 'Erase',
                                value: 'erase',
                                disabled: !(currentState > 0)
                            },
                        ]}
                        onChange={changeTool}
                        value={selectedTool}
                        // defaultValue={selectedTool}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Space>
                <Space>
                    <Popover
                        className="brush-popover"
                        placement="bottom"
                        trigger="click"
                        // open={open}
                        // onOpenChange={handleOpenChange}
                        content={
                            <div style={{ width: 100 }}>
                                <Slider defaultValue={brushSize} max={10} onChange={setBrushSize} />
                            </div>
                        }
                    >
                        <Button>Brush Size: {brushSize}</Button>
                    </Popover>
                    <input type="color" name="color-pick" id="colorPick" defaultValue='#c90808' onChange={changeColor} />
                </Space>
                <Space>
                    <Button
                        title='Undo'
                        onClick={handleUndo}
                        disabled={!(currentState > 0)}
                    ><UndoOutlined /></Button>
                    <Button
                        title='Redo'
                        onClick={handleRedo}
                        disabled={!(currentState < history.length - 1)}
                    ><RedoOutlined /></Button>
                     <Button onClick={()=>{
                       setOperation("rotate");
                      setRotate(rotate - 90);
                    
                    }}>
                    <RotateLeftOutlined />
                    </Button>
                    <Button onClick={()=>{
                      
                      setOperation("rotate");
                      setRotate(rotate + 90);
                     
                    }}>
                    <RotateRightOutlined />
                    </Button>
                    <Button title='Reset' onClick={(e) => resetCanvas()}>Reset</Button>
                    {/* <Button onClick={(e) => saveImageToLocal()}><DownloadOutlined /></Button> */}
                </Space>
                <Space>
                    <Button
                        title='-'
                        onClick={()=>{

                          if(scale > 0.5){
                            setScale(scale - 0.25);
                            redrawCanvasZoom(scale - 0.25);
                          }
                        }}
                        // disabled={!(currentState > 0)}
                    ><ZoomOutOutlined /></Button>
                    <Button
                        title='+'
                         onClick={()=>{
                          if(scale < 2){
                            setScale(scale + 0.25);
                            redrawCanvasZoom(scale + 0.25);
                          }

                         }}
                        // disabled={!(currentState < history.length - 1)}
                    ><ZoomInOutlined /></Button>
                   
                    {/* <Button onClick={(e) => saveImageToLocal()}><DownloadOutlined /></Button> */}
                </Space>
                
                {/* {(currentState > 0) && <Button onClick={undo}>Undo</Button>} */}
            </Space>
            <div className="canvas-container-wrap position-relative rotate-90">
                <canvas className="canvas-container"
                    ref={canvasRef}
                    onMouseDown={startDrawing}
                    onMouseMove={draw}
                    onMouseUp={stopDrawing}
                    onMouseLeave={() => stopDrawing(true)}
                    width={canvasWidth*scale}
                    height={canvasHeight*scale}
                    rotate={rotate}
                    style={{
                        position: "relative",
                        zIndex: 999999,
                        transform: `translateX(250px) rotate(${rotate}deg)`,
                        
                      
                    }}
                />
                <canvas className="canvas-container"
                    ref={canvasBgRef}
                    width={canvasWidth*scale}
                    height={canvasHeight*scale}
                    rotate={rotate}
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 999998,
                        transform: `translateX(250px) rotate(${rotate}deg)`
                        
                    }}
                />
            </div>
        </div>

    )
});

export default DrawingCanvas;