import React, { useState } from 'react';
import { Modal, Form, Input, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player/youtube';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import {
	addVideo,
	patchFileRequest
} from '../../stateManager/reducers/contentSlice';
import { instance } from '../../constants/constString';

const { Dragger } = Upload;

const AddVideoModal = props => {
	const {
		chapters,
		courses,
		lectures,
		questionSolves,
		subjects,
		isVisible,
		contentName
	} = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [videoUrl, setVideoUrl] = useState(undefined);
	const [videoID, setVideoID] = useState(undefined);
	const [file, setFile] = useState(undefined);
	const [title, setTitle] = useState(undefined);

	const status = useSelector(state => state.contents.status);

	const handleSubmit = async () => {
		if (contentName !== 'file' && !videoID && !videoUrl) {
			toastr.error('Please add videor url or id');
			return;
		}
		if (!title) {
			toastr.error('Please add title for the video/file');
			return;
		}
		if (contentName === 'file' && !file) {
			toastr.error('Please add a file');
			return;
		}

		if (contentName === 'file') {
			// eslint-disable-next-line
			const url = URL.createObjectURL(file);
			try {
				const res = await instance.get(
					`/content/signed-request?mimeType=${file.type}`
				);
				const { signedUrl, key } = res?.data?.data;
				const response = await patchFileRequest({ signedUrl, file });
				if (response.status === 200) {
					const data = {
						title,
						type: 'file',
						key: key,
						chapters: chapters ? [chapters] : undefined,
						courses: [courses],
						lectures: lectures ? [lectures] : undefined,
						questionSolves: questionSolves ? [questionSolves] : undefined,
						subjects: [subjects]
					};
					try {
						const res1 = await dispatch(addVideo(data));
					} catch (err) {
						console.log(err);
					}
					props.toggleModal();
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			const data = {
				title,
				type: 'video',
				key: videoID,
				URL: videoUrl,
				chapters: chapters ? [chapters] : undefined,
				courses: [courses],
				lectures: lectures ? [lectures] : undefined,
				questionSolves: questionSolves ? [questionSolves] : undefined,
				subjects: [subjects],
				isVOD: false,
				isAvailable: true
			};
			try {
				const res = await dispatch(addVideo(data));
			} catch (error) {
				console.log(error);
			}
			props.toggleModal();
		}
	};

	return (
		<Modal
			maskClosable={false}
			visible={isVisible}
			centered
			destroyOnClose={true}
			okText={contentName === 'file' ? 'Add Note' : 'Add Video'}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => handleSubmit()}
			onCancel={() => props.toggleModal()}
			title={
				contentName === 'file' ? (
					<strong>Add Note</strong>
				) : (
					<strong>Add Video</strong>
				)
			}
		>
			<Form form={form} layout='vertical' preserve={false}>
				<Form.Item
					label={contentName === 'file' ? 'File title' : 'Video Title'}
					rules={[{ required: true, message: 'Please enter video title' }]}
					name='title'
				>
					<Input
						placeholder='Enter video title'
						onChange={e => setTitle(e.target.value)}
					/>
				</Form.Item>
				{contentName !== 'file' && (
					<Form.Item label='Add Youtube Video URL' name='videoUrl'>
						<Input
							value={videoUrl}
							onChange={e => {
								setVideoUrl(e.target.value);
								setVideoID(undefined);
							}}
						/>
					</Form.Item>
				)}
				{contentName !== 'file' && (
					<Form.Item label='Add Youtube Video ID' name='videoID'>
						<Input
							value={videoID}
							onChange={e => {
								setVideoID(e.target.value);
								setVideoUrl(undefined);
							}}
						/>
					</Form.Item>
				)}
				{contentName === 'file' && (
					<Dragger onChange={e => setFile(e.file.originFileObj)}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p>Drag and drop file or click to browse</p>
					</Dragger>
				)}
			</Form>
			{contentName !== 'file' && <strong>Preview</strong>}
			{contentName !== 'file' && (
				<ReactPlayer
					width='100%'
					height='35vh'
					url={
						(videoID || videoUrl) && videoUrl
							? videoUrl
							: `https://www.youtube.com/watch?v=${videoID}`
					}
					onError={error => console.log('error', error)}
					light={false}
					controls={true}
				/>
			)}
		</Modal>
	);
};

export default AddVideoModal;
