import React, { useEffect, useState, useCallback } from 'react';
import { Card, Input, Layout, Space, Tabs, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { useHistory } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import debounce from 'lodash/debounce';

import ExamQuestionAddFromCSV from './ExamQuestionAddFromCSV';
import CreateExamModal from './CreateExamModal';
import SubHeader from '../Common/SubHeader';
import QuestionCard from '../QuestionBank/QuestionCard';
import CourseWiseQuestion from '../QuestionBank/CourseWiseQuestion';
import '../QuestionBank/question.css';

import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';
import {
	saveQuestionRequest,
	resetQuestionToInitial,
	addQuestion
} from '../../stateManager/reducers/questionSlice';
import {
	addQuestionForExam,
	clearUnsavedQuestions
} from '../../stateManager/reducers/examSlice';

const { Content } = Layout;
const { TabPane } = Tabs;

const AddExam = props => {
	const { courseId, subjectId } = props.match.params;
	const dispatch = useDispatch();
	const history = useHistory();
	const [isSaveDisabled, setSaveDisable] = useState(false);
	const [isCreateModalVisible, setCreateModalVisible] = useState(false);
	const [title, setTitle] = useState('');
	const [instruction, setInstruction] = useState(
		RichTextEditor.createEmptyValue()
	);

	const course = JSON.parse(localStorage.getItem('selectedCourse'));
	const subjectList = useSelector(state => state.courses.subjectList);
	const questionsForExam = useSelector(state => state.exams.questionsForExam);
	const unSavedQuestion = useSelector(state => state.questions.questionList);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}
		fetchData();
	}, [courseId]);

	const validateQuestion = async () => {
		let isValid = true;
		for (let i = 0; i < unSavedQuestion.length; i++) {
			const question = unSavedQuestion[i];
			if (!question.title || question.title.length < 5) {
				toastr.error('Please add a valid title to the question ' + (i + 1));
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.options || question.options.length < 2)
			) {
				toastr.error(
					'Please add at least two valid option for the question ' + (i + 1)
				);
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.answer || question.answer.length < 1)
			) {
				toastr.error('Please select an answer for the question ' + (i + 1));
				isValid = false;
				break;
			}
		}

		if (isValid) {
			await saveQuestion();
		}
		return;
	};

	const saveQuestion = async () => {
		const questions = unSavedQuestion.map(item => {
			if (item.hasOwnProperty('point')) {
				const obj = { ...item };
				delete obj['point'];
				return {
					...obj,
					courseId
				};
			}
			return {
				...item,
				courseId
			};
		});
		const data = { questions: questions };

		try {
			const res = await dispatch(saveQuestionRequest(data));

			if (res?.payload?.status === '200') {
				const resData = res.payload.data;
				const dataToAdd = resData.map((item, index) => {
					return { ...item, point: unSavedQuestion[index]?.point };
				});
				dispatch(addQuestionForExam(dataToAdd));
				dispatch(clearUnsavedQuestions());
				dispatch(resetQuestionToInitial());
				setSaveDisable(false);
			}
		} catch (error) {
			console.log(error);
		}
		// history.goBack();
	};

	const handleAddQuestion = () => {
		dispatch(
			addQuestion({
				title: '',
				type: 'MCQ',
				options: [],
				answer: []
			})
		);
	};

	const toggleCreateModal = () => {
		setCreateModalVisible(false);
		history.goBack();
	};

	const debouncedInput = useCallback(
		debounce((name, value) => {
			if (name === 'title') {
				setTitle(value);
			} else if (name === 'instruction') {
				setInstruction(value);
			}
		}, 1000),
		[]
	);

	return (
		<React.Fragment>
			<CreateExamModal
				isVisible={isCreateModalVisible}
				toggleModal={toggleCreateModal.bind(this)}
				courseId={courseId}
				subjectId={subjectId}
				title={title}
				instruction={instruction}
			/>

			<SubHeader headText={course.name} backIcon={true} />

			<Content>
				<Card style={{ width: '100%', marginTop: 10 }}>
					{!!title && !!instruction && (
						<Button
							onClick={() => setCreateModalVisible(true)}
							style={{ alignSelf: 'flex-end' }}
							disabled={isSaveDisabled}
						>
							Save Exam
						</Button>
					)}
					<Space direction='vertical' className='main'>
						<Input
							onChange={e => debouncedInput('title', e.target.value)}
							placeholder='Exam Title'
						/>
						<RichTextEditor
							value={instruction}
							onChange={value => setInstruction(value)}
						/>
					</Space>
				</Card>

				<Card>
					<Tabs type='card'>
						<TabPane tab='Add Questions' key='1'>
							{questionsForExam.map((item, index) => (
								<QuestionCard
									isExam={true}
									questionObject={item}
									questionIndex={index}
								/>
							))}
							{unSavedQuestion.map((item, index) => (
								<QuestionCard
									courseId={courseId}
									subjectList={subjectList}
									isExam={true}
									isCreateForExam={true}
									questionObject={item}
									questionIndex={index}
								/>
							))}
							<div className='main'>
								<div style={{ width: 500 }}>
									<Button
										type='dashed'
										icon={<PlusOutlined />}
										onClick={() => handleAddQuestion()}
									>
										Add Another Question
									</Button>
									<Button
										style={{ marginLeft: 10 }}
										type='primary'
										onClick={() => validateQuestion()}
									>
										Save Questions
									</Button>
								</div>
							</div>
						</TabPane>
						<TabPane tab='Import from Question Bank' key='2'>
							<CourseWiseQuestion courseId={courseId} />
						</TabPane>
						<TabPane tab='Import from CSV ' key='3'>
							<ExamQuestionAddFromCSV
								courseId={courseId}
								subjectList={subjectList}
							/>
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</React.Fragment>
	);
};

export default AddExam;
