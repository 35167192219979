import axios from "axios";
import { roles } from "./constString";

const user = JSON.parse(localStorage.getItem("user"));

export const passwordValidator = (value) => {
  const pass = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/);
  return value.match(pass);
};

export const isPhoneNoValid = (value) => {
  const phone = new RegExp(/^01[3-9]\d{8}$/);
  return value.match(phone);
};

export const shouldUseKatex = (value) => {
  const regex = new RegExp(/\^|\\|\+|_|\{|\}|\$/);
  return value.search(regex);
};

export const downloadFileRequest = async ({ url }) => {
  try {
    const response = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

let seed = Date.now();
function random() {
  seed += 1;
  const xseed = Math.sin(seed) * 1000000;
  return Math.floor((xseed - Math.floor(xseed)) * 100000);
}

const shuffle = (arr = []) => {
  const ret = arr;
  for (let i = 0; i < arr.length; i += 1) {
    const rand = random() % arr.length;
    const temp = ret[i];
    ret[i] = ret[rand];
    ret[rand] = temp;
  }
  return ret;
};

const getSuffledQuestionsWithOption = (arr) => {
  const qs = [];
  arr.forEach((q) => {
    qs.push({
      ...q,
      options: shuffle(q.options),
    });
  });
  return qs;
};

export const questionSuffle = (questions) => {
  const qs = shuffle(questions);
  const final = getSuffledQuestionsWithOption(qs);
  return final;
};

export const isAdmin = () => {
  return user?.roles.indexOf(roles.ADMIN) > -1;
};

export const isAdminOrModerator = () => {
  return (
    user?.roles.indexOf(roles.ADMIN) > -1 ||
    user?.roles.indexOf(roles.MODERATOR) > -1
  );
};

export const isExamModarator = () => {
  return user.roles.indexOf(roles.EXAM_MODERATOR) > -1;
};

export const isExaminer = () => {
  return user.roles.indexOf(roles.EXAMINER) > -1;
};

export const isQuestionUploader = () => {
  return user.roles.indexOf(roles.QUESTION_UPLOADER) > -1;
};

export const isContentUploader = () => {
  return user.roles.indexOf(roles.CONTENT_UPLOADER) > -1;
};

export const isMentor = () => {
  return user.roles.indexOf(roles.MENTOR) > -1;
};

export const isInstructor = () => {
  return user.roles.indexOf(roles.INSTRUCTOR) > -1;
};

export const setUpdatedAnswer = (extra, questionId) => {
  let _evaluatedExtra = {};
  if (extra[questionId]) {
    extra[questionId].map((item) => {
      if (!_evaluatedExtra[item?.key]) {
        _evaluatedExtra[item?.key] = item;
      }
    });
  }

  return _evaluatedExtra;
};

export const saveExtraToLocal = (question) => {
  const extra = JSON.parse(localStorage.getItem("extra"));
  let _extra = {};
  if (extra) {
    _extra = { ...extra };
  }
  _extra[question.questionId] = question?.extra;
  localStorage.setItem("extra", JSON.stringify(_extra));

  return _extra;
};
