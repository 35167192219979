import {
	Form,
	Input,
	Button,
	Card,
	Space
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import CSVReader from 'react-csv-reader'

import {
	addAlternateExam
} from '../../../../stateManager/reducers/studentAuthSlice';


import { useState } from 'react';

export default function AlternateExam() {
    const [form] = Form.useForm();
    const [username, setUsername] = useState('');
    const [alternateExamCode, setAlternateExamCode] = useState('');
    const [alternateExamLink, setAlternateExamLink] = useState('');
	const [csvData, setCsvData] = useState([]);
	const [completed, setCompleted] = useState(0);
	const dispatch = useDispatch();
	

	const handleSubmit = async () => {
		if (!username) {
			toastr.error('Please give a valid ID');
			return;
		}
		if (!alternateExamCode) {
			toastr.error('Please Give Exam Code');
			return;
		}
		if (!alternateExamLink) {
			toastr.error('Please Give Exam Link');
			return;
		}
		const data = {
			username,
			code: alternateExamCode,
			link: alternateExamLink
		};
		const response = await dispatch(addAlternateExam(data));
		console.log('response', response);
		if(!response.payload)
			toastr.error(response?.error?.message);
		else{
			form.resetFields();
			setUsername('');
			setAlternateExamCode('');
			setAlternateExamLink('');
			toastr.success('Alternate Exam Added Successfully');
		} 
			
		
	
	};

	const handleFileUpload = async () => {
		if (csvData.length === 0) {
			toastr.error('Please upload a valid CSV file');
			return;
		}
		csvData.map(async (item) => {
			const data = {
				username: item[0],
				code: item[1],
				link: item[2]
			};
			const response = await dispatch(addAlternateExam(data))
			console.log('response', response);
			if(!response.payload)
			toastr.error(response?.error?.message);
			else{
				toastr.success('Alternate Exam Added Successfully for username ' + item[0]);
				setCompleted(completed + 1);
			} 
			
			
		});
		
		
	}


    return <div>
        <Card style={{ maxWidth: '600px' }}>
						
							<Form
								className='notification-create-form'
								form={form}
								layout='vertical'
								
							>
								 
								<Form.Item label='Student ID' required tooltip='Notification Title'>
									<Input
										value={username}
										onChange={e => setUsername(e.target.value)}
										placeholder='Student ID'
									/>
								</Form.Item>
								<Form.Item label='Unique Identifier' required tooltip='Unique Identifier'>
									<Input
										value={alternateExamCode}
										onChange={e => setAlternateExamCode(e.target.value)}
										placeholder='Unique Identifier'
									/>
								</Form.Item>
								<Form.Item label='Alternate Exam Link' required tooltip='Alternate Exam Link'>
									<Input
										value={alternateExamLink}
										onChange={e => setAlternateExamLink(e.target.value)}
										placeholder='Alternate Exam Link'
									/>
								</Form.Item>
								<Form.Item>
									<Space>
										<Button
											type='primary'
											onClick={() => {
												handleSubmit();
												
											}}
											htmlType='submit'
										>
											Submit
										</Button>
										
									</Space>
								</Form.Item>
							
							</Form>

					</Card>
					<Card style={{
					
						marginTop: '20px',
						padding: '20px',
						width: 'auto'
					
					}}>
					<CSVReader onFileLoaded={(data, fileInfo, originalFile) => setCsvData(data)} />
					{
						csvData.length > 0 && <table>
							<thead>
								<tr>
									{
										csvData[0].map((item, index) => <th key={index}>{item}</th>)
									}
								</tr>
							</thead>
							<tbody>
								{
									csvData.map((item, index) => {
										if (index === 0) return null;
										return <tr key={index}>
											{
												item.map((data, index) => <td key={index}>{data}</td>)
											}
										</tr>
									})
								}
							</tbody>
						</table>
					}
					<div style={{
						margin: '10px'
					}}>
						Completed: {completed}
					</div>
					<Button
						style={{
							
							marginTop: '10px'
						
						}}
						type='primary'
						onClick={(e) => {
							e.preventDefault();
							setCompleted(0);
							handleFileUpload();
							
						}}
					>
						Upload
					</Button>
					</Card>
					
    </div>;
    }