import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { isAdmin } from '../../../constants/constFunction';
import Dashboard from '../../pages/admin/Dashboard/Dashboard.page';
import QNAManagement from '../../QNAForum/QNAManagement';
import CommentList from '../../QNAForum/QuestionDetails';

const MentorNavigation = props => {
	return (
		<React.Fragment>
			<Route path='/' exact component={isAdmin() ? Dashboard : QNAManagement} />
			<Route path='/qna-management' component={QNAManagement} />
            <Route path='/question-details/:id' component={CommentList} />
		</React.Fragment>
	);
};

export default MentorNavigation;