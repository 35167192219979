import React, { useState } from 'react';
import { Input, Modal, Form, Radio, Typography, Switch } from 'antd';
import { useDispatch } from 'react-redux';

import { publishTotalExamResult } from '../../../../stateManager/reducers/groupSlice';

const { Text } = Typography;

const SendSmsModal = props => {
	const { isVisible, studentIds, examId, groupId } = props;
	const dispatch = useDispatch();
	const [isSendSMS, setIsSendSMS] = useState(true);
	const [beginingText, setBegininText] = useState('');
	const [middleText, setMiddleText] = useState(
		'Sadman Safiul, Roll: 123456, Live Exam: Exam 12, Mark: 47.5, Neg: 0.5, Position: 231, Highest: 49.25'
	);
	const [endingText, setEndingText] = useState('');
	const [sendToGuardian, setSendToGuardian] = useState(false);

	const totalText = () => {
		const text = beginingText + middleText + endingText;
		return text;
	};

	const characterCount = () => {
		const text = totalText();
		return text.length;
	};

	const handleSubmit = async () => {
		try {
			const data = {
				students: studentIds,
				message:
					!!beginingText && !!endingText
						? { startString: beginingText, endString: endingText }
						: undefined,
				sendSms: isSendSMS,
				sendToGuardian: sendToGuardian
			};
			const res = await dispatch(
				publishTotalExamResult({ examId, groupId, data })
			);
		} catch (error) {
			console.log(error);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			title='Publish Exam'
			okText={'Publish Exam'}
			onOk={() => handleSubmit()}
			onCancel={() => props.toggleModal()}
		>
			<Form>
				<Form.Item>
					<Radio.Group
						options={[
							{ label: 'SEND SMS', value: true },
							{ label: "DON'T SEND SMS", value: false }
						]}
						onChange={e => {
							setIsSendSMS(e.target.value);
						}}
						value={isSendSMS}
						defaultValue={true}
						optionType='button'
						buttonStyle='solid'
					/>
				</Form.Item>
				{isSendSMS && (
					<React.Fragment>
						<Form.Item>
							<Input.TextArea
								placeholder='Benginning Text'
								onChange={e => setBegininText(e.target.value)}
							/>
						</Form.Item>
						<Form.Item>
							<Input.TextArea placeholder='Default Text' value={middleText} />
						</Form.Item>
						<Form.Item>
							<Input.TextArea
								placeholder='Ending Text'
								onChange={e => setEndingText(e.target.value)}
							/>
						</Form.Item>
						<Form.Item
									label='Send to Guardian'
									required
									tooltip='You can send notification to Guardian of the students'
								>
									
									<Switch
										className='receiver-type'
										checkedChildren='Yes'
										unCheckedChildren='No'
										defaultChecked = {false}
										onChange={(e)=>{
											console.log('send to guardian')
											setSendToGuardian(e)
										
										}}
										
									/>
								</Form.Item>
					</React.Fragment>
				)}
			</Form>
			{isSendSMS && (
				<React.Fragment>
					<Text>{totalText()}</Text>
					<br />
					<br />
					<Text>Charater Count: {characterCount()}</Text>
				</React.Fragment>
			)}
		</Modal>
	);
};

export default SendSmsModal;
