import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";
import toastr from "toastr";

const initialState = {
  adminUsers: [],
  status: "idle",
  error: null,
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  try {
    const response = await instance.get("/admin");
    return response;
  } catch (err) {
    toastr.error(err.response.data.errors.title);
    return Promise.reject(err);
  }
});
export const fetchMentors = createAsyncThunk("users/fetchMentors", async () => {
  try {
    const response = await instance.get("/admin/get-all-mentors");
    return response;
  } catch (err) {
    toastr.error(err.response.data.errors.title);
    return Promise.reject(err);
  }
});

export const addUser = createAsyncThunk(
  "user/addUser",
  async ({
    username,
    firstName,
    lastName,
    password,
    roles,
    email,
    adminId,
  }) => {
    try {
      const response = await instance.post("/admin/add", {
        username,
        firstName,
        lastName,
        password,
        adminId,
        roles,
        email,
      });
      toastr.success("User added successfully");
      return response;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, data }) => {
    try {
      const response = await instance.patch(`admin/update/${userId}`, data);
      if (response.status === 200) {
        toastr.success("User updated successfully!");
      }
      return response.data;
    } catch (error) {
      if (error.response?.data?.errors?.title) {
        toastr.error(error.response?.data?.errors?.title);
      }
      return Promise.reject(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async ({ userId, index }) => {
    try {
      const response = await instance.delete(`admin/delete/${userId}`);
      if (response.status === 200) {
        toastr.success("User deleted successfully!");
      }

      return { data: response.data, index };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    getUser: (state) => {
      return state.adminUsers;
    },
  },
  extraReducers: {
    [fetchUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.adminUsers = action.payload?.data?.data;
    },
    [fetchUsers.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [addUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.adminUsers = state.adminUsers.concat(action.payload?.data?.data);
    },
    [addUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { index } = action.payload;
      const tempData = [...state.adminUsers];
      tempData.splice(index, 1);
      state.adminUsers = [...tempData];
    },
    [deleteUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const newData = action.payload?.data;
      const tempData = [...state.adminUsers];
      state.adminUsers = tempData.map((item) =>
        item._id === newData._id ? newData : item
      );
    },
    [updateUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { getUser } = userManagementSlice.actions;
export const selectAllUsers = (state) => state.userManagement.adminUsers;

export default userManagementSlice.reducer;
