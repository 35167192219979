import React from 'react';
import { Row, Col, Select, Button, Space } from 'antd';
import { UploadOutlined, ExportOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
	resetFilters,
	setSearchName
} from '../../stateManager/reducers/studentManagementSlice';
import { setSearchName as setFilters } from '../../stateManager/reducers/groupSlice';
import { sessions } from '../../constants/constString';

const { Option } = Select;

const FilterStudents = props => {
	// eslint-disable-next-line
	const { courseList, branchList, isGroup, groupFilter } = props;
	const dispatch = useDispatch();
	const filterBranch = useSelector(state =>
		groupFilter ? state.groups.filterBranch : state.studentsAdmin.filterBranch
	);
	const filterSession = useSelector(state =>
		groupFilter ? state.groups.filterSession : state.studentsAdmin.filterSession
	);
	const filterCourse = useSelector(state =>
		groupFilter ? state.groups.filterCourse : state.studentsAdmin.filterCourse
	);

	const handleFilters = (name, value) => {
		if (props.groupFilter) {
			dispatch(setFilters({ key: name, value }));
		} else {
			dispatch(setSearchName({ key: name, value }));
		}
	};

	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
			>
				<Col xs={24} sm={24} md={3} lg={3}>
					<strong style={{ fontSize: 16 }}>Filter</strong>
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					<Select
						onChange={value => handleFilters('filterBranch', value)}
						style={{ width: '100%' }}
						placeholder='Branch'
						value={filterBranch}
					>
						{branchList.map(item => (
							<Option value={item._id} key={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					<Select
						onChange={value => handleFilters('filterCourse', value)}
						style={{ width: '100%' }}
						placeholder='Course'
						value={filterCourse}
					>
						{courseList
							.filter(item => item?.session == filterSession)
							.map(item => (
								<Option value={item._id} key={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					<Select
						onChange={value => handleFilters('filterSession', value)}
						style={{ width: '100%' }}
						placeholder='Session'
						value={filterSession}
					>
						{sessions.map(item => (
							<Option value={item} key={item}>
								{item}
							</Option>
						))}
					</Select>
				</Col>
				{props.groupFilter && (
					<Col xs={24} sm={24} md={4} lg={4}>
						<Space>
							<Button
								onClick={() => props.handleCsvExport()}
								type='primary'
								icon={<ExportOutlined />}
							>
								Export as CSV
							</Button>
						</Space>
					</Col>
				)}
				{!props.isGroup && (
					<Col xs={24} sm={24} md={4} lg={4}>
						<Space>
							<Button onClick={() => dispatch(resetFilters())}>Reset</Button>
							<Button
								onClick={() => props.toggleAddModal()}
								type='primary'
								icon={<UploadOutlined />}
							>
								Upload CSV
							</Button>

							<Button onClick={() => props.toggleSingleModal()} type='primary'>
								Add
							</Button>
						</Space>
					</Col>
				)}
			</Row>
		</div>
	);
};

export default FilterStudents;
