import React from 'react';
import {
	Row,
	Col,
	Descriptions,
	PageHeader,
	Button,
	Affix,
	Typography,
	Table
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './PublishResult.page.style.css';

const { Text } = Typography;

const columns = [
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id',
		align: 'center'
	},
	{
		title: 'Name',
		dataIndex: 'student_info',
		key: 'student_info',
		align: 'left',
		render: student_info => <Text strong>{student_info.name}</Text>
	},
	{
		title: 'Correct Ansewer',
		dataIndex: 'correct_answer',
		key: 'correct_answer',
		align: 'center'
	},
	{
		title: 'Incorrect Ansewer',
		dataIndex: 'incorrect_answer',
		key: 'incorrect_answer',
		align: 'center'
	},
	{
		title: 'Negative Marks',
		dataIndex: 'negative_marks',
		key: 'negative_marks',
		align: 'center'
	},
	{
		title: 'Total Marks',
		dataIndex: 'total_marks',
		key: 'total_marks',
		align: 'center'
	},
	{
		title: 'Position',
		dataIndex: 'position',
		key: 'position',
		align: 'center'
	},
	{
		title: 'Status',
		dataIndex: 'pass',
		key: 'pass',
		align: 'center',
		render: pass => (
			<Text strong style={{ color: pass ? '#4caf50' : '#f44336' }}>
				{pass ? 'Passed' : 'Failed'}
			</Text>
		)
	}
];

const dataSource = [
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '1st',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '2nd',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '3rd',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '4th',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '5th',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '6th',
		pass: true
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '--',
		pass: false
	},
	{
		id: '352133843',
		student_info: {
			name: 'Tameem Iqbal',
			avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
		},
		roll: '001',
		correct_answer: '85/100',
		incorrect_answer: '85/100',
		negative_marks: '85/100',
		total_marks: '85/100',
		position: '--',
		pass: false
	}
];

const PublishResult = props => {
	return (
		<div className='main-content-wrap'>
			<Affix className='page-header-affix' style={{ margin: '0px -25px' }}>
				<PageHeader
					ghost={true}
					onBack={() => window.history.back()}
					title='Result'
					// subTitle="This is a subtitle"
					extra={[
						<Button key='3' type='primary'>
							Combine <PlusOutlined />
						</Button>,
						<Button key='3' type='primary'>
							Send via SMS
						</Button>,
						<Button key='3' type='primary'>
							Export
						</Button>
					]}
				/>
			</Affix>
			<Row style={{ margin: '0 25px' }}>
				<Col xs={24}>
					<Descriptions title='Exam Title'>
						<Descriptions.Item label='Total Participant'>
							100/100
						</Descriptions.Item>
						<Descriptions.Item label='Highest Score'>90/100</Descriptions.Item>
					</Descriptions>
				</Col>
			</Row>
			<Row style={{ marginTop: 30 }}>
				<Col xs={24}>
					<Table
						columns={columns}
						dataSource={dataSource}
						scroll={{ x: '100%' }}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default PublishResult;
