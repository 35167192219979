import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Tabs, Select, DatePicker, Form, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ExamListCardComponent from './ExamListCard.component';

import { isInstructor } from '../../constants/constFunction';
import ExamDeleteModal from './ExamDeleteModal';

const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ExamListCard = props => {
	const history = useHistory();
	const { courseId, examList } = props;
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const subjectList = useSelector(state => state.courses.subjectList);

	const handleCancelDelete = (e) => {
		console.log(e);
		setDeleteModalVisible(false);
	}

	// useEffect(() => {
	// 	if (publishedGrups.length > 0) {
	// 		toggleDeleteModal()
	// 	}
	// }, [publishedGrups])

	const toggleDeleteModal = () => {
		setDeleteModalVisible(!deleteModalVisible);
	}

	return (
		<Row>
			<Col xs={24}>
				<ExamDeleteModal
					isModalVisible={deleteModalVisible}
					onCancel={handleCancelDelete}
					toggleDeleteModal={toggleDeleteModal}
				/>
				<Tabs
					className='CoursWiseExamListTab'
					defaultActiveKey='live'
					tabBarExtraContent={
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{!isInstructor() && (
								<React.Fragment>
									<Select
										value={selectedSubject}
										placeholder='Select Subject'
										allowClear
										onChange={value => setSelectedSubject(value)}
										style={{ width: 150 }}
									>
										{subjectList &&
											subjectList.map((item, index) => (
												<Option key={index} value={item?._id}>
													{item?.name}
												</Option>
											))}
									</Select>
									<Button
										disabled={!selectedSubject}
										onClick={() =>
											history.push(`/add-exam/${courseId}/${selectedSubject}`)
										}
										type='primary'
										icon={<PlusOutlined />}
										style={{ margin: 5 }}
									>
										Add Exam
									</Button>
								</React.Fragment>
							)}
							<Form>
								<Form.Item style={{ margin: 5 }}>
									<Input
										placeholder='Search Exam'
										onChange={e => props.handleSearchByName(e.target.value)}
									/>
								</Form.Item>
							</Form>
							<RangePicker
								style={{ margin: 5 }}
								onChange={range => {
									props.handleDateWiseFilter(range);
								}}
							/>
						</div>
					}
				>
					<TabPane
						tab={
							<span style={{ fontSize: 16, fontWeight: 800 }}>Live Exam</span>
						}
						key='live'
					>
						<Row
							gutter={[20, 20]}
							style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
						>
							{examList
								.filter(
									item =>
										!item.isPracticeExam &&
										(selectedSubject
											? item?.subjectId === selectedSubject
											: true)
								)
								.map((item, index) => (
									<ExamListCardComponent
										item={item}
										{...props}
										subjectList={subjectList}
										selectedSubject={selectedSubject}
										toggleDeleteModal={toggleDeleteModal}
									/>
								))}
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span style={{ fontSize: 16, fontWeight: 800 }}>
								Practice Exam
							</span>
						}
						key='practice'
					>
						<Row
							gutter={[20, 20]}
							style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
						>
							{examList
								.filter(
									item =>
										item.isPracticeExam &&
										(selectedSubject
											? item?.subjectId === selectedSubject
											: true)
								)
								.map((item, index) => (
									<ExamListCardComponent
										item={item}
										selectedSubject={selectedSubject}
										{...props}
										subjectList={subjectList}
										toggleDeleteModal={toggleDeleteModal}
									/>
								))}
						</Row>
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default ExamListCard;