import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Form, Input, InputNumber, Layout, Row, Select, Space, Tabs } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RichTextEditor from 'react-rte';
import toastr from 'toastr';

import { Table } from 'antd';
import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';
import { addQuestionForExam, addSegmentedExamSubjectIds, clearUnsavedQuestions, setIsSegmentedExam } from '../../stateManager/reducers/examSlice';
import {
  addQuestion,
  resetQuestionToInitial,
  saveQuestionRequest
} from '../../stateManager/reducers/questionSlice';
import SubHeader from '../Common/SubHeader';
import CourseWiseQuestion from '../QuestionBank/CourseWiseQuestion';
import QuestionCard from '../QuestionBank/QuestionCard';
import CreateExamModal from './CreateExamModal';
import ExamQuestionAddFromCSV from './ExamQuestionAddFromCSV';

import CrossIcon from '../../assets/images/icons/cross.png';
import TickIcon from '../../assets/images/icons/tick.png';
const { Content } = Layout;
const { TabPane } = Tabs;

const { Panel } = Collapse;

const { Option } = Select;



const QuestionSelectionTable = ({ questionSelection }) => {
  const columns = [
    {
      title: <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Subject Name</span>,
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span style={{ fontSize: '17px' }}>{text}</span>,
    },
    {
      title: <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Total Selected</span>,
      dataIndex: 'totalSelected',
      key: 'totalSelected',
      render: (text) => <span style={{ fontSize: '17px' }}>{text}</span>,
    },
    {
      title: <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Total Count</span>,
      dataIndex: 'count',
      key: 'count',
      render: (text) => <span style={{ fontSize: '17px' }}>{text}</span>,
    },{
      title: <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Status</span>,
      dataIndex: 'valid',
      key: 'valid',
      render: (valid) =>
        valid ? (
          <img style={{width:'20px'}}  src={TickIcon} />
        ) : (
          <img style={{width:'20px'}} src={CrossIcon} />
        ),
    }
  ];

  const data = Object.entries(questionSelection).map(([subjectId, { name, totalSelected, count }]) => {
    const valid = totalSelected === count;
    return {
      key: subjectId,
      name,
      totalSelected,
      count,
      valid,
    };
  });
  console.log("Data:", data);
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

const AddSegmentedExam = props => {
	const { courseId, subjectId } = props.match.params;
	const dispatch = useDispatch();
	const history = useHistory();
	const [isSaveDisabled, setSaveDisable] = useState(false);
	const [isCreateModalVisible, setCreateModalVisible] = useState(false);
	const [title, setTitle] = useState('');
  const [formValues, setFormValues] = useState({});
  const [questionSelection, setQuestionSelection] = useState({});
	const [instruction, setInstruction] = useState(
		RichTextEditor.createEmptyValue()
	);
    const [form] = Form.useForm();
    const debounceTimeout = React.useRef(null);
    
	const course = JSON.parse(localStorage.getItem('selectedCourse'));
	const subjectList = useSelector(state => state.courses.subjectList);
  const segmentedExamDetails = useSelector(state => state.exams.segmentedExamDetails);
	const questionsForExam = useSelector(state => state.exams.questionsForExam);
    //const questionsCOuntBySubject = getQuestionCountBySubject(questionsForExam)
	const unSavedQuestion = useSelector(state => state.questions.questionList);
    console.log("questionsForExam Questions:", questionsForExam);

    function showToastrErrorWithCancel(message, index) {
    
      const content = `
        <div>
          ${message}
          <button id="cancel-btn-${index}" 
            style="margin-left: 10px; 
                   background-color: #ff6666; 
                   color: white; 
                   border: none; 
                   padding: 5px 10px; 
                   cursor: pointer;">
            Cancel
          </button>
        </div>
      `;
    
      toastr.info(content, "", {
        timeOut: 2000,
        extendedTimeOut: 0,
        closeButton: true,
        allowHtml: true,
        onShown: () => {
          const cancelButton = document.getElementById("cancel-btn");
          if (cancelButton) {
            cancelButton.addEventListener("click", () => {
              toastr.clear(); // Clear all toastr notifications
            });
          }
        },
      });
    }
    function generateCombinations(array, minCount, maxCount) {
      const result = [];
  
      // Helper function to generate combinations
      function helper(temp, start) {
          // Add the current combination to the result if it meets the minimum count
          if (temp.length >= minCount && temp.length <= maxCount) {
              result.push([...temp]);
          }
  
          // Stop recursion if the current combination exceeds maxCount
          if (temp.length >= maxCount) return;
  
          // Generate further combinations by iterating over the remaining elements
          for (let i = start; i < array.length; i++) {
              temp.push(array[i]);
              helper(temp, i + 1);
              temp.pop(); // Backtrack
          }
      }
  
      // Start the recursive combination generation
      helper([], 0);
  
      return result;
  }

  const getQuestionCount = (subjects)=>{
    return subjects.reduce((acc, subject) => {
      if (!subject) return acc;
       const count = subject.count || 0;
       return acc + count; 
     }, 0);

  }

    const validateQuestionCount=()=>{
      const { totalSubjects, totalQuestions, minimumCompulsoryOptionalSubjects } = formValues.segmentedExamDetails || {};

      const { mandatorySubjects = [], compulsoryOptionalSubjects = [], optionalSubjects = [] } = formValues.segmentedExamDetails || {};
      const allSubjects = [...mandatorySubjects, ...compulsoryOptionalSubjects, ...optionalSubjects].map((subject) => subject?.subjectId?.value);

      const maxCount = totalSubjects - mandatorySubjects.length;
      const minCount = minimumCompulsoryOptionalSubjects;

      const combinations = generateCombinations(compulsoryOptionalSubjects, minCount, maxCount);
      const mandatoryQuestionsCount = getQuestionCount(mandatorySubjects);
      const availableQuestionsCount = totalQuestions - mandatoryQuestionsCount;
      console.log("Mandatory:", mandatorySubjects.length);
      console.log("Mandatory Questions Count:", mandatoryQuestionsCount);
      console.log("Available Questions Count:", availableQuestionsCount);
      let all = [];
      combinations.map((combination) => {
        const selectedCount = mandatorySubjects.length + combination.length;
        const availableCount = totalSubjects - selectedCount;
        console.log("Available Count:", availableCount);
        console.log("Compulsory Optional:", combination.length);
        let compulsoryOptionalQuestionsCount = getQuestionCount(combination)
        console.log("Compulsory Optional Questions Count:", compulsoryOptionalQuestionsCount);
       
        if(availableCount > 0 && optionalSubjects.length > 0){
          const combinationOfOptional = generateCombinations(optionalSubjects, availableCount, availableCount);
          combinationOfOptional.map((optional) => all.push([...mandatorySubjects, ...combination, ...optional]));
        }else{
          all.push([...mandatorySubjects, ...combination]);
        }
     });   

     console.log("All:", all);

     const valid = all.map((combination, index) => {
      const questionsCount = combination.reduce((acc, subject) => {
        if (!subject) return acc;
          const count = subject.count || 0;
          return acc + count;
      }, 0);
     
      if(questionsCount === totalQuestions){
        return true;
      }
      else{
        const subjects = combination.map((subject) => subject?.subjectId?.label);
        console.log("Invalid Combination:", subjects);
        showToastrErrorWithCancel(`Invalid Combination: ${subjects.join(", ")}, Questions Count: ${questionsCount}`, index);
        return false;
      }
    });

    console.log("Valid Combinations:", valid);

    return valid.every((item) => item === true);


  }
    const validateSegmentedDetails = ()=>{

      const { totalSubjects, totalQuestions, minimumCompulsoryOptionalSubjects, mandatorySubjectsCount } = formValues.segmentedExamDetails || {};

      const { mandatorySubjects = [], compulsoryOptionalSubjects = [], optionalSubjects = [] } = formValues.segmentedExamDetails || {};
      const allSubjects = [...mandatorySubjects, ...compulsoryOptionalSubjects, ...optionalSubjects].map((subject) => subject?.subjectId?.value);

      if(mandatorySubjectsCount !== mandatorySubjects.length){
        toastr.error('Select required number of Mandatory Subjects');
      }
      
      if(compulsoryOptionalSubjects.length < minimumCompulsoryOptionalSubjects){
        toastr.error('Minimum Compulsory Optional Subjects should be less than or equal to Compulsory Optional Subjects');
        return false;
      }
      if(totalSubjects > allSubjects.length){
        toastr.error('Please select required number of subjects');
        return false;
      }
      if(Object.values(questionSelection).some(item => item.totalSelected < item.count)){
        toastr.error('Please select required number of questions for each subject');
        return false;
      }

      if(Object.values(questionSelection).some(item => item.totalSelected > item.count)){
        toastr.error('Please select less number of questions for each subject');
        return false;
      }

     return true;

    }

    const getQuestionCountBySubject = (questions) => {
        return questions.reduce((acc, question) => {
          const { subjectId } = question; // Assuming each question has a subjectId field
    
          if (subjectId) {
            acc[subjectId] = (acc[subjectId] || 0) + 1;
          }
          return acc;
        }, {});
      };

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}

    dispatch(setIsSegmentedExam(true));
		fetchData();
	}, [courseId]);

  useEffect(() => {
    console.log("Segmented Exam Details:", segmentedExamDetails);
    

    if (!segmentedExamDetails) return;
    const m = segmentedExamDetails.mandatorySubjects.map((item) => {
      return {
        subjectId: {
          label: getSubjectNameById(item.subjectId),
          value: item.subjectId,
          key: item.subjectId,
        },
        count: item.count,
      };
    });

    const c = segmentedExamDetails.compulsoryOptionalSubjects.map((item) => {
      return {
        subjectId: {
          label: getSubjectNameById(item.subjectId),
          value: item.subjectId,
          key: item.subjectId,
        },
        count: item.count,
      };
    });

    const o = segmentedExamDetails.optionalSubjects.map((item) => {
      return {
        subjectId: {
          label: getSubjectNameById(item.subjectId),
          value: item.subjectId,
          key: item.subjectId,
        },
        count: item.count,
      };
    }
    );

    const mc = segmentedExamDetails.mandatorySubjects.length;
    form.setFieldsValue({
      segmentedExamDetails: {
        totalSubjects: segmentedExamDetails.totalSubjects,
        totalQuestions: segmentedExamDetails.totalQuestions,
        mandatorySubjectsCount: mc || 0,
        mandatorySubjects: m,
        compulsoryOptionalSubjects: c,
        optionalSubjects: o,
        minimumCompulsoryOptionalSubjects: segmentedExamDetails.minimumCompulsoryOptionalSubjects,
      }
    });

    setFormValues({
      segmentedExamDetails: {
        totalSubjects: segmentedExamDetails.totalSubjects,
        totalQuestions: segmentedExamDetails.totalQuestions,
        mandatorySubjectsCount: mc || 0,
        mandatorySubjects: m,
        compulsoryOptionalSubjects: c,
        optionalSubjects: o,
        minimumCompulsoryOptionalSubjects: segmentedExamDetails.minimumCompulsoryOptionalSubjects,
      }
    });

  }, [segmentedExamDetails]);

  useEffect(() => {
    console.log("Form Values:", formValues);
  
    const { mandatorySubjects = [], compulsoryOptionalSubjects = [], optionalSubjects = [] } = formValues.segmentedExamDetails || {};
    const allSubjects = [...mandatorySubjects, ...compulsoryOptionalSubjects, ...optionalSubjects];
    console.log("All Subjects:", allSubjects);

    const subjectIds = allSubjects.map((subject) => subject?.subjectId?.value);

    if(subjectIds.length > 0)
      dispatch(addSegmentedExamSubjectIds(subjectIds));
  
    // Create a map of subjectId to count for faster lookups
    const questionCountMap = questionsForExam.reduce((acc, question) => {
      acc[question.subjectId] = (acc[question.subjectId] || 0) + 1;
      return acc;
    }, {});
  
    // Build questionSelection object
    const questionSelection = allSubjects.reduce((acc, subject) => {
      if (!subject || !subject.subjectId) return acc;
  
      const subjectId = subject.subjectId.value;
      acc[subjectId] = {
        name: subject.subjectId.label,
        totalSelected: questionCountMap[subjectId] || 0,
        count: subject.count || 0,
      };
  
      return acc;
    }, {});
  
    setQuestionSelection(questionSelection);
  }, [questionsForExam, formValues.segmentedExamDetails]);
  

	const validateQuestion = async () => {
		let isValid = true;
		for (let i = 0; i < unSavedQuestion.length; i++) {
			const question = unSavedQuestion[i];
			if (!question.title || question.title.length < 5) {
				toastr.error('Please add a valid title to the question ' + (i + 1));
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.options || question.options.length < 2)
			) {
				toastr.error(
					'Please add at least two valid option for the question ' + (i + 1)
				);
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.answer || question.answer.length < 1)
			) {
				toastr.error('Please select an answer for the question ' + (i + 1));
				isValid = false;
				break;
			}
		}

		if (isValid) {
			await saveQuestion();
		}
		return;
	};

	const saveQuestion = async () => {
		const questions = unSavedQuestion.map(item => {
			if (item.hasOwnProperty('point')) {
				const obj = { ...item };
				delete obj['point'];
				return {
					...obj,
					courseId
				};
			}
			return {
				...item,
				courseId
			};
		});
		const data = { questions: questions };

		try {
			const res = await dispatch(saveQuestionRequest(data));

			if (res?.payload?.status === '200') {
				const resData = res.payload.data;
				const dataToAdd = resData.map((item, index) => {
					return { ...item, point: unSavedQuestion[index]?.point };
				});
				// dispatch(addQuestionForExam(dataToAdd));
        dataToAdd.forEach((question) => {
          dispatch(addQuestionForExam({
            selected: true,
            question,
          }));
        });
				dispatch(clearUnsavedQuestions());
				dispatch(resetQuestionToInitial());
				setSaveDisable(false);
			}
		} catch (error) {
			console.log(error);
		}
		// history.goBack();
	};

	const handleAddQuestion = () => {
		dispatch(
			addQuestion({
				title: '',
				type: 'MCQ',
				options: [],
				answer: []
			})
		);
	};

	const toggleCreateModal = () => {
		setCreateModalVisible(false);
		history.goBack();
	};

	const debouncedInput = useCallback(
		debounce((name, value) => {
			if (name === 'title') {
				setTitle(value);
			} else if (name === 'instruction') {
				setInstruction(value);
			}
		}, 1000),
		[]
	);
    const getSubjectNameById = (id) => {
        const subject = subjectList.find((sub) => sub._id === id);
        return subject ? subject.name : "Unknown Subject";
      };
    const getSelectedSubjects = () => {
      console.log("getSelectedSubjects");
    const values = form.getFieldsValue(["segmentedExamDetails"]);
    const { mandatorySubjects = [], compulsoryOptionalSubjects = [], optionalSubjects = [] } =
      values.segmentedExamDetails || {};

      console.log("Mandatory Subjects:", mandatorySubjects);
        console.log("Compulsory Optional Subjects:", compulsoryOptionalSubjects);
        console.log("Optional Subjects:", optionalSubjects);

    // Combine all selected subject IDs
    return [
      ...mandatorySubjects.map((item) => item?.subjectId?.value).filter(Boolean),
      ...compulsoryOptionalSubjects.map((item) => item?.subjectId?.value).filter(Boolean),
      ...optionalSubjects.map((item) => item?.subjectId?.value).filter(Boolean),
    ];
  };

  // Function to filter subjects dynamically
  const getFilteredSubjects = (selectedSubjects) => {
    console.log("getFilteredSubjects Selected Subjects:", selectedSubjects);
    return subjectList.filter((subject) => !selectedSubjects.includes(subject._id));
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      setFormValues(allValues);
    }, 500); // 500ms debounce delay
  };

  const getCountBySubject = (subjectId) => {
    // Get the count of questions for the given subjectId from the form values
    const values = form.getFieldsValue(["segmentedExamDetails"]);
    const { mandatorySubjects = [], compulsoryOptionalSubjects = [], optionalSubjects = [] } =
      values.segmentedExamDetails || {};

    const allSubjects = [...mandatorySubjects, ...compulsoryOptionalSubjects, ...optionalSubjects];

    return allSubjects.reduce((acc, item) => {
      if (item && item.subjectId === subjectId) {
        return acc + item.count;
      }
      return acc;
    }, 0);
  };

  const disableAddManadatorySubject = () => {
    const values = form.getFieldsValue(["segmentedExamDetails"]);
    const { mandatorySubjects = [], mandatorySubjectsCount = 0 } = values.segmentedExamDetails || {};

    console.log("disableAddManadatorySubject:", mandatorySubjects.length, mandatorySubjectsCount);
    if(mandatorySubjects.length >= mandatorySubjectsCount){
      return true;
    }
    return false;
  };
    
  
    const segmentedDetailsForm = () => {
      console.log("segmentedDetailsForm");
        return (
            <div>
                 <Form
              form={form}
              layout="vertical"
              onFinish={(values) => console.log("Form Values:", values)}
              onValuesChange={handleValuesChange}
              
            >
              {/* Total Subjects */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Total Subjects"
                    name={["segmentedExamDetails", "totalSubjects"]}
                    rules={[
                      { required: true, message: "Total Subjects is required" },
                      {
                        validator: (_, value) =>
                          value >= 1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Total Subjects must be at least 1")),
                      },
                    ]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Mandatory Subjects"
                    name={["segmentedExamDetails", "mandatorySubjectsCount"]}
                    rules={[
                      { required: true, message: "Total Subjects is required" },
                      {
                        validator: (_, value) =>
                          value >= 1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Total Subjects must be at least 1")),
                      },
                    ]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Total Questions"
                    min={1}
                    name={["segmentedExamDetails", "totalQuestions"]}
                    rules={[{ required: true, message: "Total Questions is required" },
                      {
                        validator: (_, value) =>
                          value >= 1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Total Questions must be at least 1")),
                      },
                    ]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Min Compulsory Optional Subjects"
                    min={1}
                    name={["segmentedExamDetails", "minimumCompulsoryOptionalSubjects"]}
                    rules={[{ required: true, message: "This field is required" },
                      {
                        validator: (_, value) =>
                          value >= 1
                            ? Promise.resolve()
                            : Promise.reject(new Error("Subjects must be at least 1")),
                      },
                    ]}
                  >
                    <InputNumber min={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
        
              {/* Mandatory Subjects */}
              <Card style={{
                marginBottom: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "5px",
                backgroundColor: "#fafafa",
              }}>
              <Form.List name={["segmentedExamDetails", "mandatorySubjects"]}>
                {(fields, { add, remove }) => (
                  <>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                  <h2>Mandatory Subjects</h2>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        disabled={disableAddManadatorySubject()}
                        style={{ width: "100%", }}
                      >
                        Add Mandatory Subject
                      </Button>
                    </Form.Item>
                  </div>
                    
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} align="middle">
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "subjectId"]}
                            label="Subject"
                            rules={[{ required: true, message: "Please select a subject" }]}
                          >
                            <Select placeholder="Select a Subject" labelInValue >
                              {getFilteredSubjects(getSelectedSubjects()).map((subject) => (
                                <Option key={subject._id} value={subject._id}>
                                  {subject.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "count"]}
                            label="Count"
                            rules={[{ required: true, message: "Count is required" }]}
                          >
                            <InputNumber min={1} style={{ width: "100%" }}/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <MinusCircleOutlined onClick={() => {
                              
                              console.log("Name:", name, key);
                              // find all questions with this subjectId and remove them
                             

                              const subjectId = form.getFieldValue(["segmentedExamDetails", "mandatorySubjects", name, "subjectId"]);
                              const questionsToRemove = questionsForExam.filter((question) => question.subjectId === subjectId);
                              questionsToRemove.forEach((question) => {
                                dispatch(addQuestionForExam({
                                  selected: false,
                                  question,
                                }));
                              }
                              );

                              remove(name);

                          }} />
                        </Col>
                      </Row>
                    ))}
                   
                  </>
                )}
              </Form.List>
              </Card>
             
        
              {/* Compulsory Optional Subjects */}
              <Card  style={{
                marginBottom: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "5px",
                backgroundColor: "#fafafa",
              }}>
              <Form.List name={["segmentedExamDetails", "compulsoryOptionalSubjects"]}>
                {(fields, { add, remove }) => (
                  <>
                   <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>
                      <h2>Compulsory Optional Subjects</h2>
                      <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        style={{ width: "100%" }}
                      >
                        Add Compulsory Optional Subject
                      </Button>
                    </Form.Item>
                  </div>
                  
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} align="middle">
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "subjectId"]}
                            label="Subject"
                            rules={[{ required: true, message: "Please select a subject" }]}
                          >
                            <Select placeholder="Select a Subject" labelInValue>
                              {getFilteredSubjects(getSelectedSubjects()).map((subject) => (
                                <Option key={subject._id} value={subject._id}>
                                  {subject.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "count"]}
                            label="Count"
                            rules={[{ required: true, message: "Count is required" }]}
                          >
                            <InputNumber min={1} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <MinusCircleOutlined onClick={() => {

                            const subjectId = form.getFieldValue(["segmentedExamDetails", "compulsoryOptionalSubjects", name, "subjectId"]);
                            const questionsToRemove = questionsForExam.filter((question) => question.subjectId === subjectId);
                            questionsToRemove.forEach((question) => {
                              dispatch(addQuestionForExam({
                                selected: false,
                                question,
                              }));
                            }
                            );

                            remove(name);

                          }} />
                        </Col>
                      </Row>
                    ))}
                    
                  </>
                )}
              </Form.List>
              </Card>
              
        
              {/* Optional Subjects */}
              <Card  style={{
                marginBottom: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "5px",
                backgroundColor: "#fafafa",
              }}>
              <Form.List name={["segmentedExamDetails", "optionalSubjects"]}>
                {(fields, { add, remove }) => (
                  <>
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}>

                    <h2>Optional Subjects</h2>
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                        style={{ width: "100%" }}
                      >
                        Add Optional Subject
                      </Button>
                    </Form.Item>
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <Row key={key} gutter={16} align="middle">
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "subjectId"]}
                            label="Subject"
                            rules={[{ required: true, message: "Please select a subject" }]}
                          >
                            <Select placeholder="Select a Subject" labelInValue>
                              {getFilteredSubjects(getSelectedSubjects()).map((subject) => (
                                <Option key={subject._id} value={subject._id}>
                                  {subject.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            {...restField}
                            name={[name, "count"]}
                            label="Count"
                            rules={[{ required: true, message: "Count is required" }]}
                          >
                            <InputNumber min={1} style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <MinusCircleOutlined onClick={() => {

                            const subjectId = form.getFieldValue(["segmentedExamDetails", "optionalSubjects", name, "subjectId"]);
                            const questionsToRemove = questionsForExam.filter((question) => question.subjectId === subjectId);
                            questionsToRemove.forEach((question) => {
                              dispatch(addQuestionForExam({
                                selected: false,
                                question,
                              }));
                            }
                            );

                            remove(name);
                          }} />
                        </Col>
                      </Row>
                    ))}
                   
                  </>
                )}
              </Form.List>
              </Card>
              
            </Form>
            <Card  style={{
                marginBottom: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "5px",
                backgroundColor: "#fafafa",
              }}>

      <h2>Selected Questions Count by Subject</h2>
      {/* {Object.entries(questionSelection).map(([subjectId, { name, totalSelected, count }]) => (
        <div key={subjectId}>
          <strong>{name}</strong>: {totalSelected} / {count}
        </div>
      ))} */}
      <QuestionSelectionTable questionSelection={questionSelection} />
       </Card>
            
            </div>
           
          );
    };

    const prepareSegmentedExamData = (values) => {
      console.log("prepareSegmentedExamData:", values);
      if(!isCreateModalVisible) return {};
      const segmentedExamDetails = values.segmentedExamDetails || {};
    
      return {
        totalSubjects: segmentedExamDetails.totalSubjects,
        totalQuestions: segmentedExamDetails.totalQuestions,
        minimumCompulsoryOptionalSubjects: segmentedExamDetails.minimumCompulsoryOptionalSubjects || 0,
        mandatorySubjects: segmentedExamDetails.mandatorySubjects?.map((subject) => ({
          subjectId: subject?.subjectId?.value,
          count: subject.count,
        })) || [],
        compulsoryOptionalSubjects: segmentedExamDetails.compulsoryOptionalSubjects?.map((subject) => ({
          subjectId: subject?.subjectId?.value,
          count: subject.count,
        })) || [],
        optionalSubjects: segmentedExamDetails.optionalSubjects?.map((subject) => ({
          subjectId: subject?.subjectId?.value,
          count: subject.count,
        })) || [],
      };
    };

	return (
		<React.Fragment>
			<CreateExamModal
				isVisible={isCreateModalVisible}
				toggleModal={toggleCreateModal.bind(this)}
				courseId={courseId}
				subjectId={subjectId}
				title={title}
        isSegmentedExam = {true}
        segmentedExamDetails={prepareSegmentedExamData(form.getFieldsValue())}
				instruction={instruction}
			/>

			<SubHeader headText={course.name} backIcon={true} />

			<Content>
				<Card style={{ width: '100%', marginTop: 10 }}>
					{!!title && !!instruction && (
						<Button
							onClick={() => {
                //validate the form
                form.validateFields().then((values) => {
                  console.log("Form Values:", values);


                  if(!validateSegmentedDetails()){
                    return;
                  }
                  if(!validateQuestionCount()){
                    return;
                  }
                  // setValues(values);
                  setCreateModalVisible(true);
                }
                ).catch((error) => {
                  console.log("Error:", error);
                }
                );

                // setCreateModalVisible(true);
              }}
							style={{ alignSelf: 'flex-end' }}
							disabled={isSaveDisabled}
						>
							Save Exam
						</Button>
					)}
					<Space direction='vertical' className='main'>
						<Input
							onChange={e => debouncedInput('title', e.target.value)}
							placeholder='Exam Title'
						/>
						<RichTextEditor
							value={instruction}
							onChange={value => setInstruction(value)}
						/>
					</Space>
				</Card>
                <Card>
                    <Collapse defaultActiveKey={['1']}>
                    <Panel header='Segmented Exam Details' key='1'>
                    {segmentedDetailsForm()}
                    </Panel>
                    </Collapse>
                </Card>

				<Card>
					<Tabs type='card'>
						<TabPane tab='Add Questions' key='1'>
							{questionsForExam.map((item, index) => (
								<QuestionCard
									isExam={true}
									questionObject={item}
									questionIndex={index}
								/>
							))}
							{unSavedQuestion.map((item, index) => (
								<QuestionCard
									courseId={courseId}
									subjectList={subjectList}
									isExam={true}
									isCreateForExam={true}
									questionObject={item}
									questionIndex={index}
								/>
							))}
							<div className='main'>
								<div style={{ width: 500 }}>
									<Button
										type='dashed'
										icon={<PlusOutlined />}
										onClick={() => handleAddQuestion()}
									>
										Add Another Question
									</Button>
									<Button
										style={{ marginLeft: 10 }}
										type='primary'
										onClick={() => validateQuestion()}
									>
										Save Questions
									</Button>
								</div>
							</div>
						</TabPane>
						<TabPane tab='Import from Question Bank' key='2'>
							<CourseWiseQuestion courseId={courseId} />
						</TabPane>
						<TabPane tab='Import from CSV ' key='3'>
							<ExamQuestionAddFromCSV
								courseId={courseId}
								subjectList={subjectList}
							/>
						</TabPane>
					</Tabs>
				</Card>
			</Content>
		</React.Fragment>
	);
};

export default AddSegmentedExam;
