import React, { useState, useEffect } from "react";
import { Modal, Table, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getBranchStatsById } from "../../stateManager/reducers/mentorSlice";

import { CSVDownloader } from "react-papaparse";


const QuestionDetailsModal = ({ branch, startDate, endDate, onClose, visible }) => {
  // Define columns for the Ant Design table

 
    const dispatch = useDispatch();
    const data = useSelector((state) => state.mentor.branchStatsById);

    const stats = {};

    // "_id": "672210fdb14e9a0a258783a9",
    // "status": "COMPLETED",
    // "courseId": "660d2d3bf113e1f50a92017c",
    // "subjectId": "66768d311e6ec378043c43fc",
    // "chapterId": "670f3ef7d5ca25cb89c5d71c",
    // "course": "Testing 2024",
    // "subject": "Subject A",
    // "chapter": "test1"
    data.forEach((item) => {

     // group question by courseId and subjectId
     
        if (!stats[item.courseId]) {
            stats[item.courseId] = {};
        }
        if (!stats[item.courseId][item.subjectId]) {
            stats[item.courseId][item.subjectId] = {
                course: item.course,
                subject: item.subject,
                totalQuestions: 0,
                completedQuestions: 0,
                declinedQuestions: 0,
                notYetAnsweredQuestions: 0,
            };
        }

        stats[item.courseId][item.subjectId].totalQuestions += 1;

        if (item.status === "COMPLETED") {
            stats[item.courseId][item.subjectId].completedQuestions += 1;
        }
        if (item.status === "DECLINED") {
            stats[item.courseId][item.subjectId].declinedQuestions += 1;
        }
        if (item.answers && item.answers === 0) {
            stats[item.courseId][item.subjectId].notYetAnsweredQuestions += 1;
        }



    });

    console.log(stats);

const getCsvData = () => {
    const data = [];
    Object.entries(stats).forEach(([courseId, subjects]) => {
      Object.entries(subjects).forEach(([subjectId, details]) => {
        data.push({
          course: details.course,
          subject: details.subject,
          totalQuestions: details.totalQuestions,
          completedQuestions: details.completedQuestions,
          declinedQuestions: details.declinedQuestions,
          notYetAnsweredQuestions: details.notYetAnsweredQuestions,
        });
      });
    }
    );
    return data;
  };

  useEffect(() => {
    // Fetch branch statistics data
    const getBranchStatsData = async () => {
      // Call API to get branch statistics data
      await dispatch(getBranchStatsById({branchId: branch.branchId, startDate, endDate}));
    };

    // Fetch data only when the modal is visible
    branch && visible && getBranchStatsData();
  
    }, [visible, branch, startDate, endDate]);

    const columns = [
        {
          title: "Course",
          dataIndex: "course",
          key: "course",
        },
        {
          title: "Subject",
          dataIndex: "subject",
          key: "subject",
        },
        {
          title: "Total Questions",
          dataIndex: "totalQuestions",
          key: "totalQuestions",
        },
        {
          title: "Completed Questions",
          dataIndex: "completedQuestions",
          key: "completedQuestions",
        },
        {
          title: "Declined Questions",
          dataIndex: "declinedQuestions",
          key: "declinedQuestions",
        },
        {
          title: "Not Yet Answered Questions",
          dataIndex: "notYetAnsweredQuestions",
          key: "notYetAnsweredQuestions",
        },
      ];

      const tableData = Object.entries(stats).flatMap(([courseId, subjects]) =>
        Object.entries(subjects).map(([subjectId, details]) => ({
          course: details.course,
          subject: details.subject,
          totalQuestions: details.totalQuestions,
          completedQuestions: details.completedQuestions,
          declinedQuestions: details.declinedQuestions,
          notYetAnsweredQuestions: details.notYetAnsweredQuestions,
          key: `${courseId}-${subjectId}`, // Unique key for table rows
        }))
      );

      if (!branch) {
        return <></>
        }
    

  return (
    <Modal
      title={`${branch.name} Branch Statistics`}
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800} // Adjust modal width as needed
    >
      			<CSVDownloader
			data={getCsvData()}
			type='link'
			filename={`branch_stats_${branch.name}`}
			bom={true}
		>
			
			<Button
			size={'large'}
			danger={true}
			type={'primary'}
			style={{
				backgroundColor: '#EE473F',
				borderRadius   : '8px',
				height         : '56px',
				minWidth       : '200px',
			}}>
				{'Download CSV'}
			</Button>
			</CSVDownloader>
      <Table
        dataSource={tableData}
        columns={columns}
        pagination={false} // Disable pagination inside the modal
        scroll={{
            y: 500, // Set vertical scroll height (in pixels)
          
          }}
      />
    </Modal>
  );
};


export default QuestionDetailsModal;
