import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import AdminEntry from '../Admin';

import Login from '../Auth/Login';
import ForgetPassword from '../Auth/ForgetPassword';
import Registration from '../Auth/Registration';
import VerifyPage from '../Auth/Verify';
import VerifyLogin from '../Auth/VerifyLogin';

const AdminRoutes = props => {
	let loggedIn = localStorage.getItem('user') ? true : false;

	let studentLoggedIn = localStorage.getItem('student') ? true : false;

	if (studentLoggedIn) {
		window.location.replace('/');
	}

	if (loggedIn) {
		return (
			<Router>
				<AdminEntry />
			</Router>
		);
	}
	
	return (
		<Router>
			<Switch>
				<Route exact path='/' component={Login} />
				<Route path='/login' component={Login} />
				<Route path='/forget-password' component={ForgetPassword} />
				<Route path='/registration' component={Registration} />
				<Route path='/verify-account' component={VerifyPage} />
				<Route path='/verify-login' component={VerifyLogin} />
			</Switch>
		</Router>
	);
};

export default AdminRoutes;
