import React, { useState } from 'react'
import { Image } from 'antd';
import { bucket_url } from '../../../../constants/constString';


function EvaluatePreview({ imageKey, toggleModal }) {
    const [visible, setVisible] = useState(true);
    console.log("imageKey", imageKey);

    return (
        <>
            <div className='mt-2'>
                <div className='d-none'>
                    <Image
                        width={'100%'}
                        style={{
                            display: 'none',
                        }}
                        preview={{
                            visible,
                            src: `${bucket_url + imageKey}`,
                            onVisibleChange: (value) => {
                                toggleModal()
                            },
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default EvaluatePreview