import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownOutlined } from '@ant-design/icons';

import GroupExamCard from './GroupExamCard';
import CombineResultModal from './CombineResultModal';
import { getExamByCourse } from '../../stateManager/reducers/examSlice';
import { isAdminOrModerator } from '../../constants/constFunction';

const GroupExamList = props => {
	const { courseId, isPracticeExam, groupId } = props;

	const dispatch = useDispatch();
	const [filterType, setFilterType] = useState(undefined);
	const [isCombineModalVisible, setCombineModalVisible] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [showLoadMore, setShowLoadMore] = useState(true);
	const examList = useSelector(state =>
		state.exams.examListByCourse.filter(
			item =>
				item?.isPracticeExam === isPracticeExam &&
				item?.status === 'approved' &&
				item?.title?.toLowerCase()?.includes(searchQuery)
		)
	);
	const examListByCourse = useSelector(state => state.exams.examListByCourse);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getExamByCourse({ courseId }));
		}
		fetchData();
	}, [courseId]);

	const toggleCombineModal = () =>
		setCombineModalVisible(!isCombineModalVisible);

	const handleLoadMore = async () => {
		const isLoadMore = true;
		const lastId =
			isLoadMore && !!examListByCourse && examListByCourse.length > 0
				? examListByCourse[examListByCourse.length - 1]._id
				: undefined;
		const res = await dispatch(
			getExamByCourse({ isLoadMore, courseId, startDate: "", endDate: "", lastId })
		);
		if (
			res &&
			res?.payload?.data?.data &&
			res?.payload?.data?.data?.length != 50
		) {
			setShowLoadMore(false);
		}
	};

	return (
		<React.Fragment>
			{isAdminOrModerator() && (
				<CombineResultModal
					groupId={groupId}
					isVisible={isCombineModalVisible}
					toggleModal={toggleCombineModal.bind(this)}
					examList={examList}
				/>
			)}
			<Row gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}>
				<Col xs={24} sm={24} md={4} lg={4}>
					<Input
						placeholder='Search exam'
						onChange={e => setSearchQuery(e.target.value)}
					/>
				</Col>
				<Col xs={24} sm={24} md={4} lg={4}>
					{!isPracticeExam && isAdminOrModerator() && (
						<Button
							onClick={() => setCombineModalVisible(!isCombineModalVisible)}
							style={{ marginBottom: 20 }}
							type='primary'
						>
							Combine Results
						</Button>
					)}
				</Col>
			</Row>

			<Row gutter={[8]}>
				{examList.map(item => (
					<Col xs={24} sm={12} md={8} lg={8}>
						<GroupExamCard
							examData={item}
							courseId={courseId}
							groupId={groupId}
							isPracticeExam={isPracticeExam}
							filterType={filterType}
						/>
					</Col>
				))}
			</Row>
			{showLoadMore && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center'
					}}
				>
					<Button onClick={() => handleLoadMore()} type='link'>
						Load More <ArrowDownOutlined />
					</Button>
				</div>
			)}
		</React.Fragment>
	);
};

export default GroupExamList;
