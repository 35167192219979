import React, { useEffect, useState, useMemo } from 'react';
import { Modal, Form, Input, Upload, Divider, Progress } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import fs from 'fs';
import axios from 'axios';
import axiosRetry from 'axios-retry';

import {
	addVideo,
	patchFileRequest
} from '../../stateManager/reducers/contentSlice';
import { instance } from '../../constants/constString';


const { Dragger } = Upload;
const partSize = 50 * 1024 * 1024;

const UploadVideoModal = props => {
	const {
		chapters,
		courses,
		lectures,
		questionSolves,
		subjects,
		isVisible,
		contentName,
	} = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [file, setFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
	const [title, setTitle] = useState(undefined);
    const [uploadPercenatge, setUploadPercentage] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const videoSrc = useMemo(() => {
        return file ? URL.createObjectURL(file) : null;
      }, [file]);

	const status = useSelector(state => state.contents.status);

    axiosRetry(axios, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

    async function uploadParts(signedUrls) {
        const multipartMap = { Parts: [] };
        console.log('partSize', partSize);
        const uploadPromises = signedUrls.map(async ({ signedUrl, partNumber }, index) => {
          const start = (partNumber - 1) * partSize;
          const end = Math.min(partNumber * partSize, file.size);
          const partBuffer = file.slice(start, end);
      
          console.log(`Uploading part ${partNumber}...`, partBuffer);
          try {
            const response = await axios.put(signedUrl, partBuffer, {
              headers: {
                'Content-Type': 'application/octet-stream'
              }
            });
      
            multipartMap.Parts.push({
              ETag: response.headers.etag,
              PartNumber: partNumber
            });
      
            const percentage =  Math.ceil(multipartMap.Parts.length/ signedUrls.length * 100);
            console.log('percentage', percentage);
            setUploadPercentage(percentage);
      
            console.log(`Uploaded part ${partNumber} successfully`);
          } catch (err) {
            console.error(`Error uploading part ${partNumber}:`, err);
            throw err;
          }
        });
      
        await Promise.all(uploadPromises);
        
        return multipartMap;
      }

	const handleSubmit = async () => {

        if (!file) {
            alert('Please select a file');
            return;
        }
        if (!thumbnail) {
            alert('Please select a thumbnail');
            return;
        }

	
        setIsUploading(true);

        const totalParts = Math.ceil(file.size / partSize);

        // setUploadPercentage(5);
		try {
            // const res = await instance.get(
            //     `/content/signed-request?mimeType=${file.type}`
            // );
            // const { signedUrl, key } = res?.data?.data;
            // const response = await patchFileRequest({ signedUrl, file });
            // console.log('response', response);
            // setUploadPercentage(70);
            // multipart upload

            console.log('file', file);
            let res = await instance.get(`/content/create-sign-multipart-upload?mimeType=${file.type}`);
            const { key, uploadId } = res?.data?.data;

            console.log('key', key, 'uploadId', uploadId);
            res = await instance.post(`/content/generate-signed-urls`, {
                key,
                uploadId,
                fileSize: file.size,
            });

            const signedUrls = res?.data?.data;

            console.log('signedUrls', signedUrls);

           const multipartMap = await uploadParts(signedUrls);
           //sort parts
            multipartMap.Parts.sort((a, b) => a.PartNumber - b.PartNumber);

           console.log('multipartMap', multipartMap);

              res = await instance.post(`/content/complete-multipart-upload`, {
                key,
                uploadId,
                parts: multipartMap.Parts,
                });
            console.log('response', res);
                
            //upload thumbnail
            const res1 = await instance.get(
                `/content/signed-request?mimeType=${thumbnail.type}`
            );
            const { signedUrl: signedUrl1, key: key1 } = res1?.data?.data;
            const response1 = await patchFileRequest({ signedUrl: signedUrl1, file: thumbnail });
            console.log('response', response1);
       
                const data = {
                    title,
                    type: 'video',
                    key: key,
                    chapters: chapters ? [chapters] : undefined,
                    courses: [courses],
                    lectures: lectures ? [lectures] : undefined,
                    questionSolves: questionSolves ? [questionSolves] : undefined,
                    subjects: [subjects],
                    thumbnail: key1,
                    isVOD: true,
					isAvailable: false
                };
                try {
                    const res1 = await dispatch(addVideo(data));
                } catch (err) {
                    console.log(err);
                }
                setIsUploading(false);
                resetFields();
                props.toggleModal();
            
        } catch (error) {
            alert('Error uploading file');
            setIsUploading(false);
           
        }
	};


    const resetFields = () => {
        form.resetFields();
        setFile(null);
        setThumbnail(null);
        setUploadPercentage(0);
    }


	return (
		<Modal
			maskClosable={false}
			open={isVisible}
			centered
			destroyOnClose={true}
			okText={contentName === 'file' ? 'Add Note' : 'Upload Video'}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => handleSubmit()}
			onCancel={() => {
                resetFields();
                props.toggleModal();
            }
            }
			title={<strong>Add Video</strong>}
		>
            
			<Form form={form} layout='vertical'>
				<Form.Item
					label={ 'Video Title'}
					rules={[{ required: true, message: 'Please enter video title' }]}
					name='title'
				>
					<Input
						placeholder='Enter video title'
						onChange={e => setTitle(e.target.value)}
					/>
				</Form.Item>
                {/* thumbnail */}
               
            
			</Form>

            <Dragger
            accept='video/*'
            multiple={false}        

            onChange={e => {

            setFile(e.file.originFileObj)
            }}
            onRemove={() => {
                console.log('removed');
                setFile(null);
            }}
            >
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p>Drag and drop file or click to browse</p>
            </Dragger>
                    {file && (
                <div>
                    <Divider />
                    <video 
                    
                    style={{
                    marginTop: 20,
                }} src={videoSrc}  width="100%" controls ></video>
                </div>
				
			)}

            {
                isUploading && (
                    <div>
                        <Divider />
                        <Progress percent={uploadPercenatge} />
                    </div>
                  
                )
            }
            <div>
            <Divider />
			<h2><span style={{
                color: 'red'
            
            }}>*</span> Thumbnail</h2>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            
            }}>
                 <input type="file" accept="image/*" onChange={(e)=>{
                    setThumbnail(e.target.files[0]);
                 }} />
                {thumbnail && (
                   
                    <img src={URL.createObjectURL(thumbnail)} alt="Preview" style={{ maxWidth: '25%' }} />
                   
                )}
            </div>
               
                </div>


		</Modal>
	);
};

export default UploadVideoModal;
