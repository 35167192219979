import React, { useRef, useEffect } from "react";

const RotatingCanvas = ({ imageSrc, rotation }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!imageSrc) return;

    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.src = imageSrc;

    img.onload = () => {
      // Calculate the bounding box of the rotated image
      const angleInRadians = (rotation * Math.PI) / 180;
      const sin = Math.abs(Math.sin(angleInRadians));
      const cos = Math.abs(Math.cos(angleInRadians));

      // Calculate the new canvas size to fit the rotated image
      const newWidth = img.width * cos + img.height * sin;
      const newHeight = img.width * sin + img.height * cos;

      // Scale down if the image exceeds the maximum canvas size
      const maxDimension = Math.max(newWidth, newHeight);
      const scaleFactor = maxDimension > 800 ? 800 / maxDimension : 1;

      canvas.width = newWidth * scaleFactor;
      canvas.height = newHeight * scaleFactor;

      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Translate to the center of the new canvas
      ctx.save();
      ctx.translate(canvas.width / 2, canvas.height / 2);

      // Apply scaling
      ctx.scale(scaleFactor, scaleFactor);

      // Rotate the canvas
      ctx.rotate(angleInRadians);

      // Draw the image, offsetting by half its width and height
      ctx.drawImage(img, -img.width / 2, -img.height / 2);

      // Restore the canvas state
      ctx.restore();
    };

    img.onerror = () => {
      console.error("Failed to load the image.");
    };
  }, [imageSrc, rotation]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
        backgroundColor: "#f0f0f0",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ border: "1px solid black", backgroundColor: "#fff" }}
      ></canvas>
    </div>
  );
};

export default RotatingCanvas;
