import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toastr from "toastr";
import { instance } from "../../constants/constString";

const initialState = {
  seniorMentorSubjectsList: [],
  seniorMentorAssignedSubjects: [],
  mentorChapterList: [],
  lockedQuestions: [],
  mentorAssignedChapters: [],
  mentors: [],
  totalRecords: 0,
  seniorMentors: [],
  qnaList: [],
  courses: [],
  comments: [],
  question: null,
  status: "idle",
  error: null,
};

function organizeData(data) {
  const courses = {};

  data.forEach((chapter) => {
    const courseId = chapter.subjectId.courseId._id;
    const courseName = chapter.subjectId.courseId.name;
    const courseSession = chapter.subjectId.courseId.session;

    if (!courses[courseId]) {
      courses[courseId] = {
        _id: courseId,
        name: courseName,
        session: courseSession,
        subjects: {},
      };
    }

    const subjectId = chapter.subjectId._id;
    const subjectName = chapter.subjectId.name;

    if (!courses[courseId].subjects[subjectId]) {
      courses[courseId].subjects[subjectId] = {
        _id: subjectId,
        name: subjectName,
        chapters: [],
      };
    }

    courses[courseId].subjects[subjectId].chapters.push({
      _id: chapter._id,
      name: chapter.name,
    });
  });

  return Object.values(courses).map((course) => ({
    ...course,
    subjects: Object.values(course.subjects),
  }));
}

export const getCourseSubjects = createAsyncThunk(
  "course/subject",
  async () => {
    try {
      const res = await instance.get("/course/subject");
      // console.log('mentor subjects', res.data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const fetchSeniorMentors = createAsyncThunk(
  "users/fetchSeniorMentors",
  async ({ searchKey = "", startDate, endDate, status, page, limit }) => {
    try {
      const response = await instance.post(`/admin/get-all-senior-mentors`, {
        startDate,
        endDate,
        status,
        page,
        limit,
      });
      return response;
    } catch (err) {
      toastr.error(err.response.data.errors.title);
      return Promise.reject(err);
    }
  }
);

export const fetchMentors = createAsyncThunk(
  "users/fetchMentors",
  async ({ searchKey = "", startDate, endDate, status, page, limit }) => {
    try {
      const response = await instance.post(`/admin/get-all-mentors`, {
        searchKey,
        startDate,
        endDate,
        status,
        page,
        limit,
      });
      return response;
    } catch (err) {
      toastr.error(err.response.data.errors.title);
      return Promise.reject(err);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "qna/deleteQuestion",
  async (questionId) => {
    try {
      const response = await instance.delete(
        `/qa/delete-question/${questionId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const lockQuestion = createAsyncThunk(
  "qna/lockQuestion",
  async ({ questionId, mentorId }) => {
    try {
      const response = await instance.post(`/qa/lock-question`, {
        questionId,
        mentorId,
      });

      toastr.success("Question locked successfully!");
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.message);
      return Promise.reject(error);
    }
  }
);

export const unlockQuestion = createAsyncThunk(
  "qna/unlockQuestion",
  async ({ questionId, mentorId }) => {
    try {
      const response = await instance.post(`/qa/unlock-question`, {
        questionId,
        mentorId,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const forwardQuestion = createAsyncThunk(
  "qna/forwardQuestion",
  async ({ questionId, mentorId }) => {
    try {
      const response = await instance.post(`/qa/forward-question`, {
        questionId,
        mentorId,
      });
      toastr.success("Question forwarded successfully!");
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.message);
      return Promise.reject(error);
    }
  }
);

export const createComment = createAsyncThunk(
  "qna/createComment",
  async (data) => {
    try {
      const response = await instance.post(`/qa/add-comment`, data);
      return response.data;
    } catch (error) {
      console.log("error", error.response.data.message);
      toastr.error(error.response.data.message);
      return Promise.reject(error);
    }
  }
);

export const updateComment = createAsyncThunk(
  "qna/updateComment",
  async (data) => {
    try {
      const response = await instance.patch(`/qa/update-comment`, data);
      toastr.success("Comment updated successfully!");
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.message);
      return Promise.reject(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "qna/deleteComment",
  async (commentId) => {
    try {
      const response = await instance.delete(`/qa/delete-comment/${commentId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getComments = createAsyncThunk(
  "qna/getComments",
  async (questionId) => {
    try {
      const response = await instance.get(`/qa/get-comments/${questionId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const assignSubjectToSeniorMentor = createAsyncThunk(
  "seniorMentor/assign-subject",
  async (data) => {
    try {
      const res = await instance.post("qa/add-senior-mentor-subject", data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const assignChapterToMentor = createAsyncThunk(
  "mentor/assign-chapter",
  async (data) => {
    try {
      const res = await instance.post("qa/add-mentor-chapter", data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const addComment = createAsyncThunk("qna/addComment", async (data) => {
  try {
    const response = await instance.post(`/qa/add-comment`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const getSeniorMentorAssignedSubject = createAsyncThunk(
  "seniorMentor/assigned-subject",
  async (userId) => {
    try {
      const res = await instance.get(`qa/get-senior-mentor-subjects/${userId}`);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getLockedQuestions = createAsyncThunk(
  "qna/getLockedQuestions",
  async (ids) => {
    try {
      const response = await instance.post(`/qa/get-locked-questions`, { ids });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getSeniorMentorOnlyAssignedSubject = createAsyncThunk(
  "seniorMentor/assigned-subject-mentor",
  async (userId) => {
    try {
      const res = await instance.get(
        `qa/get-only-senior-mentor-subjects/${userId}`
      );
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getMentorChapters = createAsyncThunk(
  "mentor/chapters",
  async (userId) => {
    try {
      const res = await instance.get(`qa/get-mentor-chapter/${userId}`);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getALlCourseWithSubject = createAsyncThunk(
  "qna/getALlCourseWithSubject",
  async () => {
    try {
      const response = await instance.get(`/course/subject`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const createQuestion = createAsyncThunk(
  "qna/createQuestion",
  async (data) => {
    try {
      const response = await instance.post(`/qa/add-question`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getQuestions = createAsyncThunk("qna/getQNAList", async (data) => {
  try {
    const response = await instance.post(`/qa/get-questions`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const updateStatus = createAsyncThunk(
  "qna/updateStatus",
  async (data) => {
    try {
      const response = await instance.post(`/qa/update-status`, data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getQuestion = createAsyncThunk("qna/getQuestion", async (id) => {
  try {
    const response = await instance.get(`/qa/get-question/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

const formatMentorSubjects = (data) => {
  const courseMap = {};
  data.forEach((subject) => {
    const { courseId, ...subjectData } = subject;
    if (!courseMap[courseId._id]) {
      courseMap[courseId._id] = {
        _id: courseId._id,
        name: courseId.name,
        session: courseId.session,
        subjects: [],
      };
    }
    courseMap[courseId._id].subjects.push({
      _id: subjectData._id,
      name: subjectData.name,
      chapters: subjectData.chapters,
    });
  });

  const coursesWithSubjects = Object.values(courseMap);
  return coursesWithSubjects;
};

export const getQuestionKeyword = async (searchString) => {
  try {
    const response = await instance.post(`/qa/get-question-keyword`, {
      searchString,
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const mentorSlice = createSlice({
  name: "mentor",
  initialState,
  reducers: {
    // getMentorSubjectsList: (state) => {
    //   return state.mentorSubjectsList;
    // },
    getCourses: (state) => {
      return state.courses;
    },
  },
  extraReducers: {
    [getCourseSubjects.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.courses = action.payload.data;
    },
    [getCourseSubjects.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCourseSubjects.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getSeniorMentorAssignedSubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const courseMap = {};

      //state.assignedSubjects = action.payload.data.subjects;
      state.seniorMentorSubjectsList = formatMentorSubjects(
        action.payload.data.subjects
      );
    },
    [getSeniorMentorAssignedSubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSeniorMentorAssignedSubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [assignSubjectToSeniorMentor.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const mentorId = action.payload.data.mentorId;
      const subjectIds = action.payload.data.subjects;

      const courses = JSON.parse(JSON.stringify(state.courses));
      const subjects = [];
      courses.forEach((course) => {
        course.subjects.forEach((subject) => {
          if (subjectIds.includes(subject._id)) {
            subjects.push({
              _id: subject._id,
              name: subject.name,
            });
          }
        });
      });

      const seniorMentorIndex = state.seniorMentors.findIndex(
        (mentor) => mentor._id === mentorId
      );
      state.seniorMentors[seniorMentorIndex].subjects = subjects;
    },
    [assignSubjectToSeniorMentor.pending]: (state, action) => {
      state.status = "loading";
    },
    [assignSubjectToSeniorMentor.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getQuestions.pending]: (state, action) => {
      state.status = "loading";
    },
    [getQuestions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action.payload?.data", action.payload?.data);
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user.roles.includes("admin")) {
        //filter out locked questions if locked by other mentor

        const questions = action.payload?.data.questions.filter((question) => {
          if (question.locked) {
            if (question.locked.userId === user.id) {
              const lockedAt = new Date(question.locked.lockedAt);
              const currentTime = new Date();
              const diffInMinutes = Math.floor(
                (currentTime - lockedAt) / 60000
              );

              // Unlock if locked for more than 10 minutes
              return diffInMinutes <= 10;
            }
            // If locked by a different user, filter it out
            return false;
          }
          // Include if not locked
          return true;
        });

        console.log("questions filtered", questions);

        state.qnaList = questions;
        state.totalRecords = action.payload?.data.totalRecords;
      } else {
        state.qnaList = action.payload?.data.questions;
        state.totalRecords = action.payload?.data.totalRecords;
      }
    },
    [getQuestions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [getQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.question = action.payload.data;
    },
    [getQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [createQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [createQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [createComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const admin = JSON.parse(localStorage.getItem("user"));
      console.log("student", admin);
      state.comments = [
        ...state.comments,
        {
          ...action.payload.data,
          userId: {
            _id: admin.id,
            firstName: admin.firstName,
            lastName: admin.lastName,
          },
        },
      ];
      toastr.success("Comment added successfully");
    },
    [createComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const comment = action.payload.data;
      const index = state.comments.findIndex(
        (item) => item._id === comment._id
      );
      state.comments[index] = comment;
    },
    [updateComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getComments.pending]: (state, action) => {
      state.status = "loading";
    },
    [getComments.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.comments = action.payload.data;
    },
    [getComments.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [createComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("action.payload.data", action.payload.data);
      const user = JSON.parse(localStorage.getItem("user"));
      state.comments = [
        ...state.comments,
        {
          ...action.payload.data,
          userId: {
            _id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
          },
        },
      ];
    },

    [createComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateStatus.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const question = action.payload.data;
      const index = state.qnaList.findIndex(
        (item) => item._id === question._id
      );
      state.qnaList[index] = question;
    },
    [updateStatus.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.qnaList = state.qnaList.filter(
        (item) => item._id !== action.payload.data._id
      );
    },
    [deleteQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchSeniorMentors.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchSeniorMentors.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.seniorMentors = action.payload?.data?.data;
    },
    [fetchSeniorMentors.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getSeniorMentorOnlyAssignedSubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSeniorMentorOnlyAssignedSubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.seniorMentorAssignedSubjects = action.payload.data.subjects;
      // state.mentorSubjectsList = formatMentorSubjects(
      //   action.payload.data.subjects
      // );
    },
    [getSeniorMentorOnlyAssignedSubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteComment.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteComment.fulfilled]: (state, action) => {
      state.status = "succeeded";
      toastr.success("Comment deleted successfully");
      state.comments = state.comments.filter(
        (comment) => comment._id !== action.payload.data._id
      );
    },
    [deleteComment.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [fetchMentors.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchMentors.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.mentors = action.payload?.data?.data;
    },
    [fetchMentors.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getMentorChapters.pending]: (state, action) => {
      state.status = "loading";
    },
    [getMentorChapters.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log(
        "mentor chapters",
        organizeData(action.payload.data.chapters)
      );
      state.mentorChapterList = organizeData(action.payload.data.chapters);
      state.mentorAssignedChapters = action.payload.data.chapters;
    },
    [getMentorChapters.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [assignChapterToMentor.pending]: (state, action) => {
      state.status = "loading";
    },
    [assignChapterToMentor.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const mentorId = action.payload.data.mentorId;
      const chapterIds = action.payload.data.chapters;

      const courses = JSON.parse(JSON.stringify(state.courses));
      const chapters = [];
      courses.forEach((course) => {
        course.subjects.forEach((subject) => {
          subject.chapters.forEach((chapter) => {
            if (chapterIds.includes(chapter._id)) {
              chapters.push({
                _id: chapter._id,
                name: chapter.name,
                courseId: course._id,
                subjectId: subject._id,
              });
            }
          });
        });
      });
      const mentorIndex = state.mentors.findIndex(
        (mentor) => mentor._id === mentorId
      );
      state.mentors[mentorIndex].chapters = chapters;
    },
    [getLockedQuestions.pending]: (state, action) => {
      state.status = "loading";
    },
    [getLockedQuestions.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.lockedQuestions = action.payload.data;
    },
    [getLockedQuestions.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [lockQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [lockQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const lockedQuestion = action.payload.data;
      // Update lockedQuestions list
      // check if question is already in lockedQuestions
      const index = state.qnaList.findIndex(
        (question) => question._id === lockedQuestion._id
      );
      state.qnaList[index] = lockedQuestion;
    },
    [lockQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [unlockQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [unlockQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const unlockedQuestion = action.payload.data;
      // filnd unlocked question in qnaList and update locked as null
      const index = state.qnaList.findIndex(
        (question) => question._id === unlockedQuestion._id
      );
      if (index !== -1) state.qnaList[index].locked = null;
    },
    [unlockQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [forwardQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [forwardQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [forwardQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const { getMentorSubjectsList } = mentorSlice.actions;
export const totalRecords = (state) => state.mentor.totalRecords;
export const status = (state) => state.mentor.status;
export const mentorSubjects = (state) => state.mentor.mentorSubjectsList;
export const assignedSubjects = (state) => state.mentor.assignedSubjects;
export const selectAllMentors = (state) => state.mentor.seniorMentors;
export const courses = (state) => state.mentor.courses;
export const qnaList = (state) => state.mentor.qnaList;
export const comments = (state) => state.mentor.comments;
export const question = (state) => state.mentor.question;
export const lockedQuestions = (state) => state.mentor.lockedQuestions;
export default mentorSlice.reducer;
