import React, { useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import SubHeader from '../Common/SubHeader';
import BranchListTable from './BranchListTable';
import AddBranchModal from '../StudetsManagement/AddBranchModal';

import { getBranch } from '../../stateManager/reducers/studentManagementSlice';

const { Content } = Layout;

const BranchList = props => {
	const dispatch = useDispatch();
	const [isBranchModalVisible, setBranchModalVisible] = useState(false);

	const toggleBranchModal = () => setBranchModalVisible(!isBranchModalVisible);

	useEffect(() => {
		async function fetchData() {
			const res = await dispatch(getBranch());
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<AddBranchModal
				isVisible={isBranchModalVisible}
				toggleAddModal={toggleBranchModal.bind(this)}
			/>

			<SubHeader headText='Branches' />

			<Content>
				<Button
					style={{ marginBottom: 15 }}
					onClick={() => setBranchModalVisible(!isBranchModalVisible)}
					icon={<PlusOutlined />}
					type='primary'
				>
					Add Branch
				</Button>

				<BranchListTable />
			</Content>
		</React.Fragment>
	);
};

export default BranchList;
