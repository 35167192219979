import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";
import axios from "axios";
import toastr from "toastr";

import {
  updateLocalStorage,
  getLocalStorageData,
} from "../../constants/constFunction";
import { LOCAL_KEY } from "../../constants/constString";

const filterData = getLocalStorageData(LOCAL_KEY.QUESTION_BANK_KEY);

const initialState = {
  questionList: [
    {
      title: "",
      type: "MCQ",
      options: [],
      answer: [],
      optionType: {},
    },
  ],
  questionBySubjectCourse: [],
  selectedSubjectForFilter: filterData?.selectedSubjectForFilter || undefined,
  selectedChapterForFilter: filterData?.selectedChapterForFilter || undefined,
  selectedLectureForFilter: filterData?.selectedLectureForFilter || undefined,
  selectedQuestionSolveForFilter:
    filterData?.selectedQuestionSolveForFilter || undefined,
  progress: 0,
  status: "idle",
  error: null,
};

export const getQuestion = createAsyncThunk(
  "question/getQuestion",
  async ({
    lectureId,
    courseId,
    title,
    subjectId,
    chapterId,
    questionSolveId,
    lastId,
    isLoadMore,
  }) => {
    try {
      const response = await instance.get(
        `/question/search?courseId=${courseId}${
          subjectId ? "&subjectId=" + subjectId : ""
        }${chapterId ? "&chapterId=" + chapterId : ""}${
          lectureId ? "&lectureId=" + lectureId : ""
        }${title ? "&title=" + title : ""}${lastId ? "&lastId=" + lastId : ""}${
          questionSolveId ? "&questionSolveId=" + questionSolveId : ""
        }`
      );
      return { data: response.data?.data, isLoadMore };
    } catch (error) {
      toastr.error(error?.response?.data?.errors?.title);
      return Promise.reject(error);
    }
  }
);

export const saveQuestionRequest = createAsyncThunk(
  "question/saveQuesiton",
  async (data) => {
    try {
      const response = await instance.post("/question", data);
      if (response.status === 200) {
        toastr.success("Question added successfully");
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const updateQuestionRequest = createAsyncThunk(
  "question/updateQuestion",
  async ({ data, questionId }) => {
    try {
      const response = await instance.patch(`/question/update/${questionId}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteQuestionRequest = createAsyncThunk(
  "question/deleteQuestionReq",
  async ({ questionId }) => {
    try {
      const response = await instance.patch(`/question/delete/${questionId}`);
      return questionId;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const signedUrl = createAsyncThunk(
  "question/signedUrl",
  async (fileType) => {
    try {
      const response = await instance.get(
        `/content/signed-request?mimeType=${fileType}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const patchFileRequest = createAsyncThunk(
  "question/fileUpload",
  async ({ signedUrl, file }) => {
    try {
      const { CancelToken } = axios;
      const response = await axios({
        url: signedUrl,
        method: "PUT",
        data: file,
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // state.uploading = 100 - percentCompleted;
          console.log(percentCompleted);
        },
        cancelToken: new CancelToken((c) => {
          // state.cancel = c;
          console.log(c);
        }),
        headers: {
          "Content-Type": `${file.type}`,
          // Authorization: sessionId
        },
      });
      // state.uploading = 100;
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const questionExportRequest = createAsyncThunk(
  "question/exportQuestionRequest",
  async ({
    courseId,
    subjectId,
    lectureId,
    chapterId,
    questionSolveId,
    title,
    format,
  }) => {
    try {
      const response = await instance.get(
        `/question/export?courseId=${courseId}${
          subjectId ? "&subjectId=" + subjectId : ""
        }${chapterId ? "&chapterId=" + chapterId : ""}${
          lectureId ? "&lectureId=" + lectureId : ""
        }${title ? "&title=" + title : ""}${format ? "&format=" + format : ""}${
          questionSolveId ? "&questionSolveId=" + questionSolveId : ""
        }`
      );
      if (response && format !== "doc") {
        window.open(response.data?.data?.S3?.Location);
      }
      return response.data;
    } catch (error) {
      console.log(error?.response);
      toastr.error(error?.response?.data?.errors?.title);
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "questions",
  initialState: initialState,
  reducers: {
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    resetQuestionToInitial: (state, action) => {
      state.questionList = [...initialState.questionList];
    },
    addQuestion: (state, action) => {
      state.questionList = state.questionList.concat(action.payload);
    },
    deleteQuestion: (state, action) => {
      const { index } = action.payload;
      let temp = [...state.questionList];
      temp.splice(index, 1);
      state.questionList = temp;
    },
    addQuestionForEdit: (state, action) => {
      state.questionList = action.payload;
    },
    addQuestionTitle: (state, action) => {
      const { index, title } = action.payload;
      state.questionList[index].title = title;
    },

    addQuestionNote: (state, action) => {
      const { index, explanation } = action.payload;
      state.questionList[index].explanation = explanation;
    },
    updateExpFiles: (state, action) => {
      const { index, explanationFileIndex } = action.payload;
      state.questionList[index].explanationExt.splice(explanationFileIndex, 1);
    },
    addExpTables: (state, action) => {
      const { index, explanationTables } = action.payload;
      if (
        !state.questionList[index].explanationTables ||
        state.questionList[index].explanationTables.length === 0
      ) {
        state.questionList[index].explanationTables = [explanationTables];
      } else {
        state.questionList[index].explanationTables = [
          ...state.questionList[index].explanationTables,
          explanationTables,
        ];
      }

      console.log("from state addExpTables", state.questionList[index]);
    },
    deleleExpTables: (state, action) => {
      const { index, tableIndex } = action.payload;
      let temp = [...state.questionList[index].explanationTables];
      temp.splice(tableIndex, 1);
      state.questionList[index].explanationTables = temp;
    },
    addQuestionType: (state, action) => {
      const { index, type } = action.payload;
      state.questionList[index].type = type;
    },
    addOption: (state, action) => {
      const { index, option } = action.payload;
      state.questionList[index].options =
        state.questionList[index].options.concat(option);
    },
    addPoint: (state, action) => {
      const { index, point } = action.payload;
      state.questionList[index].point = point;
    },
    addCourseSubjectChapterToQuestion: (state, action) => {
      const { index, key, value } = action.payload;
      state.questionList[index][key] = value;
    },
    deleteSingleOption: (state, action) => {
      const { questionIndex, optionIndex } = action.payload;
      let temp = [...state.questionList[questionIndex].options];
      temp.splice(optionIndex, 1);
      state.questionList[questionIndex].options = temp;

      let ansTemp = [...state.questionList[questionIndex].answer];
      ansTemp.splice(optionIndex, 1);
      state.questionList[questionIndex].answer = ansTemp;
    },
    editSingleOption: (state, action) => {
      const { questionIndex, optionIndex, data } = action.payload;
      let temp = [...state.questionList[questionIndex].options];
      temp[optionIndex] = data;
      state.questionList[questionIndex].options = temp;
    },
    addOptionType: (state, action) => {
      const { option, type, index } = action.payload;
      //optionType json object string

      const optionType = state.questionList[index].optionType;
      if (optionType && typeof optionType === "string") {
        const temp = JSON.parse(optionType);
        temp[option] = type;
        state.questionList[index].optionType = JSON.stringify(temp);
      } else {
        //optionType" must be of type object'
        state.questionList[index].optionType = JSON.stringify({
          [option]: type,
        });
      }

      //state.questionList[index].optionType[option] = type;
    },
    addAnswer: (state, action) => {
      const { index, answer, answerIndex } = action.payload;
      console.log("from reducer", answer, answerIndex, state);
      if (answerIndex || answerIndex == 0) {
        state.questionList[index].answer[answerIndex] = answer;
      } else {
        state.questionList[index].answer = answer;
      }
    },
    addLink: (state, action) => {
      const { index, link } = action.payload;
      state.questionList[index].URL = state.questionList[index].URL
        ? state.questionList[index].URL.concat(link)
        : [link];
    },
    addImage: (state, action) => {
      const { index, image } = action.payload;
      state.questionList[index].image = state.questionList[index].image
        ? state.questionList[index].image.concat(image)
        : [image];
    },
    updateImage: (state, action) => {
      const { index, image } = action.payload;
      state.questionList[index].image = image;
    },
    addFile: (state, action) => {
      const { index, file } = action.payload;
      state.questionList[index].file = state.questionList[index].file
        ? state.questionList[index].file.concat(file)
        : [file];
    },
    addExplanationExt: (state, action) => {
      const { index, file } = action.payload;
      state.questionList[index].explanationExt = state.questionList[index]
        .explanationExt
        ? state.questionList[index].explanationExt.concat(file)
        : [file];
    },
    addFilters: (state, action) => {
      const { value, key } = action.payload;
      state[key] = value;
      updateLocalStorage(LOCAL_KEY.QUESTION_BANK_KEY, key, value);
    },
  },
  extraReducers: {
    [getQuestion.pending]: (state, action) => {
      state.status = "loading";
    },
    [getQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { data, isLoadMore } = action.payload;
      state.questionBySubjectCourse = isLoadMore
        ? [...state.questionBySubjectCourse, ...data]
        : [...data];
    },
    [getQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [saveQuestionRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [saveQuestionRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.questionBySubjectCourse = state.questionBySubjectCourse.concat(
        action.payload?.data
      );
    },
    [saveQuestionRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [patchFileRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [patchFileRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [patchFileRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error?.message;
    },
    [updateQuestionRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateQuestionRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { _id } = action.payload.data;
      state.questionBySubjectCourse = state.questionBySubjectCourse.map(
        (item) => (item._id === _id ? action.payload.data : item)
      );
    },
    [updateQuestionRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteQuestionRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteQuestionRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const questionId = action.payload;
      state.questionBySubjectCourse = state.questionBySubjectCourse.filter(
        (item) => item._id !== questionId
      );
    },
    [deleteQuestionRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const {
  addQuestion,
  addQuestionTitle,
  deleleExpTables,
  addQuestionType,
  addOption,
  addOptionType,
  deleteSingleOption,
  addAnswer,
  deleteQuestion,
  addLink,
  addImage,
  addFile,
  addExplanationExt,
  addQuestionNote,
  addQuestionForEdit,
  addFilters,
  addCourseSubjectChapterToQuestion,
  addPoint,
  resetQuestionToInitial,
  setProgress,
  editSingleOption,
  updateImage,
  updateExpFiles,
  addExpTables,
} = slice.actions;

export default slice.reducer;
