import React, { useState } from 'react';
import { Form, Modal, Select, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { readString } from 'react-papaparse';
import { useSelector, useDispatch } from 'react-redux';
import toastr from 'toastr';
import { addStudent } from '../../stateManager/reducers/studentManagementSlice';
import { Progress } from "antd";




const { Dragger } = Upload;
const { Option } = Select;

const AddStudentsModal = props => {
	const { isAddModalVisible } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const courseList = useSelector(state => state.courses.courseList);
	const [isAddingStudent, setAddingStudentStatus] = useState(false);
	const [progress, setProgress] = useState(0);
	const cancelUploadRef = React.useRef(false);
	

	const [cancelUpload, setCancelUpload] = useState(false);
	const status = useSelector(state => state.studentsAdmin.status);

	React.useEffect(() => {
	
		console.log("Cancel upload ref", cancelUploadRef.current);
		
	}, [cancelUploadRef.current]);


	const handleCancel = () => {
		setCancelUpload(true);
		cancelUploadRef.current = true;
		setProgress(0);
		props.toggleAddModal();
	};

	const handleSubmit = async values => {
		cancelUploadRef.current = false;
		setAddingStudentStatus(true);
		let read = new FileReader();
		read.readAsBinaryString(values.file.file.originFileObj);

		read.onloadend = async function () {
			const result = await readString(read.result, {
				header: true,
				// dynamicTyping: true,
				skipEmptyLines: true,
				transform: (value, header) => {
					if (!value || value.length <= 0) {
						return undefined;
					}
					return value;
				}
			});
			const { data } = result;

			let processedData = [];
			for (let i = 0; i < data.length; i++) {
				const element = data[i];
				if (element.sid) {
					const preprocessedData = {
						...element,
						firstTime: element.firstTime === '1st' ? true : false,
						courses: [values.courses],
						branch: values.branch
					};
					processedData.push(preprocessedData);
				}
			}

			// for (let i = 0; i < processedData.length; i += 100) {
			// 	const request = processedData
			// 		.slice(i, i + 100)
			// 		.map(item =>
			// 			dispatch(addStudent(item)).catch(err =>
			// 				console.log(`Error in adding student for ${item} - ${err}`)
			// 			)
			// 		);
			// 	// eslint-disable-next-line
			// 	const res = await Promise.all(request).catch(err =>
			// 		console.log(`Error in adding student for the batch ${i} - ${err}`)
			// 	);
			// }
			//request sequencially one after another
			for (let i = 0; i < processedData.length; i++) {
				const item = processedData[i];
				if (cancelUploadRef.current) {
					console.log("Upload canceled.");
					break;
				}

				await dispatch(addStudent(item)).catch(err =>
					console.log(`Error in adding student for ${item} - ${err}`)
				);
				setProgress(parseInt(((i + 1) / processedData.length) * 100));	
			}

			setAddingStudentStatus(false);
			props.toggleAddModal();
		};
	};

	return (
		<Modal
			open={isAddModalVisible}
			centered
			maskClosable={false}
			okText='Add'
			destroyOnClose
			okButtonProps={{
				loading: status === 'loading' || isAddingStudent
			}}
			onOk={() => form.submit()}
			onCancel={() =>{
				handleCancel();
		
				
			} }
			title='Add Students'
		>
			
			<Form
				layout='vertical'
				preserve={false}
				onFinish={handleSubmit}
				form={form}
			>
				<Form.Item
					name='branch'
					rules={[{ required: true }]}
					required
					label={<strong>Select Branch</strong>}
				>
					<Select placeholder='Select Branch'>
						{branchList.map(item => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='courses'
					rules={[{ required: true }]}
					label={<strong>Select Course</strong>}
				>
					<Select placeholder='Select Course'>
						{courseList.map(item => (
							<Option value={item._id} key={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>



				<Form.Item name='file' rules={[{ required: true }]}>
					<Dragger accept={'.csv'}>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
					</Dragger>
				</Form.Item>
			</Form>
			{
				progress > 0 && <Progress percent={progress} />
			}
			
		</Modal>
	);
};

export default AddStudentsModal;
