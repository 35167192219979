import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import CourseCard from '../Course/CourseCard';
import Spinner from '../Common/Spinner';
import { getCourses } from '../../stateManager/reducers/courseSlice';
import { sessions } from '../../constants/constString';

const { Content } = Layout;
const { Option } = Select;

const ExamHome = props => {
	const dispatch = useDispatch();
	const [selectedSession, setSession] = useState(undefined);

	const courseList = useSelector(state =>
		selectedSession
			? state.courses.courseList?.filter(
					item => selectedSession && item?.session == selectedSession
			  )
			: state.courses.courseList
	);
	const status = useSelector(state => state.courses.status);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourses());
		}
		fetchData();
	}, []);

	if (status === 'loading') {
		return <Spinner />;
	}

	return (
		<React.Fragment>
			<SubHeader headText='Create Model Tests' />

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>Choose a course to create an exam</p>
				</div>

				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					<Col xs={24} sm={24} md={6} lg={4}>
						<Select
							style={{ width: '100%' }}
							placeholder='Select Session'
							onChange={value => setSession(value)}
							allowClear
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
				</Row>

				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					{courseList.map(item => (
						<Col xs={24} sm={24} md={6} lg={6}>
							<CourseCard data={item} />
						</Col>
					))}
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default ExamHome;
