import React, { useState } from 'react';
import { Card, Image, Button, Popconfirm } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import EditCourseModal from './EditCourseModal';

import DummyImage from '../../assets/images/dummy-image.png';
import { bucket_url } from '../../constants/constString';

import './CourseCard.style.css';
import { deleteSubjectRequest } from '../../stateManager/reducers/courseSlice';

const { Meta } = Card;

const CourseCard = props => {
	const { data, isSubject, from } = props;
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const history = useHistory();
	const [isEditModalVisible, setEditModalVisible] = useState(false);
	console.log('data', data);

	const toggleEditModal = () => setEditModalVisible(!isEditModalVisible);

	return (
		<div style={{ position: 'relative', height: '100%' }}>
			<EditCourseModal
				isVisible={isEditModalVisible}
				toggleModal={toggleEditModal.bind(this)}
				dataToEdit={data}
				isSubject={isSubject}
			/>
			{from === 'course' && (
				<Button
					className='course-edit-btn'
					type='primary'
					shape='circle'
					icon={<EditOutlined />}
					onClick={() => setEditModalVisible(!isEditModalVisible)}
				/>
			)}
			{/* <Link
				to={{
					pathname: isSubject
						? `/subject/${data._id}`
						: `${match.url}/${data._id}`
				}}
			> */}
			<Card
				onClick={() =>
					localStorage.setItem('selectedCourse', JSON.stringify(data))
				}
				className='admin-course-card'
				cover={
					!!data && (
						<Image
							height='250px'
							alt='image'
							placeholder
							onClick={() =>
								history.push({
									pathname: isSubject
										? `/subject/${data._id}`
										: `${match.url}/${data._id}`
								})
							}
							src={
								data?.image
									? `${bucket_url}${data?.image}`
									: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
							}
							fallback={DummyImage}
						/>
					)
				}
				actions={
					from === 'subject' && [
						<EditOutlined
							key='edit'
							onClick={() => setEditModalVisible(!isEditModalVisible)}
						/>,
						<Popconfirm
							title='Are you sure to delete the subject?'
							onConfirm={async () =>
								await dispatch(deleteSubjectRequest({ subjectId: data?._id }))
							}
						>
							<DeleteOutlined key='setting' />
						</Popconfirm>
					]
				}
				hoverable
			>
				<Meta
					onClick={() =>
						history.push({
							pathname: isSubject
								? `/subject/${data._id}`
								: `${match.url}/${data._id}`
						})
					}
					title={<strong>{data?.name || 'Title'}</strong>}
					description={data?.description || 'Description'}
				/>
			</Card>
			{/* </Link> */}
		</div>
	);
};

export default CourseCard;
