import React, { forwardRef } from 'react';

import { Form, Input, Button, message, } from 'antd';

import { EditOutlined } from '@ant-design/icons';

import { Layout } from 'antd';

import  FileUploader  from './FileUploader';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import {
	patchFileRequest2,
	signedUrl as signedUrlRequest
}  from '../../stateManager/reducers/contentSlice';

import { DeleteOutlined } from '@ant-design/icons';

import { deleteComment, updateComment } from '../../stateManager/reducers/mentorSlice';

const { TextArea } = Input;
const { Content } = Layout;
const EditReply =forwardRef((props, ref) => {
  const {comment} = props;
  console.log('comment', comment);
  const [form] = Form.useForm();
  const [files, setFiles] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const onFinish = async () => {
    
    const media = []
    for (const file of files) {
        if(file.isUploaded) {
            media.push({
                mediaType: file.type,
                mediaUrl: file.src,
            });
            continue;
        }

      const signedUrlRes = await dispatch(signedUrlRequest(file.type));
        try {
        const res =  await dispatch(patchFileRequest2({signedUrl: signedUrlRes?.payload?.data?.signedUrl,file: file}));
        console.log('File upload response:', res);
        media.push({
          mediaType: file.type,
          mediaUrl: signedUrlRes?.payload?.data?.key,
        });
        
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }

    // const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      commentId: comment._id,
      reply: form.getFieldValue('reply'),
      media: media,
      // tags: form.getFieldValue('tags'),
    };

     await dispatch(updateComment(data));
    setIsModalOpen(false);
    form.resetFields();

  };
  const onFinishFailed = (errorInfo) => {

  };

  React.useEffect(() => {

    if(comment && isModalOpen) {
        const media = [];
        form.setFieldsValue({
            reply: comment.reply,
        });
    comment?.media?.forEach(async (item) => {
        // Construct the full URL
        media.push({
            type: item.mediaType,
            isUploaded: true,
            src: item.mediaUrl, // You can still keep the src if needed
        });
    });

    setFiles(media);
    }

  }, [isModalOpen, comment]);

  const showEditPanel = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const roles = user.roles;

    if(roles.includes('admin') || roles.includes('seniorMentor')) {
        return true;
    }

    if(comment.userId._id === user.id && roles.includes('mentor')) {
        return true;
    }

    return false;

  }

  return (
    <div>
      {
        showEditPanel() && <div>
            <Button type="outlined" danger onClick={()=>{
        setIsModalOpen(true);
    }} style={{
           
            textAlign: 'left', 
          }}
          icon={ <EditOutlined />}
          > 
            </Button>
             <Button icon={<DeleteOutlined/>} onClick={async ()=>{
                      console.log('comment:', comment);
                        await dispatch(deleteComment(comment._id));
                        
                    }
                    }>
            </Button>
        </div>

      }

      <Modal
      centered
      open={isModalOpen}
      destroyOnClose={true}        
      footer={null}
      onCancel={() => {
       
        form.resetFields();
        setFiles([]);
        setIsModalOpen(false);
      }
      }
      className="custom-modal"
        closeIcon={<div className="modal-close-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M24 8L8 24M8 8L24 24" stroke="#fff" stroke-width="2.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>}
     >
      <Layout style={{
          height: 'auto',
        }} className="modal-layout">
          <Content className="modal-content">
          <h4 className="modal-title">Reply</h4>
            <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          >

          <Form.Item
            
            name="reply"
            rules={[{ required: true, message: 'Please enter the question details' }, { max: 1000, message: 'Details cannot exceed 500 characters' }]}
          >
            <TextArea rows={4} placeholder="Write your reply" />
          </Form.Item>
          <Form.Item
            name="file"
            valuePropName="fileList"
            >
            <FileUploader files={files} setFiles={setFiles}/>
            </Form.Item>
            
             <Form.Item style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '0',
              marginTop: '48px',
            }}>
            <Button type="primary" danger htmlType="submit"
              className="modal-submit-btn"
              >Submit</Button>
          </Form.Item> 
        </Form>
            </Content>
          </Layout>
      </Modal>
    </div>
  );
});

export default EditReply;
