import React, { useEffect, useState } from 'react';
import { Layout, Button, DatePicker } from 'antd';

import SubHeader from '../Common/SubHeader';
import UserListTable from './StudentListTable';
import AddUserModal from './AddUserModal';
import Spinner from '../Common/Spinner';

import { getBranchStats } from '../../stateManager/reducers/mentorSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Content } = Layout;

const BranchStatistic = props => {
	const dispatch = useDispatch();
	const [isAddModalVisible, setAddModalVisible] = useState(false);
    const startOfMonth = moment().startOf('month').startOf('day');

    // Get the current month end date (last day of the month, at 23:59)
    const endOfMonth = moment().endOf('month').endOf('day');
  
    // Set the default value to the current month range
    const defaultValue = [startOfMonth, endOfMonth];
    const [dateRange, setDateRange] = useState(defaultValue);

	const userStatus = useSelector(state => state.userManagement.status);

	useEffect(() => {
		async function fetchData() {
            
			await dispatch(getBranchStats({
                startDate: dateRange ? dateRange[0] : null,
                endDate: dateRange ? dateRange[1] : null
            }));
		}
		const res = fetchData();
		// eslint-disable-next-line
	}, [dateRange]);

	const toggleAddModal = () => setAddModalVisible(!isAddModalVisible);

	if (userStatus === 'loading') {
		return <Spinner />;
	}

	return (
		<React.Fragment>
			<AddUserModal
				isVisible={isAddModalVisible}
				toggleModal={toggleAddModal.bind(this)}
			/>

			<SubHeader headText='Branch Statistics' />

			<Content>
			
            <RangePicker 
            defaultValue=   {dateRange}
			onChange={(_, info)=>{
				console.log(info)
				setDateRange(info)
			}} />

				<div style={{ padding: 15 }}>
					<UserListTable 
                    startDate={ dateRange ? dateRange[0] : null} 
                    endDate={ dateRange ? dateRange[1] : null}
                    />
				</div>
			</Content>
		</React.Fragment>
	);
};

export default BranchStatistic;
