import {
  Document,
  Packer,
  Paragraph,
  HeadingLevel,
  TextRun,
  BulletList,
  AlignmentType,
} from "docx";
import fs from "fs";
const questions = [
  {
    status: "pending",
    options: [
      "ধ্রুব বেগে গতিশীল",
      "জড়তার সূত্র প্রযোজ্য",
      "নিউটনের সূত্র প্রযোজ্য নয়",
      "নিউটনের সূত্র প্রযোজ্য",
    ],
    answer: ["নিউটনের সূত্র প্রযোজ্য নয়"],
    URL: [],
    image: [],
    file: [],
    isDeleted: false,
    _id: "6799ca37c19f929edf3be0f2",
    title: "অজড় প্রসঙ্গ কাঠামোর বৈশিষ্ট্য কোনটি?",
    type: "MCQ",
    explanation:
      "Is-619; যেসব প্রসঙ্গ কাঠামো পরস্পরের সাথে দ্রুব বেগে গতিশীল নয় এবং কাঠামোতে জড়তার সূত্র ও নিউটনের গতির সূত্র প্রযোজ্য হয় না তাকে অজড় কাঠামো বলে।",
    courseId: "6288824323b5b50b2ad559fa",
    subjectId: "63b7f2397c9d090b54d70908",
    chapterId: "679615bfc7cd28c50fbd428c",
    createdAt: "2025-01-29T06:27:03.094Z",
    updatedAt: "2025-01-29T06:27:03.094Z",
    createdBy: "66fea10a9de1b0c644183d20",
    __v: 0,
  },
];
const filePath = "questions.docx";
const indexToAlphabet = (index) => {
  return String.fromCharCode(65 + index);
};
export function generateQuestionsDocx(questions) {
  const doc = new Document({
    sections: [
      {
        children: questions
          .map((question, index) => {
            const questionElements = [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${index + 1}. ${question.title}`,
                    heading: HeadingLevel.HEADING_2,
                    color: "000000",
                  }),
                ],
                spacing: { after: 200 },
              }),
              ...question.options.map(
                (option, index) =>
                  new Paragraph({
                    text: `${indexToAlphabet(index)}. ${option}`,
                    spacing: { after: 100 },
                  })
              ),
              new Paragraph({
                text: `Answer: ${question.answer.join(", ")}`,
                spacing: { before: 200 },
                bold: true,
              }),
              question.explanation &&
                new Paragraph({
                  text: `Explanation: ${question.explanation}`,
                  spacing: { after: 400 },
                }),
            ];
            return questionElements;
          })
          .flat(),
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `questions_${new Date()
      .toISOString()
      .replace(/[:.-]/g, "_")}.docx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
}
