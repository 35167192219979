import {
	Form,
	Input,
	Button,
	Card,
	Space
} from 'antd';
import { useEffect, useState } from 'react';
import { Col, Row, Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import CSVReader from 'react-csv-reader'
import { getCourses } from '../../../../stateManager/reducers/courseSlice';
import { sessions } from '../../../../constants/constString';
import {
	addAlternateExam
} from '../../../../stateManager/reducers/studentAuthSlice';
import { insertOfflineResult } from '../../../../stateManager/reducers/examSlice';

const { Option } = Select;


export default function OfflineResult() {
    const [form] = Form.useForm();
    const [selectedSession, setSession] = useState(undefined);
    const [selectedCourse, setCourse] = useState(undefined);
	const [examName, setExamName] = useState('');
	const [roll, setRoll] = useState('');
	const [obtainMark, setObtainMark] = useState('');
	const [totalMark, setTotalMark] = useState('');
	const [negativeMark, setNegativeMark] = useState('');
	const [meritPosition, setMeritPosition] = useState('');
	const [date, setDate] = useState('');


	const [csvData, setCsvData] = useState([]);
	const [completed, setCompleted] = useState(0);
        const courseList = useSelector(state =>
            state.courses.courseList?.filter(item => item?.session == selectedSession)
        );
	const dispatch = useDispatch();
	    useEffect(() => {
            async function fetchData() {
                await dispatch(getCourses());
            }
            fetchData();
        }, []);

	const handleSubmit = async () => {
		if (!selectedSession) {
			toastr.error('Please select a session');
			return;
		}
		if (!selectedCourse) {
			toastr.error('Please select a course');
			return;
		}
		const data = {
			date,
			examName,
			roll,
			obtainMark,
			totalMark,
			negativeMark,
			meritPosition,
			session: selectedSession,
			courseId: selectedCourse
		};
		const results = [data];
		const response = await dispatch(insertOfflineResult({
			results,
		}));
		if(!response.payload)
		toastr.error(response?.error?.message);
		else{
			toastr.success('Exam result added Successfully');
			form.resetFields();
		}

	};

	const handleFileUpload = async () => {
		if (!selectedSession) {
			toastr.error('Please select a session');
			return;
		}
		if (!selectedCourse) {
			toastr.error('Please select a course');
			return;
		}
		if (csvData.length === 0) {
			toastr.error('Please upload a valid CSV file');
			return;
		}

		const results = csvData.slice(1).map(row => ({
			date: new Date(row[0].split("-").reverse().join("-")), // Convert "DD-MM-YYYY" to "YYYY-MM-DD"
			examName: row[1],
			roll: row[2],
			totalMark: Number(row[3]),
			obtainMark: Number(row[4]),
			negativeMark: Number(row[5]),
			meritPosition: Number(row[6]),
			session: selectedSession,
			courseId: selectedCourse
		}));

		try{
		const response = await dispatch(insertOfflineResult({
			results,
		}));
		if(!response.payload)
		toastr.error(response?.error?.message);
		else{
			toastr.success('Offline Result Added Successfully');
			setCsvData([]);
		}
	} catch(e){
		console.log('error', e);
	}


		// csvData.map(async (item) => {
		// 	const data = {
		// 		username: item[0],
		// 		code: item[1],
		// 		link: item[2]
		// 	};
		// 	const response = await dispatch(addAlternateExam(data))
		// 	console.log('response', response);
		// 	if(!response.payload)
		// 	toastr.error(response?.error?.message);
		// 	else{
		// 		toastr.success('Alternate Exam Added Successfully for username ' + item[0]);
		// 		setCompleted(completed + 1);
		// 	} 
			
			
		// });
		
		
	}

    return <div>
        
        <Card style={{ maxWidth: '800px', marginTop: '20px', padding: '20px' }}>
				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					style={{ marginBottom: '20px' }}
				>
					<Col xs={24} sm={24} md={8} lg={8}>
						<Select
							value={selectedSession}
							style={{ width: '100%' }}
							placeholder='Select Session'
							onChange={value => {
								setSession(value);
								setCourse(null);
							}}
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={8} lg={8}>
						<Select
							onChange={value => {
								
								setCourse(value);
							}}
							value={selectedCourse}
							style={{ width: '100%' }}
							placeholder='Select Course'
						>
							{courseList.map(item => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Col>
					
				</Row>
							<Form
								className='notification-create-form'
								form={form}
								layout='vertical'
								
							>
								<Form.Item
									label='Date'
									name='date'
									rules={[
										{
											required: true,
											message: 'Please input date!',
										},
									]}
								>
									<DatePicker
										value={date}
										onChange={e => setDate(e)}
									/>

								</Form.Item>
								<Form.Item
									label='Exam Name'
									name='examName'
									rules={[
										{
											required: true,
											message: 'Please input exam name!',
										},
									]}
								>
									<Input
										value={examName}
										onChange={e => setExamName(e.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label='Roll'
									name='roll'
									rules={[
										{
											required: true,
											message: 'Please input roll!',
										},
									]}

								>
									<Input
										value={roll}
										onChange={e => setRoll(e.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label='Total Mark'
									name='totalMark'
									rules={[
										{
											required: true,
											message: 'Please input total mark!',
										},
									]}
								>
									<Input
										value={totalMark}
										onChange={e => setTotalMark(e.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label='Obtain Mark'
									name='obtainMark'
									rules={[
										{
											required: true,
											message: 'Please input obtain mark!',
										},
									]}
								>
									<Input
										value={obtainMark}
										onChange={e => setObtainMark(e.target.value)}
									/>
								</Form.Item>
								
								<Form.Item
									label='Negative Mark'
									name='negativeMark'
									rules={[
										{
											required: true,
											message: 'Please input negative mark!',
										},
									]}
								>
									<Input
										value={negativeMark}
										onChange={e => setNegativeMark(e.target.value)}
									/>
								</Form.Item>
								<Form.Item
									label='Merit Position'
									name='meritPosition'
									rules={[
										{
											required: true,
											message: 'Please input merit position!',
										},
									]}
								>
									<Input
										value={meritPosition}
										onChange={e => setMeritPosition(e.target.value)}
									/>
								</Form.Item>
								
							
								

								
								<Form.Item>
									<Space>
										<Button
											type='primary'
											onClick={() => {
												handleSubmit();
												
											}}
											htmlType='submit'
										>
											Submit
										</Button>
										
									</Space>
								</Form.Item>
							
							</Form>

					</Card>
					<Card style={{
					
						marginTop: '20px',
						padding: '20px',
						width: 'auto'
					
					}}>
						<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					style={{ marginBottom: '20px' }}
				>
					<Col xs={24} sm={24} md={8} lg={8}>
						<Select
							value={selectedSession}
							style={{ width: '100%' }}
							placeholder='Select Session'
							onChange={value => {
								setSession(value);
								setCourse(null);
							}}
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={8} lg={8}>
						<Select
							onChange={value => {
								
								setCourse(value);
							}}
							value={selectedCourse}
							style={{ width: '100%' }}
							placeholder='Select Course'
						>
							{courseList.map(item => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Col>
					
				</Row>
					<CSVReader onFileLoaded={(data, fileInfo, originalFile) => setCsvData(data)} />
					{
						csvData.length > 0 && <table>
							<thead>
								<tr>
									{
										csvData[0].map((item, index) => <th key={index}>{item}</th>)
									}
								</tr>
							</thead>
							<tbody>
								{
									csvData.map((item, index) => {
										if (index === 0) return null;
										return <tr key={index}>
											{
												item.map((data, index) => <td key={index}>{data}</td>)
											}
										</tr>
									})
								}
							</tbody>
						</table>
					}
					{/* <div style={{
						margin: '10px'
					}}>
						Completed: {completed}
					</div> */}
					<Button
						style={{
							
							marginTop: '10px'
						
						}}
						type='primary'
						onClick={(e) => {
							e.preventDefault();
							setCompleted(0);
							handleFileUpload();
							
						}}
					>
						Upload
					</Button>
					</Card>
					
    </div>;
    }