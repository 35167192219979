import React from 'react';
import { Button, Layout } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { useHistory } from 'react-router-dom';

import SubHeader from '../Common/SubHeader';
import QuestionCard from './QuestionCard';
import {
	addQuestion,
	resetQuestionToInitial,
	saveQuestionRequest
} from '../../stateManager/reducers/questionSlice';

import './question.css';

const { Content } = Layout;

const AddQuestion = props => {
	const { subjectId, courseId, chapterId, topic } = props.match.params;
	const questionList = useSelector(state => state.questions.questionList);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleAddQuestion = () => {
		dispatch(
			addQuestion({
				title: '',
				type: 'MCQ',
				options: [],
				answer: []
			})
		);
	};

	const validateQuestion = () => {
		let isValid = true;
		for (let i = 0; i < questionList.length; i++) {
			const question = questionList[i];
			if (!question.title || question.title.length < 5) {
				toastr.error('Please add a valid title to the question ' + (i + 1));
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.options || question.options.length < 2)
			) {
				toastr.error(
					'Please add at least two valid option for the question ' + (i + 1)
				);
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.answer || question.answer.length < 1)
			) {
				toastr.error('Please select an answer for the question ' + (i + 1));
				isValid = false;
				break;
			}
		}

		if (isValid) {
			saveQuestion();
		}
		return;
	};

	const saveQuestion = async () => {
		const tempChapterId = topic === 'chapter' ? chapterId : undefined;
		const lectureId = topic === 'lecture' ? chapterId : undefined;

		const questions = questionList.map(item => {
			if (
				item.type === 'shortAns' ||
				(item.type === 'paragraph' && item['options'])
			) {
				const obj = { ...item };
				delete obj['options'];
				return {
					...obj,
					courseId,
					subjectId,
					lectureId,
					chapterId: tempChapterId
				};
			}
			return {
				...item,
				courseId,
				subjectId,
				lectureId,
				optionType:
								item?.optionType &&
								(typeof item.optionType === 'string' ||
									Object.keys(item?.optionType).length > 0)
									? JSON.parse(item?.optionType)
									: {},
				chapterId: tempChapterId
			};
		});

		const data = { questions: questions };

		try {
			const res = await dispatch(saveQuestionRequest(data));
			dispatch(resetQuestionToInitial());
		} catch (error) {
			console.log(error);
		}
		history.goBack();
	};

	return (
		<React.Fragment>
			<SubHeader headText='Add Question' backIcon={true} />

			<Content>
				{questionList.map((item, index) => (
					<QuestionCard questionObject={item} questionIndex={index} />
				))}

				<div className='main'>
					<div style={{ width: 500 }}>
						<Button
							type='dashed'
							icon={<PlusOutlined />}
							onClick={() => handleAddQuestion()}
						>
							Add Another Question
						</Button>
						<Button
							style={{ marginLeft: 10 }}
							type='primary'
							onClick={() => validateQuestion()}
						>
							Save Questions
						</Button>
					</div>
				</div>
			</Content>
		</React.Fragment>
	);
};

export default AddQuestion;
