import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownOutlined } from '@ant-design/icons';

import SubHeader from '../Common/SubHeader';
import ExamListCard from './ExamListCard';
import { getExamByCourse } from '../../stateManager/reducers/examSlice';
import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';

const { Content } = Layout;

const CourseWiseExamList = props => {
	const { courseId } = props.match.params;
	const dispatch = useDispatch();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [showLoadMore, setShowLoadMore] = useState(true);

	const course = JSON.parse(localStorage.getItem('selectedCourse'));
	const examListByCourse = useSelector(state => state.exams.examListByCourse);
	const examList = useSelector(state =>
		state.exams.examListByCourse.filter(item =>
			item?.title?.toLowerCase().includes(searchQuery)
		)
	);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}
		fetchData();
	}, [courseId]);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getExamByCourse({ courseId, startDate, endDate }));
		}
		fetchData();
	}, [courseId, startDate, endDate]);

	const handleDateWiseFilter = range => {
		if (range) {
			setStartDate(range[0].toISOString());
			setEndDate(range[1].toISOString());
		} else {
			setStartDate('');
			setEndDate('');
		}
	};

	const handleSearchByName = query => setSearchQuery(query);

	const handleLoadMore = async () => {
		const isLoadMore = true;
		const lastId =
			isLoadMore && !!examListByCourse && examListByCourse.length > 0
				? examListByCourse[examListByCourse.length - 1]._id
				: undefined;
		const res = await dispatch(
			getExamByCourse({ isLoadMore, courseId, startDate, endDate, lastId })
		);
		if (
			res &&
			res?.payload?.data?.data &&
			res?.payload?.data?.data?.length != 50
		) {
			setShowLoadMore(false);
		}
	};

	return (
		<React.Fragment>
			<SubHeader headText={course.name} backIcon={true} />

			<Content>
				<ExamListCard
					courseId={courseId}
					examList={examList}
					handleDateWiseFilter={handleDateWiseFilter.bind(this)}
					handleSearchByName={handleSearchByName.bind(this)}
				/>

				{showLoadMore && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center'
						}}
					>
						<Button onClick={() => handleLoadMore()} type='link'>
							Load More <ArrowDownOutlined />
						</Button>
					</div>
				)}
			</Content>
		</React.Fragment>
	);
};

export default CourseWiseExamList;
