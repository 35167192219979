import React, { useState, useEffect } from 'react';
import { Table, Space, Popconfirm, Button, Modal, Typography, Row, Layout, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './video.css';
import {
	DeleteOutlined,
	EditOutlined,
	MenuOutlined,
	CloseCircleFilled,
	FileTextOutlined
} from '@ant-design/icons';
import moment from 'moment';
import ReactPlayer from 'react-player';
import arrayMove from 'array-move';
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from 'react-sortable-hoc';
import EditContentModal from './EditContentModal';
import PlayerSection from './Videos/PlayerSection.component';
import Settings from './Videos/settings';
import { useRef } from 'react';
import {
	setReorderData,
	deleteContentById,
	publishContentById,
	getDownloadLink,
} from '../../stateManager/reducers/contentSlice';
import { bucket_url, vod_url } from '../../constants/constString';
import EditVODMOdal from './EditVODModal';
import {
	getAccessToken
	} from '../../stateManager/reducers/contentSlice';
import './Videos/StudentVideosCard.component.style.css';
const { Content } = Layout;
const { Title } = Typography;

const DragHandle = SortableHandle(() => (
	<MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableContainerCustom = SortableContainer(props => (
	<tbody {...props} />
));

const VideoListTable = props => {
	const { contentName } = props;
	const [isEditModalVisible, setEditModalVisible] = useState(false);
	const [isEditVODModalVisible, setEditVODModalVisible] = useState(false);
	const [isVideoModalVisible, setVideoModalVisible] = useState(false);
	const [videoPlaying, setVideoPlaying] = useState(true);
	const [YtId, setYtId] = useState(undefined);
	const [videoData, setVideoData] = useState(null);
	const [contentData, setContentData] = useState(undefined);
	const [videoURL, setVideoURL] = useState("");
	const [visible, setVisible] = useState(false);
	const [accessToken, setAccessToken] = useState(null);
	const  playerRef = useRef(null);
	const [intervalID, setIntervalID] = useState(null);
	const dataSource = useSelector(state =>
		contentName === 'video' ? state.contents.videoList : state.contents.fileList
	);


	const getAccessTokenAsync = async () => {
    
		const res = await dispatch(getAccessToken({
			key: videoData?.URL
		}));

  console.log("Access Token", res?.payload?.data?.accessToken);
  setAccessToken(res?.payload?.data?.accessToken)
  
	}

	window.onpopstate = () => {
		
		if(playerRef && playerRef.current){
			playerRef.current?.dispose();
			playerRef.current = null;
			
		}
		
		clearInterval(Number(Settings.get('interval')));
		setAccessToken(null);
		
	  }


	const handleDownload = ({ url, fileName }) => {
		setVisible(true);
		fetch(url)
		  .then((response) => response.blob())
		  .then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement("a");
			link.href = url;
			link.download = fileName || "downloaded-file";
			document.body.appendChild(link);
	
			link.click();
	
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
			setVisible(false);
		  })
		  .catch((error) => {
			console.error("Error fetching the file:", error);
			setVisible(false);
		  });
	  };

	useEffect(() => {
		if(isVideoModalVisible){
			if(videoData?.isVOD){
				getAccessTokenAsync();
				const interval = setInterval(getAccessTokenAsync, 4000);
				Settings.set('interval', interval);
			}
		
		}
		if(!isVideoModalVisible && playerRef && playerRef.current){
			playerRef.current?.dispose();
			playerRef.current = null;
			setAccessToken(null);
			clearInterval(intervalID);
		}

		return () => {
			clearInterval(Number(Settings.get('interval')));
			setAccessToken(null);
			if(playerRef && playerRef.current){
				playerRef.current?.dispose();
				playerRef.current = null;
			}
		}

	}, [isVideoModalVisible]);
	console.log('dataSource', dataSource);
	const status = useSelector(state => state.contents.status);
	const dispatch = useDispatch();

	const toggleModal = () => setEditModalVisible(!isEditModalVisible);

	const onSortEnd = async ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove(
				[].concat(dataSource),
				oldIndex,
				newIndex
			).filter(el => !!el);
			dispatch(setReorderData({ oldIndex, newIndex, dataSource, contentName }));
			const contents = newData.map(item => item._id);
			await props.handleReorderContents(contents);
		}
	};

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = dataSource.findIndex(
			x => x._id === restProps['data-row-key']
		);
		return <SortableItem index={index} {...restProps} />;
	};

	const DraggableContainer = props => (
		<SortableContainerCustom
			useDragHandle
			helperClass='row-dragging'
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const columns = [
		{
			title: 'Sort',
			key: 'sort',
			render: () => <DragHandle />
		},
		{
			title: <strong>Sl no.</strong>,
			key: 'slNo',
			dataIndex: 'slNo',
			render: (item, record, index) => index + 1
		},
		{
			title: <strong>Thumbnail</strong>,
			key: 'thumbnail',
			dataIndex: 'thumbnail',
			align: 'center',
			render: (text, record, index) => {
				if (contentName === 'video') {
					let key;
					if (record.key) {
						key = record.key;
					} else {
						key = record?.URL?.split('v=')[1];
					}
					return (
						<img
							onClick={() => {
								const cloudUrl = vod_url;//"https://streams.retinalms.com/";
								const url = record?.isVOD?cloudUrl+record?.URL:`https://www.youtube.com/watch?v=${key}`;
								console.log(url);
								setVideoURL(url);
								setYtId(key);
								setVideoData(record);
								setVideoModalVisible(!isVideoModalVisible);
								setVideoPlaying(!videoPlaying);
							}}
							style={{ width: 100 }}
							src={record?.isVOD?`${bucket_url + record?.thumbnail}`:`https://img.youtube.com/vi/${key}/mqdefault.jpg`}
							alt='thumnail'
						/>
					);
				}
				return (
					<a href={bucket_url + record.key} target='_blank' rel='noreferrer'>
						<FileTextOutlined style={{ fontSize: 30 }} />
					</a>
				);
			}
		},
		{
			title: <strong>Title</strong>,
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: <strong>Available</strong>,
			key: 'isAvailable',
			dataIndex: 'isAvailable',
			render: (text, record, index) => {
				if(!record?.isVOD || record?.isVOD === undefined) return 'Yes';
				else{
					return record?.isAvailable?'Yes':'No';
				}
			}
		},
		{
			title: <strong>Created Date</strong>,
			key: 'createdAt',
			dataIndex: 'createdAt',
			render: text => moment(text).format('MMMM, DD YYYY')
		},
		{
			title: '',
			key: 'action',
			dataIndex: 'action',
			render: (text, record, index) => (
				<Space>
					
					
						<Button
						onClick={() => {
							if(record?.isVOD){
								setEditVODModalVisible(!isEditVODModalVisible);
							}
							else{
								setEditModalVisible(!isEditModalVisible);
							}
							
							setContentData(record);
						}}
						icon={<EditOutlined />}
						type='text'
					></Button>
					{/* unpublish video */}


					
					
					<Popconfirm
						title='Are you sure to delete the content?'
						okButtonProps={{ loading: status === 'loading' }}
						placement='topRight'
						onConfirm={async () => {
							await dispatch(deleteContentById({ contentId: record._id }));
						}}
					>
						<Button
							// onClick={async () => {
							// 	console.log(text, record);
							// 	await dispatch(deleteContentById({ contentId: record._id }));
							// }}
							icon={<DeleteOutlined />}
							type='text'
						></Button>
					</Popconfirm>
					{
						record?.type === 'video' && <Button
						onClick={async () => {
							const res = await dispatch(publishContentById({ contentId: record._id, data: { publish: !record.isPublished } }));
						
						}}
						
						type='primary'
					>
						
							
							{record.isPublished ? 'Unpublish' : 'Publish'}
							
						
					</Button>
					}
					{
						record?.isVOD && <Button
						onClick={async () => {
							const response = await getDownloadLink({ key: record.key});
							console.log('response here', response.data);
							const file = record.key.split('/');
							const name = record.title.split(' ').join('_');
							const fileName = `${name}.${file[file.length - 1].split('.')[1]}`;
							handleDownload({ url: response.data.downloadLink, fileName: fileName});
						}}
						
						type='primary'
					>
						
							
							Download
							
						
					</Button>
					}
				</Space>
			)
		}
	];

	return (
		<React.Fragment>
			<Content>
				<Row>
				<Modal
				className='video-player-modal'
				open={isVideoModalVisible}
				onCancel={() => {
					console.log('cancel');
					setVideoModalVisible(!isVideoModalVisible);
				}}
				destroyOnClose
				footer={null}
				maskClosable={false}
				maskStyle={{ background: '#000' }}
				closeIcon={
					<CloseCircleFilled style={{ color: '#fff', fontSize: '1.8em' }} />
				}
				centered
				zIndex={9999}
				// closable={true}
				
			>
				<PlayerSection 
							isVod={videoData?.isVOD} 
							sourceURL={videoURL}
							sourceKey={videoData?.URL}
							playerRef={playerRef}
							accessToken={accessToken}
							
						/>
			</Modal>
				</Row>
			</Content>
		
			<EditContentModal
				isVisible={isEditModalVisible}
				toggleModal={toggleModal.bind(this)}
				contentData={contentData}
				type={contentName}
			/>
			<EditVODMOdal
				isVisible={isEditVODModalVisible}
				toggleModal={setEditVODModalVisible.bind(this)}
				contentData={contentData}
				type={contentName}
			/>
			<Table
				loading={status === 'loading'}
				scroll={{ x: 100 }}
				rowKey='_id'
				columns={columns}
				dataSource={dataSource}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			/>
			 <Modal
        title="Downloading Video"
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <p>Downloading... Please wait.</p>
        </div>
      </Modal>
		</React.Fragment>
	);
};

export default VideoListTable;
