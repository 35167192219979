import React from 'react';
import { Row, Col, Button, Space, Upload } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { readString } from 'react-papaparse';

import { addQuestion } from '../../stateManager/reducers/questionSlice';

const ExamQuestionAddFromCSV = props => {
	const { courseId } = props;
	const dispatch = useDispatch();

	const selectedSubjectForFilter = useSelector(
		state => state.questions.selectedSubjectForFilter
	);
	const selectedChapterForFilter = useSelector(
		state => state.questions.selectedChapterForFilter
	);
	const selectedLectureForFilter = useSelector(
		state => state.questions.selectedLectureForFilter
	);

	const handleFileUpload = file => {
		let read = new FileReader();
		read.readAsText(file);

		read.onloadend = async function () {
			const result = await readString(read.result, {
				header: true,
				skipEmptyLines: true,
				transform: (value, header) => {
					if (header === 'answer') {
						return value.split(',').map(item => item.trim());
					}
					if (header === 'notes' || header === 'explanation') {
						return !!value && value.length > 0 ? value : undefined;
					}
					return value;
				}
			});
			const { data: questionData } = result;
			const questions = questionData.map(item => {
				let answer = [];
				if (item.type === 'MCQ' || item.type === 'checkbox') {
					item.answer.map(element => {
						const index = parseInt(element);
						answer.push(item[`option${index}`]);
					});
				}
				if (item.type === 'shortAns' || item.type === 'paragraph') {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					delete obj['options'];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					return {
						...obj,
						answer,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter
					};
				} else {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					const options = [
						item.option1,
						item.option2,
						item.option3,
						item.option4
					];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					return {
						...obj,
						answer,
						options,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter
					};
				}
			});

			questions.map(item => {
				dispatch(addQuestion(item));
			});
		};
	};

	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[8, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
				justify='space-between'
			>
				<Col xs={24} sm={24} md={8} lg={5}>
					<Space>
						<Upload
							accept={'.csv'}
							showUploadList={false}
							onChange={e => {
								if (e.file.status === 'done' || e.file.status === 'error') {
									handleFileUpload(e.file.originFileObj);
								}
							}}
						>
							<Button type='dashed' icon={<UploadOutlined />}>
								Upload
							</Button>
						</Upload>
					</Space>
				</Col>
			</Row>
		</div>
	);
};

export default ExamQuestionAddFromCSV;
