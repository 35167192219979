import React, { useEffect, useState } from 'react';
import { Layout, Button } from 'antd';

import SubHeader from '../Common/SubHeader';
import SeniorMentorListTable from './SeniorMentorListTable';
import AddUserModal from './AddUserModal';
import Spinner from '../Common/Spinner';

import { fetchMentors } from '../../stateManager/reducers/mentorSlice';
import { useDispatch, useSelector } from 'react-redux';

const { Content } = Layout;

const SeniorMentorMangement = props => {
	const dispatch = useDispatch();
	// const [isAddModalVisible, setAddModalVisible] = useState(false);
	const userStatus = useSelector(state => state.userManagement.status);

	// useEffect(() => {
	// 	async function fetchData() {
	// 		await dispatch(fetchMentors(''));
	// 	}
	// 	const res = fetchData();
	// 	// eslint-disable-next-line
	// }, []);

	// const toggleAddModal = () => setAddModalVisible(!isAddModalVisible);

	if (userStatus === 'loading') {
		return <Spinner />;
	}

	return (
		<React.Fragment>

			<SubHeader headText='Senior Mentor Management' />

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>Choose chaptes within subjects and course and ADD videos</p>
					{/* <Button
						onClick={() => setAddModalVisible(!isAddModalVisible)}
						type='primary'
					>
						Add New
					</Button> */}
				</div>

				<div style={{ padding: 15 }}>
					<SeniorMentorListTable />
				</div>
				
			</Content>
		</React.Fragment>
	);
};

export default SeniorMentorMangement;
