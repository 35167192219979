import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import ChapterLectureTable from './ChapterLecutreTable';
import {
	getSubjectByCourse,
	getChapterBySubject,
	getLectureBySubject,
	getQuestionSolveBySubject
} from '../../stateManager/reducers/courseSlice';

const { Option } = Select;

const ChapterAndLecturePermission = props => {
	const { courseId, isChapter, groupId, type } = props;
	const dispatch = useDispatch();
	const subjectList = useSelector(state => state.courses.subjectList);
	const chapterList = useSelector(state => state.courses.chapterList);
	const lectureList = useSelector(state => state.courses.lectureList);
	const questionSolveList = useSelector(
		state => state.courses.questionSolveList
	);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}
		fetchData();
	}, [courseId, dispatch]);

	const handleSelectedSubject = value => {
		if (type === 'chapter') {
			dispatch(getChapterBySubject({ subjectId: value }));
		} else if (type === 'lecture') {
			dispatch(getLectureBySubject({ subjectId: value }));
		} else {
			dispatch(getQuestionSolveBySubject({ subjectId: value }));
		}
	};

	return (
		<React.Fragment>
			<Select
				onChange={value => handleSelectedSubject(value)}
				placeholder='Select a subject'
			>
				{subjectList.map((item, index) => (
					<Option value={item._id} key={item._id}>
						{item.name}
					</Option>
				))}
			</Select>

			<ChapterLectureTable
				type={type}
				groupId={groupId}
				data={
					type === 'chapter'
						? chapterList
						: type === 'lecture'
						? lectureList
						: questionSolveList
				}
			/>
		</React.Fragment>
	);
};

export default ChapterAndLecturePermission;
