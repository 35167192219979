import React, { useState } from 'react';
import { Table, Space, Button, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import AddBranchModal from '../StudetsManagement/AddBranchModal';

import { deleteBranch } from '../../stateManager/reducers/studentManagementSlice';

const BranchListTable = props => {
	const dispatch = useDispatch();
	const [isModalVisible, setModalVisible] = useState(false);
	const [branchData, setBranchData] = useState(undefined);
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const status = useSelector(state => state.studentsAdmin.status);

	const toggleModal = () => setModalVisible(!isModalVisible);

	const handleDelete = async branchId => {
		const res = await dispatch(deleteBranch({ branchId }));
	};

	const columns = [
		{
			title: <strong>Sl No.</strong>,
			key: 'slNo',
			render: (text, record, index) => index + 1
		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: <strong>Division</strong>,
			key: 'division',
			dataIndex: 'division'
		},
		{
			title: <strong>Phone</strong>,
			key: 'phone',
			dataIndex: 'phone'
		},
		{
			title: <strong>Actions</strong>,
			key: 'actions',
			render: (text, record, index) => (
				<Space>
					<Button
						onClick={() => {
							setBranchData(record);
							setModalVisible(!isModalVisible);
						}}
						icon={<EditOutlined />}
						type='text'
					></Button>
					<Popconfirm
						title='Are you sure you want to delete the branch?'
						onConfirm={() => handleDelete(record._id)}
					>
						<Button icon={<DeleteOutlined />} type='text'></Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	return (
		<React.Fragment>
			<AddBranchModal
				isVisible={isModalVisible}
				toggleAddModal={toggleModal.bind(this)}
				isEdit={true}
				branchData={branchData}
			/>
			<Table
				loading={status === 'loading'}
				columns={columns}
				dataSource={branchList}
			/>
		</React.Fragment>
	);
};

export default BranchListTable;
