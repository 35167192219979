import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select, Button, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';

import SubHeader from '../Common/SubHeader';

import { getCourses } from '../../stateManager/reducers/courseSlice';
import {
	getGroup,
} from '../../stateManager/reducers/groupSlice';
import { getExamByCourse } from '../../stateManager/reducers/examSlice';
import { sessions, examStatus } from '../../constants/constString';
import SendSmsModal from './SendSmsModal';

const { Content } = Layout;
const { Option } = Select;

const columns = [
	{
		title: <strong>Exam Name</strong>,
		key: 'title',
		dataIndex: 'title'
	},
	{
		title: <strong>Group Name</strong>,
		key: 'groupName',
		dataIndex: 'groupName'
	}
];

const CombineResults = props => {
	const dispatch = useDispatch();
	const [selectedSession, setSession] = useState(undefined);
	const [selectedCourse, setCourse] = useState(undefined);
	const [selectedGroup, setSelectedGroup] = useState();
	const [selectedExams, setSelectedExams] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loadingGroups, setGroupLoading] = useState(false);
	const [isSmsModalVisible, setSmsModalVisible] = useState(false);
	const courseList = useSelector(state =>
		state.courses.courseList?.filter(item => item?.session == selectedSession)
	);
	const groupList = useSelector(state => state.groups.groupList);
	const examListByCourse = useSelector(state =>
		state.exams.examListByCourse.filter(
			item => item?.isPracticeExam === false && item?.status === 'approved'
		)
	);

	const targetTableColumns = [
		{
			title: <strong>Exam Name</strong>,
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: <strong>Group Name</strong>,
			key: 'groupName',
			dataIndex: 'groupName'
		},
		{
			title: <strong>Actions</strong>,
			key: 'action',
			render: (text, record, index) => (
				<Button
					onClick={() => removeSelectedExam({ customId: record?.customId })}
					type='text'
					icon={<DeleteOutlined />}
				></Button>
			)
		}
	];

	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourses());
		}
		fetchData();
	}, []);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getExamByCourse({ courseId: selectedCourse }));
		}
		fetchData();
	}, [selectedCourse]);

	const searchGroups = async () => {
		if (selectedCourse && selectedSession) {
			setGroupLoading(true);
			await dispatch(
				getGroup({ session: selectedSession, courseId: selectedCourse })
			);
			setGroupLoading(false);
		}
	};

	const removeSelectedExam = ({ customId }) => {
		const fileteredExams = selectedExams.filter(
			item => item?.customId !== customId
		);
		const fileteredKeys = selectedRowKeys.filter(item => item !== customId);
		setSelectedExams(fileteredExams);
		setSelectedRowKeys(fileteredKeys);
	};

	const handleSelecteExams = rows => {
		let exams = [];
		let tempKeys = [];
		for (let i = 0; i < rows.length; i++) {
			let found = false;
			const element1 = rows[i];
			for (let j = 0; j < selectedExams.length; j++) {
				const element2 = selectedExams[j];
				if (element1?.customId === element2?.customId) {
					found = true;
					break;
				}
			}
			if (!found) {
				tempKeys.push(element1?.customId);
				exams.push(element1);
			}
		}
		setSelectedRowKeys([...selectedRowKeys, ...tempKeys]);
		setSelectedExams([...selectedExams, ...exams]);
	};

	// const handleGenerateMeritList = async () => {
	// 	try {
	// 		let data = '';
	// 		let group = '';
	// 		selectedExams.map((item, index) => {
	// 			index === 0
	// 				? (data += `exam=${item?._id}`)
	// 				: (data += `&exam=${item?._id}`);
	// 			index === 0
	// 				? (group += `&group=${item?.groupId}`)
	// 				: (group += `&group=${item?.groupId}`);
	// 		});
	// 		const res = await dispatch(generateMeritList({ data, group }));
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	const sourceData = () => {
		
		if (groupList && selectedGroup) {
			console.log('groupList', groupList, selectedGroup);
			console.log('examFromCourse', examListByCourse);
			for (let i = 0; i < groupList.length; i++) {
				const group = groupList[i];
				if (group?._id === selectedGroup) {
					const groupExamList = group?.exams || [];		
					const temp = [];
					for (let j = 0; j < examListByCourse.length; j++) {
						const examFromCourse = examListByCourse[j];
						
						for (let k = 0; k < groupExamList.length; k++) {
							const groupExam = groupExamList[k];
							if (
								 examFromCourse?._id === groupExam?.examId &&
								groupExam?.status === examStatus?.RESULT_PUBLISHED
							) {
								
								const customId = examFromCourse?._id + group?._id;
								
									const exam ={
										...examFromCourse,
										groupName: group?.name,
										groupId: group?._id,
										customId: customId
									}
									
									temp.push(exam);								

							}
						}
					}
					return temp;
				}
			}
		}
		return [];
	};
	const toggleModal = () => setSmsModalVisible(!isSmsModalVisible);
	return (
		<React.Fragment>
			<SendSmsModal
				isVisible={isSmsModalVisible}
				toggleModal={toggleModal.bind(this)}
				selectedExams={selectedExams}
			/>
			<SubHeader headText='Combine Results' />

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>
						Create student groups. Add individual student in a group or upload
						CSV
					</p>
					<Button
						disabled={selectedExams.length <= 0}
						onClick={() => setSmsModalVisible(!isSmsModalVisible)}
						type='primary'
						size='middle'
					>
						Generate Result
					</Button>
				</div>
				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					<Col xs={24} sm={24} md={6} lg={4}>
						<Select
							value={selectedSession}
							style={{ width: '100%' }}
							placeholder='Select Session'
							onChange={value => {
								setSession(value);
								setCourse(null);
							}}
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={6} lg={8}>
						<Select
							onChange={value => {
								setSelectedGroup(null);
								setCourse(value);
							}}
							value={selectedCourse}
							style={{ width: '100%' }}
							placeholder='Select Course'
						>
							{courseList.map(item => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={6} lg={2}>
						<Button onClick={() => searchGroups()}>Search</Button>
					</Col>

					<Col xs={24} sm={24} md={6} lg={2}>
						<Button
							onClick={() => {
								setSelectedGroup(null);
								setSession(null);
								setSelectedExams([]);
								setSelectedRowKeys([]);
								setCourse(null);
							}}
							type='primary'
						>
							Reset
						</Button>
					</Col>
				</Row>

				<Select
					placeholder='Select a group'
					loading={loadingGroups}
					value={selectedGroup}
					onChange={value => setSelectedGroup(value)}
					style={{ marginBottom: 10 }}
				>
					{groupList.map((item, index) => (
						<Option value={item?._id} key={index}>
							{item?.name}
						</Option>
					))}
				</Select>

				<Row gutter={[10]}>
					<Col span={12}>
						<Table
							rowKey='customId'
							dataSource={sourceData()}
							columns={columns}
							rowSelection={{
								selectedRowKeys,
								type: 'checkbox',
								onChange: (keys, rows) => {
									handleSelecteExams(rows, keys);
								}
							}}
						/>
					</Col>
					<Col span={12}>
						<Table
							rowKey='customId'
							dataSource={selectedExams}
							columns={targetTableColumns}
						/>
					</Col>
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default CombineResults;
