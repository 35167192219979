import React, { useEffect, useState } from 'react';
import { Layout, Button, Row, Col, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import AddGroupModal from './AddGroupModal';
import GroupCard from './GroupCard';
import Spinner from '../Common/Spinner';

import { getCourses } from '../../stateManager/reducers/courseSlice';
import { sessions } from '../../constants/constString';
import { getGroup, setData, resetData } from '../../stateManager/reducers/groupSlice';

const { Content } = Layout;
const { Option } = Select;

const GroupList = props => {
	const [isAddGroupModalVisible, setAddGroupModalVisible] = useState(false);
	const [selectedSession, setSession] = useState(undefined);
	const [selectedCourse, setCourse] = useState(undefined);

	const dispatch = useDispatch();

	const courseList = useSelector(state =>
		state.courses.courseList?.filter(item => item?.session == selectedSession)
	);
	const { groupList, sessionNumber, courseName, courseId } = useSelector(state => state.groups);
	const status = useSelector(state => state.groups.status);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourses());
			if (sessionNumber && courseId) {
				await dispatch(
					getGroup({ session: sessionNumber, courseId })
				);
			}
			// dispatch(resetData({ key: 'groupList' }));
		}
		fetchData();
	}, []);

	const searhCourses = async () => {
		if (selectedCourse && selectedSession) {
			const res = await dispatch(
				getGroup({ session: selectedSession, courseId: selectedCourse })
			);
		}
	};

	const toogleAddGroupModal = () =>
		setAddGroupModalVisible(!isAddGroupModalVisible);

	const handleCourseChange = value => {
		setCourse(value);
		const course = courseList.find(item => item._id === value);
		dispatch(setData({ key: 'courseName', value: course ? course.name : null }))
		dispatch(setData({ key: 'courseId', value: course ? value : null }))
	}

	const handleSessionChange = value => {
		setSession(value);
		dispatch(setData({ key: 'sessionNumber', value }))
	}

	const clearFilter = () => {
		dispatch(resetData({ key: 'groupList' }));
		setCourse(null);
		setSession(null);
		dispatch(resetData({ key: 'courseName' }));
		dispatch(resetData({ key: 'sessionNumber' }))
	}

	return (
		<React.Fragment>
			<AddGroupModal
				isVisible={isAddGroupModalVisible}
				toggleModal={toogleAddGroupModal}
			/>

			<SubHeader headText='Group' />
			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>
						Create student groups. Add individual student in a group or upload
						CSV
					</p>
					<Button
						onClick={() => setAddGroupModalVisible(!isAddGroupModalVisible)}
						type='primary'
						size='middle'
					>
						Add
					</Button>
				</div>

				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					<Col xs={24} sm={24} md={6} lg={4}>
						<Select
							style={{ width: '100%' }}
							placeholder={sessionNumber || 'select session'}
							onChange={value => handleSessionChange(value)}
							value={selectedSession}
							allowClear
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={6} lg={4}>
						<Select
							onChange={value => handleCourseChange(value)}
							style={{ width: '100%' }}
							placeholder={courseName || 'select course'}
							value={selectedCourse}
							allowClear
						>
							{courseList.map(item => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
						</Select>
					</Col>
					<Col xs={24} sm={24} md={6} lg={2}>
						<Button onClick={() => searhCourses()}>Search</Button>
					</Col>

					<Col xs={24} sm={24} md={6} lg={2}>
						<Button onClick={clearFilter} type='primary'>Reset</Button>
					</Col>
				</Row>

				{status === 'loading' ? (
					<Spinner />
				) : (
					<Row
						gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
						className='single-row-container'
					>
						{groupList.map(item => (
							<Col xs={24} sm={24} md={6} lg={6}>
								<GroupCard data={item} selectedCourse={selectedCourse} />
							</Col>
						))}
					</Row>
				)}
			</Content>
		</React.Fragment>
	);
};

export default GroupList;
