import React, { useState } from 'react';
import {
	Table,
	Button,
	Space,
	Switch,
	Popconfirm,
	Tooltip,
	Dropdown,
	Menu,
	Typography
} from 'antd';
import {
	DeleteOutlined,
	EditOutlined,
	DownloadOutlined,
	DownOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import toastr from 'toastr';
import FileSaver from 'file-saver';
import JSZip from 'jszip';

import EditStudentModal from './EditStudentModal';
import {
	deleteStudentRequest,
	studentStatusUpdateRequest,
	studentStatusUpdateByBatchRequest,
	getStudent
} from '../../stateManager/reducers/studentManagementSlice';
import { studentStatus, bucket_url } from '../../constants/constString';
import { downloadFileRequest } from '../../constants/constFunction';

const { Text } = Typography;

const StudentsListTable = props => {
	const { selectedRowKeys, selectedRows } = props;
	const dispatch = useDispatch();
	const [isEditModalVisible, setEditModalVisible] = useState(false);
	const [selectedStudentData, setSelectedStudent] = useState(undefined);
	const courseList = useSelector(state => state.courses.courseList);
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const status = useSelector(state => state.studentsAdmin.status);

	const toggleEditModal = () => setEditModalVisible(!isEditModalVisible);

	const handleBatchDelete = async () => {
		const data = { students: [...selectedRowKeys] };
		await dispatch(deleteStudentRequest({ data }));
		props.setSelectRowForGroup([], []);
	};

	const handleBatchDownload = async () => {
		toastr.info(
			"Please wait, we'll let you know when the file is prepared",
			'Preparing the file'
		);
		let zip = new JSZip();
		for (let i = 0; i < selectedRows.length; i++) {
			const element = selectedRows[i];
			if (element?.profilePic) {
				try {
					const fileUrl = bucket_url + element?.profilePic;
					const res = await downloadFileRequest({ url: fileUrl });
					const url = URL.createObjectURL(new Blob([res.data]));
					const extension = element?.profilePic.split('.')[1];

					if (res?.data) {
						zip.file(
							`${element.name}-${element?.sid}.${extension}`,
							new Blob([res.data])
						);
					}
				} catch (error) {
					console.log(error);
				}
			}
		}
		zip.generateAsync({ type: 'blob' }).then(content => {
			FileSaver.saveAs(content, 'download.zip');
		});
	};

	const handleBatchStatusChange = async status => {
		const data = { students: [...selectedRowKeys], status: status };
		try {
			const res = await dispatch(studentStatusUpdateByBatchRequest({ data }));
			await dispatch(getStudent());
		} catch (error) {
			console.log(error);
		}
	};

	const columns = [
		{
			title: <strong>Sl No</strong>,
			key: 'slNo',
			render: (text, record, index) => index + 1
		},
		{
			title: <strong>ID</strong>,
			key: 'sid',
			dataIndex: 'sid'
		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name',
			render: (text, record, index) => (
				<Link
					to={{
						pathname: `/scoreboard/${record?._id}`,
						state: { studentProfile: record }
					}}
				>
					{text}
				</Link>
			)
		},
		{
			title: <strong>Attempt</strong>,
			key: 'firstTime',
			dataIndex: 'firstTime',
			render: text => (text === '1st' || text === true ? '1st' : '2nd')
		},
		{
			title: <strong>Session</strong>,
			key: 'session',
			dataIndex: 'session'
		},
		{
			title: <strong>Course</strong>,
			key: 'courses',
			dataIndex: 'courses',
			render: text =>
				// eslint-disable-next-line
				text?.map((item, index) => {
					for (let i = 0; i < courseList?.length; i++) {
						const element = courseList[i];
						if (item === element._id) {
							return index > 0 ? ' | ' + element.name : element.name;
						}
					}
				})
		},
		{
			title: <strong>Branch</strong>,
			key: 'branch',
			dataIndex: 'branch',
			render: text =>
				// eslint-disable-next-line
				{	
					
					if(text.name){
						
						return text.name
					}
						
					else{
						const branch = branchList.map(item => {
							if (item._id === text) {
								
								return item.name;
							}})

							return branch;
					}

				}
				
		},
		{
			title: <strong>SSC</strong>,
			key: 'SSCGPA',
			dataIndex: 'SSCGPA',
			responsive: ['lg', 'md', 'sm', 'xs']
		},
		{
			title: <strong>HSC</strong>,
			key: 'HSCGPA',
			dataIndex: 'HSCGPA'
		},
		{
			title: <strong>Contact</strong>,
			key: 'username',
			dataIndex: 'username'
		},
		{
			title: <strong>Parents</strong>,
			key: 'contact',
			dataIndex: 'contact'
		},
		{
			title: <strong>Activations</strong>,
			key: 'activation',
			dataIndex: 'status',
			filters: [
				{
					text: 'Active',
					value: [studentStatus.ACTIVE]
				},
				{
					text: 'Pending',
					value: [studentStatus.PENDING]
				},
				{
					text: 'Deactive',
					value: [studentStatus.BANNED, studentStatus.DIACTIVE]
				}
			],
			filterMultiple: false,
			onFilter: (value, record) => value.indexOf(record.status) > -1,
			render: (text, record, item) => (
				<Switch
					onChange={() => {

						console.log('text', text);
						if (text === 'pending') {
							alert('Status still pending, you cannot change the status now!');
							return;
						} else if (text === studentStatus.ACTIVE) {
							const data = { status: studentStatus.DIACTIVE };
							dispatch(
								studentStatusUpdateRequest({ studentId: record._id, data })
							);
						} else if (text === studentStatus.DIACTIVE) {
							const data = { status: studentStatus.ACTIVE };
							dispatch(
								studentStatusUpdateRequest({ studentId: record._id, data })
							);
						}
					}}
					checkedChildren='ON'
					checked={text === 'active'}
					unCheckedChildren='OFF'
				/>
			)
		},
		{
			title:
				selectedRowKeys.length > 0 ? (
					<strong>
						<Dropdown
							arrow
							overlay={
								<Menu>
									<Menu.Item danger onClick={() => handleBatchDelete()}>
										<DeleteOutlined />
										<span>Delete</span>
									</Menu.Item>
									<Menu.Item onClick={() => handleBatchDownload()}>
										<DownloadOutlined />
										<span>Download Profile Image</span>
									</Menu.Item>
									<Menu.Item
										onClick={() =>
											handleBatchStatusChange(studentStatus.ACTIVE)
										}
									>
										<CheckCircleOutlined />
										<span>Activate</span>
									</Menu.Item>
									<Menu.Item
										onClick={() =>
											handleBatchStatusChange(studentStatus.DIACTIVE)
										}
									>
										<CloseCircleOutlined />
										<span>Deactivate</span>
									</Menu.Item>
								</Menu>
							}
						>
							<Text>
								Actions <DownOutlined />{' '}
							</Text>
						</Dropdown>
					</strong>
				) : (
					<strong>Actions</strong>
				),
			key: 'action',
			dataIndex: 'action',
			render: (text, record, index) =>
				!props.isGroup ? (
					<Space>
						<Tooltip title='Download profile picture'>
							<Button
								onClick={() => {
									if (!record?.profilePic) {
										toastr.error('The students has no profile picture');
										return;
									}
									window.open(bucket_url + record.profilePic);
									// window.location.href = bucket_url + record?.profilePic;
								}}
								type='text'
								icon={<DownloadOutlined />}
							></Button>
						</Tooltip>

						<Button
							onClick={() => {
								setEditModalVisible(!isEditModalVisible);
								setSelectedStudent(record);
							}}
							icon={<EditOutlined />}
							type='text'
						></Button>
						<Popconfirm
							title='Are you sure to delete the student?'
							okButtonProps={{ loading: status === 'loading' }}
							placement='topRight'
							onConfirm={async () => {
								const data = { students: [record.username] };
								await dispatch(deleteStudentRequest({ data }));
							}}
						>
							<Button icon={<DeleteOutlined />} type='text'></Button>
						</Popconfirm>
					</Space>
				) : null
		}
	];

	if (props.isGroup) {
		columns.splice(columns.length - 2, 1);
	}

	return (
		<React.Fragment>
			<EditStudentModal
				isVisible={isEditModalVisible}
				toggleModal={toggleEditModal.bind(this)}
				studentData={selectedStudentData}
			/>
			<Table
				rowSelection={{
					selectedRowKeys,
					type: 'checkbox',
					onChange: (key, rows) => {
						props.setSelectRowForGroup(key, rows);
					}
				}}
				expandable={{
					expandedRowRender: record => (
						<ul>
							{record?.groups &&
								record?.groups.map((item, index) => (
									<li key={`${index}`}>{item.name}</li>
								))}
						</ul>
					),
					rowExpandable: record => record?.groups && record?.groups.length > 0
				}}
				pagination={false}
				loading={status === 'loading'}
				size='small'
				scroll={{ x: 100 }}
				columns={columns}
				dataSource={props.data}
				rowKey='username'
			/>
		</React.Fragment>
	);
};

export default StudentsListTable;
