import React, { useState } from 'react';
import { Table, Tag, Popconfirm, Space, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Input from 'antd/lib/input';

import AddUserModal from './AddUserModal';
import { deleteUser } from '../../stateManager/reducers/userManagementSlice';

const UserListTable = props => {
	const dispatch = useDispatch();
	const [isAddModalVisible, setAddModalVisible] = useState(false);
	const [selectedUser, setSelectedUser] = useState(undefined);
	const [searchKey, setSearchKey] = useState('');
	let data = useSelector(state => state.userManagement.adminUsers);
	//sort data based on updatedAt
	//data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

	if (searchKey) {
		data = data.filter(item => {
			return item.firstName.toLowerCase().includes(searchKey.toLowerCase()) ||
				item?.lastName?.toLowerCase().includes(searchKey.toLowerCase()) ||
				item?.username?.toLowerCase().includes(searchKey.toLowerCase()) ||
				item?.adminId?.toLowerCase().includes(searchKey.toLowerCase()) ||
				item?.email?.toLowerCase().includes(searchKey.toLowerCase()) ||
				item?.roles?.includes(searchKey.toLowerCase());
		});
	}

	const toggleModal = () => setAddModalVisible(!isAddModalVisible);

	const columns = [
		{
			title: <strong>ID</strong>,
			dataIndex: 'adminId',
			key: 'adminId'

		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name',
			render: (text, record) => record.firstName + ' ' + record.lastName
		},
		{
			title: <strong>Phone</strong>,
			key: 'phone',
			dataIndex: 'username'
		},
		{
			title: <strong>Email</strong>,
			key: 'email',
			dataIndex: 'email'
		},
		{
			title: <strong>Roles</strong>,
			key: 'roles',
			dataIndex: 'roles',
			render: roles => roles.map(item => <Tag>{item}</Tag>)
		},
		{
			title: <strong>Actions</strong>,
			dataIndex: 'username',
			key: 'edit',
			render: (text, item, index) => (
				<Space>
					<Button
						onClick={() => {
							setSelectedUser(item);
							setAddModalVisible(!isAddModalVisible);
						}}
						type='text'
					>
						<EditOutlined />
					</Button>
					<Popconfirm
						title='Are you sure to delete the user?'
						onConfirm={async () => {
							await dispatch(deleteUser({ userId: text, index }));
						}}
					>
						<Button type='text'>
							<DeleteOutlined />
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	];

	return (
		<React.Fragment>
			<AddUserModal
				isVisible={isAddModalVisible}
				toggleModal={toggleModal.bind(this)}
				isEdit={true}
				user={selectedUser}
			/>
			<Input
						size={'large'}
						style={{ width: '100%', height: '56px', borderRadius: '8px' }}
						placeholder={'Search'}
						suffix={
							<div style={{ paddingRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path
										d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
										stroke="#475569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>
						}
						onChange={e => setSearchKey(e.target.value)}
						
					/>
			<Table columns={columns} dataSource={data} />
		</React.Fragment>
	);
};

export default UserListTable;
