import React, { useState } from 'react';
import { Table, Tag, Popconfirm, Space, Button, Select } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { CSVDownloader } from 'react-papaparse';

import QuestionDetailsModal from './QuestionDetailsModal';
import { title } from 'process';


const UserListTable = (props) => {
    const {startDate, endDate} = props;
	const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedBranch, setSelectedBranch] = useState(null);
    const [branch, setBranch] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	let allBranch                              = useSelector(state => state.mentor.branchStats)
	let branchStats = allBranch;
	//sort data based on updatedAt
	//data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    if(selectedBranch){
		branchStats = branchStats.filter(item => item.branchId === selectedBranch);
	}
	

	const getCsvData = () => {
		const data = [];
		branchStats.forEach((item, index) => {
			data.push({
				'Name'          : item.branchName,
				'Question Count': item.questionCount,
			});
		});
		return data;
	}

	const columns = [
		{
			title: <strong>Sl</strong>,
			dataIndex: 'sl',
			key: 'sl',
			render: (text, item, index) => (
				<Space>
					{(index + 1) + (currentPage - 1) * pageSize}
				</Space>
			)
		},
		
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'branchName',
			
		},
		{
			title: <strong>Question Count</strong>,
			key: 'Question Count',
			dataIndex: 'questionCount'
		},
		
		{
			title: <strong>Actions</strong>,
			dataIndex: 'username',
			key: 'edit',
			render: (text, item, index) => (
				<Space>
					{
						item.questionCount > 0 && <Button
						
						onClick={() => {
                            setIsModalVisible(true)
                            setBranch({
                                branchId: item.branchId,
                                name: item.branchName
                            })
                        }
                        }
                        icon={<EyeOutlined />}
					>
                        

					</Button>
					}
					

				</Space>
			)
		}
	];

	return (
		<React.Fragment>
			
            <QuestionDetailsModal visible={isModalVisible} onClose={()=>{
                setIsModalVisible(false)
            }}
            branch={branch}
            startDate={startDate}
            endDate={endDate}
            /> 
			<div style={{
				display       : 'flex',
				justifyContent: 'flex-end',
				alignItems	: 'center',
				gap		   : '20px',
				marginBottom  : '30px',
			}}>
			<Select size={'large'}
			style={{
				width: '200px',
			}}
			value={selectedBranch}
			type={'primary'}
			onChange={(branchId)=>{
				console.log(branchId)
				setSelectedBranch(branchId)
			}}>
				<Select.Option value={null}>All Branches</Select.Option>
					{allBranch.map((item, index) => {
									return (
										<Select.Option key={index} value={item.branchId}>
											{item.branchName}
										</Select.Option>
									);	
					})}
			</Select>
			<CSVDownloader
			data={getCsvData()}
			type='link'
			filename={'branch_stats.csv'}
			bom={true}
		>
			
			<Button
			size={'large'}
			danger={true}
			type={'primary'}
			style={{
				backgroundColor: '#EE473F',
				borderRadius   : '8px',
				height         : '56px',
				minWidth       : '200px',
			}}>
				{'Download CSV'}
			</Button>
			</CSVDownloader>
			{/* <Button
		size={'large'}
		danger={true}
		type={'primary'}
		style={{
			backgroundColor: '#EE473F',
			borderRadius   : '8px',
			height         : '56px',
			minWidth       : '200px',
		}}
		onClick={() => {
			setSelectedBranch(null)
		}
		}
	>
		{'Reset'}
	</Button> */}
			</div>
			
			<Table columns={columns} dataSource={branchStats} 
			onChange={(pagination, filters, sorter) => {
				setCurrentPage(pagination.current);
				setPageSize(pagination.pageSize);
			}
			}
			pagination={{
    pageSizeOptions: ['10', '20', '50', '100'], // Options for 10 or 100 rows per page
    defaultPageSize: 10, // Default rows per page
    showSizeChanger: true, // Show the dropdown to select page size
  }} />
		</React.Fragment>
	);
};

export default UserListTable;
