import React, { useEffect } from 'react';
import { Form, Modal, Select, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { editStudentRequest } from '../../stateManager/reducers/studentManagementSlice';

const { Option } = Select;

const EditStudentModal = props => {
	const { isVisible, studentData } = props;
	console.log('studentData', studentData);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const status = useSelector(state => state.studentsAdmin.status);

	useEffect(() => {
		form.setFieldsValue({
			name: studentData?.name,
			contact: studentData?.contact,
			SSCGPA: studentData?.SSCGPA,
			HSCGPA: studentData?.HSCGPA,
			firstTime: studentData?.firstTime,
			branch: studentData?.branch?._id,
			sid: studentData?.sid,
			username: studentData?.username
		});
		// eslint-disable-next-line
	}, [studentData]);

	const handleSubmit = async values => {
		// eslint-disable-next-line
		const res = await dispatch(
			editStudentRequest({
				data: {
					...values,
					HSCGPA: values.HSCGPA === '' ? undefined : values.HSCGPA,
					SSCGPA: values.SSCGPA === '' ? undefined : values.SSCGPA,
					
				},
				studentId: studentData._id
			})
		);
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			maskClosable={false}
			destroyOnClose
			onCancel={() => props.toggleModal()}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			okText='Update Student'
			title={<strong>Edit Student</strong>}
		>
			<Form layout='vertical' form={form} onFinish={handleSubmit}>
				<Form.Item
					name='branch'
					rules={[{ required: true }]}
					label={<strong>Select Branch</strong>}
				>
					<Select>
						{branchList.map((item, index) => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					name='name'
					rules={[{ required: true }]}
					label={<strong>Student Name</strong>}
				>
					<Input placeholder='Enter student name' />
				</Form.Item>
				<Form.Item
					name='sid'
					rules={[{ required: true }]}
					label={<strong>Student ID</strong>}
				>
					<Input placeholder='Enter student id' />
				</Form.Item>
				<Form.Item
					name='username'
					label={<strong>Phone Number</strong>}
					// rules={[
					// 	{ required: true, message: 'Please enter contact no.' },
					// 	() => ({
					// 		validator(rule, value) {
					// 			let phone = new RegExp(/^01\d{9}$/);
					// 			if (!value || value.match(phone)) {
					// 				return Promise.resolve();
					// 			}
					// 			return Promise.reject('The given number is not valid');
					// 		}
					// 	})
					// ]}
				>
					<Input placeholder='Enter phone number' />
				</Form.Item>
				<Form.Item
					name='contact'
					label={<strong>Parent's Phone Number</strong>}
					requiredMark='optional'
					required
					// rules={[
					// 	{ required: true, message: "Please enter parent's contact no." },
					// 	() => ({
					// 		validator(rule, value) {
					// 			let phone = new RegExp(/^01\d{9}$/);
					// 			if (!value || value.match(phone)) {
					// 				return Promise.resolve();
					// 			}
					// 			return Promise.reject('The given number is not valid');
					// 		}
					// 	})
					// ]}
				>
					<Input placeholder='Enter phone number' />
				</Form.Item>
				<Form.Item
					name='SSCGPA'
					// rules={[{ required: true, message: 'Enter SSC GPA' }]}
					label={<strong>SSC GPA</strong>}
				>
					<Input placeholder='Enter SSC GPA' />
				</Form.Item>
				<Form.Item
					name='HSCGPA'
					// rules={[{ required: true, message: 'Enter HSC GPA' }]}
					label={<strong>HSC GPA</strong>}
				>
					<Input placeholder='Enter HSC GPA' />
				</Form.Item>
				<Form.Item
					name='firstTime'
					rules={[{ required: true, message: 'Select 1st/2nd time' }]}
					label={<strong>First Time? </strong>}
				>
					<Select placeholder='Select 1st/2nd time'>
						<Option value={true} key={'1'}>
							Yes
						</Option>
						<Option value={false} key={'2'}>
							No
						</Option>
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditStudentModal;
