// import React, { useState, useEffect } from 'react';
// import { Button, Card } from 'antd';
// import { useDispatch, useSelector } from 'react-redux';
// import moment from 'moment';
// import { useHistory } from 'react-router-dom';
// import toastr from 'toastr';

// import PublishExamModal from './PublishExamModal';
// import {
// 	isAdminOrModerator,
// 	isExaminer,
// 	isExamModarator
// } from '../../constants/constFunction';
// import { examStatus } from '../../constants/constString';

// const GroupExamCard = props => {
// 	const { examData, courseId, groupId, isPracticeExam, filterType } = props;
// 	const [isModalVisible, setModalVisible] = useState(false);
// 	const [isAssessable, setAssessable] = useState(false);
// 	const [counter, setCounter] = useState(0);
// 	const dispatch = useDispatch();
// 	const history = useHistory();
// 	const groupsExam =
// 		useSelector(state => state.groups.selectedGroup?.exams) ||
// 		JSON.parse(localStorage.getItem('selectedGroup'))?.exams;
// 	const isPublished =
// 		!!groupsExam && groupsExam.find(exam => exam.examId === examData._id);

// 	useEffect(() => {
// 		setAssessable(
// 			isPublished && !isPracticeExam && moment(isPublished.endsAt) < new Date()
// 		);
// 		const interval = setInterval(() => {
// 			setCounter(counter + 1);
// 			setAssessable(
// 				isPublished &&
// 				!isPracticeExam &&
// 				moment(isPublished.endsAt) < new Date()
// 			);
// 		}, 10000);

// 		return () => {
// 			clearInterval(interval);
// 		};
// 	}, [counter, isPublished, isPracticeExam]);

// 	const toggleModal = () => setModalVisible(!isModalVisible);

// 	return (
// 		<React.Fragment>
// 			<PublishExamModal
// 				isVisible={isModalVisible}
// 				toggleModal={toggleModal.bind(this)}
// 				examData={examData}
// 				examId={examData._id}
// 				groupId={groupId}
// 				isPublished={isPublished}
// 				isPracticeExam={isPracticeExam}
// 				isAssessable={isAssessable}
// 			/>
// 			<Card
// 				style={{ width: '100%', marginBottom: 10 }}
// 				title={
// 					<strong style={{ fontWeight: 600 }}>{examData?.title}</strong> ||
// 					'Not given'
// 				}
// 				actions={[
// 					isAssessable ? (
// 						<Button
// 							type='primary'
// 							shape='round'
// 							onClick={() => setModalVisible(!isModalVisible)}
// 						>
// 							{isPublished?.status === examStatus.UNPUBLISHED
// 								? 'Publish'
// 								: 'Unpublish'}
// 						</Button>
// 					) : !isAssessable && isPublished && !(moment(isPublished.startsAt) <= new Date() &&
// 						moment(isPublished.endsAt) >= new Date()) && (
// 						<Button
// 							type='primary'
// 							shape='round'
// 							onClick={() => setModalVisible(!isModalVisible)}
// 						>
// 							{isPublished?.status === 'created' || isPublished.status === 'published'
// 								? 'Unpublish'
// 								: 'Publish'}
// 						</Button>
// 					),
// 					(isAssessable && (
// 						// <div style={{ textAlign: 'right', padding: '0 10px' }}>
// 						<Button
// 							onClick={() => {
// 								if (isExaminer() || isAdminOrModerator()) {
// 									history.push(`/evaluate/${examData._id}`);
// 								} else {
// 									toastr.warning('You are not allowed');
// 								}
// 							}}
// 							type='primary'
// 							shape='round'
// 						>
// 							{isPublished?.status !== 'resultPublished' &&
// 								!isPublished?.publishedAt
// 								? 'Assess Answer'
// 								: 'Result Published'}
// 						</Button>
// 					)) || (
// 						<Button
// 							onClick={() => {
// 								if (isAdminOrModerator() || isExamModarator()) {
// 									setModalVisible(!isModalVisible);
// 								} else {
// 									toastr.warning('You are not allowed');
// 								}
// 							}}
// 							type='primary'
// 							shape='round'
// 							disabled={
// 								!isPracticeExam &&
// 								isPublished &&
// 								moment(isPublished.startsAt) <= new Date() &&
// 								moment(isPublished.endsAt) >= new Date()
// 							}
// 						>
// 							{isPracticeExam &&
// 								isPublished &&
// 								isPublished?.status === examStatus.UNPUBLISHED
// 								? 'Unpublished'
// 								: isPracticeExam && isPublished
// 									? 'Published'
// 									: isPracticeExam && !isPublished
// 										? 'Publish'
// 										: !isPracticeExam && !isPublished
// 											? 'Schedule'
// 											: !isPracticeExam &&
// 												isPublished &&
// 												moment(isPublished.startsAt) <= new Date() &&
// 												moment(isPublished.endsAt) >= new Date()
// 												? 'Running'
// 												: 'Scheduled'}
// 						</Button>
// 					)
// 				]}
// 			>
// 				<p>
// 					Start Time:{' '}
// 					{(isPublished?.startsAt &&
// 						moment(isPublished.startsAt).format('DD MMM, YYYY HH:mm A')) ||
// 						'Not given'}
// 				</p>
// 				<p>
// 					End Time:{' '}
// 					{(isPublished?.endsAt &&
// 						moment(isPublished.endsAt).format('DD MMM, YYYY HH:mm A')) ||
// 						'Not given'}
// 				</p>
// 			</Card>
// 		</React.Fragment>
// 	);
// };

// export default GroupExamCard;




import React, { useState, useEffect } from 'react';
import { Button, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';

import PublishExamModal from './PublishExamModal';
import {
	isAdminOrModerator,
	isExaminer,
	isExamModarator
} from '../../constants/constFunction';
import { examStatus } from '../../constants/constString';

const GroupExamCard = props => {
	const { examData, courseId, groupId, isPracticeExam, filterType } = props;
	const [isModalVisible, setModalVisible] = useState(false);
	const [isAssessable, setAssessable] = useState(false);
	const [counter, setCounter] = useState(0);
	const dispatch = useDispatch();
	const history = useHistory();
	const groupsExam =
		useSelector(state => state.groups.selectedGroup?.exams) ||
		JSON.parse(localStorage.getItem('selectedGroup'))?.exams;
		
	const isPublished = !!groupsExam && groupsExam.find(exam => exam.examId === examData._id);


	useEffect(() => {
		setAssessable(
			isPublished && !isPracticeExam && moment(isPublished.endsAt) < new Date()
		);
		const interval = setInterval(() => {
			setCounter(counter + 1);
			setAssessable(
				isPublished &&
				!isPracticeExam &&
				moment(isPublished.endsAt) < new Date()
			);
		}, 10000);

		return () => {
			clearInterval(interval);
		};
	}, [counter, isPublished, isPracticeExam]);

	const toggleModal = () => setModalVisible(!isModalVisible);

	return (
		<React.Fragment>
			<PublishExamModal
				isVisible={isModalVisible}
				toggleModal={toggleModal.bind(this)}
				examData={examData}
				examId={examData._id}
				groupId={groupId}
				isPublished={isPublished}
				isPracticeExam={isPracticeExam}
				isAssessable={isAssessable}
			/>
			<Card
				style={{ width: '100%', marginBottom: 10 }}
				title={
					<strong style={{ fontWeight: 600 }}>{examData?.title}</strong> ||
					'Not given'
				}
				actions={[
					// isAssessable && (
					// 	<Button
					// 		type='primary'
					// 		shape='round'
					// 		onClick={() => setModalVisible(!isModalVisible)}
					// 	>
					// 		{isPublished?.status === examStatus.UNPUBLISHED
					// 			? 'Publish'
					// 			: 'Unpublish'}
					// 	</Button>
					// ),
					isAssessable ? (
						<Button
							type='primary'
							shape='round'
							onClick={() => setModalVisible(!isModalVisible)}
						>
							{isPublished?.status === examStatus.UNPUBLISHED
								? 'Publish'
								: 'Unpublish'}
						</Button>
					) : !isAssessable && isPublished && !(moment(isPublished.startsAt) <= new Date() &&
						moment(isPublished.endsAt) >= new Date()) && (
						<Button
							type='primary'
							shape='round'
							onClick={() => setModalVisible(!isModalVisible)}
						>
							{isPublished?.status === examStatus.CREATED || isPublished.status === examStatus.PUBLISHED
								? 'Unpublish'
								: 'Publish'}
						</Button>
					),
					(isAssessable && (
						// <div style={{ textAlign: 'right', padding: '0 10px' }}>
						<Button
							onClick={() => {
								if (isExaminer() || isAdminOrModerator()) {
									history.push(`/evaluate/${examData._id}`);
								} else {
									toastr.warning('You are not allowed');
								}
							}}
							type='primary'
							shape='round'
						>
							{isPublished?.status !== 'resultPublished' &&
								!isPublished?.publishedAt
								? 'Assess Answer'
								: 'Result Published'}
						</Button>
					)) || (
						<Button
							onClick={() => {
								if (isAdminOrModerator() || isExamModarator()) {
									setModalVisible(!isModalVisible);
								} else {
									toastr.warning('You are not allowed');
								}
							}}
							type='primary'
							shape='round'
							disabled={
								!isPracticeExam &&
								isPublished &&
								moment(isPublished.startsAt) <= new Date() &&
								moment(isPublished.endsAt) >= new Date()
							}
						>
							{isPracticeExam &&
								isPublished &&
								isPublished?.status === examStatus.UNPUBLISHED
								? 'Unpublished'
								: isPracticeExam && isPublished
									? 'Published'
									: !isPublished
										? 'Schedule'
										: !isPracticeExam &&
											isPublished &&
											moment(isPublished.startsAt) <= new Date() &&
											moment(isPublished.endsAt) >= new Date()
											? 'Running'
											: 'Scheduled'}
						</Button>
					)
				]}
			>
				<p>
					Start Time:{' '}
					{(isPublished?.startsAt &&
						moment(isPublished.startsAt).format('DD MMM, YYYY HH:mm A')) ||
						'Not given'}
				</p>
				<p>
					End Time:{' '}
					{(isPublished?.endsAt &&
						moment(isPublished.endsAt).format('DD MMM, YYYY HH:mm A')) ||
						'Not given'}
				</p>
			</Card>
		</React.Fragment>
	);
};

export default GroupExamCard;