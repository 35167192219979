import React from 'react';
import { Button, Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toastr from 'toastr';

import QuestionCard from './QuestionCard';
import SubHeader from '../Common/SubHeader';
import {
	updateQuestionRequest,
	resetQuestionToInitial
} from '../../stateManager/reducers/questionSlice';

const { Content } = Layout;

const EditQuestion = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const questionList = useSelector(state => state.questions.questionList);
	console.log('questionList', questionList);

	const handleUpdateQuestion = async () => {
		try {
			// eslint-disable-next-line
			const promies = questionList.map(question => {
				const obj = { ...question };
				delete obj['_id'];
				delete obj['isDeleted'];
				dispatch(
					updateQuestionRequest({
						questionId: question._id,
						data: {
							...obj,
							status: 'pending',
							optionType:
								question?.optionType &&
								(typeof question.optionType === 'string' ||
									Object.keys(question?.optionType).length > 0)
									? JSON.parse(question?.optionType)
									: {}
						}
					})
				);
			});
			// eslint-disable-next-line
			const res = await Promise.all(promies);
			dispatch(resetQuestionToInitial());
			history.goBack();
		} catch (error) {
			console.log(error);
		}
	};

	const validateQuestion = () => {
		let isValid = true;
		for (let i = 0; i < questionList.length; i++) {
			const question = questionList[i];
			if (!question.title || question.title.length < 5) {
				toastr.error('Please add a valid title to the question ' + (i + 1));
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.options || question.options.length < 2)
			) {
				toastr.error(
					'Please add at least two valid option for the question ' + (i + 1)
				);
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.answer || question.answer.length < 1)
			) {
				toastr.error('Please select an answer for the question ' + (i + 1));
				isValid = false;
				break;
			}
		}

		if (isValid) {
			handleUpdateQuestion();
		}
		return;
	};

	return (
		<React.Fragment>
			<SubHeader headText='Edit Question' backIcon={true} />

			<Content>
				{questionList.map((item, index) => (
					<QuestionCard questionObject={item} questionIndex={index} />
				))}

				<div className='main'>
					<div style={{ width: 500 }}>
						<Button type='primary' onClick={() => validateQuestion()}>
							Update Questions
						</Button>
					</div>
				</div>
			</Content>
		</React.Fragment>
	);
};

export default EditQuestion;
