import React, { useEffect } from 'react';
import { Row, Col, Select, Button, Space, Input, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { readString } from 'react-papaparse';

import {
	getLectureBySubject,
	getChapterBySubject
} from '../../stateManager/reducers/courseSlice';
import {
	addFilters,
	saveQuestionRequest
} from '../../stateManager/reducers/questionSlice';

const { Option } = Select;

const FilterForAdd = props => {
	const { subjectList, courseId } = props;
	const dispatch = useDispatch();
	const chapterList = useSelector(state => state.courses.chapterList);
	const lectureList = useSelector(state => state.courses.lectureList);
	const status = useSelector(state => state.questions.status);

	useEffect(() => {
		localStorage.setItem('selectedSubjectExamView', '');
	}, []);

	const selectedSubjectForFilter = useSelector(
		state => state.questions.selectedSubjectForFilter
	);
	const selectedChapterForFilter = useSelector(
		state => state.questions.selectedChapterForFilter
	);
	const selectedLectureForFilter = useSelector(
		state => state.questions.selectedLectureForFilter
	);

	const handleFileUpload = file => {
		let read = new FileReader();
		read.readAsText(file);

		read.onloadend = async function () {
			const result = await readString(read.result, {
				header: true,
				skipEmptyLines: true,
				transform: (value, header) => {
					if (header === 'answer') {
						return value.split(',').map(item => item.trim());
					}
					if (header === 'notes' || header === 'explanation') {
						return !!value && value.length > 0 ? value : undefined;
					}
					return value;
				}
			});
			const { data: questionData } = result;
			const questions = questionData.map(item => {
				let answer = [];
				if (item.type === 'MCQ' || item.type === 'checkbox') {
					// eslint-disable-next-line
					item.answer.map(element => {
						const index = parseInt(element);
						answer.push(item[`option${index}`]);
					});
				}
				if (item.type === 'shortAns' || item.type === 'paragraph') {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					delete obj['options'];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					return {
						...obj,
						answer,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter
					};
				} else {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					const options = [
						item.option1,
						item.option2,
						item.option3,
						item.option4
					];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					return {
						...obj,
						answer,
						options,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter
					};
				}
			});

			const data = { questions };
			try {
				const res = await dispatch(saveQuestionRequest(data));
			} catch (error) {
				console.log(error);
			}
		};
	};

	const handleSubjectChange = async subjectId => {
		console.log('subject', subjectId);
		const subject = subjectList.find(item => item._id === subjectId);
		localStorage.setItem('selectedSubjectExamView', subject.name);
		await dispatch(getLectureBySubject({ subjectId }));
		await dispatch(getChapterBySubject({ subjectId }));
	};

	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[8, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
				justify='space-between'
			>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Search Question</strong>
					<Input
						onChange={e => {
							setTimeout(() => {
								props.getQuestionByTitle(e.target.value);
							}, 1000);
						}}
						style={{ width: '100%' }}
						placeholder='Search with title'
					/>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Subject</strong>
					<Select
						value={selectedSubjectForFilter}
						onChange={value => {
							handleSubjectChange(value);
							dispatch(
								addFilters({ key: 'selectedSubjectForFilter', value: value })
							);
							dispatch(
								addFilters({
									key: 'selectedLectureForFilter',
									value: undefined
								})
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: undefined
								})
							);
						}}
						style={{ width: '100%' }}
						placeholder='Select Subject'
					>
						{subjectList.map((item, index) => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Lecture</strong>
					<Select
						onChange={value => {

							const lecture = lectureList.find(item => item._id === value);
							localStorage.setItem('selectedLectureExamView', lecture.name);
							dispatch(
								addFilters({ key: 'selectedLectureForFilter', value: value })
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: undefined
								})
							);
						}}
						value={selectedLectureForFilter}
						style={{ width: '100%' }}
						placeholder='Select Lecutre'
					>
						{!!lectureList &&
							lectureList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Chapter</strong>
					<Select
						onChange={value => {
							dispatch(
								addFilters({
									key: 'selectedLectureForFilter',
									value: undefined
								})
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: value
								})
							);
						}}
						style={{ width: '100%' }}
						placeholder='Select Chapter'
						value={selectedChapterForFilter}
					>
						{!!chapterList &&
							chapterList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={8} lg={5} offset={1}>
					<Space>
						<Upload
							accept={'.csv'}
							showUploadList={false}
							onChange={e => {
								if (e.file.status === 'done' || e.file.status === 'error') {
									handleFileUpload(e.file.originFileObj);
								}
							}}
						>
							<Button
								loading={status === 'loading'}
								disabled={
									!selectedSubjectForFilter ||
									(!selectedChapterForFilter && !selectedLectureForFilter)
								}
								type='dashed'
								icon={<UploadOutlined />}
							>
								Upload
							</Button>
						</Upload>
						<Link
							to={`/add-question/${courseId}/${selectedSubjectForFilter}/${
								!!selectedChapterForFilter
									? selectedChapterForFilter + '/chapter'
									: selectedLectureForFilter + '/lecture'
							}`}
						>
							<Button
								disabled={
									!selectedSubjectForFilter ||
									(!selectedChapterForFilter && !selectedLectureForFilter)
								}
								// onClick={() => props.toogleAddVideoModal()}
								type='primary'
							>
								Add Question
							</Button>
						</Link>
					</Space>
				</Col>
			</Row>
		</div>
	);
};

export default FilterForAdd;
