import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import GroupList from '../../Group/GroupList';
import SingleGroup from '../../Group/SingleGroup';

import Evaluate from '../../pages/admin/evaluate/Evaluate.page';
import Assessment from '../../pages/admin/evaluate/Assessment.page';
import PublishResult from '../../pages/admin/evaluate/PublishResult.page';

const ExaminerNavigation = props => {
	return (
		<React.Fragment>
			<Redirect exact from='/' to='/groups' />
			<Route path='/groups/:courseId/:groupId' component={SingleGroup} />
			<Route exact path='/groups' component={GroupList} />
			<Route path='/evaluate/:examId' component={Evaluate} />
			<Route path='/assessment/:studentId' component={Assessment} />
			<Route path='/publish' component={PublishResult} />
		</React.Fragment>
	);
};

export default ExaminerNavigation;
