import React from 'react';
import { Card, Row, Col, Tag, Table } from 'antd';
import { useSelector } from 'react-redux';

const SegmentedExamDetails = ({ examDetails }) => {

  const subjectList = useSelector((state) => state.courses.subjectList);
  console.log(subjectList);

  const getSubjectName = (subjectId) => {
    const subject = subjectList.find((subject) => subject._id === subjectId);
    return subject ? subject.name : '';
  }


  
  const {
    totalSubjects,
    totalQuestions,
    minimumCompulsoryOptionalSubjects,
    mandatorySubjects,
    compulsoryOptionalSubjects,
    optionalSubjects,
  } = examDetails;

  const SubjectTable = ({ data, title }) => {
    const columns = [
      {
        title: 'Subject Name',
        dataIndex: 'subjectId',
        key: 'subjectId',
        render: (subjectId) => getSubjectName(subjectId),
      },
      {
        title: 'Questions',
        dataIndex: 'count',
        key: 'count',
      },
    ];
  
    return (
      <Card
      title={title}
      style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", border: "2px solid #aaaaaa", }}
    >
      <Table 
        dataSource={data} 
        columns={columns} 
        rowKey="subjectId" 
        pagination={false} 
        bordered
      />
    </Card>
    );
  };
  

  return (
    <Card title="Exam Information" style={{ width: '100%' }}>
      <Row gutter={16}>
        {/* Total Subjects */}
        <Col span={8}>
          <Card bordered={false} title="Total Subjects">
            <p>{totalSubjects}</p>
          </Card>
        </Col>

        {/* Total Questions */}
        <Col span={8}>
          <Card bordered={false} title="Total Questions">
            <p>{totalQuestions}</p>
          </Card>
        </Col>

        {/* Minimum Compulsory Optional Subjects */}
        <Col span={8}>
          <Card bordered={false} title="Minimum Compulsory Optional Subjects">
            <p>{minimumCompulsoryOptionalSubjects}</p>
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SubjectTable 
            data={mandatorySubjects} 
            title="Mandatory Subjects" 
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SubjectTable 
            data={compulsoryOptionalSubjects} 
            title="Compulsory Optional Subjects" 
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24}>
          <SubjectTable 
            data={optionalSubjects} 
            title="Optional Subjects" 
          />
        </Col>
      </Row>
    </Card>
  );
};

export default SegmentedExamDetails;
