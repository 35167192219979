import React, { useEffect, useState } from 'react'
import { Button, Input, Popconfirm, Space, Switch, Table, Tag,DatePicker ,Pagination, Select} from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getCourseSubjects } from '../../stateManager/reducers/mentorSlice'
import AssignSubjectModal from './AssignSubjectModal'

import { fetchSeniorMentors } from '../../stateManager/reducers/mentorSlice';
import { CSVDownloader } from 'react-papaparse';

const { RangePicker } = DatePicker;
const { Option } = Select
const MentorListTable = () => {
	const dispatch                                = useDispatch()
	const [isAddModalVisible, setAddModalVisible] = useState(false)
	const [isEdit, setIsEdit]                     = useState(false)
	const [selectedUser, setSelectedUser]         = useState(undefined)
	const [currentPage, setCurrentPage]                   = useState(1)
	const [pageSize, setPageSize]                   = useState(10)
	const [searchKey, setSearchKey]               = useState('')
	const [dateRange, setDateRange]               = useState([])
	const [selectedCourse, setSelectedCourse]     = useState(null)
	const [selectedSubject, setSelectedSubject]   = useState(null)
	const [selectedSubjects, setSelectedSubjects] = useState([])
	const [selectedSession, setSelectedSession]   = useState(new Date().getFullYear().toString())
	let mentorData                              = useSelector(state => state.mentor.seniorMentors)
	let courses                                                  = useSelector(state => state.mentor.courses)

	console.log('mentorData', mentorData)
	if(selectedSession){
		courses = courses.filter(course => course.session === selectedSession)
	}
	if(selectedCourse){
		//courseId in subjects array with evry subject
		mentorData = mentorData.filter(mentor => mentor.subjects.find(subject => subject.courseId === selectedCourse))
		if(selectedSubject){
			mentorData = mentorData.filter(mentor => mentor.subjects.find(subject => subject._id === selectedSubject))
		}
		
	}

	//slice data for pagination
	const indexOfLastPost = currentPage * pageSize;
	const indexOfFirstPost = indexOfLastPost - pageSize;
	let paginatedMentorData = mentorData.slice(indexOfFirstPost, indexOfLastPost);
	//filter data based on search key
	if (searchKey) {
		paginatedMentorData = mentorData.filter(item => {
			return item.firstName.toLowerCase().includes(searchKey.toLowerCase()) || 
			item.lastName.toLowerCase().includes(searchKey.toLowerCase()) || 
			item.username.toLowerCase().includes(searchKey.toLowerCase()) ||
			item?.adminId?.toLowerCase().includes(searchKey.toLowerCase())

		})
	}

	const toggleModal = () => setAddModalVisible(!isAddModalVisible)

	async function fetchDataMentor() {
		await dispatch(fetchSeniorMentors({
			searchKey: searchKey,
			startDate: dateRange[0],
			endDate: dateRange[1]
		}));
	}

	useEffect(() => {
		fetchDataMentor();
	}, [searchKey, dateRange, currentPage, pageSize])

	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourseSubjects())
		}

		fetchData()
	}, [])

	// handle modal open
	const handleModalOpen = (_isModalOpen, _isEdit) => {
		setAddModalVisible(_isModalOpen)
		setIsEdit(_isEdit)
	}

	const getCsvData = () => {
		const csvData = mentorData.map(item => {
			return {
				'ID': item.adminId,
				'Phone'      : item.username,
				'Name'       : item.firstName + ' ' + item.lastName,
				'Subject'    : item.subjects.map(subject => subject.name).join(', '),
				'Upvote'     : item.totalUpvotes,
				'Answer'     : item.totalAnswers,
				'ResponseTime': getFormattedResponseTime(item.responseTime)
			}
		})
		return csvData
	}


	const getFormattedResponseTime = (responseTime) => {
		let time = responseTime;
				let days = Math.floor(time / (24 * 60));
				time = time % (24 * 60);
				let hours = Math.floor(time / 60);
				time = time % 60;
				let minutes = time;
				let responseTimeString = '';
				if (days > 0) {
					responseTimeString += days + 'd ';
				}
				if (hours > 0) {
					responseTimeString += hours + 'h ';
				}
				if (minutes > 0) {
					responseTimeString += minutes + 'm';
				}
				return responseTimeString;
	}

	// useEffect(() => {
	// 	if (mentorSubjects?.data) {

	// 		const courseSubjects = mentorSubjects.data.reduce((acc, item) => {
	// 			acc[item._id] = item.subjects;
	// 			return acc;
	// 		}, {});
	// 		console.log("courseSubjects", courseSubjects);
	// 	}
	// }, [mentorSubjects]);
	const columns = [
		{
			title    : <strong>ID</strong>,
			key: 'id',
			dataIndex: 'adminId',
		
		},
		{
			title    : <strong>Phone</strong>,
			key	  : 'phone',
			dataIndex: 'username',
		},
		{
			title    : <strong>Name</strong>,
			key      : 'name',
			dataIndex: 'name',
			render   : (text, record) => record.firstName + ' ' + record.lastName,
		},
		{
			title    : <strong>Subject</strong>,
			key      : 'subject',
			dataIndex: 'subjects',
			render   : subjects => subjects.map(item => <Tag>{item.name}</Tag>),
		},
		{
			title    : <strong>Upvote</strong>,
			key      : 'upvote',
			dataIndex: 'totalUpvotes',
		},
		{
			title    : <strong>Answer</strong>,
			key      : 'answer',
			dataIndex: 'totalAnswers',
		},
		{
			title    : <strong>Res. Time </strong>,
			key      : 'answer',
			dataIndex: 'responseTime',
			render   : responseTime => getFormattedResponseTime(responseTime),
		},


		// {
		// 	title: <strong>Roles</strong>,
		// 	key: 'roles',
		// 	dataIndex: 'roles',
		// 	render: roles => roles.map(item => <Tag>{item}</Tag>)
		// },
		{
			title    : <strong>Actions</strong>,
			dataIndex: 'username',
			key      : 'edit',
			render   : (text, item, index) => (
				<Space size={20}>
					<div style={{
						background    : '#E4F1FD',
						borderRadius  : '12px',
						height        : '32px',
						width         : '32px',
						display       : 'flex',
						justifyContent: 'center',
						alignItems    : 'center',
					}}
					>
						<Switch defaultChecked size={'small'} style={{ background: '#c5d1db', borderColor: 'red' }} />
					</div>
					<Button
						onClick={() => {
							setSelectedUser(item)
							handleModalOpen(true, true)
						}}
						type={'text'}
						icon={<EditOutlined style={{ color: '#475569' }} />}
						style={{ borderRadius: '12px', background: '#DFF2E0' }}
					/>

					<Popconfirm
						title="Are you sure to delete the user?"
						onConfirm={async () => {
							//await dispatch(deleteUser({ userId: text, index }))
						}}
					>
						<Button
							type={'text'}
							danger={true}
							icon={<DeleteOutlined />}
							style={{ borderRadius: '12px', background: 'rgba(246, 7, 7, 0.10)' }}
						/>
					</Popconfirm>
				</Space>
			),
		},
	]

	return (
		<div style={{
			borderRadius   : '8px',
			padding        : '48px 40px 32px',
			backgroundColor: 'white',
		}}>
			<AssignSubjectModal
				isVisible={isAddModalVisible}
				toggleModal={toggleModal.bind(this)}
				isEdit={isEdit}
				user={selectedUser}
				updateUser={setSelectedUser}
				handleModalOpen={handleModalOpen}
			/>
			<RangePicker 
			onChange={(_, info)=>{
				console.log(info)
				setDateRange(info)
			}} />
			<div style={{
				display       : 'flex',
				justifyContent: 'space-between',
				alignItems    : 'center',
				gap           : '40px',
				paddingBottom : '32px',
			}}
			>
				<div style={{ width: '100%' }}>
					<Input
						size={'large'}
						style={{ width: '100%', height: '56px', borderRadius: '8px' }}
						placeholder={'Search'}
						suffix={
							<div style={{ paddingRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path
										d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
										stroke="#475569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>
						}
						onChange={e => setSearchKey(e.target.value)}
						
					/>
				</div>
				<div style={{ display: 'flex', gap: '32px' }}>
					{/* <Space direction="vertical" size={12} className="date-picker-section">
						<RangePicker />
					</Space> */}
					<CSVDownloader
								data={getCsvData()}
								type='link'
								filename={'mentor_list'}
								bom={true}
							>
								{/* <Button
									key='3'
									type='primary'
									// className='result-publish-btn'
									icon={<ExportOutlined />}
								// onClick={() => history.push('/publish')}
								>
									Export as CSV
								</Button> */}
								<Button
						size={'large'}
						danger={true}
						type={'primary'}
						style={{
							backgroundColor: '#EE473F',
							borderRadius   : '8px',
							height         : '56px',
							minWidth       : '200px',
						}}
					>
						{'Download CSV'}
					</Button>
							</CSVDownloader>
					<Button
						size={'large'}
						danger={true}
						type={'primary'}
						style={{
							backgroundColor: '#EE473F',
							borderRadius   : '8px',
							height         : '56px',
							minWidth       : '200px',
						}}
						onClick={() => handleModalOpen(true, false)}
					>
						{'Assign Subject'}
					</Button>
				</div>
			</div>
			
			<Select
						placeholder={'Select Session'}
						style={{ width: '100%' }}
						size={'large'}
						value={selectedSession}
						onChange={(value) => {
							setSelectedCourse(null)
							setSelectedSubject(null)
							setSelectedSession(value)
						}}>
						{
							['2026', '2025', '2024', '2023', '2022' ].map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))
						}
					</Select>
					<Select
						placeholder={'Select Course'}
						style={{ width: '100%' }}
						size={'large'}
						value={selectedCourse}
						onChange={(value) => {
							setSelectedCourse(value)
							setSelectedSubject(null)
							const course = courses.find(course => course._id === value)
							setSelectedSubjects(course.subjects)
						}}>
						{
							courses.map((course) => (
								<Option key={course._id} value={course._id}>
									{course.name}
								</Option>
							))
						}
					</Select>
					<Select
						placeholder={'Select subject'}
						style={{ width: '100%' }}
						size={'large'}
						value={selectedSubject}
						onChange={(value) => setSelectedSubject(value)}>
						{
							selectedSubjects.map((course) => (
								<Option key={course._id} value={course._id}>
									{course.name}
								</Option>
							))
						}
					</Select>
			<Table
				columns={columns}
				dataSource={paginatedMentorData}
				pagination={false}
				onRow={(record, index) => ({
					style: {
						background: index % 2 ? '#F1F5F9' : 'default',
						borderRadius: '8px !important'
					},
				}
				)}
			/>
			<div className="pagination-section">
                <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={mentorData.length}
				onChange={(page, pageSize) => {
					setCurrentPage(page);
					setPageSize(pageSize);
				}}
                style={{ textAlign: 'center', marginTop: '20px' ,paddingBottom: '0px'}}
              />
            </div>
		</div>
	)
}

export default MentorListTable
