import { configureStore } from '@reduxjs/toolkit';

import userManagementReducer from './reducers/userManagementSlice';
import authAdminReducer from './reducers/adminAuthSlice';
import courseReducer from './reducers/courseSlice';
import studentsManagementReducer from './reducers/studentManagementSlice';
import groupReducer from './reducers/groupSlice';
import studentAuthReducer from './reducers/studentAuthSlice';
import contentReducer from './reducers/contentSlice';
import questionReducer from './reducers/questionSlice';
import examReducer from './reducers/examSlice';
import notificationReducer from './reducers/notificationSlice';
import dashboardReducer from './reducers/dashboardSlice';
import fileSystem from './reducers/fileSystem';
import mentorReducer from './reducers/mentorSlice';

const store = configureStore({
	reducer: {
		userManagement: userManagementReducer,
		authAdmin: authAdminReducer,
		courses: courseReducer,
		studentsAdmin: studentsManagementReducer,
		groups: groupReducer,
		studentAuth: studentAuthReducer,
		contents: contentReducer,
		questions: questionReducer,
		exams: examReducer,
		notifications: notificationReducer,
		dashboard: dashboardReducer,
		fileSystem: fileSystem,
		mentor: mentorReducer

	}
});

export default store;
