/**@module registration */
import React from 'react';
import { Layout, Button, Form, Input } from 'antd';
import {
	UserOutlined,
	MailOutlined,
	LockOutlined,
	PhoneOutlined
} from '@ant-design/icons';

const FormItem = Form.Item;
const { Content } = Layout;
/**
 * This component is used to registration new user
 */
const Registration = props => {
	return (
		<div className='body'>
			<div className='wrap-registration'>
				<div className='logo'>
					<img src='/mystudy-horizontal.png' alt='Logo' />
				</div>
				<div className='form-wrap'>
					<Form>
						<Content style={{ margin: '0 16px' }}>
							<FormItem
								name='name'
								noStyle
								rules={[
									{
										required: true,
										message: 'Enter Your Name'
									}
								]}
							>
								<Input
									prefix={
										<UserOutlined
											type='user'
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									placeholder='Enter Your Name'
								/>
							</FormItem>
							<FormItem
								noStyle
								rules={[{ required: true, message: 'Enter your mail.' }]}
							>
								<Input
									prefix={
										<MailOutlined
											type='mail'
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									placeholder='Enter Your Email'
								/>
							</FormItem>
							<FormItem
								noStyle
								rules={[{ required: true, message: 'Enter your phone number' }]}
							>
								<Input
									prefix={
										<PhoneOutlined
											type='phone'
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									placeholder='Enter Your Phone Number'
								/>
							</FormItem>
							<FormItem
								noStyle
								rules={[{ required: true, message: 'Enter Password' }]}
							>
								<Input
									prefix={
										<LockOutlined
											type='lock'
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									type='password'
									placeholder='Enter Password'
								/>
							</FormItem>
							<FormItem
								noStyle
								rules={[{ required: true, message: 'Confirm your password' }]}
							>
								<Input
									prefix={
										<LockOutlined
											type='lock'
											style={{ color: 'rgba(0,0,0,.25)' }}
										/>
									}
									type='password'
									placeholder='Confirm your password'
								/>
							</FormItem>
							<FormItem>
								<Button type='primary' htmlType='submit' block>
									Registration
								</Button>{' '}
								<br />
								<div className='form-item w-100 text-right'>
									<a className='text-uppercase' href='/verify-account'>
										Already registered? Veerify your acccount here
									</a>
								</div>
								<div className='form-item w-100 text-center'>
									{'or '}
									<a className='text-uppercase' href='/login'>
										Log In
									</a>
								</div>
							</FormItem>
						</Content>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default Registration;
