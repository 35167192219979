import React, { useState } from 'react';
import { Button, Space, Table, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MenuOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
	setReorderData,
	reorderChapterLecture,
	deleteChapterLecture,
	deleteChapterLectureLocal,
	createQuestionSolveFromChapter
} from '../../stateManager/reducers/courseSlice';
import EditChapterLectureModal from './EditChapterLectureModal';

const DragHandle = SortableHandle(() => (
	<MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
));
const SortableItem = SortableElement(props => <tr {...props} />);
const SortableContainerCustom = SortableContainer(props => (
	<tbody {...props} />
));

const LectureTable = props => {
	const { data, type, subjectId } = props;
	const dispatch = useDispatch();
	const [isEditModalVisible, setEditModalVisible] = useState(false);
	const [selectedData, setSelectedData] = useState(undefined);
	const dataSource = useSelector(state =>
		type === 'lecture'
			? state.courses.lectureList
			: type === 'chapter'
				? state.courses.chapterList
				: state.courses.questionSolveList
	);
	const status = useSelector(state => state.courses.status);

	const toggleModal = () => setEditModalVisible(!isEditModalVisible);

	const onSortEnd = async ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMove(
				[].concat(dataSource),
				oldIndex,
				newIndex
			).filter(el => !!el);
			dispatch(setReorderData({ oldIndex, newIndex, dataSource, type }));
			const contents = newData.map(item => item._id);
			const data = {
				lectures: type === 'lecture' ? contents : undefined,
				chapters: type === 'chapter' ? contents : undefined,
				questionSolves: type === 'question-solve' ? contents : undefined
			};
			const res = await dispatch(reorderChapterLecture({ subjectId, data }));
		}
	};

	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = dataSource.findIndex(
			x => x._id === restProps['data-row-key']
		);
		return <SortableItem index={index} {...restProps} />;
	};

	const DraggableContainer = props => (
		<SortableContainerCustom
			useDragHandle
			helperClass='row-dragging'
			onSortEnd={onSortEnd}
			{...props}
		/>
	);

	const columns = [
		{
			title: 'Sort',
			key: 'sort',
			render: () => <DragHandle />
		},
		{
			title: <strong>Sl no.</strong>,
			key: 'slNo',
			render: (value, item, index) => index + 1
		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Action',
			key: 'action',
			render: (text, record, index) => {
				return (
					<Space>
						<Button
							onClick={() => {
								setSelectedData(record);
								setEditModalVisible(!isEditModalVisible);
							}}
							icon={<EditOutlined />}
							type='text'
						></Button>
						<Popconfirm
							title={`Are you sure to delete the ${type}?`}
							okButtonProps={{ loading: status === 'loading' }}
							placement='topRight'
							onConfirm={async () => {
								try {
									const res = await dispatch(
										deleteChapterLecture({
											type,
											subjectId,
											typeId: record._id,
											name: record.name
										})
									);
									dispatch(deleteChapterLectureLocal({ type, index }));
								} catch (error) {
									console.log(error);
								}
							}}
						>
							<Button icon={<DeleteOutlined />} type='text'></Button>
						</Popconfirm>
						{
							type === 'chapter' && (
							<Popconfirm
							title={`Are you sure to migrate the ${type}?`}
							okButtonProps={{ loading: status === 'loading' }}
							placement='topRight'
							onConfirm={async () => {
								try {
									const res = await dispatch(
										createQuestionSolveFromChapter({
											chapterId: record._id
										})

									);
									
								} catch (error) {
									console.log(error);
								}
							}}
						>
							<Button icon={<CopyOutlined />} type='text'></Button>
						</Popconfirm>
							)
						}
						
					</Space>
				);
			}
		}
	];

	return (
		<React.Fragment>
			<EditChapterLectureModal
				isVisible={isEditModalVisible}
				toggleModal={toggleModal.bind(this)}
				subjectId={subjectId}
				type={type}
				chapterLectureData={selectedData}
			/>
			<Table
				loading={status === 'loading'}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				size='middle'
				rowKey='_id'
				dataSource={dataSource}
				columns={columns}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			/>
		</React.Fragment>
	);
};

export default LectureTable;
