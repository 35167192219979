import React from 'react';
import { Card, Col, Typography, Popconfirm, Button } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
	getExamByIdForCopy,
	deleteExamRequest
} from '../../stateManager/reducers/examSlice';

import {
	isAdminOrModerator,
	isExamModarator,
	isInstructor
} from '../../constants/constFunction';

const { Title } = Typography;

const ExamListCardComponent = props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { courseId, item, subjectList, selectedSubject, toggleDeleteModal } = props;

	const getSubjectName = () => {
		if (item?.subjectId && subjectList) {
			for (let i = 0; i < subjectList.length; i++) {
				const element = subjectList[i];
				if (element?._id === item?.subjectId) {
					return element?.name;
				}
			}
		}
		return 'Not given';
	};

	return (
		<Col xs={24} sm={12} md={8} lg={6}>
			<Card
				className={
					(item?.status === 'approved' ? 'approved' : 'notapproved') +
					' examListCardWrap'
				}
				title={
					<Title
						level={5}
						ellipsis={true}
						onClick={() => {
							if (
								isInstructor() &&
								!isAdminOrModerator() &&
								!isExamModarator()
							) {
								history.push(`/exam-details/${item._id}/${courseId}`);
							} else {
								history.push(`/exam-details/${item._id}`);
							}
						}}
						style={{
							fontWeight: 600
						}}
					>
						{item.title}
					</Title>
				}
				extra={
					<span className='status'>
						{item?.status === 'approved' ? 'Approved' : 'Need Approval'}
					</span>
				}
				hoverable
				style={{ height: '100%', width: '100%' }}
				actions={
					!isInstructor()
						? [
							<Button
								style={{ fontWeight: 800 }}
								type='text'
								color='green'
								disabled={!selectedSubject}
								key='copy'
								onClick={async () => {
									await dispatch(getExamByIdForCopy({ examId: item._id }));
									history.push(`/add-exam/${courseId}/${selectedSubject}`);
								}}
							>
								<CopyOutlined /> Copy
							</Button>,
							<Button type='text' danger style={{ fontWeight: 800 }}>
								<Popconfirm
									title='Are you sure you want to delete the exam?'
									onConfirm={async () => {
										await dispatch(deleteExamRequest({ examId: item._id, force: false, toggleDeleteModal, examData: item }));
									}}
								>
									<DeleteOutlined key='delete' /> Delete{' '}
								</Popconfirm>
							</Button>
						]
						: []
				}
			>
				<Card.Meta
					onClick={() => {
						if (isInstructor() && !isAdminOrModerator() && !isExamModarator()) {
							history.push(`/exam-details/${item._id}/${courseId}`);
							return;
						} else {
							history.push(`/exam-details/${item._id}`);
						}
					}}
					description={
						<div>
							{item?.totalMarks && (
								<span>
									<strong>Total Marks: </strong>
									{item?.totalMarks}
								</span>
							)}
							<br />
							{item?.questions && item?.questions.length > 0 && (
								<span>
									<strong>Total Questions: </strong>
									{item?.questions.length}
								</span>
							)}
							<br />
							<span>
								<strong>Subject: </strong>
								{getSubjectName()}
							</span>
						</div>
					}
				/>
			</Card>
		</Col>
	);
};

export default ExamListCardComponent;
