// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA5nBWy6GKx1wWd_lk82XET4Wbc8cxaqsg",
  authDomain: "retina-lms-824b3.firebaseapp.com",
  projectId: "retina-lms-824b3",
  storageBucket: "retina-lms-824b3.firebasestorage.app",
  messagingSenderId: "324726476399",
  appId: "1:324726476399:web:ee6b76015798cea6c3879a",
  measurementId: "G-F4FMZH185G",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
