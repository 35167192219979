import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../constants/constString';
import axios from 'axios';
import toastr from 'toastr';
import { setData } from './groupSlice';

const initialState = {

};


export const patchFileRequest = createAsyncThunk(
    'question/fileUpload',
    async ({ signedUrl, file }, { getState, dispatch }) => {
        try {
            const { CancelToken } = axios;
            const response = await axios({
                url: signedUrl,
                method: 'PUT',
                data: file,
                onUploadProgress(progressEvent) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // state.uploading = 100 - percentCompleted;
                    dispatch(setData({ key: 'progress', value: percentCompleted }))
                },
                cancelToken: new CancelToken(c => {
                    // state.cancel = c;
                    // console.log(c);
                }),
                headers: {
                    'Content-Type': `${file.type}`
                    // Authorization: sessionId
                }
            });
            // state.uploading = 100;
            dispatch(setData({ key: 'progress', value: 0 }))
            return response;
        } catch (error) {
            toastr.error("Image Upload Failed");
            return Promise.reject(error);
        }
    }
);

export const signedUrl = createAsyncThunk(
    'question/signedUrl',
    async fileType => {
        try {
            const response = await instance.get(
                `/content/signed-request?mimeType=${fileType}`
            );
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }
);


const slice = createSlice({
    name: 'fileSystem',
    initialState: initialState,
    reducers: {

    },
    extraReducers: {

    }
});

export const {

} = slice.actions;

export default slice.reducer;
