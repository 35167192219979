import { ArrowDownOutlined, PrinterFilled } from '@ant-design/icons';
import {
	Avatar,
	Button,
	Card,
	Col,
	Divider,
	Form,
	Image,
	Input,
	Layout,
	Row,
	Space,
	Table,
	Typography,
	Tabs
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toastr from 'toastr';
import defaultAvatar from '../../../assets/images/default-avatar.jpg';
import { ReactComponent as AverageMarksIcon } from '../../../assets/images/icons/average-marks.svg';
import { ReactComponent as ExamFailedIcon } from '../../../assets/images/icons/exam-failed.svg';
import { ReactComponent as ExamPassedIcon } from '../../../assets/images/icons/exam-passed.svg';
import { ReactComponent as HighestMarksIcon } from '../../../assets/images/icons/highest-marks.svg';
import { ReactComponent as LiveExamIcon } from '../../../assets/images/icons/live-exam.svg';
import { ReactComponent as LowestMarksIcon } from '../../../assets/images/icons/lowest-marks.svg';
import { bucket_url } from '../../../constants/constString';
import PrintScoreboard from './PrintScoreboard';

import {
	addAlternateExam
} from '../../../stateManager/reducers/studentAuthSlice';
import ScoreSummaryCard from '../../Header/student/ScoreSummaryCard.component';
import './StudentScoreboard.page.style.css';

import {
	getAlternateExamById,
	getStudentScoreRequest,
	resetState
} from '../../../stateManager/reducers/studentManagementSlice';

const { Content } = Layout;
const { Title } = Typography;
const { TabPane } = Tabs;

const StudentScoreboard = props => {
	const [form] = Form.useForm();
	const { studentId } = props.match.params;
	const dispatch = useDispatch();
	const location = useLocation();
	const [showLoadMore, setShowLoadMore] = useState(true);
    const [alternateExamCode, setAlternateExamCode] = useState('');
    const [alternateExamLink, setAlternateExamLink] = useState('');
	const [startPrinting, setStartPrinting] = useState(false);
	let studentProfile = location?.state?.studentProfile || undefined;
	if (!studentProfile) {
	  studentProfile = JSON.parse(localStorage.getItem(studentId)) || {};
	}

	// const studentProfile = useSelector(state => state.studentAuth.studentProfile);
	// const scoreData = useSelector(
	// 	state => state.studentsAdmin.studentScoreBoardData
	// );
	// const examResult = useSelector(state =>
	// 	state.studentsAdmin.scoreboardExamResult?.filter(
	// 		item => item?.examId && item?.examId?.isPracticeExam === false
	// 	)
	// );

  const scoreData = useSelector(
    (state) => state.studentsAdmin.studentScoreBoardData
  );
  const examResult = useSelector((state) =>
    state.studentsAdmin.scoreboardExamResult?.filter(
      (item) => item?.examId && item?.examId?.isPracticeExam === false
    )
  );

  const offlineResults = useSelector(
	(state) => state.studentsAdmin.offlineResults
	  );

	  console.log('offlineResults', offlineResults);

  const allExams = useSelector(
    (state) => state.studentsAdmin.scoreboardExamResult
  );
  const liveExam = scoreData?.analitycs?.liveExam || {};
  const columns = [
	{
		title: "Exam Date",
		dataIndex: "startsAt",
		key: "ExamDate",
		render: (text) => (
		  <span className="table-center-text">
			{moment(text).format("MMM DD, YYYY")}
		  </span>
		),
	  },
    {
      title: "Exam Name",
      dataIndex: "examId",
      key: "ExamName",
      render: (text, record, index) =>
        record?.examId ? record?.examId?.title : "N/A",
    },
    
    {
      title: "Pass/Fail",
      key: "PassFail",
      render: (text, record, index) => (
        <span
          className={
            (record?.marksObtained * 100) / record?.examId?.totalMarks >=
            record?.examId?.passMark
              ? "table-center-text is-success"
              : "table-center-text is-error"
          }
        >
          {(record?.marksObtained * 100) / record?.examId?.totalMarks >=
          record?.examId?.passMark
            ? "PASS"
            : "FAIL"}
        </span>
      ),
    },
	{
		title: "Total Marks",
		dataIndex: "examId",
		key: "TotalMarks",
		render: (text, record) => (
		  <span className="table-center-text">
			{record?.gpaFactor
			  ? text?.totalMarks +
				record?.gpaFactor?.hsc * 5 +
				record?.gpaFactor?.ssc * 5
			  : text?.totalMarks}
		  </span>
		),
	  },
    {
      title: "Obtained Marks",
      dataIndex: "marksObtained",
      key: "ObtainedMarks",
      render: (text) => (
        <span className="table-center-text">{text?.toFixed(2)}</span>
      ),
    },
    
    {
      title: "Negative Marks",
      dataIndex: "negativeMarks",
      key: "NegativeMarks",
      render: (text) => (
        <span className="table-center-text">{text?.toFixed(2)}</span>
      ),
    },
	{
		title: "Merit Position",
		dataIndex: "rank",
		key: "MeritPosition",
		render: (text) => <span className="table-center-text">{text}</span>,
	  },
  ];

  const columns2 = [
	{
		title: "Exam Date",
		dataIndex: "date",
		key: "Exam Date",
		render: (text) => (
		  <span className="table-center-text">
			{moment(text).format("MMM DD, YYYY")}
		  </span>
		),
	  },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "ExamName",
      render: (text, record, index) => <span>{text}</span>
    },
    
    // {
    //   title: "Pass/Fail",
    //   key: "PassFail",
    //   render: (text, record, index) => (
    //     <span
    //       className={
    //         (record?.marksObtained * 100) / record?.totalMarks >=
    //         record?.examId?.passMark
    //           ? "table-center-text is-success"
    //           : "table-center-text is-error"
    //       }
    //     >
    //       {(record?.marksObtained * 100) / record?.examId?.totalMarks >=
    //       record?.examId?.passMark
    //         ? "PASS"
    //         : "FAIL"}
    //     </span>
    //   ),
    // },
	{
		title: "Total Marks",
		dataIndex: "totalMark",
		key: "TotalMarks",
		render: (text, record) => (
		  <span className="table-center-text">
			{text}
		  </span>
		),
	  },
    {
      title: "Obtained Marks",
      dataIndex: "obtainMark",
      key: "ObtainedMarks",
      render: (text) => (
        <span className="table-center-text">{text?.toFixed(2)}</span>
      ),
    },
    
    {
      title: "Negative Marks",
      dataIndex: "negativeMark",
      key: "NegativeMarks",
      render: (text) => (
        <span className="table-center-text">{text?.toFixed(2)}</span>
      ),
    },
	{
		title: "Merit Position",
		dataIndex: "meritPosition",
		key: "MeritPosition",
		render: (text) => <span className="table-center-text">{text}</span>,
	  },
  ];


	useEffect(() => {
		async function fetchData() {
			dispatch(resetState({ key: 'scoreboardExamResult' }));
			await dispatch(getStudentScoreRequest({ studentId }));
		}
		fetchData();

		async function fetchAlternateExam() {
			const res = await dispatch(getAlternateExamById(studentProfile?.sid));
			
			if(res?.payload?.data?.status === '200'){
				console.log('res', res?.payload?.data);
				setAlternateExamCode(res?.payload?.data?.data?.code);
				setAlternateExamLink(res?.payload?.data?.data?.link);
			}
		}

		if(studentProfile){
			// setAlternateExamCode(studentProfile?.code);
			// setAlternateExamLink(studentProfile?.link);
			fetchAlternateExam();
			
		}
	}, [studentId]);

	const handleSubmit = async () => {
		
		if (!alternateExamCode) {
			toastr.error('Please Give Exam Code');
			return;
		}
		if (!alternateExamLink) {
			toastr.error('Please Give Exam Link');
			return;
		}
		const data = {
			username: studentProfile?.sid,
			code: alternateExamCode,
			link: alternateExamLink
		};
		const response = await dispatch(addAlternateExam(data));
		console.log('response', response);
		if(!response.payload)
			toastr.error(response?.error?.message);
		else{
			toastr.success('Alternate Exam Added Successfully');

		} 
			
		
	
	};

	const summary = [
		{
			title: 'Live Exams',
			value: liveExam?.totalExamCount || '0',
			icon: <LiveExamIcon />
		},
		{
			title: 'Exam Passed',
			value: liveExam?.passCount,
			icon: <ExamPassedIcon />
		},
		{
			title: 'Exam Failed',
			value: liveExam?.failCount || '0',
			icon: <ExamFailedIcon />
		},
		{
			title: 'Highest Marks',
			value: liveExam?.maxMarkObtained?.toFixed(2) || '0',
			icon: <HighestMarksIcon />
		},
		{
			title: 'Lowest Marks',
			value: liveExam?.minMarkObtained?.toFixed(2) || '0',
			icon: <LowestMarksIcon />
		},
		{
			title: 'Average Marks',
			value: liveExam?.averageMarkObtained
				? liveExam?.averageMarkObtained.toFixed(2)
				: 0,
			icon: <AverageMarksIcon />
		}
	];

	

	const handleLoadMore = async (limit) => {
		const isLoadMore = true;
		const res = await dispatch(
			getStudentScoreRequest({
				studentId,
				lastId: allExams[allExams.length - 1]?._id, limit: limit
			})
		);
		if (
			res &&
			res?.payload?.data?.data?.results?.examResult &&
			res?.payload?.data?.data?.results?.examResult?.length !== 10
		) {
			setShowLoadMore(false);
		}
	};

	return (
		<Layout>
			<Button
						size='large'
						type='primary'
						icon={<PrinterFilled />}
						style={{ margin: '10px', marginLeft: "auto", width: '100px'}}
						onClick={async () => {
							//await handleLoadMore(1000);
							setStartPrinting(true)
							setTimeout(() => {

							window.print()
							}, 1000)
							
							setTimeout(() => {
								setStartPrinting(false)
							}
							, 2000)
							
						}}
					>
						Print
					</Button>
			<Layout>
				<Content className='secondary-header custom-container'>
					<Row>
						<Col xs={24}>
							<div style={{ textAlign: 'center' }}>
								<Avatar
									className='page-avatar'
									style={{
										marginTop: -40,
										textAlign: 'center',
										backgroundColor: '#fff',
										border: 2
									}}
									size={80}
									src={
										<Image
											preview={false}
											src={
												(!!studentProfile?.profilePic &&
													bucket_url + studentProfile?.profilePic) ||
												defaultAvatar
											}
											fallback={defaultAvatar}
										/>
									}
								/>
							</div>
						</Col>
					</Row>
					
				</Content>
				
			</Layout>
			<Layout>
				<Content
					className='custom-container section-padding sec-mh'
					style={{ paddingTop: 15 }}
				>
					<Row style={{ marginBottom: 30 }} >
						<Col xs={24}>
							<Card style={{ width: '100%', borderRadius: 10 }}>
								<Row>
									<Col xs={24} md={24}>
										<div className='score-user'>
											<div className='user-info'>
												<Title level={3} style={{ marginBottom: 0 }}>
													{studentProfile?.name}
												</Title>
												<ul className='avatar-group-list'>
													{studentProfile?.groups &&
														studentProfile?.groups.map((item, index) => (
															<li key={index + ''}>{item?.name}</li>
														))}
												</ul>
											</div>
										</div>
									</Col>
									<Divider style={{ marginTop: 0, marginBottom: 15 }} />
									<Col xs={24} md={24}>
										<Title level={4} className='score-total-marks'>
											Total Marks Obtained :{' '}
											{`${
												liveExam?.totalMarksObtained
													? liveExam?.totalMarksObtained.toFixed(2)
													: '0'
											} / ${liveExam?.totalMarks || '0'}`}
										</Title>
									</Col>
									<Col xs={24} md={24}>
										<Row gutter={[10, 10]}>
											{summary.map(summaryData => (
												<Col xs={12} md={12} lg={8}>
													<ScoreSummaryCard summaryData={summaryData} />
												</Col>
											))}
										</Row>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
					<Row style={{
						marginTop: '20px',
						marginBottom: '20px',
						backgroundColor: '#fff',
						padding: '20px',
						borderRadius: '10px'
					}} >
					<Form
								className='notification-create-form'
								form={form}
								layout='vertical'
								
							>
								 
								<Form.Item label='Unique Identifier' required tooltip='Unique Identifier'>
									<Input
										value={alternateExamCode}
										onChange={e => setAlternateExamCode(e.target.value)}
										placeholder='Unique Identifier'
									/>
								</Form.Item>
								<Form.Item label='Alternate Exam Link' required tooltip='Alternate Exam Link'>
									<Input
										value={alternateExamLink}
										onChange={e => setAlternateExamLink(e.target.value)}
										placeholder='Alternate Exam Link'
									/>
								</Form.Item>
								<Form.Item>
									<Space>
										<Button
											type='primary'
											onClick={() => {
												handleSubmit();
												
											}}
											htmlType='submit'
										>
											Submit
										</Button>
										
									</Space>
								</Form.Item>
							
							</Form>
					</Row>
					<Row>
						
						<Tabs defaultActiveKey="1">
      {/* Live Exam Tab */}
      <TabPane tab="Live Exam" key="1">
	  <Col xs={24}>
							<Table
								pagination={false}
								columns={columns}
								dataSource={examResult}
								className='students-scoreboard-table'
							/>
							{showLoadMore && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignContent: 'center'
									}}
								>
									<Button
										onClick={() => {
											handleLoadMore(10);
										}}
										type='link'
									>
										Load More <ArrowDownOutlined />
									</Button>
								</div>
							)}
						</Col>
      </TabPane>

      {/* Offline Exam Tab */}
      <TabPane tab="Offline Exam" key="2">
        <Table
          pagination={false}
          columns={columns2}
          dataSource={offlineResults}
          className="students-scoreboard-table"
        />
        {examResult && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => handleLoadMore("offline", 10)} type="link">
              Load More <ArrowDownOutlined />
            </Button>
          </div>
        )}
      </TabPane>
    </Tabs>
					</Row>
					{
						 <Row className='print-teacher'>
						<PrintScoreboard liveExam={liveExam} studentProfile={studentProfile} examResult={examResult} />
					</Row>
					}
				</Content>
			</Layout>
		</Layout>
	);
};

export default StudentScoreboard;
