import React from 'react';
import { Switch, Table } from 'antd';
import { useDispatch } from 'react-redux';

import {
	accessStatusChangeByGroup,
	addAccessToGroupById
} from '../../stateManager/reducers/groupSlice';

const ChapterLectureTable = props => {
	const { data, groupId, isChapter, type } = props;
	const dispatch = useDispatch();
	const groupList = JSON.parse(localStorage.getItem('selectedGroup'));
	const [currentPage, setCurrentPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(10);
	const { lectures, chapters, questionSolves } = groupList;
	const lecturesId = lectures.map(item => item.lectureId);
	const chaptersId = chapters.map(item => item.chapterId);
	const questionSolvesId = questionSolves.map(item => item.questionSolveId);

	const handleChangeStatus = async (check, id) => {
		try {
			if (
				lecturesId.indexOf(id) >= 0 ||
				chaptersId.indexOf(id) >= 0 ||
				questionSolvesId.indexOf(id) >= 0
			) {
				const res = await dispatch(
					accessStatusChangeByGroup({
						groupId,
						lectureId: type === 'lecture' ? id : undefined,
						chapterId: type === 'chapter' ? id : undefined,
						questionSolveId: type === 'questionSolve' ? id : undefined,
						status: check
					})
				);
			} else {
				const res = await dispatch(
					addAccessToGroupById({
						groupId,
						lectureId: type === 'lecture' ? id : undefined,
						chapterId: type === 'chapter' ? id : undefined,
						questionSolveId: type === 'questionSolve' ? id : undefined
					})
				);
			}
		} catch (error) {
			console.log(error);
		}
	};
	const handleTableChange = (pagination) => {
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		console.log("Page:", pagination.current, "PageSize:", pagination.pageSize);
	  };

	const checkStatus = id => {
		if (type === 'chapter') {
			const chapter = chapters.find(item => item.chapterId === id);
			return chapter?.showStatus;
		} else if (type === 'lecture') {
			const lecture = lectures.find(item => item.lectureId === id);
			return lecture?.showStatus;
		} else {
			const questionSolve = questionSolves.find(
				item => item.questionSolveId === id
			);
			return questionSolve?.showStatus;
		}
	};

	const columns = [
		{
			title: <strong>Sl no.</strong>,
			key: 'sl',
			render: (record, item, index) => (index + 1) + pageSize * (currentPage - 1)
		},
		{
			title: <strong>Name</strong>,
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: <strong>Action</strong>,
			key: 'action',
			dataIndex: '_id',
			render: (text, record, index) => {
				return (
					<Switch
						onChange={check => {
							handleChangeStatus(check, text);
						}}
						checked={checkStatus(text)}
						checkedChildren='ON'
						unCheckedChildren='OFF'
					/>
				);
			}
		}
	];

	return <Table columns={columns} rowKey='_id' dataSource={data} onChange={handleTableChange}/>;
};

export default ChapterLectureTable;
