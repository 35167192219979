import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Table, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	generateMeritList,
	getFileRequest
} from '../../stateManager/reducers/groupSlice';

const columns = [
	{
		title: <strong>Exam Name</strong>,
		key: 'name',
		dataIndex: 'title'
	}
];

const CombineResultModal = props => {
	const { isVisible, examList, groupId } = props;
	const dispatch = useDispatch();
	const groupsExam = useSelector(state => state.groups.selectedGroup?.exams);
	const meritListFiles = useSelector(state => state.groups.meritListFile);
	const [publishedExam, setPublishedExam] = useState([]);
	const [selectedExams, setSelectedExams] = useState([]);

	useEffect(() => {
		if (groupsExam && groupsExam.length > 0) {
			let temp = [];
			for (let i = 0; i < examList.length; i++) {
				const element1 = examList[i];
				for (let j = 0; j < groupsExam.length; j++) {
					const element2 = groupsExam[j];
					if (
						element1._id === element2.examId &&
						element2.status === 'resultPublished'
					) {
						temp.push(element1);
					}
				}
			}
			setPublishedExam(temp);
		}
	}, [examList, groupsExam]);

	useEffect(() => {
		async function fetchFiles() {
			await dispatch(getFileRequest());
		}
		fetchFiles();
	}, []);

	const handleOk = async () => {
		try {
			let temp = '';
			selectedExams.map((item, index) => {
				index === 0 ? (temp += `exam=${item}`) : (temp += `&exam=${item}`);
			});
			const group = '&group=' + groupId;
			const res = await dispatch(generateMeritList({ data: temp, group }));
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Modal
			visible={isVisible}
			onCancel={() => props.toggleModal()}
			onOk={() => handleOk()}
			title='Combine Resluts'
			width={1000}
		>
			<Row>
				<Col span={16}>
					<Table
						rowSelection={{
							type: 'checkbox',
							onChange: (key, rows) => {
								setSelectedExams(key);
							}
						}}
						style={{ padding: 10 }}
						columns={columns}
						dataSource={publishedExam}
						rowKey='_id'
						size='small'
					/>
				</Col>
				<Col span={8}>
					<strong>Previous Generated File</strong>
					<List
						dataSource={meritListFiles}
						className='combine-list'
						renderItem={item => (
							<List.Item>
								<List.Item.Meta
									title={<a href={item?.URL}>{item.name}</a>}
									description={
										item?.exams?.length > 0 &&
										item?.exams.map(item => item.title).toString()
									}
								/>
							</List.Item>
						)}
					></List>
				</Col>
			</Row>
		</Modal>
	);
};

export default CombineResultModal;
