import "./App.css";
import "antd/dist/antd.css";
import "toastr/build/toastr.min.css";
// import 'katex/dist/katex.min.css';
import "./Additional.css";
import "antd-css-utilities/utility.min.css";

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createBrowserHistory } from "history";

import { getSocket } from "./constants/socketInstance";
import { addMeritListFile } from "./stateManager/reducers/groupSlice";
import AdminRoutes from "./components/Navigations/adminRoutes";
import config from "./config";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = config.title;
    const socket = getSocket();
    socket.on("CSVGenerated", (data) => {
      console.log("CSVGenerated: ", data);
      window.location.href = data.URL;
      dispatch(addMeritListFile(data));
    });
  }, [dispatch]);

  const history = createBrowserHistory();

  if (window.location.href.includes("#!/")) {
    const path = window.location.href.split("#!/")[1];
    history.replace(path);
  }

  return (
    <Router>
      <Route path="/" component={AdminRoutes} />
    </Router>
  );
}
export default App;
