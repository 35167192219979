import React from 'react';
import { Row, Col, Typography, Card, Image, Space } from 'antd';

import MathInput from '../../Common/MathJax';
import PdfPlaceholder from '../../../assets/images/pdf_placeholder.png';
import { bucket_url } from '../../../constants/constString';
import './AdminParagraph.component.style.css';
import { shouldUseKatex } from '../../../constants/constFunction';

const { Text } = Typography;

const ParagraphQuestion = props => {
	const { question, questionIndex, point } = props;

	return (
		<Card className='paragraph-que' bordered={false} style={{ width: '100%' }}>
			<Row gutter={[10, 15]}>
				<Col xs={24} sm={1} style={{ textAlign: 'left' }}>
					<Text strong className='question-serial-label'>
						Question{' '}
					</Text>
					<Text strong className='no-overflow-break'>
						{questionIndex + 1 + '.'}
					</Text>
				</Col>
				<Col
					xs={24}
					sm={20}
					style={{
						overflowX: 'auto'
					}}
				>
					<Text
						strong
						style={{
							fontSize: '18px',
							display: 'inline-block',
							width: '100%'
						}}
					>
						{question?.title ? (
							shouldUseKatex(question?.title) > -1 ? (
								<MathInput mathItem={question?.title} />
							) : (
								question?.title
							)
						) : (
							'Not given'
						)}
					</Text>
				</Col>
				<Col
					xs={24}
					sm={3}
					className='marks-col'
					style={{ textAlign: 'right' }}
				>
					<Text type='secondary' strong>
						Marks {point || 'Not given'}
					</Text>
				</Col>
			</Row>

			{(question?.image || question?.file) &&
				(question?.image?.length > 0 || question?.file?.length > 0) && (
					<Row style={{ marginTop: 20, marginBottom: 20 }}>
						<Col xs={23} md={23} offset={1}>
							<Space>
								{!!question &&
									question.image.map(item => (
										<Image
											placeholder
											src={bucket_url + item}
											width={'100%'}
											style={{ objectFit: 'contain' }}
										/>
									))}
								{!!question &&
									question.file.map(item => (
										<a
											href={bucket_url + item}
											target='_blank'
											rel='noreferrer'
										>
											<Image
												placeholder={<Image src={PdfPlaceholder} />}
												src={bucket_url + item}
												fallback={PdfPlaceholder}
												width={'100%'}
												style={{ objectFit: 'contain' }}
											/>
										</a>
									))}
							</Space>
						</Col>
					</Row>
				)}

			{/* Explanation Section Start */}
			{(question?.explanation || question?.explanationExt) && (
				<Row>
					<Col xs={24} md={24}>
						<Card className='explanation-card' bordered>
							<Text strong>Explanation</Text>

							<p style={{ marginBottom: 0 }}>
								{question?.explanation &&
									(shouldUseKatex(question?.explanation) > -1 ? (
										<MathInput mathItem={question?.explanation} />
									) : (
										question?.explanation
									))}
							</p>
							<Row>
								<Col xs={24} md={24}>
									<Space>
										{!!question &&
											question?.explanationExt &&
											question.explanationExt.map(item => (
												<a href={bucket_url + item} target='_blank'>
													<Image
														preview={false}
														placeholder
														src={bucket_url + item}
														width={'100%'}
														style={{ objectFit: 'contain' }}
														fallback={PdfPlaceholder}
													/>
												</a>
											))}
									</Space>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			)}
			{/* Explanation Section end */}
		</Card>
	);
};

export default ParagraphQuestion;
