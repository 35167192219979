import React, { useEffect, useState } from 'react';
import {
	PageHeader,
	Layout,
	Row,
	Col,
	Button,
	Card,
	Typography,
	Table,
	Modal
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { ExportOutlined } from '@ant-design/icons';
import { CSVDownloader } from 'react-papaparse';

import SendSmsModal from './SendSmsModal';
import Spinner from '../../../Common/Spinner';
import {
	getExamById,
	getExamResultById,
	resetData,
	getSubmissionProcessStatus
} from '../../../../stateManager/reducers/groupSlice';
import { isAdminOrModerator } from '../../../../constants/constFunction';
import './Evaluate.page.style.css';
import moment from 'moment';

const { Content } = Layout;
const { Title, Text } = Typography;

const Evaluate = props => {
	const { examId } = props.match.params;
	const [hasCQ, setHasCQ] = useState(false);
	const [hasCQAnswer, setHasCQAnswer] = useState(false);
	const [isPublishable, setPublishable] = useState(false);
	const [isSmsModalVisible, setSmsModalVisible] = useState(false);
	const [isAllDataFetched, setAllDataFetched] = useState(false);
	const [isProcessing, setProcessing] = useState(false);
	const [studentIds, setStudentIds] = useState([]);
	const dispatch = useDispatch();
	const history = useHistory();
	const status = useSelector(state => state.groups.status);
	const examData = useSelector(state => state.groups.examDataForResult);
	const examResult = useSelector(state => state.groups.examResult);
	const groupId =
		useSelector(state => state.groups.selectedGroup?._id) ||
		JSON.parse(localStorage.getItem('selectedGroup'))?._id;
		const groupsExam =
		useSelector(state => state.groups.selectedGroup?.exams) ||
		JSON.parse(localStorage.getItem('selectedGroup'))?.exams;
		
	const isPublished = !!groupsExam && groupsExam.find(exam => exam.examId === examId);

	const fetchExamResult = async (lastId = undefined) => {
		const res = await dispatch(getExamResultById({ examId, lastId, groupId }));
		if (
			res?.payload?.status === '200' &&
			res?.payload?.data &&
			res?.payload?.data?.length > 0
		) {
			const data = res?.payload?.data;
			await fetchExamResult(data[data.length - 1]?._id);
		} else {
			setAllDataFetched(true);
		}
	};

	useEffect(() => {
		async function fetchData() {
			const historyAction = history.action;
			const res = await dispatch(
				getSubmissionProcessStatus({ groupId, examId })
			);
			if (
				res?.payload?.data?.status === 'processed' ||
				res?.payload?.data?.status === null
			) {
				setProcessing(false);
				await dispatch(getExamById({ examId, groupId }));
				if (!examResult || examResult?.length < 1 || history.action !== 'POP') {
					dispatch(resetData({ key: 'examResult' }));
					await fetchExamResult();
				}
			} else {
				setProcessing(true);
			}
		}
		fetchData();
	}, [examId]);

	const onShowSizeChange = (current, pageSize) => {
		console.log(current, pageSize);
	}

	const chekcForCQAnswerForSingleStudent = ({ answers }) => {
		console.log('answers', answers);
		if (answers) {
			for (let i = 0; i < answers.length; i++) {
				const ans = answers[i];
				if (
					ans?.questionType === 'shortAns' ||
					ans?.questionType === 'paragraph'
				) {
					return true;
				}
			}
		}
		return false;
	};

	const isPublishableFunc = () => {
		if (examResult && examResult.length > 0) {
			let allPublished = true;
			let temp = [];
			for (let i = 0; i < examResult.length; i++) {
				const element = examResult[i];
				if (element?.answers) {
					const res = chekcForCQAnswerForSingleStudent({
						answers: element?.answers
					});
					console.log('res', res);
					if (res === true && !element?.publishedAt) {
						allPublished = false;
					}
				}

				temp.push(element.studentId?._id);
			}
			return allPublished;
		}
		return false;
	};

	useEffect(() => {
		if (examResult && examResult.length > 0) {
			let allPublished = true;
			let temp = [];
			for (let i = 0; i < examResult.length; i++) {
				const element = examResult[i];
				if (!element?.publishedAt) {
					allPublished = false;
				}
				temp.push(element.studentId?._id);
			}
			setStudentIds(temp);
			setPublishable(allPublished);
		}
	}, [examResult]);

	useEffect(() => {
		if (examResult && examResult.length > 0) {
			for (let i = 0; i < examResult.length; i++) {
				const element = examResult[i];
				if (element?.answers) {
					const res = chekcForCQAnswerForSingleStudent({
						answers: element?.answers
					});
					if (res === true) {
						setHasCQAnswer(true);
						break;
					}
				}
				if (hasCQAnswer === true) {
					break;
				}
			}
		}
	}, [examResult]);

	useEffect(() => {
		if (examData && examData?.questions?.length > 0) {
			for (let i = 0; i < examData.questions.length; i++) {
				const element = examData.questions[i].question;
				if (element.type === 'shortAns' || element.type === 'paragraph') {
					setHasCQ(true);
					break;
				}
			}
		}
	}, [examData]);

	const averageScore = () => {
		if (examResult.length > 0) {
			let total = 0;
			examResult.map(item => (total += item.marksObtained));
			const res = total / examResult.length;
			return res.toFixed(2);
		}
		return 0;
	};
	const highestScore = () => {
		if (examResult.length > 0) {
			let hScore = 0;
			for (let i = 0; i < examResult.length; i++) {
				const element = examResult[i];
				if (element.marksObtained > hScore) hScore = element.marksObtained;
			}
			return hScore.toFixed(2);
		}
		return 0;
	};

	const toggleModal = () => setSmsModalVisible(!isSmsModalVisible);

	// const calculateStats = () => {
	// 	if (
	// 		examResult &&
	// 		examResult.length > 0 &&
	// 		examData &&
	// 		examData?.questions &&
	// 		examData?.questions.length > 0
	// 	) {
	// 		const data = [...examResult];
	// 		for (let k = 0; k < examResult.length; k++) {
	// 			const individualResult = examResult[k];
	// 			let correctCount = 0;
	// 			let notAnsweredCount = 0;
	// 			let incorrectCount = 0;
	// 			let negativeCount = 0;
	// 			for (let i = 0; i < individualResult?.answers?.length; i++) {
	// 				const element = individualResult?.answers[i];
	// 				if (!element) continue
	// 				const ans = !!element?.answer ? [...element?.answer] : [];
	// 				negativeCount += element?.marks < 0 ? Math.abs(element?.marks) : 0;
	// 				for (let j = 0; j < examData?.questions.length; j++) {
	// 					const question = examData?.questions[j].question;
	// 					const actual = [...question?.answer] || [];
	// 					if (
	// 						element.questionId === question._id &&
	// 						(question?.type === 'MCQ' || question?.type === 'checkbox')
	// 					) {
	// 						if (
	// 							element.questionId === question._id &&
	// 							(!element?.answer || element?.answer?.length <= 0)
	// 						) {
	// 							notAnsweredCount += 1;
	// 							break;
	// 						} else if (
	// 							JSON.stringify(actual.sort()) === JSON.stringify(ans.sort())
	// 						) {
	// 							correctCount += 1;
	// 							break;
	// 						} else {
	// 							incorrectCount += 1;
	// 							break;
	// 						}
	// 					}
	// 				}
	// 			}
	// 			data[k] = {
	// 				...data[k],
	// 				correctAnswer: correctCount,
	// 				incorrectAnswer: incorrectCount,
	// 				negativeMark: negativeCount
	// 			};
	// 		}
	// 		return data;
	// 	}
	// 	return examResult;
	// };

	const calculateStats = () => {
		if (
			examResult &&
			examResult.length > 0 &&
			examData &&
			examData?.questions &&
			examData?.questions.length > 0
		) {
			const data = [...examResult];
			for (let k = 0; k < examResult.length; k++) {
				const individualResult = examResult[k];
				let correctCount = 0;
				let notAnsweredCount = 0;
				let incorrectCount = 0;
				let negativeCount = 0;
				for (let i = 0; i < individualResult?.answers?.length; i++) {
					const element = individualResult?.answers[i];
					if (!element) continue
					// const ans = !!element?.answer ? [...element?.answer] : [];
					negativeCount += element?.marks < 0 ? Math.abs(element?.marks) : 0;
					if (!element?.answer || element?.answer?.length <= 0) {
						notAnsweredCount += 1;
					} else if (element?.marks && element?.marks > 0) {
						correctCount += 1;
					} else {
						incorrectCount += 1;
					}
					// for (let j = 0; j < examData?.questions.length; j++) {
					// 	const question = examData?.questions[j].question;
					// 	const actual = [...question?.answer] || [];
					// 	if (
					// 		element.questionId === question._id &&
					// 		(question?.type === 'MCQ' || question?.type === 'checkbox')
					// 	) {
					// 		if (
					// 			element.questionId === question._id &&
					// 			(!element?.answer || element?.answer?.length <= 0)
					// 		) {
					// 			notAnsweredCount += 1;
					// 			break;
					// 		} else if (
					// 			JSON.stringify(actual.sort()) === JSON.stringify(ans.sort())
					// 		) {
					// 			correctCount += 1;
					// 			break;
					// 		} else {
					// 			incorrectCount += 1;
					// 			break;
					// 		}
					// 	}
					// }
				}
				data[k] = {
					...data[k],
					correctAnswer: correctCount,
					incorrectAnswer: incorrectCount,
					negativeMark: negativeCount
				};
			}
			return data;
		}
		return examResult;
	};

	const handleGenerateCSV = () => {
		if (examResult && examData) {
			const data = sortData();
			const jsonData = data.map(item => {
				return {
					rank: item?.rank,
					name: item?.studentId?.name,
					studentId: item?.studentId?.sid,
					username: item?.studentId?.username,
					parentPhone: item?.studentId?.contact,
					marksObtained: item?.marksObtained,
					negativeMark: item?.negativeMark,
					correctAnswer: item?.correctAnswer,
					incorrectAnswer: item?.incorrectAnswer,
					started: item?.startsAt,
					Ended: item?.submittedAt,
					completionTime: item?.completionTime
				};
			});
			return jsonData;
		}
		return [];
	};

	function getMinuteDifference(timestamp1, timestamp2) {
		// Find the difference in milliseconds
		var difference = Math.abs(timestamp2 - timestamp1);
		
		// Convert milliseconds to minutes
		var minutesDifference = Math.ceil(Number(difference / (1000 * 60)).toFixed(2));
		if(timestamp1 ===0 || timestamp2 === 0){
			minutesDifference = 0;
		}
		console.log('difference', difference, timestamp1, timestamp2, minutesDifference);
	
		return minutesDifference;
	}

	const timeTaken = (startsAt, submittedAt) => {
		if (startsAt && submittedAt) {
			const submitMoment = moment(submittedAt);
			const startMoment = moment(startsAt);

			// Getting the difference: hours (h), minutes (m) and seconds (s)
			let h = submitMoment.diff(startMoment, 'hours');
			let m = submitMoment.diff(startMoment, 'minutes') - 60 * h;
			let s = submitMoment.diff(startMoment, 'seconds') - 60 * 60 * h - 60 * m;

			// Formating in hh:mm:ss (appends a left zero when num < 10)
			let hh = ('0' + h).slice(-2);
			let mm = ('0' + m).slice(-2);
			let ss = ('0' + s).slice(-2);

			return `${hh}:${mm}:${ss}`;
		}
		return 0;
	};

	const sortData = () => {
		const newData = calculateStats();
		const rankGenerate = (results = []) => {
			results.sort((a, b) => b.marksObtained - a.marksObtained);
			const ret = [];
			let rank = 1;
			let prevMark = null;
			console.log('results', results);
			results.forEach((res, idx) => {
				if (prevMark === null || prevMark !== res.marksObtained) {
					rank = idx + 1;
					prevMark = res.marksObtained;
				}
				ret.push({
					...res,
					rank,
					cutMarks: isPublished?.cutMarks,
					marksObtained:  res?.marksObtained,
					//completionTime: getMinuteDifference(res?.startsAt, res?.submittedAt) === 0? 'Not Completed' : getMinuteDifference(res?.startsAt, res?.submittedAt) + ' min',
					completionTime: timeTaken(res?.startsAt, res?.submittedAt),
					startsAt: new Date(res?.startsAt).toLocaleString(),
					submittedAt: res?.submittedAt > 0? new Date(res?.submittedAt).toLocaleString(): '',
				});
					
			});
			
			return ret;
		};
		const data = rankGenerate(newData);
		console.log('data', data);
	
		return data;
	};

	// !record.publishedAt &&
	// 				chekcForCQAnswerForSingleStudent({ answers: record?.answers }) &&
	const columns = [
		{
			title: 'Merit Position',
			dataIndex: 'rank',
			align: 'left',
			render: (text, item, index) => text
		},
		{
			title: 'Student Name',
			dataIndex: 'studentId',
			key: 'student_info',
			align: 'left',
			render: (studentId, record, index) => (
				<div className='student_info'>
					<Text>{studentId?.name || record?.student?.name}</Text>
				</div>
			)
		},
		{
			title: 'Roll No.',
			dataIndex: 'studentId',
			key: 'roll',
			align: 'center',
			render: (studentId, record, index) =>
				studentId?.sid || record?.student?.sid
		},
		{
			title: 'Total Marks Obtained',
			dataIndex: 'marksObtained',
			key: 'marks',
			align: 'center',
			render: text => text?.toFixed(2)
			// sorter: (a, b) => b.marksObtained - a.marksObtained
		},
		{
			title: 'Exam Marks Obtained',
			dataIndex: 'withoutGPA',
			key: 'marks',
			align: 'center',
			render: text => text?.toFixed(2)
			// sorter: (a, b) => b.marksObtained - a.marksObtained
		},
		{
			title: 'Correct Ansewer',
			dataIndex: 'correctAnswer',
			key: 'correct_answer',
			align: 'center',
			render: (text, record, index) =>
			text

		},
		{
			title: 'Incorrect Ansewer',
			key: 'correct_answer',
			dataIndex: 'incorrectAnswer',
			align: 'center',
			render: (text, record, index) =>
				(!record.publishedAt && (
					<Button className='assessment-btn' type='link'>
						{/* <Link to={`/assessment/${record.studentId._id}`}>
							Need Assessment
						</Link> */}
					</Button>
				)) ||
				text
		},
		{
			title: 'Negative Mark',
			key: 'marks',
			align: 'center',
			dataIndex: 'negativeMark',
			render: (text, item, index) => {
				return text?.toFixed(2);
			}
		},
		{
			title: 'Pass/Failed',
			dataIndex: 'marksObtained',
			key: 'pass',
			align: 'center',
			render: marksObtained => (
				<Text
					strong
					style={{
						color:
							(marksObtained / examData.totalMarks) * 100 > examData.passMark
								? 'green'
								: 'red'
					}}
				>
					{(marksObtained / examData.totalMarks) * 100 > examData.passMark
						? 'PASSED'
						: 'FAILED'}
				</Text>
			)
		},
		{
			title: 'Start Time',
			dataIndex: 'startsAt',
			align: 'center',
			key: 'startsAt',
			
		},
		{
			title: 'End Time',
			dataIndex: 'submittedAt',
			align: 'center',
			key: 'submittedAt',
		},
		{
			title: 'Completion Time',
			dataIndex: 'completionTime',
			align: 'center',
			key: 'completionTime',

		},
		
		{
			title: 'Need Assessment',
			dataIndex: 'correctAnswer',
			key: 'correct_answer',
			align: 'center',
			render: (text, record, index) =>
			(chekcForCQAnswerForSingleStudent({ answers: record?.answers }) && (
					<Button className='assessment-btn' type='link'>
						<Link to={`/assessment/${record.studentId._id}`}>
							<span style={{
								color: record?.isAssessed?'red':'',
								
							
							}}>Assess</span>
						</Link>
					</Button>
				)) || "Not Available"

		},
	];

	if (status === 'loading') {
		return <Spinner />;
	}

	return (
		<div className='main-content-wrap'>
			<Modal
				footer={[
					<Button onClick={() => history.goBack()} type='primary'>Back</Button >,
				]}
				visible={isProcessing}>
				<p>Result is processing</p>
			</Modal>
			<SendSmsModal
				isVisible={isSmsModalVisible}
				toggleModal={toggleModal.bind(this)}
				studentIds={studentIds}
				examId={examId}
				groupId={groupId}
			/>
			<PageHeader
				ghost={true}
				onBack={() => history.goBack()}
				title={examData?.title || 'Not given'}
				// subTitle="This is a subtitle"
				extra={[
					// (isPublishableFunc() || !hasCQ || !hasCQAnswer) &&
					// isAllDataFetched &&
					isAdminOrModerator() && (
						<React.Fragment>
							<CSVDownloader
								data={handleGenerateCSV()}
								type='link'
								filename={'meritlist'}
								bom={true}
							>
								<Button
									key='3'
									type='primary'
									// className='result-publish-btn'
									icon={<ExportOutlined />}
								// onClick={() => history.push('/publish')}
								>
									Export as CSV
								</Button>
							</CSVDownloader>
							<Button
								key='5'
								type='primary'
								// className='result-publish-btn'
								onClick={() => {
									setSmsModalVisible(!isSmsModalVisible);
									// handlePublishResult();
								}}
							// onClick={() => history.push('/publish')}
							>
								Publish Result
							</Button>
						</React.Fragment>
					)
				]}
			/>
			<Row gutter={[20, 20]}>
				<Col xs={24} md={6} className='evaluation-widget-warpper'>
					<Card className='evaluation-widget'>
						<Content>
							<Text strong style={{ color: '#fff', fontSize: 18 }}>
								Number of Participent
							</Text>
							<Title
								style={{
									color: '#fff',
									fontSize: 42,
									marginTop: 10,
									marginBottom: 0
								}}
							>
								{examResult.length || 'Not given'}
							</Title>
						</Content>
					</Card>
				</Col>
				<Col xs={24} md={6} className='evaluation-widget-warpper'>
					<Card className='evaluation-widget'>
						<Content>
							<Text strong style={{ color: '#fff', fontSize: 18 }}>
								Average Score
							</Text>
							<Title
								style={{
									color: '#fff',
									fontSize: 42,
									marginTop: 10,
									marginBottom: 0
								}}
							>
								{averageScore()}
							</Title>
						</Content>
					</Card>
				</Col>
				<Col xs={24} md={6} className='evaluation-widget-warpper'>
					<Card className='evaluation-widget'>
						<Content>
							<Text strong style={{ color: '#fff', fontSize: 18 }}>
								Highest Score
							</Text>
							<Title
								style={{
									color: '#fff',
									fontSize: 42,
									marginTop: 10,
									marginBottom: 0
								}}
							>
								{highestScore()}
							</Title>
						</Content>
					</Card>
				</Col>
				<Col xs={24} md={6} className='evaluation-widget-warpper'>
					<Card className='evaluation-widget'>
						<Content>
							<Text strong style={{ color: '#fff', fontSize: 18 }}>
								Exam Duration
							</Text>
							<Title
								style={{
									color: '#fff',
									fontSize: 42,
									marginTop: 10,
									marginBottom: 0
								}}
							>
								{isPublished?.duration || ''} min
							</Title>
						</Content>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginTop: 30 }}>
				<Col xs={24}>
					<Table
						columns={columns}
						dataSource={sortData(examResult)}
						scroll={{ x: '100%' }}
						pagination={{ defaultPageSize:100, defaultCurrent: localStorage.getItem('evaluate') || 1, onChange: (pageNo, pageSize) => localStorage.setItem('evaluate', pageNo), showSizeChanger: true, onShowSizeChange: onShowSizeChange }}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default Evaluate;
