import React, { useCallback, useState } from 'react';
import { Row, Col, Input, Button, Space, DatePicker } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';

import {
	exportStudentRequest,
	setSearchName
} from '../../stateManager/reducers/studentManagementSlice';
import { setSearchName as setSearchFilters } from '../../stateManager/reducers/groupSlice';

const { RangePicker } = DatePicker;

const SearchStudents = props => {
	const { courseList, branchList, groupFilter } = props;
	const dispatch = useDispatch();
	const [isLoadingCsv, setLoadingCsv] = useState(false);
	const filterStartDate = useSelector(state =>
		groupFilter
			? state.groups.filterStartDate
			: state.studentsAdmin.filterStartDate
	);
	const filterEndDate = useSelector(state =>
		groupFilter ? state.groups.filterEndDate : state.studentsAdmin.filterEndDate
	);
	// eslint-disable-next-line
	const debouncedSearch = useCallback(
		debounce((name, value) => {
			if (props.groupFilter) {
				dispatch(setSearchFilters({ key: name, value }));
			} else {
				dispatch(setSearchName({ key: name, value }));
			}
		}, 1000),
		[]
	);

	const getCourseName = courses => {
		// eslint-disable-next-line
		let coursesName = courses.map(item => {
			for (let i = 0; i < courseList?.length; i++) {
				const element = courseList[i];
				if (element._id === item) {
					return element.name;
				}
			}
		});
		return coursesName;
	};

	const getBranchName = branchId => {
		let branchName;
		branchName = branchList
			.filter(item => item._id === branchId)
			.map(item => item.name);
		return branchName;
	};

	let jsonData = [];

	const generateCSV = async (lastId = undefined) => {
		const res = await dispatch(exportStudentRequest(lastId));
		if (
			res?.payload?.status === '200' &&
			res?.payload?.data &&
			res?.payload?.data?.length > 0
		) {
			const data = res?.payload?.data;
			jsonData = [...jsonData, ...data];
			await generateCSV(data[data.length - 1]._id);
		} else {
			// setAllDataFetched(true);
		}
		return jsonData;
	};

	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
			>
				<Col xs={24} sm={24} md={3} lg={3}>
					<strong style={{ fontSize: 16 }}>Search</strong>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<Input
						// value={searchName}
						style={{ width: '100%' }}
						placeholder='Search By Student Name'
						onChange={e => {
							debouncedSearch('searchName', e.target.value);
						}}
					/>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<Input
						// value={searchId}
						style={{ width: '100%' }}
						placeholder='Search By Student ID'
						onChange={e => debouncedSearch('searchId', e.target.value)}
					/>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<Input
						// value={searchPhone}
						style={{ width: '100%' }}
						placeholder='Search By Phone Number'
						onChange={e => debouncedSearch('searchPhone', e.target.value)}
					/>
				</Col>
				<Col xs={24} sm={24} md={6} lg={5}>
					<RangePicker
						value={
							filterStartDate === ''
								? undefined
								: [moment(filterStartDate), moment(filterEndDate)]
						}
						onChange={range => {
							if (range) {
								if (props.groupFilter) {
									dispatch(
										setSearchFilters({
											key: 'filterStartDate',
											value: range[0].toISOString()
										})
									);
									dispatch(
										setSearchFilters({
											key: 'filterEndDate',
											value: range[1].toISOString()
										})
									);
								} else {
									dispatch(
										setSearchName({
											key: 'filterStartDate',
											value: range[0].toISOString()
										})
									);
									dispatch(
										setSearchName({
											key: 'filterEndDate',
											value: range[1].toISOString()
										})
									);
								}
							}
						}}
					/>
				</Col>
				{!groupFilter && !props.isGroup && (
					<Col xs={24} sm={24} md={6} lg={4}>
						<Space>
							<Button
								loading={isLoadingCsv}
								icon={<ExportOutlined />}
								onClick={async () => {
									setLoadingCsv(true);
									dispatch(exportStudentRequest());
									setLoadingCsv(false);
								}}
								type='ghost'
							>
								Export as CSV
							</Button>
						</Space>
					</Col>
				)}
			</Row>
		</div>
	);
};

export default SearchStudents;
