import React from 'react';
import { Column } from '@ant-design/charts';

const ColumnChart = props => {
	const {
		data,
		xField,
		yField,
		seriesField,
		meta,
		columnWidthRatio,
		isGroup,
		style
	} = props;

	const config = {
		data: data,
		isGroup: !!isGroup ? true : false,
		xField: xField,
		yField: yField,
		seriesField: seriesField,
		columnWidthRatio: columnWidthRatio,
		meta: meta,
		label: {
			position: 'middle',
			layout: [
				{ type: 'interval-adjust-position' },
				{ type: 'interval-hide-overlap' },
				{ type: 'adjust-color' }
			]
		}
	};

	return (
		<div className='column-chart'>
			<Column {...config} style={style} />
		</div>
	);
};

export default ColumnChart;
