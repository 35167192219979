import React from 'react';
import { Layout, Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';

import SubHeader from '../Common/SubHeader';

const { Content } = Layout;
const { Meta } = Card;

const SubjectWiseChapterLecture = props => {
	const { subjectId } = props.match.params;
	const selectedCourse = JSON.parse(localStorage.getItem('selectedCourse'));

	return (
		<React.Fragment>
			<SubHeader
				headText='Chapters and Lectures'
				subTitle={selectedCourse?.name}
				backIcon={true}
			/>

			<Content>
				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					<Col xs={24} sm={24} md={6} lg={6}>
						<Link to={`/lecture/${subjectId}`}>
							<Card
								style={{ width: 300 }}
								cover={
									<img
										alt='example'
										src='https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
									/>
								}
							>
								<Meta
									title={<strong>Lecture</strong>}
									description={'Description'}
								/>
							</Card>
						</Link>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6}>
						<Link to={`/chapter/${subjectId}`}>
							<Card
								style={{ width: 300 }}
								cover={
									<img
										alt='example'
										src={
											'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
										}
									/>
								}
							>
								<Meta
									title={<strong>Chapter</strong>}
									description={'Description'}
								/>
							</Card>
						</Link>
					</Col>
					<Col xs={24} sm={24} md={6} lg={6}>
						<Link to={`/question-solve/${subjectId}`}>
							<Card
								style={{ width: 300 }}
								cover={
									<img
										alt='example'
										src={
											'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
										}
									/>
								}
							>
								<Meta
									title={<strong>Question Solve</strong>}
									description={'Description'}
								/>
							</Card>
						</Link>
					</Col>
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default SubjectWiseChapterLecture;
