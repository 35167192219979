import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";
import toastr from "toastr";

const initialState = {
  isLoggedIn: false,
  user: {},
  hash: undefined,
  phone: undefined,
  password: undefined,
  status: "idle",
  error: null,
};

export const logInAdmin = createAsyncThunk(
  "admin/login",
  async ({ username, password }) => {
    try {
      const response = await instance.post("/admin/login", {
        username: username,
        password: password,
      });
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const setPushToken = createAsyncThunk(
  "admin/setPushToken",
  async ({ data }) => {
    try {
      const response = await instance.post("/qa/set-push-token", data);
      return response;
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const getVerificationOtp = createAsyncThunk(
  "admin/getVerificationOtp",
  async ({ data }) => {
    try {
      const response = await instance.post(`/admin/otp`, data);
      return response?.data;
    } catch (error) {
      if (error?.response?.data?.errors?.message) {
        toastr.error(error?.response?.data?.errors?.message);
      } else if (error?.response?.data?.errors?.title) {
        toastr.warning(error?.response?.data?.errors?.title);
      }
      return Promise.reject(error);
    }
  }
);

export const verifyLoginRequest = createAsyncThunk(
  "admin/verifyLoginRequest",
  async ({ data }) => {
    try {
      const response = await instance.post(`/admin/verify-login`, data);
      if (response) {
        const data = response.data.data;
        localStorage.setItem("user", JSON.stringify(data.admin));
        localStorage.setItem(
          "sessionId",
          JSON.stringify(response.headers.sessionid)
        );
        window.location.replace("/");
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const resetAdminPasswordRequest = createAsyncThunk(
  "admin/resetPassword",
  async ({ data }) => {
    try {
      const response = await instance.patch("/admin/reset-passowrd", data);
      if (response?.data?.data?.title) {
        toastr.success(response?.data?.data?.title);
      }
    } catch (error) {
      if (error?.response?.data?.errors?.title) {
        toastr.error(error?.response?.data?.errors?.title);
      }
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "adminAuth",
  initialState,
  reducers: {
    getUser: (state) => state.list,
    setLogin: (state, action) => (state.isLoggedIn = action.payload),
    setData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
  extraReducers: {
    [resetAdminPasswordRequest.pending]: (state, action) => {
      state.status = "pending";
    },
    [resetAdminPasswordRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [resetAdminPasswordRequest.rejected]: (state, action) => {
      state.status = "rejected";
      state.error = action.error.message;
    },
    [getVerificationOtp.fulfilled]: (state, action) => {
      state.phone = action?.payload?.data?.handle;
      state.hash = action?.payload?.data?.hash;
    },
  },
});

export const { getUser, setLogin, setData } = slice.actions;

export default slice.reducer;
