import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";
import { LOCAL_KEY } from "../../constants/constString";
import { getLocalStorageData } from "../../constants/constFunction";
import toastr from "toastr";
const { isSegmentedExam } = getLocalStorageData(
  LOCAL_KEY.EXAM_KEY,
  "isSegmentedExam"
);
const initialState = {
  questionsForExam: [],
  unsavedQuestionsForExam: [
    {
      title: "",
      type: "MCQ",
      options: [],
      answer: [],
    },
  ],
  segmentedExamDetails: undefined,
  segmentedSubjectIds: [],
  isSegmentedExam: isSegmentedExam || false,
  examListByCourse: [],
  examDetails: {},
  status: "idle",
  error: null,
  publishedGrups: [],
  examData: null,
};

export const createExamRequest = createAsyncThunk(
  "exam/createExam",
  async (data) => {
    try {
      const response = await instance.post("/exam", data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getExamByCourse = createAsyncThunk(
  "exam/getExamByCourse",
  async ({ courseId, startDate, endDate, isLoadMore, lastId }) => {
    try {
      const response = await instance.get(
        `/exam/course-id/${courseId}?startDate=${
          startDate ? startDate : ""
        }&endDate=${endDate ? endDate : ""}&lastId=${lastId ? lastId : ""}`
      );
      return { data: response.data, isLoadMore };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const deleteExamRequest = createAsyncThunk(
  "exam/deleteExamRequest",
  async ({ examId, force, toggleDeleteModal, examData }, { dispatch }) => {
    try {
      let response;
      if (force) {
        response = await instance.delete(`/exam/delete/${examId}?confirm=true`);
      } else {
        response = await instance.delete(`/exam/delete/${examId}`);
      }

      if (response.status === 200) {
        toastr.success("Exam deleted successfully!");
        dispatch(resetState({ key: "publishedGrups" }));
      }
      return response.data;
    } catch (error) {
      dispatch(setExamData(examData));
      dispatch(setGroupsOfPublisedExam(error.response.data.errors.groups));
      if (force === false && toggleDeleteModal) toggleDeleteModal();
      return Promise.reject(error);
    }
  }
);

export const changeExamType = createAsyncThunk(
  "exam/changeExamType",
  async ({ id, type }) => {
    try {
      const response = await instance.patch(`/exam/change-exam-type/${id}`);
      toastr.success("Practice exam created successfully!");
      return response.data;
    } catch (error) {
      toastr.error("Failed to create practice exam!");
      return Promise.reject(error);
    }
  }
);

export const insertOfflineResult = createAsyncThunk(
  "exam/insertOfflineResult",
  async (data) => {
    try {
      console.log("data", data);
      const response = await instance.post("/exam/insert-offline-result", data);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getExamById = createAsyncThunk(
  "exam/getExamById",
  async ({ examId, courseId }) => {
    try {
      const response = await instance.get(
        `/exam/id/${examId}/groupId/lksj348jh3${
          courseId ? "?courseId=" + courseId : ""
        }`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getExamByIdForCopy = createAsyncThunk(
  "exam/getExamByIdForCopy",
  async ({ examId }) => {
    try {
      const response = await instance.get(
        `/exam/id/${examId}/groupId/kdfkui23`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const removeQuestionFromExamRequest = createAsyncThunk(
  "exam/removeQuestionRequest",
  async ({ examId, questionId }) => {
    try {
      const response = await instance.patch(
        `/exam/remove-question/${examId}/${questionId}`
      );
      if (response.status === 200) {
        toastr.success("Question deleted successfully!");
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const updateQuestionStatusRequest = createAsyncThunk(
  "exam/updateQuestionStatusRequest",
  async ({ data, questionId }) => {
    try {
      const response = await instance.patch(`/question/update/${questionId}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
);

export const updateQuestionRequest = createAsyncThunk(
  "exam/updateQuestionRequest",
  async ({ data, questionId }) => {
    try {
      const response = await instance.patch(`/question/update/${questionId}`, {
        ...data,
      });
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
);

export const addQuestionToExamRequest = createAsyncThunk(
  "exam/addQuestionToExamRequest",
  async ({ examId, data, questionData }) => {
    try {
      const response = await instance.patch(
        `/exam/add-question/${examId}`,
        data
      );
      return { data: response.data, question: [...questionData] };
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const updateExamStatusRequest = createAsyncThunk(
  "exam/updateExamStatusRequest",
  async ({ examId, data }) => {
    try {
      const response = await instance.patch(`/exam/update/${examId}`, data);
      if (response.status === 200) {
        toastr.success("Exam approved successfully!");
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const examExportRequest = createAsyncThunk(
  "exam/examExportRequest",
  async ({ examId }) => {
    try {
      const response = await instance.get(`/exam/export/${examId}`);
      window.open(response.data?.data?.S3?.Location);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    addQuestionForExam: (state, action) => {
      console.log("action.payload", action.payload);
      const { selected, question } = action.payload;

      console.log("selected", selected, "question", question);
      // newArr = action.payload.filter(item => {
      // 	if (state.questionsForExam.length === 0) {
      // 		return item;
      // 	} else {
      // 		let unique = true;
      // 		for (let i = 0; i < state.questionsForExam.length; i++) {
      // 			const element = state.questionsForExam[i];
      // 			if (element._id === item._id) {
      // 				unique = false;
      // 			}
      // 		}
      // 		if (unique) return item;
      // 	}
      // });

      // already exist in state.questionsForExam remove from newArr and add to state.questionsForExam

      // console.log('state.questionsForExam', state.questionsForExam.length);
      // const index = state.questionsForExam.findIndex(item => item._id === action.payload._id);
      // console.log('index', index);
      // if (index === -1) {
      // 	state.questionsForExam = state.questionsForExam.concat(action.payload);
      // }
      // else{
      // 	state.questionsForExam = state.questionsForExam.splice(index, 1);
      // }

      if (selected) {
        state.questionsForExam = state.questionsForExam.concat(question);
      } else {
        state.questionsForExam = state.questionsForExam.filter(
          (item) => item._id !== question._id
        );
      }

      // state.questionsForExam.find(item => {
      // 	if (item._id !== action.payload._id) {
      // 		newArr.push(item);
      // 	}
      // 	else{
      // 		newArr.splice(newArr.length - 1, 1);
      // 	}
      // });

      //state.questionsForExam = state.questionsForExam.concat(action.payload);
    },
    addQuestionForExamAll: (state, action) => {
      const { selected, questions } = action.payload;
      if (selected) {
        state.questionsForExam = state.questionsForExam.concat(questions);
      } else {
        state.questionsForExam = state.questionsForExam.filter(
          (item) => !questions.find((el) => el._id === item._id)
        );
      }
    },
    resetQuestionsForExam: (state, action) => {
      console.log("resetQuestionsForExam");
      state.questionsForExam = [];
    },
    setIsSegmentedExam: (state, action) => {
      state.isSegmentedExam = action.payload;
    },
    addSegmentedExamSubjectIds: (state, action) => {
      state.segmentedSubjectIds = action.payload;
    },
    deleteQuestionForExam: (state, action) => {
      const { index } = action.payload;
      let temp = [...state.questionsForExam];
      temp.splice(index, 1);
      state.questionsForExam = temp;
    },
    addPointToQuestion: (state, action) => {
      const { index, point } = action.payload;
      state.questionsForExam[index].point = point;
    },
    clearUnsavedQuestions: (state, action) => {
      state.unsavedQuestionsForExam = [...initialState.unsavedQuestionsForExam];
    },
    clearQuestionForExam: (state, action) => {
      state.questionsForExam = [...initialState.questionsForExam];
    },
    setGroupsOfPublisedExam: (state, action) => {
      state.publishedGrups = action.payload;
    },
    setExamData: (state, action) => {
      state.examData = action.payload;
    },
    resetState: (state, action) => {
      const { key } = action.payload;
      state[key] = initialState[key];
    },
    removeGroupFromExamDeleteModal: (state, action) => {
      const groupId = action.payload;
      state.publishedGrups = state.publishedGrups.filter(
        (item) => item._id !== groupId
      );
    },
  },
  extraReducers: {
    [getExamByCourse.pending]: (state, action) => {
      state.status = "loading";
    },
    [getExamByCourse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { data, isLoadMore } = action.payload;
      state.examListByCourse = !!isLoadMore
        ? state.examListByCourse.concat(data?.data)
        : data?.data;
    },
    [getExamByCourse.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getExamById.pending]: (state, action) => {
      state.status = "loading";
    },
    [getExamById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examDetails = action.payload.data;
    },
    [getExamById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getExamByIdForCopy.pending]: (state, action) => {
      state.status = "loading";
    },
    [getExamByIdForCopy.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { questions } = action.payload?.data || {};
      const questionList =
        questions && questions.length > 0
          ? questions.map((item, index) => item.question)
          : [];

      console.log("questionList", questionList);
      state.questionsForExam = questionList;
      if (action.payload?.data?.isSegmentedExam) {
        state.isSegmentedExam = true;
        state.segmentedExamDetails = action.payload?.data?.segmentedExamDetails;
      }
    },
    [getExamByIdForCopy.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [createExamRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [createExamRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examListByCourse = state.examListByCourse.concat(
        action.payload.data
      );
    },
    [createExamRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateQuestionRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateQuestionRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const newData = { ...action.payload?.data };
      const examDetailsTemp = { ...state.examDetails };
      examDetailsTemp.questions = examDetailsTemp?.questions.map((item) =>
        item.question._id === newData?._id
          ? { question: newData, point: item?.point, _id: item._id }
          : item
      );
      state.examDetails = { ...examDetailsTemp };
    },
    [updateQuestionRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateQuestionStatusRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateQuestionStatusRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const newData = { ...action.payload?.data };
      const examDetailsTemp = { ...state.examDetails };
      examDetailsTemp.questions = examDetailsTemp?.questions.map((item) =>
        item.question._id === newData?._id
          ? { question: newData, point: item?.point, _id: item._id }
          : item
      );
      state.examDetails = { ...examDetailsTemp };
    },
    [updateQuestionStatusRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [removeQuestionFromExamRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [removeQuestionFromExamRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { questions } = action.payload?.data || [];
      const tempQuestions = [...state.examDetails?.questions];
      const newQuestions = [];
      for (let i = 0; i < tempQuestions.length; i++) {
        const element1 = tempQuestions[i];
        for (let j = 0; j < questions.length; j++) {
          const element2 = questions[j];
          if (element1?.question?._id === element2.question) {
            newQuestions.push(element1);
            break;
          }
        }
      }
      state.examDetails.questions = [...newQuestions];
    },
    [removeQuestionFromExamRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addQuestionToExamRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [addQuestionToExamRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { question, data } = action.payload;
      const tempQuestions = [...state.examDetails?.questions];
      let point = 1;
      if (data?.data?.questions) {
        data?.data?.questions.forEach((element) => {
          if (element?.question === question[0]?._id) {
            point = element?.point;
          }
        });
      }
      tempQuestions.push({ question: question[0], point: point });
      state.examDetails.questions = [...tempQuestions];
    },
    [addQuestionToExamRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteExamRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteExamRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const exam = action?.payload?.data;
      const tempData = state.examListByCourse;
      state.examListByCourse = tempData.filter(
        (item) => item._id !== exam?._id
      );
    },
    [deleteExamRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [changeExamType.pending]: (state, action) => {
      state.status = "loading";
    },
    [changeExamType.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const exam = action?.payload?.data;
      const tempData = state.examListByCourse;
      state.examListByCourse = tempData.map((item) =>
        item._id === exam?._id ? exam : item
      );
    },
  },
});

export const {
  addQuestionForExam,
  addSegmentedExamSubjectIds,
  resetQuestionsForExam,
  addQuestionForExamAll,
  deleteQuestionForExam,
  setIsSegmentedExam,
  addPointToQuestion,
  clearUnsavedQuestions,
  clearQuestionForExam,
  setGroupsOfPublisedExam,
  setExamData,
  resetState,
  aremoveGroupFromExamDeleteModala,
  removeGroupFromExamDeleteModal,
} = slice.actions;

export default slice.reducer;
