import React from 'react';
import { Form, Modal, Upload, Input, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { addGroup } from '../../stateManager/reducers/groupSlice';
import {
	signedUrl as signedUrlRequest,
	patchFileRequest
} from '../../stateManager/reducers/questionSlice';
import { sessions } from '../../constants/constString';

const { Dragger } = Upload;
const { Option } = Select;

const AddGroupModal = props => {
	// eslint-disable-next-line
	const { isVisible, isSubject } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const courseList = useSelector(state => state.courses.courseList);

	const handleSubmit = async values => {
		const { name, courseId, session, image } = values;
		try {
			if (image) {
				const signResp = await dispatch(signedUrlRequest(image.file.type));
				var { key, signedUrl } = signResp.payload?.data;
				// eslint-disable-next-line
				const patchResp = await dispatch(
					patchFileRequest({ signedUrl, file: image.file.originFileObj })
				);
			}
			const res = await dispatch(
				addGroup({ name, courseId, session, image: key })
			);
		} catch (error) {
			console.log(error);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			onOk={() => form.submit()}
			title={<strong>{'Add Group'} </strong>}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='name'
					rules={[{ required: true, message: 'Please enter group name' }]}
					label={<strong>{'Group Name'}</strong>}
				>
					<Input placeholder='Enter Group name' />
				</Form.Item>

				<Form.Item
					name='session'
					label={<strong>Session</strong>}
					rules={[{ required: true, message: 'Please select a session' }]}
				>
					<Select>
						{sessions.map((item, index) => (
							<Option key={index} value={item}>
								{item}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name='courseId'
					label={<strong>Course</strong>}
					rules={[{ required: true, message: 'Please select a course' }]}
				>
					<Select>
						{courseList.map((item, index) => (
							<Option key={index} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item name='image' label={<strong>Upload Image</strong>}>
					<Dragger>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p>Drag and drop images here or click to browse</p>
					</Dragger>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddGroupModal;
