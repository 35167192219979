import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import VideoLectures from '../../VideoLectures/VideoLectures';
import CourseWiseVideoList from '../../VideoLectures/CourseWiseVideoList';

const ContentUploaderNavigation = props => {
	return (
		<React.Fragment>
			<Redirect exact from='/' to='/video-lectures' />
			<Route
				path='/video-lectures/:courseId'
				exact
				component={CourseWiseVideoList}
			/>
			<Route exact path='/video-lectures' component={VideoLectures} />
		</React.Fragment>
	);
};

export default ContentUploaderNavigation;
