import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import ExamHome from '../../Exam/index';
import CourseWiseExamList from '../../Exam/CourseWiseExamList';
import ExamDetails from '../../pages/admin/ExamDetails/ExamDetails.page';

const InstructorNavigation = props => {
	return (
		<React.Fragment>
			<Redirect exact from='/' to='/exam' />
			<Route path='/exam-details/:examId/:courseId' component={ExamDetails} />
			<Route exact path='/exam' component={ExamHome} />
			<Route exact path='/exam/:courseId' component={CourseWiseExamList} />
		</React.Fragment>
	);
};

export default InstructorNavigation;
