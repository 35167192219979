import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Card, Table, Select, Menu, Affix } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import './Dashboard.page.style.css';
import PieChart from './Component/Pie.component';
import Counter from './Component/Counter.component';
import BarChart from './Component/BarChart.component';
import ColumnChart from './Component/ColumnChart.component';
import Spinner from '../../../Common/Spinner';

import {
	getCourses,
	getDashboardData,
	getGroupByCourseAndSession,
	getBranchAndAdmin,
	questionStatistics,
	getAnswerStatistics,
	
} from '../../../../stateManager/reducers/dashboardSlice';
import { rolesForDashboard } from '../../../../constants/constString';

const { Title } = Typography;
const { Option } = Select;

const Dashboard = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const [session, setSession] = useState('2023');
	const questionStatisticsData = useSelector(
		state => state.dashboard.questionStatistics
	);
	const answerStatisticsData = useSelector(
		state => state.dashboard.answerStatistics
	);
	const courses = useSelector(state => state.dashboard.courses);
	const courseData = useSelector(state =>
		state.dashboard.courseData?.filter(item => item?.session == session)
	);
	const dashboardData = useSelector(state => state.dashboard.dashboardData);
	const courseWiseGroupData = useSelector(
		state => state.dashboard.courseWiseGroupData
	);
	const branchData = useSelector(state => state.dashboard.branchData);
	const adminData = useSelector(state => state.dashboard.adminData);

	const [
		selectedCourseForStudentCount,
		setSelectedCourseForStudentCount
	] = useState();
	const [
		selectedCourseForGroupExam,
		setSelectedCourseForGroupExam
	] = useState();

	useEffect(() => {
		async function fetchData() {
			setLoading(true);
			await dispatch(getCourses());
			await dispatch(getDashboardData({ session }));
			await dispatch(getBranchAndAdmin());
			await dispatch(questionStatistics());
			await dispatch(getAnswerStatistics());
			setLoading(false);
		}
		fetchData();
	}, []);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getDashboardData({ session }));
			setSelectedCourseForStudentCount(courseData[0]?._id);
			setSelectedCourseForGroupExam(courseData[0]?._id);
		}
		fetchData();
	}, [session]);

	useEffect(() => {
		if (selectedCourseForGroupExam) {
			async function fetchData() {
				await dispatch(
					getGroupByCourseAndSession({
						courseId: selectedCourseForGroupExam,
						session
					})
				);
			}
			fetchData();
		}
	}, [selectedCourseForGroupExam, session]);

	// useEffect(() => {
	// 	if (courseData && courseData.length > 0) {
	// 		console.log('came here');
	// setSelectedCourseForStudentCount(courseData[0]?._id);
	// setSelectedCourseForGroupExam(courseData[0]?._id);
	// 	}
	// }, [courseData]);
	// console.log(courseData, selectedCourseForStudentCount);

	const handleSessionChange = e => {
		setSession(e.key);
	};

	const studentRegularityData = [
		{
			type: '1st Timer',
			value: !!dashboardData && dashboardData?.students?.firstTime
		},
		{
			type: '2nd Timer',
			value: !!dashboardData && dashboardData?.students?.secondTime
		}
	];

	const courseWiseGroupCount =
		dashboardData?.courseData &&
		courses &&
		dashboardData?.courseData.map((item) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					return { type: courses[key], value: element?.group };
				}
			}
		});

	let totalVideos = 0;
	const videosData =
		dashboardData?.chapterLectureQustionSolveInCourse &&
		courses &&
		dashboardData?.chapterLectureQustionSolveInCourse.map((item) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					totalVideos +=
						element?.lecture?.videos +
						element?.chapter?.videos +
						element?.questionSolves?.videos;
					return {
						type: courses[key],
						value:
							element?.lecture?.videos +
							element?.chapter?.videos +
							element?.questionSolves?.videos
					};
				}
			}
		});

	let totalNotes = 0;
	const notesData =
		dashboardData?.chapterLectureQustionSolveInCourse &&
		courses &&
		dashboardData?.chapterLectureQustionSolveInCourse.map((item) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					totalNotes +=
						element?.lecture?.notes +
						element?.chapter?.notes +
						element?.questionSolves?.notes;
					return {
						type: courses[key],
						value:
							element?.lecture?.notes +
							element?.chapter?.notes +
							element?.questionSolves?.notes
					};
				}
			}
		});

	let totalLiveExams = 0;
	const liveExams = dashboardData?.exams?.courseWise?.live.map(item => {
		totalLiveExams += item?.count;
		return { type: courses[item?._id], value: item?.count };
	});

	let totalPracticeExams = 0;
	const practiceExams = dashboardData?.exams?.courseWise?.practice.map(item => {
		totalPracticeExams += item?.count;
		return { type: courses[item?._id], value: item?.count };
	});

	const studentCourseWiseData =
		dashboardData?.courseData &&
		courses &&
		dashboardData?.courseData.map((item) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					return { course: courses[key], students: element?.student };
				}
			}
		});

	const coursWiseSubjectColumns = [
		{
			title: 'Course Name',
			dataIndex: 'name',
			key: 'name',
			width: 100,
			fixed: 'left'
		},
		{
			title: 'Total Subject',
			dataIndex: 'subject',
			key: 'subject',
			width: 80,
			align: 'center'
		},
		{
			title: 'Total Chapter',
			dataIndex: 'chapter',
			key: 'chapter',
			width: 80,
			align: 'center'
		},
		{
			title: 'Total Lecture',
			dataIndex: 'lecture',
			key: 'lecture',
			width: 80,
			align: 'center'
		},
		{
			title: 'Total Question Solve',
			dataIndex: 'questionSolve',
			key: 'questionSolve',
			width: 80,
			align: 'center'
		}
	];

	const getSubjectCount = courseId => {
		if (dashboardData?.courseData) {
			let count = 0;
			dashboardData?.courseData.forEach(item => {
				for (const key in item) {
					if (Object.hasOwnProperty.call(item, key)) {
						const element = item[key];
						if (key === courseId) {
							count = element?.subject;
							break;
						}
					}
				}
			});
			return count;
		}
	};

	const coursWiseSubjectData =
		dashboardData?.chapterLectureQustionSolveInCourse &&
		courses &&
		dashboardData?.chapterLectureQustionSolveInCourse.map((item, index) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					return {
						key: index + '',
						name: courses[key],
						subject: getSubjectCount(key),
						lecture: element?.lecture?.lectureCount,
						chapter: element.chapter?.chapterCount,
						questionSolve: element?.questionSolves?.questionSolvesCount
					};
				}
			}
		});

	const getGroupData = () => {
		const temp = [];
		dashboardData?.groupWiseStudent.map(item => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					if (key === selectedCourseForStudentCount) {
						for (let i = 0; i < element.length; i++) {
							const group = element[i];
							temp.push({ group: group?.name, students: group?.total });
						}
					}
				}
			}
		});
		return temp.length > 0 ? temp : [];
	};

	const groupStudentsCountData =
		dashboardData?.groupWiseStudent &&
		selectedCourseForStudentCount &&
		getGroupData();

	const getGroupExamData = () => {
		const temp = [];
		courseWiseGroupData?.groupWiseExam.map((item) => {
			for (const key in item) {
				if (Object.hasOwnProperty.call(item, key)) {
					const element = item[key];
					temp.push(
						{
							type: 'Scheduled',
							examCount: element?.scheduled,
							group: courseWiseGroupData?.groups[key]
						},
						{
							type: 'Result Published',
							examCount: element?.resultPublished,
							group: courseWiseGroupData?.groups[key]
						},
						{
							type: 'Waiting for Assessment',
							examCount: element?.published,
							group: courseWiseGroupData?.groups[key]
						},
						{
							type: 'Unpublished',
							examCount: element?.unpublished,
							group: courseWiseGroupData?.groups[key]
						}
					);
				}
			}
		});
		return temp.length > 0 ? temp : [];
	};

	const groupExamsCountData =
		courseWiseGroupData &&
		courseWiseGroupData?.groupWiseExam &&
		selectedCourseForGroupExam &&
		getGroupExamData();

	const coursWiseQuestionColumns = [
		{
			title: 'Course Name',
			dataIndex: 'courseName',
			key: 'courseName',
			width: 100,
			fixed: 'left'
		},
		{
			title: 'Total Questions',
			dataIndex: 'totalQuestions',
			key: 'totalQuestions',
			width: 80,
			align: 'center'
		},
		{
			title: 'MCQ',
			dataIndex: 'mcq',
			key: 'mcq',
			width: 80,
			align: 'center'
		},
		{
			title: 'Checkbox',
			dataIndex: 'checkbox',
			key: 'checkbox',
			width: 80,
			align: 'center'
		},
		{
			title: 'Short Question',
			dataIndex: 'shortQuestion',
			key: 'shortQuestion',
			width: 80,
			align: 'center'
		},
		{
			title: 'Paragraph',
			dataIndex: 'paragraph',
			key: 'paragraph',
			width: 80,
			align: 'center'
		}
	];

	let totalQuestions = 0;
	const coursWiseQuestionData =
		(dashboardData?.questions &&
			dashboardData?.questions.map((item, index) => {
				for (const key in item) {
					if (Object.hasOwnProperty.call(item, key)) {
						const element = item[key];
						totalQuestions += element?.total;
						return {
							key: index + '',
							courseName: courses[key],
							totalQuestions: element?.total,
							mcq: element?.type?.MCQ,
							checkbox: element?.type?.checkbox,
							shortQuestion: element?.type?.short,
							paragraph: element?.type?.paragraph
						};
					}
				}
			})) ||
		[];

	const branches = {
		columns: [
			{
				title: 'Division',
				dataIndex: '_id',
				key: 'division',
				width: 100,
				fixed: 'left'
			},
			{
				title: 'Total Branches',
				dataIndex: 'count',
				key: 'totalBranches',
				width: 80,
				align: 'center'
			}
		]
	};

	const adminUsersData = () => {
		let data = [];
		if (adminData?.rolesWise) {
			for (const key in adminData?.rolesWise) {
				if (Object.hasOwnProperty.call(adminData?.rolesWise, key)) {
					const element = adminData?.rolesWise[key];
					data.push({ role: rolesForDashboard[key], userCount: element });
				}
			}
		}
		return data;
	};

	const AdminPanelUsers = {
		columns: [
			{
				title: 'User Role',
				dataIndex: 'role',
				key: 'role',
				width: 100,
				fixed: 'left'
			},
			{
				title: 'Total User',
				dataIndex: 'userCount',
				key: 'userCount',
				width: 80,
				align: 'center'
			}
		]
	};

	if (loading) {
		return <Spinner />;
	}

	return (
		<React.Fragment>
			<Row>
				<Col xs={24}>
					<Affix offsetTop={0} style={{ textAlign: 'center' }}>
						<Menu
							onClick={handleSessionChange}
							className='session-menu'
							selectedKeys={[session]}
							mode='horizontal'
						>
							<Menu.Item key='2023'>2023</Menu.Item>
							<Menu.Item key='2024'>2024</Menu.Item>
							<Menu.Item key='2025'>2025</Menu.Item>
						</Menu>
					</Affix>
				</Col>
			</Row>
			<Row style={{ marginBottom: 20 }}>
				<Col xs={24}>
					<Card style={{ borderRadius: 10, marginTop: 5 }}>
						<Row gutter={[10, 10]}>
							<Col xs={24} sm={6} lg={6} xl={6} xxl={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Total'
										subTitle='Students'
										value={dashboardData?.students?.total}
										isTotal={false}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={6} xl={6} xxl={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Active'
										subTitle='Students'
										value={dashboardData?.students?.active}
										isTotal={false}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={6} xl={6} xxl={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Deactive'
										subTitle='Students'
										value={dashboardData?.students?.deactive}
										isTotal={false}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={6} lg={6} xl={6} xxl={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Pending'
										subTitle='Students'
										value={dashboardData?.students?.pending}
										isTotal={false}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={24} lg={24} xl={24} xxl={8}>
								<Card className='chart-wrapper'>
									<PieChart
										data={studentRegularityData}
										radius={1}
										style={{ height: 180 }}
									/>
								</Card>
							</Col>
						</Row>

						<Row gutter={[10, 10]}>
							<Col xs={24} sm={24} lg={24} xl={8} xxl={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Total Course'
										subTitle='Students'
										value={dashboardData?.totalCourseBySession}
										isTotal={true}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={24} lg={24} xl={16} xxl={8}>
								<Card className='chart-wrapper h100p'>
									<Row style={{ height: '100%', width: '100%' }}>
										<Col xs={24}>
											<Title level={5} style={{ textAlign: 'center' }}>
												Course-wise Student Count
											</Title>
										</Col>
										<Col xs={24}>
											<BarChart
												data={studentCourseWiseData}
												xField='students'
												yField='course'
												seriesField='course'
												legend='bottom-center'
												style={{ height: 200 }}
											/>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} sm={24} lg={24} xl={24} xxl={12}>
								<Card className='chart-table-wrapper'>
									<Row style={{ height: '100%', width: '100%' }}>
										<Col xs={24}>
											<Title level={5} style={{ textAlign: 'center' }}>
												Course-wise Videos, Lecture, Chapter and Question-solve{' '}
											</Title>
										</Col>
										<Col xs={24}>
											<Table
												pagination={{ pageSize: 10 }}
												dataSource={coursWiseSubjectData}
												columns={coursWiseSubjectColumns}
												scroll={{ x: 'max-content', y: 200 }}
												size='small'
												sticky
											/>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>

						<Row gutter={[10, 10]}>
							<Col xs={24} sm={8} lg={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Total Group'
										subTitle='Students'
										value={dashboardData?.totalGroupBySession}
										isTotal={true}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<Card className='chart-wrapper h100p'>
									<Row style={{ height: '100%', width: '100%' }}>
										<Col xs={24}>
											<Title level={5} style={{ textAlign: 'center' }}>
												Course-wise Group Count
											</Title>
										</Col>
										<Col xs={24}>
											<PieChart
												data={courseWiseGroupCount}
												radius={1}
												style={{ height: 180 }}
											/>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} sm={8} lg={12}>
								<Card
									title='Group-wise Student Count'
									className='chart-wrapper has-extra'
									extra={
										<Select
											value={selectedCourseForStudentCount}
											onChange={value =>
												setSelectedCourseForStudentCount(value)
											}
											style={{ width: 120 }}
										>
											{courseData &&
												courseData.map(item => (
													<Option value={item?._id} key={item?._id}>
														{item?.name}
													</Option>
												))}
										</Select>
									}
								>
									{selectedCourseForStudentCount &&
										groupStudentsCountData &&
										groupStudentsCountData.length > 0 && (
											<ColumnChart
												data={groupStudentsCountData}
												xField='group'
												yField='students'
												isGroup={false}
												columnWidthRatio={0.8}
												meta={{
													group: { alias: 'Group' },
													students: { alias: 'Students' }
												}}
												style={{ height: 180 }}
											/>
										)}
								</Card>
							</Col>
							<Col xs={24} sm={24} lg={24}>
								<Card
									title='Group-wise Exams'
									className='chart-wrapper has-extra'
									extra={
										<Select
											value={selectedCourseForGroupExam}
											onChange={value => setSelectedCourseForGroupExam(value)}
											style={{ width: 120 }}
										>
											{courseData &&
												courseData.map(item => (
													<Option value={item?._id} key={item?._id}>
														{item?.name}
													</Option>
												))}
										</Select>
									}
								>
									{groupExamsCountData && (
										<ColumnChart
											data={groupExamsCountData}
											xField='group'
											yField='examCount'
											seriesField='type'
											isGroup={true}
											columnWidthRatio={0.8}
											meta={{
												group: { alias: 'Group' },
												examCount: { alias: 'Exams' }
											}}
											style={{ height: 180 }}
										/>
									)}
								</Card>
							</Col>
						</Row>

						<Row gutter={[10, 10]}>
							<Col xs={24} sm={12} xxl={6}>
								<Card className='chart-wrapper'>
									<Row>
										<Col xs={24}>
											<Title level={5} style={{ marginBottom: 15 }}>
												Videos
											</Title>
										</Col>
										<Col xs={24}>
											<PieChart
												data={videosData}
												radius={1}
												style={{ height: 180 }}
											/>
										</Col>
										<Col xs={24}>
											<Title level={4} style={{ marginTop: 15 }}>
												Total: {totalVideos}
											</Title>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} sm={12} xxl={6}>
								<Card className='chart-wrapper'>
									<Row>
										<Col xs={24}>
											<Title level={5} style={{ marginBottom: 15 }}>
												Notes
											</Title>
										</Col>
										<Col xs={24}>
											<PieChart
												data={notesData}
												radius={1}
												style={{ height: 180 }}
											/>
										</Col>
										<Col xs={24}>
											<Title level={4} style={{ marginTop: 15 }}>
												Total: {totalNotes}
											</Title>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} sm={12} xxl={6}>
								<Card className='chart-wrapper'>
									<Row>
										<Col xs={24}>
											<Title level={5} style={{ marginBottom: 15 }}>
												Live Exams
											</Title>
										</Col>
										<Col xs={24}>
											<PieChart
												data={liveExams}
												radius={1}
												style={{ height: 180 }}
											/>
										</Col>
										<Col xs={24}>
											<Title level={4} style={{ marginTop: 15 }}>
												Total: {totalLiveExams}
											</Title>
										</Col>
									</Row>
								</Card>
							</Col>
							<Col xs={24} sm={12} xxl={6}>
								<Card className='chart-wrapper'>
									<Row>
										<Col xs={24}>
											<Title level={5} style={{ marginBottom: 15 }}>
												Practice Exams
											</Title>
										</Col>
										<Col xs={24}>
											<PieChart
												data={practiceExams}
												radius={1}
												style={{ height: 180 }}
											/>
										</Col>
										<Col xs={24}>
											<Title level={4} style={{ marginTop: 15 }}>
												Total: {totalPracticeExams}
											</Title>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>

						<Row gutter={[10, 10]}>
							<Col xs={24} sm={4} lg={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Total Question'
										subTitle='Students'
										value={totalQuestions}
										isTotal={true}
									/>
								</Card>
							</Col>

							<Col xs={24} sm={20} lg={20}>
								<Card className='chart-table-wrapper'>
									<Row style={{ height: '100%', width: '100%' }}>
										<Col xs={24}>
											<Title level={5} style={{ textAlign: 'center' }}>
												Course-wise Questions Count
											</Title>
										</Col>
										<Col xs={24}>
											{!!coursWiseQuestionData && (
												<Table
													pagination={{ pageSize: 10 }}
													dataSource={coursWiseQuestionData}
													columns={coursWiseQuestionColumns}
													scroll={{ x: 'max-content', y: 200 }}
													size='small'
													sticky
												/>
											)}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginBottom: 20 }}>
				<Col xs={24}>
					<Card
						style={{ borderRadius: 10, marginTop: 5, background: '#90a4ae' }}
					>
						<Row gutter={[10, 10]}>
							<Col xs={24} sm={8} lg={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Total Branches'
										subTitle='Students'
										value={branchData?.total}
										isTotal={true}
									/>
								</Card>
							</Col>

							<Col xs={24} sm={16} lg={8}>
								<Card className='chart-table-wrapper'>
									<Table
										pagination={{ pageSize: 10 }}
										dataSource={branchData?.divisionWiseBranchCount}
										columns={branches.columns}
										scroll={{ x: 'max-content', y: 200 }}
										size='small'
										sticky
									/>
								</Card>
							</Col>

							<Col xs={24} sm={8} lg={4}>
								<Card className='dash-counter-wrapper'>
									<Counter
										label='Admin Panel Users'
										subTitle='Students'
										value={adminData?.total}
										isTotal={true}
									/>
								</Card>
							</Col>
							<Col xs={24} sm={16} lg={8}>
								<Card className='chart-table-wrapper'>
									<Table
										pagination={{ pageSize: 10 }}
										dataSource={adminUsersData()}
										columns={AdminPanelUsers.columns}
										scroll={{ x: 'max-content', y: 200 }}
										size='small'
										sticky
									/>
								</Card>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginBottom: 20 }}>
				<Col xs={24}>
					<Card style={{ borderRadius: 10, marginTop: 5 }}>
						<Row gutter={[10, 10]}>
							<Col xs={24} sm={12} lg={12}>
								<Card className='chart-table-wrapper'>
								<Title level={5} style={{ textAlign: 'center' }}>
												Question by Subject
											</Title>
									<PieChart
										data={questionStatisticsData}
										radius={1}
										style={{ height: 300 }}
									/>
									<Title level={5} style={{ textAlign: 'center' }}>
												Total Question
											</Title>
								</Card>
								
							</Col>
							<Col xs={24} sm={12} lg={12}>
								<Card className='chart-table-wrapper'>
									<Title level={5} style={{ textAlign: 'center' }}>
										Answer
									</Title>
									<PieChart
										data={answerStatisticsData}
										radius={1}
										style={{ height: 300 }}
									/>
									<Title level={5} style={{ textAlign: 'center' }}>
										Total Question
									</Title>
								</Card>

							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default Dashboard;
