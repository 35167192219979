import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Tag } from "antd";
import { getEditHistory } from "../../stateManager/reducers/mentorSlice";
import { useSelector, useDispatch } from "react-redux";
import { Card, Col, Row } from "antd";
import { bucket_url } from '../../constants/constString';
import { HistoryOutlined } from "@ant-design/icons";


// Sample edit history data


const EditHistoryModal = (props) => {
    const {commentId} = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const editHistoryData = useSelector((state) => state.mentor.editHistoryList);

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch edit history data

    const getEditHistoryData = async () => {
        // Call API to get edit history data
        await dispatch(getEditHistory(commentId));
        }

        commentId && isModalVisible && getEditHistoryData();
  }, [isModalVisible]);


  // Columns for Ant Design Table
  const columns = [
    {
      title: "Reply",
      dataIndex: "reply",
      key: "reply",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => new Date(date).toLocaleString(), // Format date
    },
    {
      title: "Media",
      dataIndex: "media",
      key: "media",
      render: (media) =>
        media.length > 0 ? (
          media.map((item, index) => (
            <Tag key={index}>
              <a href={item.mediaUrl} target="_blank" rel="noopener noreferrer">
                {item.mediaType.toUpperCase()}
              </a>
            </Tag>
          ))
        ) : (
          <Tag>No Media</Tag>
        ),
    },
  ];

  const renderFilePreviewOnlyName = (file, type) => {
    console.log('file', file);
    if (type.startsWith('image/')) {
      return <div className="img-section"><img src={bucket_url+ file} alt={file?.name} className="image-item" /></div>;
    } else if (type ===  'application/pdf') {
      return (<div className='pdf-section'
        style={{
          cursor: 'pointer',
         
        }}
      >
        {/* Instead of directly embedding <object> here, use a wrapper div */}
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <iframe title='pdf'
            className="pdf"
            src={bucket_url + file}
            width="200"
            height="100"
            style={{
              pointerEvents: 'none', // Disable pointer events for object tag to avoid click interference
            }}
          />
        </div>
      </div>)
    } else if (type.startsWith('audio/')) {
      return <div className="audio-section"> <audio controls src={bucket_url+ file} /></div>;
    }
    return null;
  };

  return (
    <div>
     <Button type="primary" onClick={showModal} icon={<HistoryOutlined />}>
        
      </Button>
      <Modal
        title="Edit History"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="close" onClick={handleCancel}>
            Close
          </Button>,
        ]}
        width={800}
      >
        <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
        {editHistoryData.map((question) => (
          <Col span={24} key={question._id}>
            <Card
             
              style={{ marginBottom: "20px" }}
            >
              {/* <div className="user-section">{title(question)}</div> */}
              <span className="question-title">
                {new Date(question.createdAt).toLocaleString()}
              </span>
              <p className="question-text">{question.reply}</p>
              {question.media.length > 0 && (
                <div className="attachment-section">
                  <Row gutter={[20, 20]} className="image-parent">
                    {question.media
                      .filter((item) => item.mediaType.startsWith('image/'))
                      .map((file, index) => (
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          key={index}
                        >
                          {renderFilePreviewOnlyName(
                            file.mediaUrl,
                            file.mediaType
                          )}
                        </Col>
                      ))}
                  </Row>
                  <Row gutter={[20, 20]} className="pdf-parent">
                    {question.media
                      .filter((item) => item.mediaType.startsWith('application/pdf'))
                      .map((file, index) => (
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          key={index}
                        >
                          {renderFilePreviewOnlyName(
                            file.mediaUrl,
                            file.mediaType
                          )}
                        </Col>
                      ))}
                  </Row>
                  <Row gutter={[20, 20]} className="audio-parent">
                    {question.media
                      .filter((item) => item.mediaType.startsWith('audio/'))
                      .map((file, index) => (
                        <Col
                          xs={{ span: 24 }}
                          lg={{ span: 6 }}
                          key={index}
                        >
                          {renderFilePreviewOnlyName(
                            file.mediaUrl,
                            file.mediaType
                          )}
                        </Col>
                      ))}
                  </Row>
                </div>
              )}
            </Card>
          </Col>
        ))}
        </div>
      


      </Modal>
    </div>
  );
};

export default EditHistoryModal;
