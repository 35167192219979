import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../constants/constString";

const initialState = {
  status: "idle",
  error: null,
  studentsCount: {},
  dashboardData: {},
  courses: {},
  courseData: [],
  courseWiseGroupData: [],
  branchData: {},
  adminData: {},
  questionStatistics: {},
  answerStatistics: {},
};

export const getCourses = createAsyncThunk("courses/getCourses", async () => {
  try {
    const response = await instance.get("/course");
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const questionStatistics = createAsyncThunk(
  "question/questionStatistics",
  async () => {
    try {
      const response = await instance.get("/qa/analitycs");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getAnswerStatistics = createAsyncThunk(
  "question/getAnswerStatistics",
  async () => {
    try {
      const response = await instance.get("/qa/get-answer-statistics");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const studentCountRequest = createAsyncThunk(
  "dashboard/studentCount",
  async () => {
    try {
      const response = await instance.get(`/analitycs/students`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getDashboardData = createAsyncThunk(
  "dashboard/dashboardData",
  async ({ session = 2020 }) => {
    try {
      const response = await instance.get(`/analitycs/dashboard/${session}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getGroupByCourseAndSession = createAsyncThunk(
  "dashboard/getGroupByCourseAndSession",
  async ({ session = 2020, courseId }) => {
    try {
      const response = await instance.get(
        `/analitycs/group/${courseId}/exam/${session}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getBranchAndAdmin = createAsyncThunk(
  "dashboard/getBranchAndAdmin",
  async () => {
    try {
      const response = await instance.get("/analitycs/branch-and-admin");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [getCourses.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCourses.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { data } = action.payload;
      const courses = {};
      data.forEach((element) => {
        courses[element?._id] = element.name;
      });
      state.courses = courses;
      state.courseData = data;
    },
    [studentCountRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [studentCountRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsCount = action?.payload?.data;
    },
    [getDashboardData.pending]: (state, action) => {
      state.status = "loading";
    },
    [getDashboardData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.dashboardData = action?.payload?.data;
    },
    [getGroupByCourseAndSession.pending]: (state, action) => {
      state.status = "loading";
    },
    [getGroupByCourseAndSession.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.courseWiseGroupData = action?.payload?.data;
    },
    [getBranchAndAdmin.fulfilled]: (state, action) => {
      state.branchData = action?.payload?.data?.branch;
      state.adminData = action?.payload?.data?.admin;
    },
    [questionStatistics.pending]: (state, action) => {
      state.status = "loading";
    },
    [questionStatistics.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.questionStatistics = action?.payload?.data;
    },
    [questionStatistics.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getAnswerStatistics.pending]: (state, action) => {
      state.status = "loading";
    },
    [getAnswerStatistics.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.answerStatistics = action?.payload?.data;
    },
    [getAnswerStatistics.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export default slice.reducer;
