import {
	Form,
	Card,
  Layout,
} from 'antd';

import { useEffect, useState } from 'react';
import { Col, Row, Select } from 'antd';
import { Table, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses, updateCourseAccess } from '../../stateManager/reducers/courseSlice';
import { sessions } from '../../constants/constString';
import SubHeader from '../Common/SubHeader';
const { Option } = Select;


export default function QNAAccessManagement() {
    const [form] = Form.useForm();
    const [selectedSession, setSession] = useState(undefined);

        const courseList = useSelector(state =>
            state.courses.courseList?.filter(item => item?.session == selectedSession)
        );
	const dispatch = useDispatch();
	    useEffect(() => {
            async function fetchData() {
                await dispatch(getCourses());
            }
            fetchData();
        }, []);

    const handleSwitchChange = async (checked, record) => {
        // Update the isEditable field of the course in the table data
        // const updatedCourses = courseList.map(course =>
        //   course._id === record._id ? { ...course, isEditable: checked } : course
        // );
        const res = await dispatch(updateCourseAccess({ courseId: record._id, hasFullAccess: checked }));
         
       // setCourseList(updatedCourses);
      };

    const columns = [
        {
          title: 'Course Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Access',
          dataIndex: 'isEditable',
          key: 'isEditable',
          render: (text, record) => (
            <Switch
              checked={record.hasFullAccess}
              onChange={(checked) => handleSwitchChange(checked, record)}
              checkedChildren="Read/write"
              unCheckedChildren="Read only"
            />
          ),
        },
      ];


    return  <Layout className="white-bg question-main-section">
        <SubHeader headText='Q&A Course Access Management' />
        <Card style={{ maxWidth: '800px', marginTop: '20px', padding: '20px' }}>
         
				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					style={{ marginBottom: '20px' }}
				>
					<Col xs={24} sm={24} md={8} lg={8}>
						<Select
							value={selectedSession}
							style={{ width: '100%' }}
							placeholder='Select Session'
							onChange={value => {
								setSession(value);
							}}
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
					
					
				</Row>
                <Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					style={{ marginBottom: '20px' }}
				>
                <Table
                columns={columns}
                dataSource={courseList}
                rowKey="_id"
                pagination={false}
                bordered
                />
					</Row>

					</Card>
				
    </Layout>
    }