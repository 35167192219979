import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import QuestionBank from '../../QuestionBank/index';
import CourseWiseQuestion from '../../QuestionBank/CourseWiseQuestion';
import AddQuestion from '../../QuestionBank/AddQuestion';
import EditQuestion from '../../QuestionBank/EditQuestion';

const QuestionUploaderNavigation = props => {
	return (
		<React.Fragment>
			<Redirect exact from='/' to='/question-bank' />
			<Route
				exact
				path='/question-bank/:courseId'
				component={CourseWiseQuestion}
			/>
			<Route exact path='/question-bank' component={QuestionBank} />
			<Route
				path='/add-question/:courseId/:subjectId/:chapterId/:topic'
				component={AddQuestion}
			/>
			<Route path='/edit-question' component={EditQuestion} />
		</React.Fragment>
	);
};

export default QuestionUploaderNavigation;
