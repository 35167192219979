import React from 'react';
import { useRef } from 'react';
import {  Card, Pagination, Row, Col, Popover, Select} from 'antd';

import { Button } from 'antd';
import { Option } from 'antd/lib/mentions';

import selectDropdownIcon from '../../assets/images/icons/select-dropdown-icon.svg';

import { Layout } from 'antd';
import { lockQuestion, getQuestions, getMentorChapters, getSeniorMentorAssignedSubject, updateStatus, getLockedQuestions, unlockQuestion, question } from '../../stateManager/reducers/mentorSlice';
import './QNAManagement.style.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { bucket_url } from '../../constants/constString';
import DebounceSelect from './SeachQuestion';
import SubHeader from '../Common/SubHeader';


const { Content } = Layout;

const FILTER_OPTIONS = {
  'None': 'None',
  'NEW_QUESTION': 'New Question',
  'NOT_ANSWERED': 'Not Answered Yet',
  'COMPLETED': 'Completed',
  'MY_REPLIED': 'My Replied',
  'DECLINED': 'Declined',
};

const QUESTION_STATUS = {
  "COMPLETED": "COMPLETED",
  "COMPLETE": "COMPLETE",
  "DECLINE": "DECLINE",
  "DECLINED": "DECLINED",
}


const QNAManagement = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const courses = useSelector(state => {
    if (user.roles.includes('admin')) {
      return state.mentor.seniorMentorSubjectsList
    }
    return state.mentor.mentorChapterList;
  });
  const [currentPage, setCurrentPage] = React.useState(Number(localStorage.getItem('currentPage')) || 1);
  const [pageSize, setPageSize] = React.useState(Number(localStorage.getItem('pageSize')) || 16);
  const [isOpenFilter, setIsOpenFilter] = React.useState(false);
  const [statusPopoverVisible, setStatusPopoverVisible] = React.useState(null);
  const [filter, setFilter] = React.useState(()=>{
    if(localStorage.getItem('filter') && courses.length > 0){
      return localStorage.getItem('filter');
    }
    return 'None';
  });
  const [searchString, setSearchString] = React.useState(()=>{
    if(localStorage.getItem('searchString') && courses.length > 0){
      return localStorage.getItem('searchString');
    }
    return '';
  });

  const [subjectByCourse, setSubjectByCourse] = React.useState(()=>{


    if(localStorage.getItem('selectedCourse') && courses.length > 0){
      const course = courses.find((course) => course._id === localStorage.getItem('selectedCourse'));
     
      return course?.subjects;
    }
    

    return [];
  });
  const [chapterBySubject, setChapterBySubject] = React.useState(()=>{

    if(localStorage.getItem('selectedSubject') && subjectByCourse.length > 0){
      console.log('selectedSubject', localStorage.getItem('selectedSubject'), subjectByCourse);
      const subject = subjectByCourse.find((subject) => subject._id === localStorage.getItem('selectedSubject'));
      return subject?.chapters;
    }

  
    return [];
  });
  const [selectedCourse, setSelectedCourse] = React.useState(()=>{
    if(localStorage.getItem('selectedCourse') && courses.length > 0){
      return localStorage.getItem('selectedCourse');
    }

    localStorage.setItem('selectedCourse', '');

    return '';
  });

  const [selectedSubject, setSelectedSubject] = React.useState(()=>{
    if(localStorage.getItem('selectedSubject') && subjectByCourse.length > 0){
      return localStorage.getItem('selectedSubject');
    }

    localStorage.setItem('selectedSubject', '');

    return '';

  });
  const [selectedChapter, setSelectedChapter] = React.useState(()=>{
    if(localStorage.getItem('selectedChapter') && chapterBySubject.length > 0){
      return localStorage.getItem('selectedChapter');
    }

    localStorage.setItem('selectedChapter', '');

    return '';
  });
  const debounceSelectRef = useRef(null);
  const handleScroll = () => {
    const scrollPos =
      window.scrollY || document.documentElement.scrollTop || 0;
    localStorage.setItem("scrollPosition", scrollPos.toString());
    console.log('scrollPos', scrollPos);
  };
  useEffect(() => {

    const savedScrollPosition = localStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseInt(savedScrollPosition, 10));
      }, 0);
    }
    localStorage.setItem("scrollPosition", 0);

  }, []);

  const statusChangeContent = (
    <div>
      <p>Are you sure you want to change the status of this question?</p>

      <Button type="primary" danger onClick={()=>updateStatusHandler(statusPopoverVisible)} style={{ marginRight: '8px' }}>
        Yes, Change
      </Button>
      <Button onClick={() => {
        setStatusPopoverVisible(null);
      }}>Cancel</Button>
    </div>
  );
  

  const dispatch = useDispatch();
  const history = useHistory();
  const qnaList = useSelector(state => state.mentor.qnaList);
 // const lockedQuestions = useSelector(state => state.mentor.lockedQuestions);
  const totalRecords = useSelector(state => state.mentor.totalRecords);
   
 const isLocked = (comment) => {
  // if question is exist in lockedQuestions and lockedAt greater than 10 minutes

  if(comment.locked){

    const lockedAt = new Date(comment.locked.lockedAt);
    const currentTime = new Date();
    const diff = (currentTime - lockedAt) / 1000;
    if (diff < 600) {
      return true;
    }
    else{
      return false;
    }
  }
  
  return false;
 }

  const updateStatusHandler = async (comment) => {
    let status;
    switch (comment.status) {
      case QUESTION_STATUS.COMPLETE:
          status = QUESTION_STATUS.COMPLETED;
          break;
      case QUESTION_STATUS.COMPLETED:
          status = QUESTION_STATUS.COMPLETE;
          break;
      case QUESTION_STATUS.DECLINE:
          status = QUESTION_STATUS.DECLINED;
          break;
      case QUESTION_STATUS.DECLINED:
          status = QUESTION_STATUS.COMPLETE;
          break;
      default:
          status = QUESTION_STATUS.COMPLETE;
  }

    try {
        await dispatch(updateStatus({ questionId: comment._id, status }));
        setStatusPopoverVisible(null);
    } catch (error) {

    }
}

  const onPageChange = (page) => {
    setCurrentPage(page);
  };
  console.log('qnaList', qnaList);
  useEffect(() => {

    const getMentorSubject = async ()=>{
      if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
        await dispatch(getSeniorMentorAssignedSubject(user.id));
        return;
      }
       await dispatch(getMentorChapters(user.id));
    }
    getMentorSubject();

    if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
     FILTER_OPTIONS['LOCKED'] = 'Locked';
    //  FILTER_OPTIONS['FORWARDED'] = 'Forwarded';
    }
  
  }, []);

  useEffect(() => {
    const getQNA = async ()=>{
      const data  = {
        mentorId: user.id,
      }
      if(selectedCourse){
        data.courseId = selectedCourse
      }
      if(selectedSubject){
        data.subjectId = selectedSubject
      }
      if(selectedChapter){
        data.chapterId = selectedChapter
      }
      if(filter){
        data.filter = filter;
      }
      if(searchString){
        data.searchString = searchString;
      }

      data.page = currentPage;
      data.limit = pageSize;
      

      const qna = await dispatch(getQuestions(data));

      console.log('qna', qna);
    }
    getQNA();

    // save selected course, subject, chapter, filter, search string, page, limit in local storage
    localStorage.setItem('selectedCourse', selectedCourse);
    localStorage.setItem('selectedSubject', selectedSubject);
    localStorage.setItem('selectedChapter', selectedChapter);
    localStorage.setItem('filter', filter);
    localStorage.setItem('searchString', searchString);
    localStorage.setItem('currentPage', currentPage);
    localStorage.setItem('pageSize', pageSize);

    
  }, [selectedCourse, selectedSubject, filter, searchString, selectedChapter, currentPage, pageSize]);


  const disableStatusButton = (comment) => {

    if(user.roles.includes('admin')){
      return false;
    }
    
    if((comment.status === QUESTION_STATUS.COMPLETED) || (comment.status === QUESTION_STATUS.DECLINED)){
      return true;
    }
    return false;
  }
  
  const title = (comment) => {
    console.log('comment', comment);
    return (
    <div  className="user-content">
      <div className="user-data">
        <div>
            <h3 className="user-name">{comment?.studentId?.name || 'Guest'} <span style={{
              color: '#475569',
              fontSize: '12px',
            }}>{comment?.subjectId?.name}</span></h3>
            <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
        </div>
      </div>
      <div className="question-btn-section">
       {
           (comment.status !== "COMPLETED" && comment.status !== "DECLINED") && <Button className="action-dropdown-btn" onClick={()=>{
                      
              if(isLocked(comment)){
                const user = JSON.parse(localStorage.getItem('user'));
                if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
                dispatch(unlockQuestion({ questionId: comment._id, mentorId: user }));
                }
              }
              else{
                dispatch(lockQuestion({ questionId: comment._id, mentorId: user.id }));
              }
            

            }}
            disabled={comment.lockable? false : true}
            >{
              isLocked(comment)? 'Locked' : 'Lock'
            
            } </Button>
       }
       
        <Button className="action-dropdown-btn"  id = {comment._id} disabled={ disableStatusButton(comment) } onClick={()=>{

          setStatusPopoverVisible(comment);
        }}
        
        >{comment.status}</Button>
         <Popover
                    content={statusChangeContent}
                    title="Confirm Status Change"
                    trigger="click"
                    open={
                     () => {
                      return statusPopoverVisible === comment;
                    }
                    }>
                      
                  </Popover>
        <Button type="danger" className="question-view-btn" onClick={()=>{
                    handleScroll();
                    history.push(`/question-details/${comment._id}`);
                  }}>View   {comment.answers > 0 && `Replied (${comment.answers})`} </Button>
                                     
      </div>
  </div>)
  }
  const renderFilePreviewOnlyName = (file, type) => {
    console.log('file', file);
    if (type.startsWith('image/')) {
      return <div className="img-section"><img src={bucket_url+ file} alt={file?.name} className="image-item" /></div>;
    } else if (type ===  'application/pdf') {
      return (<div className='pdf-section'
        style={{
          cursor: 'pointer',
         
        }}
      >
        {/* Instead of directly embedding <object> here, use a wrapper div */}
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <iframe title='pdf'
            className="pdf"
            src={bucket_url + file}
            width="200"
            height="100"
            style={{
              pointerEvents: 'none', // Disable pointer events for object tag to avoid click interference
            }}
          />
        </div>
      </div>)
    } else if (type.startsWith('audio/')) {
      return <div className="audio-section"> <audio controls src={bucket_url+ file} /></div>;
    }
    return null;
  };

  return (
      <Layout className="white-bg question-main-section">
          <SubHeader headText='Q&A Management' />
          <Row>
          {/* <Col>Choose a course to create an exam</Col> */}
          </Row>
          <Layout className="total-set-card">
            <Row gutter={56} style={{ marginBottom: '15px' }}>
                <Col span={24}>
                <div className='search-section'>
                  <div className="select-with-search">
                  <DebounceSelect
                    mode="multiple"
                    placeholder="Search questions..."
                    searchString={searchString}
                    setSearchString={setSearchString}
                    style={{
                      width: '100%',
                    }}
                    ref={debounceSelectRef}
                    />
                    <div className="question-search-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M21 21L16.7 16.7M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z" stroke="#475569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                  </div>
                  <Popover
                      content={<div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}onClick={()=>{
                        setIsOpenFilter(false);
                      }}>
                        {
                          Object.keys(FILTER_OPTIONS).map((key,i) => (
                            <Button className={`popover-btn ${i===Object.keys(FILTER_OPTIONS).length-1?'last':''}`} type='text' key={key} style={{
                              color: filter === key ? 'red' : 'black',
                            }} onClick={()=>{

                              console.log('filter', key, FILTER_OPTIONS[key]);
                              if(key === filter){
                                //setIsOpenFilter(false);
                                setFilter('');
                                return;
                              }
                              else{                          
                                setFilter(key);
                                //setIsOpenFilter(false);
                                
                              }

                              
                            }}>{FILTER_OPTIONS[key]}</Button>
                          ))
                        }
                        
                        </div>}
                      title={null}
                      trigger="click"
                      open={isOpenFilter}
                      onOpenChange={(open) => setIsOpenFilter(open)}
                    >
                      <Button danger className="filter-icon-btn">
                      {/* {
                    filter === 'None' ? 'Sort' : FILTER_OPTIONS[filter]
                  } */}Sort
                      </Button>
                  </Popover>
                  <Button type="danger" className="filter-icon-btn" onClick={()=>{
                    setSelectedCourse('');
                    setSelectedSubject('');
                    setSelectedChapter('');
                    setFilter('None');
                    setSearchString('');
                    localStorage.setItem('selectedCourse', '');
                    localStorage.setItem('selectedSubject', '');
                    localStorage.setItem('selectedChapter', '');
                    localStorage.setItem('filter', 'None');
                    localStorage.setItem('searchString', '');
                    localStorage.setItem('currentPage', 1);
                    localStorage.setItem('pageSize', 16);
                    setCurrentPage(1);
                    setPageSize(16);



                    if(debounceSelectRef.current){
                      console.log('debounceSelectRef', debounceSelectRef); 
                      debounceSelectRef.current.clearSelection();
                    }
                    else{
                      console.log('debounceSelectRef', debounceSelectRef);
                    }
                  }}>
                    Reset
                  </Button>
                </div>

                </Col>
              
            </Row>
            <Row gutter={[15, 15]} style={{ marginBottom: '0px' }}>
                <Col xs={{ span: 24 }} lg={{ span:8 }}>
                  <Select placeholder="Select a course" style={{ width: '100%' }} value={selectedCourse}
                    onChange={(courseId) => {
                    const course = courses.find((course) => course._id === courseId);
                      setSubjectByCourse(course?.subjects);
                      setSelectedCourse(courseId);
                      //setSelectedSubject(course?.subjects[0]?._id);
                    }
                    }
                    suffixIcon={<img src={selectDropdownIcon} />}
                  >
                    <Option value="" disabled>Select a course</Option>
                    {courses.filter(course => course.session === "2024")?.map((course) => (
                      <Option key={course._id} value={course._id}>
                        {course.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span:8 }}>
                <Select placeholder="Select a subject" value={selectedSubject} onChange={(subjectId)=>{
                  const subject = subjectByCourse.find((subject) => subject._id === subjectId);
                  setSelectedSubject(subjectId);
                  setChapterBySubject(subject?.chapters);

                }}style={{ width: '100%'}}
                suffixIcon={<img src={selectDropdownIcon} />}
                >
                  <Option value="" disabled>Select a subject</Option>
                  {subjectByCourse?.map((subject) => (
                    <Option key={subject._id} value={subject._id}>
                      {subject.name}
                    </Option>
                  ))}
                </Select>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span:8 }}>
                <Select placeholder="Select a chapter" style={{ width: '100%'}}  value={selectedChapter} suffixIcon={<img src={selectDropdownIcon} />}
                onChange={(chapterId) => {
                  setSelectedChapter(chapterId);
                }
                }
                >
                  <Option value="" disabled>Select a chapter</Option>
                  {chapterBySubject?.map((chapter) => (
                    <Option key={chapter._id} value={chapter._id}>
                      {chapter.name}
                    </Option>
                  ))}
                </Select>
                </Col>
                
            </Row>
            <Content >
            <Layout>
              <Row gutter={[20,20]} className="question-list-row">
              {qnaList.map((question) => (
                <Col span={24} key={question._id}>
                    <Card className="question-card question">
                    <div className="user-section">{title(question)}</div>
                    <p className="question-text">
                      {question.questionDescription?.length > 400 ? (
                      <>
                        {question.questionDescription?.substring(0, 400)}...
                        <Button className='p-0' type="link" onClick={() => history.push(`/question-details/${question._id}`)}>See more</Button>
                      </>
                      ) : (
                      question.questionDescription
                      )}
                    </p>
                      {question.media.length > 0 && 
                          <div  className="attachment-section"
                          >
                            <Row gutter={[20,20]}  className='image-parent'> 
                              {question.media.filter(item=> item.mediaType.startsWith('image/')).map((file, index) => (
                                    
                                      <Col xs={{ span: 24 }} lg={{ span:6 }} key={index}>{renderFilePreviewOnlyName(file.mediaUrl, file.mediaType)}
                                      </Col>
                              ))}
                            </Row>
                            <Row gutter={[20,20]}  className='pdf-parent'>
                                  {question.media.filter(item=> item.mediaType.startsWith('application/pdf')).map((file, index) => (
                                      
                                      <Col xs={{ span: 24 }} lg={{ span:6 }} key={index}>{renderFilePreviewOnlyName(file.mediaUrl, file.mediaType)}
                                      </Col>
                              ))}
                            </Row>
                            <Row gutter={[20,20]}  className='audio-parent'>
                              {question.media.filter(item=> item.mediaType.startsWith('audio/')).map((file, index) => (
                                      
                                      <Col xs={{ span: 24 }} lg={{ span:6 }} key={index}>{renderFilePreviewOnlyName(file.mediaUrl, file.mediaType)}
                                      </Col>
                              ))}
                            </Row>
                          </div>
                        }
                          {user.roles.includes('admin') && question.statusUpdatedBy && <span>{
              `Status Updated by ${question?.statusUpdatedBy?.firstName || ''} ${question?.statusUpdatedBy?.lastName || ''}, ${question?.statusUpdatedBy?.adminId || ''}`
              }</span>}
                    </Card>
                </Col>
              ))}
              </Row>
              <div className="pagination-section">
                <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={
                  (page, size) => {
                    setPageSize(size);
                    onPageChange(page);
                  }
                }
                style={{ textAlign: 'center', marginTop: '20px' ,paddingBottom: '56px'}}
              />
            </div>
            </Layout>
          
           
            </Content>
          </Layout>
         
      </Layout>
 
  );
};

export default QNAManagement;
