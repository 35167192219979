import React, { useState } from 'react';
import { Layout } from 'antd';

import AdminNavigation from '../Navigations/AdminNavigation';
import SiderAdmin from '../Common/SiderAdmin';
import Header from '../Common/Header';
import config from '../../config'

const { Footer } = Layout;

const AdminEntry = props => {
	const [isBroken, setBroken] = useState(false);

	const toggleBroken = broken => setBroken(broken);
	console.log('companyName:', config)
	return (
		<Layout>
			<SiderAdmin toggleBroken={toggleBroken.bind(this)} broken={isBroken} />
			<Layout style={{ marginLeft: isBroken ? 0 : 200 }}>
				<Header />
				<div
					className='admin-content-area'
					style={{ minHeight: 'calc(100vh - 134px)', padding: '0 25px' }}
				>
					<AdminNavigation />
				</div>
				<Footer style={{ textAlign: 'center' }}>
					{config.title} ©2024 Developed and Maintained by{' '}
					<a href='https://www.ticonsys.com/' target='_blank' rel='noreferrer'>
						{config.companyName}
					</a>
				</Footer>
			</Layout>
		</Layout>
	);
};

export default AdminEntry;
