import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import CourseCard from '../Course/CourseCard';
import AddCourseModal from '../Course/AddCourseModal';
import { useLocation } from 'react-router-dom';

import {
	getCourses,
	courseList as courses
} from '../../stateManager/reducers/courseSlice';
import { sessions } from '../../constants/constString'
import { LOCAL_KEY } from '../../constants/constString';
import { getLocalStorageData, updateLocalStorage } from '../../constants/constFunction';

const { Content } = Layout;
const { Option } = Select;

const VideoLectures = props => {
	const [isAddCourseModalVisible, setCourseModalVisible] = useState(false);
	const [selectedSession, setSession] = useState(undefined);
	const dispatch = useDispatch();
	// const courseList = useSelector(courses);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const contentName = queryParams.get('content');

	console.log('contentName', contentName);
	if (contentName) {
		updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'content', contentName);
	}
	
	const courseList = useSelector(state =>
		selectedSession
			? state.courses.courseList?.filter(
				item => selectedSession && item?.session == selectedSession
			)
			: state.courses.courseList
	);
	// const contentName =
	// 	useSelector(state => state.contents.contentName) ||
	// 	JSON.parse(localStorage.getItem('contentName'));

	const toggleAddCourseModal = () =>
		setCourseModalVisible(!isAddCourseModalVisible);
	useEffect(() => {
		const { session } = getLocalStorageData(LOCAL_KEY.VIDEO_LECTURE_KEY);
		if (session) {
			setSession(session);
		}
	}, [courseList]);
	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourses());
			
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<AddCourseModal
				isVisible={isAddCourseModalVisible}
				toggleModal={toggleAddCourseModal.bind(this)}
			/>

			<SubHeader
				headText={contentName === 'file' ? 'Lecture Notes' : 'Video Lectures'}
			/>

			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>
						Choose chaptes within subjects and course and ADD{' '}
						{contentName === 'file' ? 'notes' : 'videos'}
					</p>
				</div>

				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					<Col xs={24} sm={24} md={6} lg={4}>
						<Select
							style={{ width: '100%' }}
							placeholder='Select Session'
							value={selectedSession}
							onChange={(value) => {
								updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'session', value);
								setSession(value);
							}}
							allowClear
						>
							{sessions.map((item, index) => (
								<Option key={index} value={item}>
									{item}
								</Option>
							))}
						</Select>
					</Col>
				</Row>

				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					{courseList.map(item => (
						<Col xs={24} sm={24} md={6} lg={6}>
							<CourseCard data={item} />
						</Col>
					))}
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default VideoLectures;
