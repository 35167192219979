import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Button, List, Modal, Typography } from 'antd';
import { deleteExamRequest } from '../../stateManager/reducers/examSlice'
import { updateExamFromGroup } from '../../stateManager/reducers/groupSlice'

import './ExamDeleteModal.style.css';

const { Text } = Typography;

const ExamDeleteModal = props => {

  const dispatch = useDispatch();
  const examData = useSelector(state => state.exams.examData)
  const publishedGrups = useSelector(state => state.exams.publishedGrups)

  const { isModalVisible, onCancel, toggleDeleteModal } = props;

  const handleOk = () => {
    dispatch(deleteExamRequest({ examId: examData._id, force: true }))
    toggleDeleteModal()
  }

  return (
    <div>
      <Modal
        className='exam-delete-modal'
        title={<Text className='delete-modal-title' type='warning'>Warning...!</Text>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={onCancel}
        cancelText={'Cancel'}
        okButtonProps={{ danger: true }}
        okText={'Unpublish & Delete'}
        width="100%"
        style={{ maxWidth: 750 }}
      // footer={[
      //   <Button key="back" onClick={handleCancel}>
      //     Cancel
      //   </Button>,
      //   <Button key="submit" type="danger" onClick={handleOk}>
      //     Unpublish & Delete
      //   </Button>,
      // ]}
      >
        <Text className='text' type="danger">Before deleting an exam it is required to <Text type="danger" strong>unpublish</Text> that exam from all groups.</Text>
        <Text className='text' >This exam is already published in the following group(s):</Text>
        {
          !!publishedGrups &&
          <List
            className='list-of-group'
            size="small"
            bordered
            dataSource={publishedGrups}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button type="link" size="small" onClick={() => dispatch(updateExamFromGroup({
                    groupId: item._id,
                    examId: examData._id,
                    data: {
                      duration: parseInt(item.exams[0].duration),
                      addGPA: item.exams[0].addGPA,
                      startsAt: item.exams[0].startsAt,
                      endsAt: item.exams[0].endsAt,
                      multipleTimesSubmission: item.exams[0].multipleTimesSubmission,
                      status: 'unpublished',
                      publishedAt: item.exams[0].publishedAt,
                      type: examData && examData.isPracticeExam ? 'practice' : 'live'
                    },
                    deleteModal: true
                  }))}>
                    Unpublish
                  </Button>
                ]}
              >
                <Text>{item.name}</Text>
              </List.Item>
            )}
          />
        }
        <Text className='text'>If you need to remove the exam for the students of specific group(s), just <Text strong>unpublish</Text> the exam from those groups.</Text>
        <Text className='text' type="danger">By pressing the following "<Text type="danger" strong>Unpublish & Delete</Text>" button this exam will be unpublished from all of the above groups and then deleted.</Text>
      </Modal>
    </div>
  );
};

export default ExamDeleteModal;