import React, { useState, useEffect } from 'react';
import { Avatar, Layout, Menu, Dropdown, Button, notification } from 'antd';
import { Redirect } from 'react-router-dom';
import toastr from 'toastr';
import NotificationFilled from '@ant-design/icons/NotificationOutlined';
import ResetAdminPasswordModal from './ResetAdminPasswordModal';
import { messaging } from "../../firebase/firebaseConfig";
import { getToken, onMessage } from "firebase/messaging";
import { instance, version } from '../../constants/constString';
import { setPushToken } from '../../stateManager/reducers/adminAuthSlice';
import { useDispatch } from 'react-redux';

const { Header } = Layout;

const HeaderComponent = props => {
	const user = JSON.parse(localStorage.getItem('user'));
	const [isPasswordModalVisible, setPasswordModalVisible] = useState(false);
	const [api, contextHolder] = notification.useNotification();
	const dispatch = useDispatch();
	const actionButton = (
		<Button type="primary" size="small" onClick={()=>{

		}}>
		  Action
		</Button>
	  );
	const openNotification = (title, description) => {
		api.open({
		  message: title,
		  description:
			description,
		  duration: 0,
		  btn: actionButton,
		});
	  };
	const toggleModal = () => setPasswordModalVisible(!isPasswordModalVisible);

	onMessage(messaging, (payload) => {
		console.log("Message received. ", payload);
		openNotification(payload.notification.title, payload.notification.body);
	});

	async function requestPermission() {
		//requesting permission using Notification API
		const permission = await Notification.requestPermission();
	
		if (permission === "granted") {
		  const token = await getToken(messaging, {
			vapidKey: "BOaXe1Uo1xxAjAYHoRhVCgoPoT_RgbrVatLGv273tDoJYC_O0Y6AfIpRM9OWh_QYb9jBwADLxKoK4z3FIQEO9SA",
		  });
	
		  //We can send token to server
		  console.log("Token generated : ", token);
		  dispatch(setPushToken({ data: { token } }));
		} else if (permission === "denied") {
		  //notifications are blocked
		  alert("You denied for the notification");
		}
	  }
	
	//   useEffect(() => {
	// 	requestPermission();
	//   }, []);
	const handleLogout = async () => {
		console.log('came to logout');
		try {
			const res = await instance.get('/auth/logout');
			if (res) {
				const { data } = res;
				toastr.success(data.data.title);
				localStorage.clear();
				<Redirect to='/' />;
				window.location.replace('/');
			}
		} catch (error) {
			if (error.status === 401) {
				// localStorage.clear();
				<Redirect to='/' />;
				window.location.reload();
			}
			return Promise.reject(error);
		}
	};

	return (
		<Header style={{ background: '#fff', padding: 0 }}>
			{contextHolder}
			<ResetAdminPasswordModal
				isVisible={isPasswordModalVisible}
				toggleModal={toggleModal.bind(this)}
			/>

			
			<span style={{ float: 'right', marginRight: 40 }}>
			
				<Dropdown
					overlay={
						<Menu>
							<Menu.Item
								key='0'
								onClick={() => setPasswordModalVisible(!isPasswordModalVisible)}
							>
								Reset Password
							</Menu.Item>
							{/* <Menu.Divider /> */}
							<Menu.Item onClick={() => handleLogout()} key='logout'>
								Log Out
							</Menu.Item>
							<Menu.Divider />
							<Menu.Item>{version}</Menu.Item>
						</Menu>
					}
					trigger={['click']}
					placement='bottomRight'
					arrow
				>
					<a
						className='admin-dropdown-header-menu ant-dropdown-link'
						onClick={e => e.preventDefault()}
					>
						<Avatar
							// style={{ marginLeft: 5 }}
							size={50}
							src={
								'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
							}
						/>
						<div
							style={{
								display: 'flex',
								flexFlow: 'column',
								marginLeft: 5,
								lineHeight: 'normal'
							}}
						>
							<span style={{ fontSize: 18, fontWeight: 800, color: '#222' }}>
								{user.firstName + ' ' + user.lastName}
							</span>
							<span style={{ marginTop: 2, color: '#9e9e9e' }}>
								{user.username}
							</span>
						</div>
					</a>
				</Dropdown>
			</span>
			{/* <Button icon={<NotificationFilled/>} style={{
				background: 'transparent',
				color: '#000',
				marginRight: 20,
				float: 'right',
				marginTop: 15
			}}></Button> */}
		</Header>
	);
};

export default HeaderComponent;
