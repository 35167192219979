import React from 'react';
import { Col, Row, Typography } from 'antd';

import LoginForm from './LoginForm';

import './Student/style/ConfirmRegistration.css';
import loginBg from '../../assets/images/bg/bg-300.png';
import { ReactComponent as LoginArt } from '../../assets/images/loginArt.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const { Title } = Typography;

const Login = props => {
	return (
		<div
			className='body login-bg'
			style={{ backgroundImage: 'url(' + loginBg + ')' }}
		>
			<div>
				<Row align='middle'>
					<Col xs={24} md={10}>
						<div className='login-art' style={{ textAlign: 'center' }}>
							<LoginArt />
						</div>
					</Col>
					<Col xs={24} md={14}>
						<div className='login-form-column'>
							<div className='form-wrap' style={{ paddingTop: '5px' }}>
								<div
									className='login-logo'
									style={{
										textAlign: 'center',
										maxWidth: 200,
										margin: '0px auto'
									}}
								>
									<Logo className='logo' style={{ margin: 0 }} />
								</div>{' '}
								<Title
									className='form-title'
									level={3}
									style={{
										marginTop: '10px',
										marginBottom: '15px',
										fontWeight: 400
									}}
								>
									Admin Login
								</Title>
								<LoginForm />
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Login;
