import React, { useEffect, useState } from 'react';
import { Layout, Divider, Button, Space, Upload } from 'antd';
import { UploadOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { readString } from 'react-papaparse';

import SubHeader from '../Common/SubHeader';
import SearchStudents from './SearchStudents';
import FilterStudents from './FilterStudents';
import StudentsListTable from './StudentsListTable';
import AddStudentsModal from './AddStudentsModal';
import AddBranchModal from './AddBranchModal';
import AddSingleStudentModal from './AddSingleStudentModal';

import {
	getBranch,
	getStudent,
	allStudentsList,
	setSearchName,
	resetFilters
} from '../../stateManager/reducers/studentManagementSlice';
import { getCourses } from '../../stateManager/reducers/courseSlice';
import { addStudentsToGroup } from '../../stateManager/reducers/groupSlice';

const { Content } = Layout;

const StudentsList = props => {
	const { isGroup, courseId } = props;

	const [isAddModalVisible, toggleAddModal] = useState(false);
	const [isSingleAddModalVisible, setSingleModalVisible] = useState(false);
	const [isBranchModalVisible, setBranchModalVisible] = useState(false);
	const [selectedRowKey, setSelectedRowKey] = useState([]);
	const [selectedRow, setSelectedRow] = useState([]);
	const [showLoadMore, setShowLoadMore] = useState(true);
	const dispatch = useDispatch();
	const branchList = useSelector(state => state.studentsAdmin.branchList);
	const courseList = useSelector(state => state.courses.courseList);
	// const studentList = useSelector(state => filteredStudentList(state));
	const studentList = useSelector(allStudentsList);
	const filterSession = useSelector(state => state.studentsAdmin.filterSession);
	const filterCourse = useSelector(state => state.studentsAdmin.filterCourse);
	const filterBranch = useSelector(state => state.studentsAdmin.filterBranch);
	const searchName = useSelector(state => state.studentsAdmin.searchName);
	const searchId = useSelector(state => state.studentsAdmin.searchId);
	const searchPhone = useSelector(state => state.studentsAdmin.searchPhone);
	const filterStartDate = useSelector(
		state => state.studentsAdmin.filterStartDate
	);
	const filterEndDate = useSelector(state => state.studentsAdmin.filterEndDate);
	

	const toggleModal = () => toggleAddModal(!isAddModalVisible);
	const toggleSingleModal = () =>
		setSingleModalVisible(!isSingleAddModalVisible);
	const toggleBranchModal = () => setBranchModalVisible(!isBranchModalVisible);
	const setSelectRowForGroup = (key, row) => {
		setSelectedRowKey(key);
		setSelectedRow(row);
	};
	const handleAddStudentsToGroup = async () => {
		if (selectedRow.length <= 0 || selectedRowKey.length <= 0) {
			toastr.error('Please select student first!');
			return;
		}
		// eslint-disable-next-line
		const res = await dispatch(
			addStudentsToGroup({
				groupId: props.groupId,
				usernames: selectedRowKey,
				studentData: selectedRow
			})
		);
		setSelectedRowKey([]);
	};

	useEffect(() => {
		async function fetchStudents() {
			await dispatch(getStudent());
		}
		fetchStudents();
	}, [
		filterBranch,
		filterCourse,
		filterSession,
		searchPhone,
		searchName,
		searchId,
		filterStartDate,
		filterEndDate,
		dispatch
	]);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getBranch());
			await dispatch(getCourses());
			dispatch(resetFilters());
			if (isGroup) {
				dispatch(setSearchName({ key: 'filterCourse', value: courseId }));
			}
		}
		fetchData();
		// eslint-disable-next-line
	}, []);

	const handleAddStudetsToGroupByCSV = async file => {
		let read = new FileReader();
		read.readAsText(file);

		read.onloadend = async function () {
			const result = await readString(read.result, {
				skipEmptyLines: true
			});
			const { data } = result;
			// eslint-disable-next-line
			const res = await dispatch(
				addStudentsToGroup({ groupId: props.groupId, usernames: data[0] })
			);
		};
	};

	const handleLoadMore = async () => {
		const isLoadMore = true;
		const res = await dispatch(getStudent(isLoadMore));
		if (res && res?.payload?.data && res?.payload?.data?.length !== 100) {
			setShowLoadMore(false);
		}
	};

	return (
		<React.Fragment>
			<AddStudentsModal
				isAddModalVisible={isAddModalVisible}
				toggleAddModal={toggleModal.bind(this)}
			/>
			<AddBranchModal
				isVisible={isBranchModalVisible}
				toggleAddModal={toggleBranchModal.bind(this)}
			/>
			<AddSingleStudentModal
				isVisible={isSingleAddModalVisible}
				toggleModal={toggleSingleModal.bind(this)}
			/>

			<SubHeader headText='Students List' />
			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>Find and view all app users in this section</p>
					{isGroup && (
						<div>
							<Space>
								<Upload
									accept='.csv'
									onChange={e => {
										if (e.file.status === 'error' || e.file.status === 'done') {
											handleAddStudetsToGroupByCSV(e.file.originFileObj);
										}
									}}
									showUploadList={false}
								>
									<Button type='dashed' icon={<UploadOutlined />}>
										Upload
									</Button>
								</Upload>
								<Button
									onClick={() => handleAddStudentsToGroup()}
									type='primary'
								>
									Add
								</Button>
							</Space>
						</div>
					)}
				</div>

				<SearchStudents
					isGroup={isGroup}
					courseList={courseList}
					branchList={branchList}
				/>
				<Divider style={{ margin: '0px' }} />
				<FilterStudents
					courseList={courseList}
					branchList={branchList}
					isGroup={isGroup}
					toggleAddModal={toggleModal.bind(this)}
					toggleSingleModal={toggleSingleModal.bind(this)}
				/>

				<StudentsListTable
					data={studentList}
					isGroup={isGroup}
					selectedRowKeys={selectedRowKey}
					selectedRows={selectedRow}
					setSelectRowForGroup={setSelectRowForGroup.bind(this)}
				/>

				{showLoadMore && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignContent: 'center'
						}}
					>
						<Button onClick={() => handleLoadMore()} type='link'>
							Load More <ArrowDownOutlined />
						</Button>
					</div>
				)}
			</Content>
		</React.Fragment>
	);
};

export default StudentsList;
