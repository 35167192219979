import React, { useEffect, useState } from 'react';
import { Button, Modal, Select, Space, Tag } from 'antd';
import { assignSubjectsToGroup, getGroupSubjects } from '../../stateManager/reducers/groupSlice';
import { useDispatch, useSelector } from 'react-redux';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const AssignSubject = (props) => {
    // State for dropdowns

    const { courseId, groupId } = props;
    console.log('courseId', courseId);
    console.log('groupId', groupId);
    const [selectedSubjects, setSelectedSubjects] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(null);

    const courses = useSelector(state => state.mentor.courses);
    
    const groupSubjects = useSelector(state => state.groups.groupSubjects);
    

    console.log('groupSubjects', groupSubjects);

    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            await dispatch(getGroupSubjects({ groupId }));
        }
        const course = courses.find((course) => course._id === courseId);
        setSelectedCourse(course);
        fetchData();
    }, [groupId]);

    useEffect(() => {
        setSelectedSubjects(groupSubjects);
    }, [groupSubjects]);



console.log('selectedSubject', selectedSubjects);

    // Handle first dropdown change
    const handleFirstDropdownChange = (value) => {
        const selectedCourse = courses.find((course) => course._id === value);
        setSelectedCourse(selectedCourse);
    };

    const submit = async () => {
        console.log('selectedSubjects', selectedSubjects);
        //only subject id
        await dispatch(assignSubjectsToGroup({ groupId, courseId, subjects: selectedSubjects.map(item => item._id) }));
    }

    // Handle second dropdown change

    const handleSecondDropdownChange = (value) => {
        const selectedSubject = selectedCourse.subjects.find(
            (subject) => subject._id === value,
        );
        if (selectedSubjects.some((item) => item._id === selectedSubject._id)) {
            return;
        }
        setSelectedSubjects([...selectedSubjects, selectedSubject]);
    }

    // Remove item from selected subjects

    const removeItem = (item) => {
        setSelectedSubjects(selectedSubjects.filter((subject) => subject._id !== item._id));
    }

return (
<Space size={48} direction={'vertical'} style={{ width: '100%' }}>
<div style={{
    color     : '#EE473F',
    fontSize  : '32px',
    fontWeight: 600,
}}>{'Group Subject'}</div>
<Space size={24} direction={'vertical'} style={{ width: '100%' }}>
   
    <Select
        placeholder={'Select Course'}
        style={{ width: '100%' }}
        size={'large'}
        onChange={handleFirstDropdownChange}
        value={selectedCourse?._id}
        disabled={true}
    >
        {
            courses.map((course) => (
                <Option key={course._id} value={course._id}>
                    {course.name}
                </Option>
            ))
        }
    </Select>
    <div style={{
        border    : '1px solid #d9d9d9',
        width     : '100%',
        minHeight : '40px',
        background: 'white',
        display   : 'flex',
        alignItems: 'center',
        padding   : '8px 12px',
        flexWrap  : 'wrap',
        gap       : '8px',
    }}>
        {
            selectedCourse?.subjects && selectedCourse.subjects.length > 0 &&
            selectedCourse?.subjects
                .map(subject => (
                    <div
                        key={subject._id}
                        style={{
                            background  : '#F1F5F9',
                            padding     : '8px 16px',
                            fontSize    : '14px',
                            border      : '1px solid #CBD5E1',
                            borderRadius: '6px',
                            color       : '#EE473F',
                            cursor      : 'pointer',
                        }}
                        onClick={() => handleSecondDropdownChange(subject._id)}
                    >
                        <PlusOutlined style={{ color: '#EE473F', paddingRight: '4px' }} />
                        {subject.name}
                    </div>
                ))
        }
    </div>

    <div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '10px' }}>
        {selectedSubjects?.map((item) => (
            <Tag
                key={item._id}
                closable
                onClose={() => removeItem(item)}
                color={'#EE473F'}
                style={{
                    padding     : '8px 16px',
                    fontSize    : '14px',
                    borderRadius: '6px',
                    cursor      : 'pointer',
                }}
                closeIcon={<CloseOutlined style={{ paddingLeft: '6px', fontSize: '14px' }} />}
            >
                {item.name}
            </Tag>
        ))}
    </div>
</Space>
<div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
    <Button
        danger={true}
        type={'primary'}
        style={{
            background  : '#EE473F',
            width       : '200px',
            height      : '48px',
            borderRadius: 6,
        }}
        onClick={() => submit()}
    >{'Assign Subject'}</Button>
</div>
</Space>
);
}

export default AssignSubject;
