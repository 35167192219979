import React, { useState } from 'react';
import { Typography, Row, Col, Form, Button, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import toastr from 'toastr';
import OtpInput from 'react-otp-input';

import {
	verifyLoginRequest,
	getVerificationOtp,
	setData
} from '../../stateManager/reducers/adminAuthSlice';
import './Student/style/ConfirmRegistration.css';
import loginBg from '../../assets/images/bg/bg-300.png';
import { ReactComponent as LoginArt } from '../../assets/images/loginArt.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const { Title, Paragraph } = Typography;

const Otp = props => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [otpVaules, setOtpValues] = useState(undefined);
	const [timer, setTimer] = useState(300);
	const [isLoading, setLoading] = useState(false);
	const phone = useSelector(state => state.authAdmin.phone);
	const hash = useSelector(state => state.authAdmin.hash);
	const password = useSelector(state => state.authAdmin.password);

	const timeFuc = setTimeout(() => {
		setTimer(timer - 1);
	}, 1000);

	if (timer === 0) {
		clearTimeout(timeFuc);
	}

	const handleSubmit = async () => {
		console.log(otpVaules);
		if (!otpVaules || otpVaules.length < 6) {
			toastr.error('Please enter otp first');
			return;
		} else {
			setLoading(true);
			const otp = otpVaules;
			const data = { hash: hash, handle: `${phone}`, otp: otp };
			const res = await dispatch(verifyLoginRequest({ data }));
			setLoading(false);
		}
	};

	const handleResend = async (email = false) => {
		try {
			const data = {
				handle: phone,
				password: password,
				viaEmail: email
			};
			const res = await dispatch(getVerificationOtp({ data }));
			if (res?.payload?.status === '200') {
				setTimer(300);
			}
		} catch (error) {
			toastr.error(error.response?.data?.errors?.title);
		}
	};

	return (
		<div
			className='body login-bg'
			style={{ backgroundImage: 'url(' + loginBg + ')' }}
		>
			<div>
				<Row align='middle'>
					<Col xs={24} md={24} lg={10}>
						<div className='login-art' style={{ textAlign: 'center' }}>
							<LoginArt />
						</div>
					</Col>
					<Col xs={24} md={24} lg={14}>
						<div className='login-form-column'>
							<div className='form-wrap' style={{ paddingTop: '5px' }}>
								<div
									className='login-logo'
									style={{
										textAlign: 'center',
										maxWidth: 200,
										margin: '0px auto'
									}}
								>
									<Logo className='logo' style={{ margin: 0 }} />
								</div>
								<Title
									className='form-title'
									level={3}
									style={{
										marginTop: '10px',
										marginBottom: '15px',
										fontWeight: 400
									}}
								>
									Enter OTP
								</Title>
								<Paragraph className='form-subtitle'>
									Please type the verification code sent to{' '}
									{!!location?.state?.viaEmail ? 'your email' : `+${phone}`}
								</Paragraph>
								<Form className='login-form'>
									<Form.Item>
										<OtpInput
											value={otpVaules}
											onChange={otp => setOtpValues(otp)}
											numInputs={6}
											isInputNum={true}
											containerStyle={{
												justifyContent: 'center',
												marginBottom: 20
											}}
											inputStyle={{
												width: '3em',
												height: '3em',
												margin: '0 5px',
												borderRadius: 4,
												textAlign: 'center'
											}}
											className='otpInputWrap'
										/>
									</Form.Item>
									<Form.Item noStyle>
										<Button
											htmlType='submit'
											type='primary'
											size='large'
											onClick={() => handleSubmit()}
											loading={isLoading}
											style={{ marginTop: 20 }}
											className='login-form-button'
											disabled={!otpVaules || otpVaules.length < 6}
										>
											Verify
										</Button>
									</Form.Item>

									<Divider plain>Didn't recieve OTP?</Divider>
									<Form.Item>
										<Button
											disabled={timer > 0}
											size='large'
											type='link'
											block
											className='resend-otp'
											onClick={() => handleResend()}
										>
											Send Again {timer > 0 ? '(' + timer + 's)' : ''}
										</Button>
									</Form.Item>
									{timer === 0 && (
										<Form.Item style={{ marginBottom: 0 }}>
											<span
												style={{
													display: 'inline-block',
													textAlign: 'center',
													width: '100%'
												}}
											>
												Or
											</span>
											<span
												style={{
													display: 'inline-block',
													textAlign: 'center',
													width: '100%'
												}}
											>
												Get the verification code via{' '}
												<Button type='link' onClick={() => handleResend(true)}>
													Email
												</Button>
											</span>
										</Form.Item>
									)}
								</Form>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Otp;
