import React, { useEffect } from 'react';
import { Form, Modal, Upload, Input } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import {
	editSubjectRequest,
	updateCourseRequest
} from '../../stateManager/reducers/courseSlice';
import {
	patchFileRequest,
	signedUrl as signedUrlRequest
} from '../../stateManager/reducers/questionSlice';

const { Dragger } = Upload;

const EditCourseModal = props => {
	const { isVisible, isSubject, courseId, dataToEdit } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();

	useEffect(() => {
		form.setFieldsValue({
			name: dataToEdit?.name,
			session: dataToEdit?.session,
			description: dataToEdit?.description,
			image: dataToEdit?.image
		});
	}, [dataToEdit]);

	const handleSubmit = async values => {
		const { name, description, image } = values;

		try {
			if (image !== dataToEdit?.image) {
				const signResp = await dispatch(signedUrlRequest(image.file.type));
				var { key, signedUrl } = signResp.payload?.data;
				const patchResp = await dispatch(
					patchFileRequest({ signedUrl, file: image.file.originFileObj })
				);
			}
			if (isSubject) {
				const data = {
					name,
					description,
					image: image !== dataToEdit?.image ? key : image
				};
				const response = await dispatch(
					editSubjectRequest({
						subjectId: dataToEdit?._id,
						data
					})
				);
			} else {
				const data = {
					name,
					description,
					image: image !== dataToEdit?.image ? key : image
				};
				const res = await dispatch(
					updateCourseRequest({ courseId: dataToEdit?._id, data })
				);
			}
		} catch (error) {
			console.log(error);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			onOk={() => form.submit()}
			title={<strong>{isSubject ? 'Add Subject' : 'Add Course'} </strong>}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='name'
					required={[{ required: true }]}
					label={<strong>{isSubject ? 'Subject Name' : 'Course Title'}</strong>}
				>
					<Input
						placeholder={
							isSubject ? 'Enter Subject Name' : 'Enter Course Title'
						}
					/>
				</Form.Item>
				{/* {!isSubject && ( */}
				<Form.Item
					name='description'
					required={[{ required: true }]}
					label={
						<strong>
							{isSubject ? 'Subject Description' : 'Course Description'}
						</strong>
					}
				>
					<Input.TextArea
						placeholder={
							isSubject
								? 'Enter Subject Description'
								: 'Enter Course Description'
						}
					/>
				</Form.Item>
				{/* )} */}
				<Form.Item
					name='image'
					required={[{ required: true }]}
					label={<strong>Upload Image</strong>}
				>
					<Dragger>
						<p className='ant-upload-drag-icon'>
							<InboxOutlined />
						</p>
						<p>Drag and drop images here or click to browse</p>
					</Dragger>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditCourseModal;
