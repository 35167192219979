import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Input, Card, Button, Image, Space, InputNumber } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
	submitAnswer,
	setAnsweredQuestionCount
} from '../../../stateManager/reducers/studentAuthSlice';
import {
	patchFileRequest,
	signedUrl as signedUrlRequest
} from '../../../stateManager/reducers/questionSlice';
import { bucket_url } from '../../../constants/constString';
import { saveExtraToLocal, setUpdatedAnswer, shouldUseKatex } from '../../../constants/constFunction';
import './ShortAns.component.style.css';
import PdfPlaceholder from '../../../assets/images/pdf_placeholder.png';
import MathInput from '../../Common/MathJax';
import PictureGrid from './PictureGrid';
import QuestionsImage from './QuestionsImage';
import QuestionTitle from './QuestionTitle';
import EvaluateImageModal from './EvaluateImageModal';
import EvaluatePreview from './Sketch/EvaluatePreview';
import PdfDrawing from './Sketch/PdfDrawing';
const { Text } = Typography;
const { TextArea } = Input;

const ShortAns = props => {
	const {
		question,
		questionIndex,
		examId,
		groupId,
		isResult,
		isAssessment,
		marks,
		setMarks,
		notes,
		setNotes,
		handleSubmitMark
	} = props;

	const dispatch = useDispatch();
	const [answer, setAnswer] = useState('');
	const [fileList, setFileList] = useState([]);
	const status = useSelector(state => state.questions.status);
	const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [isEvalutaeImageModalOpen, setIsEvalutaeImageModalOpen] = useState(false)
	const [isEvaluatePreviewModalOpen, setIsEvaluatePreviewModalOpen] = useState(false)
	const [evalutaeImage, setEvalutaeImage] = useState("")
	const [imageKey, setImageKey] = useState({})
	const [previewImageKey, setPreviewImageKey] = useState('')
	const [evaluatedExtra, setExtra] = useState({})
	const [pdfInfo, setPdfInfo] = useState(null)
	const [evaluatePDF, setEvaluatePDF] = useState(false)

	useEffect(() => {
		const localData = localStorage.getItem('localSavedAnswers');
		if (localData) {
			const localSavedAnswers = JSON.parse(localData);
			const ans = localSavedAnswers[examId]?.[question._id]?.answer?.text;
			const files =
				localSavedAnswers[examId]?.[question._id]?.answer?.fileList || [];
			setAnswer(ans);
			setFileList(files);
		}
		if (question?.extra?.length > 0) {
			const extra = saveExtraToLocal(question)
			const _evaluatedExtra = setUpdatedAnswer(extra, question?.questionId)
			setExtra(_evaluatedExtra)
		}

	}, [question]);

	const saveToLocalStorage = offline => {
		const localData = localStorage.getItem('localSavedAnswers');
		let isPrevious = false;
		if (localData) {
			const localSavedAnswers = JSON.parse(localData);
			isPrevious = !!localSavedAnswers[examId];
		}
		if (isPrevious) {
			const localSavedAnswers = JSON.parse(localData);
			localSavedAnswers[examId][question._id] = {
				answer: { text: answer, fileList: fileList },
				offline: offline
			};
			localStorage.setItem(
				'localSavedAnswers',
				JSON.stringify(localSavedAnswers)
			);
		} else {
			const localSavedAnswers = {
				[examId]: {
					[question._id]: {
						answer: { text: answer, fileList: fileList },
						offline: offline
					}
				}
			};
			localStorage.setItem(
				'localSavedAnswers',
				JSON.stringify(localSavedAnswers)
			);
		}
	};

	const handleSubmitAnswer = async ({ attachments }) => {
		const data = {
			questionId: question._id,
			answer: answer,
			extra: attachments
		};
		const res = await dispatch(submitAnswer({ groupId, examId, data }));
		setIsSubmittingAnswer(false);
		if (res[0]?.error?.message === 'Network Error') {
			saveToLocalStorage(true);
		} else {
			saveToLocalStorage(false);
		}
	};

	const onFileChange = ({ fileList }) => {
		const files = fileList?.fileList || fileList;
		setFileList(files);
	};

	const handleFileUpload = async () => {
		setIsSubmittingAnswer(true);
		dispatch(setAnsweredQuestionCount(question?._id));
		if (fileList.length <= 0) {
			await handleSubmitAnswer({});
			return;
		}
		try {
			const signedPromise = fileList.map(item =>
				dispatch(signedUrlRequest(item.type))
			);
			const res = await Promise.all(signedPromise);
			if (res[0]?.error?.message === 'Network Error') {
				saveToLocalStorage(true);
			}
			const patchPromises = fileList.map((item, index) =>
				dispatch(
					patchFileRequest({
						signedUrl: res[index]?.payload?.data?.signedUrl,
						file: item.originFileObj
					})
				)
			);
			const res2 = await Promise.all(patchPromises);
			const attachments = fileList.map((item, index) => {
				const { signedUrl, key } = res[index]?.payload?.data;
				return { name: item.name, key, type: item.type };
			});
			await handleSubmitAnswer({ attachments });
		} catch (error) {
			setIsSubmittingAnswer(false);
		}
	};

	const questionTitle = () => {
		let title = '';
		if (isResult) {
			title = question?.questionId?.title || '';
		} else if (isAssessment) {
			title = question?.question?.title || '';
		} else {
			title = question?.title || '';
		}

		return shouldUseKatex(title) > -1 ? <MathInput mathItem={title} /> : title;
	};

	const toggleEvalutateImageModal = (img, evaluatedImg) => {
		if (!!img) {
			setImageKey({ key: img, questionId: question?.questionId })
			setEvalutaeImage(bucket_url + (evaluatedImg || img));
		}
		setIsEvalutaeImageModalOpen(!isEvalutaeImageModalOpen);
	}

	const toggleEvalutatePdfModal = (key, name, index) => {

		const localData = localStorage.getItem('extra');
		let info ={ name: name, key: key, questionId: question?.questionId, link: bucket_url + key}
		// if(localData)
		// 	{
		// 		const extra = JSON.parse(localData);
		// 		if (extra && extra[question?.questionId]) {
		// 			console.log("loaded from toggleEvalutatePdfModal", extra[question?.questionId]);
		// 			info.key = extra[question?.questionId][index].key;
		// 			info.name = extra[question?.questionId][index].name;
		// 			info.link = bucket_url + extra[question?.questionId][index].key;
		// 		}
		// 	}
			
		
		setPdfInfo(info)
		setEvaluatePDF(!evaluatePDF)

	}

	const togglePreviewImage = (key) => {
		if (!!key) {
			setPreviewImageKey(key)
		}
		setIsEvaluatePreviewModalOpen(!isEvaluatePreviewModalOpen)

	}

	const readLocalData = (extra) => {
		const _evaluatedExtra = setUpdatedAnswer(extra, question?.questionId)
		setExtra(_evaluatedExtra)
	}


	const uploadButton = (
		<div>
			{status === 'loading' ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);
	return (
		<>
			{!!isEvalutaeImageModalOpen && <EvaluateImageModal toggleModal={toggleEvalutateImageModal.bind(this)} visible={isEvalutaeImageModalOpen} image={evalutaeImage} imageKey={imageKey} readLocalData={readLocalData} />}
			{!!isEvaluatePreviewModalOpen && <EvaluatePreview toggleModal={togglePreviewImage.bind(this)} imageKey={previewImageKey} />}
			{!!evaluatePDF && pdfInfo && <PdfDrawing visible={evaluatePDF} setVisible={setEvaluatePDF} pdfInfo={pdfInfo} readLocalData={readLocalData}/>}
			<Row
				style={{
					marginBottom: 30,
					paddingBottom: 30,
					background: '#ffffff'
				}}
			>
				<Col xs={24} className='questions-wrapper'>
					<Card className='paragraph-que' bordered={false} style={{ width: '100%' }}>
						<QuestionTitle
							title={questionTitle()}
							marks={isResult ? question?.marks || '0' : question?.point}
							questionIndex={questionIndex}
						/>
						{!isAssessment &&
							!isResult &&
							(question?.image || question?.file) &&
							(question?.image?.length > 0 || question?.file?.length > 0) && (
								<QuestionsImage question={question} />
							)}
						{!isAssessment &&
							isResult &&
							(question?.questionId?.image || question?.questionId?.file) &&
							(question?.questionId?.image?.length > 0 ||
								question?.questionId?.file?.length > 0) && (
								<Row style={{ marginTop: 30, marginBottom: 30 }}>
									<Col xs={23} md={23} offset={1}>
										<Space>
											{question?.questionId?.image &&
												question?.questionId?.image.map(item => (
													<Image placeholder src={bucket_url + item} width={'100%'} />
												))}
											{!!question &&
												question?.questionId?.file &&
												question.questionId?.file.map(item => (
													<a
														href={bucket_url + item}
														target='_blank'
														rel='noreferrer'
													>
														<Image
															placeholder={<Image src={PdfPlaceholder} />}
															src={bucket_url + item}
															fallback={PdfPlaceholder}
															width={'100%'}
															style={{ objectFit: 'contain' }}
														/>
													</a>
												))}
										</Space>
									</Col>
								</Row>
							)}
						{isAssessment &&
							(question?.question?.image || question?.question?.file) &&
							(question?.question?.image?.length > 0 ||
								question?.question?.file?.length > 0) && (
								<Row style={{ marginTop: 30, marginBottom: 30 }}>
									<Col xs={23} md={23} offset={1}>
										<Space>
											{question?.question?.image &&
												question?.question?.image.map(item => (
													<Image
														placeholder
														src={bucket_url + item}
														width={'100%'}
														style={{ objectFit: 'contain' }}
													/>
												))}
											{!!question &&
												question?.question?.file &&
												question.question?.file.map(item => (
													<a
														href={bucket_url + item}
														target='_blank'
														rel='noreferrer'
														onClick={
															() => console.log("clicked")
														}
													>
														<Image
															placeholder={<Image src={PdfPlaceholder} />}
															src={bucket_url + item}
															fallback={PdfPlaceholder}
															width={'100%'}
															style={{ objectFit: 'contain' }}
														/>
													</a>
												))}
										</Space>
									</Col>
								</Row>
							)}
						<Row>
							<Col xs={23} md={20} offset={1}>
								{(isAssessment || isResult) && (
									<React.Fragment>
										<Text>Answer: </Text>
										<Text>{question?.answer}</Text>
									</React.Fragment>
								)}
								{!isAssessment && !isResult && (
									<Text type='secondary'>Your Answer</Text>
								)}
								{!isAssessment && !isResult && (
									<TextArea
										value={answer}
										disabled={question.result}
										onChange={e => {
											setAnswer(e.target.value);
										}}
										placeholder=''
										autoSize={{ minRows: 3, maxRows: 5 }}
									/>
								)}
							</Col>
						</Row>
						{!isResult && !isAssessment && (
							<Row style={{ marginTop: 30 }}>
								<Col xs={23} md={20} offset={1}>
									<PictureGrid
										listType='picture-card'
										onFileChange={onFileChange}
										fileList={fileList}
										multiple={true}
									>
										{uploadButton}
									</PictureGrid>
								</Col>
							</Row>
						)}
						{(isAssessment || isResult) &&
							question?.extra &&
							question?.extra?.length > 0 && (
								<Row style={{ marginTop: 30, marginBottom: 30 }}>
									<Col xs={23} md={23} offset={1}>
										<Space className=' align-start flex-wrap'>
											{question.extra.map((item, index) =>
												item.type.includes('image') ? (
													<div className='d-flex flex-column align-center flex-wrap'>
														<Image
															placeholder
															src={bucket_url + item.key}
															width={'120px'}
															height={'120px'}
															style={{ objectFit: 'contain' }}
														/>
														<Button
															type='primary'
															className='mt-2'
															size='small'
															onClick={() => {
																console.log("toggleEvalutateImageModal", item);
																toggleEvalutateImageModal(item.key, item?.evaluatedKey)
															} }
														>Evaluate</Button>
														{(item?.evaluatedKey || (evaluatedExtra[item?.key]?.evaluatedKey && evaluatedExtra[item?.key]?.key === item.key)) &&

															<>
																<Button
																	type='primary'
																	className='mt-2'
																	size='small'
																	onClick={() => 
																		{
																			togglePreviewImage(evaluatedExtra[item?.key]?.evaluatedKey)
																		}
																		}
																>
																	View
																</Button>
																{/* <EvaluatePreview item={evaluatedExtra[item?.key]} /> */}
															</>}
													</div>
												) : (
													<div className='d-flex flex-column align-center flex-wrap'>
														<a
														href={`${bucket_url}${item.key}`}
														target='_blank'
														rel='noreferrer'
													>
														<img
														placeholder
														src="/pdf.png"
														width={'120px'}
														height={'120px'}
														style={{ objectFit: 'contain' }}
														
													/>
													</a>
													<Button
															type='primary'
															className='mt-2'
															size='small'
															onClick={() => {
																console.log("toggleEvalutateImageModal", item);
																toggleEvalutatePdfModal(item.key, item?.name, index)
																}}
														>Evaluate</Button>
													</div>
													
												)
											)}
										</Space>
									</Col>
								</Row>
							)}

						{/* Explanation & Notes Section Start */}
						<Row>
							{isResult &&
								(question?.questionId?.explanation ||
									question?.questionId?.explanationExt) && (
									<Col xs={24} md={24} style={{ marginTop: 10 }}>
										<Card className='explanation-card' bordered>
											<Text strong>Explanation</Text>

											<p style={{ marginBottom: 0 }}>
												{question?.questionId?.explanation &&
													shouldUseKatex(question?.questionId?.explanation) > -1 ? (
													<MathInput mathItem={question?.questionId?.explanation} />
												) : (
													question?.questionId?.explanation
												)}
											</p>
											<Row>
												<Col xs={24} md={24}>
													<Space>
														{!!question?.questionId &&
															question?.questionId?.explanationExt &&
															question?.questionId?.explanationExt.map(item => (
																<a href={bucket_url + item} target='_blank'>
																	<Image
																		preview={false}
																		placeholder
																		src={bucket_url + item}
																		width={'100%'}
																		style={{ objectFit: 'contain' }}
																		fallback={PdfPlaceholder}
																	/>
																</a>
															))}
													</Space>
												</Col>
											</Row>
										</Card>
									</Col>
								)}

							{isResult && question?.notes && (
								<Col xs={24} md={24} style={{ marginTop: 10 }}>
									<Card className='notes-card' bordered>
										<Text strong>Notes</Text>

										<p style={{ marginBottom: 0 }}>
											{question?.notes && shouldUseKatex(question?.notes) > -1 ? (
												<MathInput mathItem={question?.notes} />
											) : (
												question?.notes
											)}
										</p>
									</Card>
								</Col>
							)}
						</Row>
						{/* Explanation & Notes Section end */}

						{!isResult && !isAssessment && (
							<Row style={{ marginTop: 30 }}>
								<Col xs={23} md={20} offset={1}>
									<Button
										loading={isSubmittingAnswer === 'loading' || status === 'loading'}
										onClick={() => handleFileUpload()}
									>
										Submit Answer
									</Button>
								</Col>
							</Row>
						)}
					</Card>
				</Col>
				<Col xs={23} offset={1} style={{ padding: '15px 30px' }}>
					<Text>Marks: </Text>
					<InputNumber
						min={0}
						type='number'
						style={{ margin: '0 10px' }}
						value={marks[question.questionId]}
						onChange={value => {
							let temp = { ...marks };
							temp[question.questionId] = value;
							setMarks(temp);
						}}
					/>
					<Text>out of {question?.point}</Text>
				</Col>
				<Col xs={23} offset={1} style={{ padding: '15px 30px' }}>
					<TextArea
						placeholder='Add Note'
						autoSize={{ minRows: 3, maxRows: 4 }}
						value={notes[question.questionId]}
						onChange={e => {
							let temp = { ...notes };
							temp[question.questionId] = e.target.value;
							setNotes(temp);
						}}
					/>
				</Col>
				<Col xs={23} offset={1} style={{ padding: '15px 30px' }}>
					<Button
						type='primary'
						onClick={() =>
							handleSubmitMark({ questionId: question.questionId, point: question.point, setIsSubmitted })
						}
					>
						{isSubmitted ? 'Submitted' : 'Submit Mark'}
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default ShortAns;
