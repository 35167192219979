import React from 'react';
import { Typography } from 'antd';

const { Title, Text } = Typography;

const DashboardCounter = props => {
	const { isTotal, label, subTitle, value } = props;

	return (
		<div className='dashboard-counter'>
			<Title level={5} style={{ textAlign: 'center', marginBottom: 0 }}>
				{label}
			</Title>
			{!isTotal && <Text>{subTitle}</Text>}
			<Title style={{ textAlign: 'center' }}>{value}</Title>
		</div>
	);
};

export default DashboardCounter;
