import React from 'react';
import { Form, Modal, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	addLectureBySubject,
	addChapterBySubject,
	addQuestionSolveBySubject
} from '../../stateManager/reducers/courseSlice';

const AddLectureModal = props => {
	const { isVisible, subjectId, isLecture, isEdit, type } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const status = useSelector(state => state.courses.status);

	const handleSubmit = async values => {
		const { name, description } = values;
		if (type === 'lecture') {
			const response = await dispatch(
				addLectureBySubject({ name, subjectId, description })
			);
		} else if (type === 'chapter') {
			const response = await dispatch(
				addChapterBySubject({ name, subjectId, description })
			);
		} else {
			const response = await dispatch(
				addQuestionSolveBySubject({ name, subjectId, description })
			);
		}

		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			centered
			destroyOnClose
			maskClosable={false}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			title={
				<strong>
					{type === 'lecture'
						? 'Add Lecture'
						: type === 'chapter'
						? 'Add Chapter'
						: 'Add Question Solve'}{' '}
				</strong>
			}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					name='name'
					label={
						<strong>
							{type === 'lecture'
								? 'Lecture Name'
								: type === 'chapter'
								? 'Chapter Title'
								: 'Question Name'}
						</strong>
					}
				>
					<Input
						placeholder={
							type === 'lecture'
								? 'Enter Lecture Name'
								: type === 'chapter'
								? 'Enter Chapter Title'
								: 'Enter Question Name'
						}
					/>
				</Form.Item>
				<Form.Item
					name='description'
					label={
						<strong>
							{type === 'lecture'
								? 'Lecture Description'
								: type === 'chapter'
								? 'Chapter Description'
								: 'Question Description'}
						</strong>
					}
				>
					<Input.TextArea
						placeholder={
							type === 'lecture'
								? 'Enter Lecture Description'
								: type === 'chapter'
								? 'Enter Chapter Description'
								: 'Enter Question Description'
						}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default AddLectureModal;
