import React from 'react';
import { Form, Input, Modal, Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { Switch } from 'antd';
import {
	createExamRequest,
	clearUnsavedQuestions,
	clearQuestionForExam
} from '../../stateManager/reducers/examSlice';
import { resetQuestionToInitial } from '../../stateManager/reducers/questionSlice';

const layout = {
	labelCol: { span: 10 },
	wrapperCol: { span: 14 }
};

const CreateExamModal = props => {
	const { isVisible, courseId, title, instruction, subjectId, segmentedExamDetails, isSegmentedExam } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const questions = useSelector(state =>
		state.exams.questionsForExam.map(item => ({
			question: item._id,
			point: Number(item.point) || undefined
		}))
	);
	const status = useSelector(state => state.exams.status);

	const handleCreateExam = async values => {
		if (!title) {
			toastr.error('Please enter a valid title!');
			return;
		}
		let totalMarks = questions.reduce((total, item) => {
			const value = item.point
				? Number(item.point)
				: Number(values.globalPoint);
			return total + value;
		}, 0);

		if(isSegmentedExam){
			totalMarks = values.globalPoint * segmentedExamDetails.totalQuestions;
		}
		
		const data = {
			title: title,
			instruction: instruction.toString('html'),
			courseId: courseId,
			subjectId,
			shuffle: values.shuffle,
			isPracticeExam: values.isPracticeExam,
			passMark: parseFloat(values.passMark),
			globalPoint: parseFloat(values.globalPoint),
			negativeMarkPerQuestion: parseFloat(values.negativeMarkPerQuestion),
			totalMarks: totalMarks || values.globalPoint * questions.length,
			isSegmentedExam: isSegmentedExam,
			segmentedExamDetails: isSegmentedExam ? segmentedExamDetails : undefined,
			// multipleTimesSubmission: values.multipleTimesSubmission,
			questions: questions
		};

		try {
			const res = await dispatch(createExamRequest(data));
			dispatch(clearUnsavedQuestions());
			dispatch(resetQuestionToInitial());
			dispatch(clearQuestionForExam());
			if (res?.payload?.data?.status === '200') {
				dispatch(clearUnsavedQuestions());
				dispatch(resetQuestionToInitial());
				toastr.success('Exam created successfully!');
			}
		} catch (error) {
			console.log(error);
		}
		props.toggleModal();
	};

	return (
		<Modal
			visible={isVisible}
			maskClosable={false}
			destroyOnClose
			onCancel={() => props.toggleModal()}
			okButtonProps={{ loading: status === 'loading' }}
			onOk={() => form.submit()}
			okText='Create Exam'
		>
			<Form
				{...layout}
				form={form}
				initialValues={{ isPracticeExam: true, shuffle: true}}
				layout='horizontal'
				onFinish={handleCreateExam}
			>
				<Form.Item name='isPracticeExam'>
					<Radio.Group
						options={[
							{ label: 'Live', value: false },
							{ label: 'Practice', value: true }
						]}
						value={form.getFieldValue('isPracticeExam')}
						defaultValue={true}
						optionType='button'
						buttonStyle='solid'
					/>
				</Form.Item>
				<Form.Item
					name='passMark'
					label='Pass Mark'
					rules={[
						{ required: true, message: 'Please give pass in percentage' }
					]}
				>
					<Input placeholder='Enter percent of pass mark' addonAfter='%' />
				</Form.Item>
				<Form.Item
					name='negativeMarkPerQuestion'
					label='Negative Mark Per Question'
					rules={[
						{
							required: true,
							message: 'Please enter negative mark per question'
						},
						{
							validator: (_, value) => {
							  if (value && parseFloat(value) > 1) {
								return Promise.reject("Negative mark cannot be more than 1!");
							  }
							  return Promise.resolve();
							},
						  },
					]}
				>
					<Input placeholder='Enter negative mark per question' />
				</Form.Item>
				<Form.Item
					name='globalPoint'
					label='Global Points'
					rules={[{ required: true, message: 'Please enter a global point' }]}
				>
					<Input placeholder='Enter global points' type='number' />
				</Form.Item>
				<Form.Item
					name='shuffle'
					label='Shuffle Questions'
					defaultValue={true}
				>
					 <Switch defaultChecked/>
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default CreateExamModal;
