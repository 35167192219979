import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Form,
	Input,
	Select,
	Button,
	PageHeader,
	Switch,
	Card,
	Layout,
	Tabs,
	List,
	Typography,
	Space
} from 'antd';
import {
	SaveOutlined,
	SendOutlined,
	CloseOutlined,
	CheckOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import { sessions } from '../../../../constants/constString';
import { getCourses } from '../../../../stateManager/reducers/courseSlice';
import {
	createNotification,
	getSmsGateway,
	changeSmsGateway
} from '../../../../stateManager/reducers/notificationSlice';
import {
	getGroup,
	getStudentsByGroup
} from '../../../../stateManager/reducers/groupSlice';
import './NotificationPanel.page.style.css';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Paragraph } = Typography;
const { Option } = Select;

const NotificationPanel = props => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [sendToGroup, setSendToGroup] = useState(true);
	const [requiredMark, setRequiredMarkType] = useState('optional');
	const [groupSelected, setGroupSelected] = useState([]);
	const [selectedCourse, setSelectedCourse] = useState(undefined);
	const [selectedSession, setSelectedSession] = useState(undefined);
	const [selectedStudents, setSeletedStudents] = useState([]);
	const [isSendSMS, setSendSMS] = useState(true);
	const [message, setMessage] = useState(undefined);
	const [title, setTitle] = useState(undefined);
	const [draftNotificationList, setDrafNotificationList] = useState([]);
	const [historyNotificationList, setHistoryNotificationList] = useState([]);
	const [isGatewayLoading, setGatewayLoading] = useState(false);
	const [sendToGuardian, setSendToGuardian] = useState(false);
	const courseList = useSelector(state => state.courses.courseList);
	const grouopList = useSelector(state => state.groups.groupList);
	const selectedGateway = useSelector(
		state => state.notifications.selectedGateway
	);

	useEffect(() => {
		const drafts = localStorage.getItem('draftNotification')
			? JSON.parse(localStorage.getItem('draftNotification'))
			: [];
		setDrafNotificationList(drafts);
		const histroyNotification = localStorage.getItem('historyNotification')
			? JSON.parse(localStorage.getItem('historyNotification'))
			: [];
		setHistoryNotificationList(histroyNotification);
	}, []);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getCourses());
			await dispatch(getSmsGateway());
		}
		fetchData();
	}, []);

	const handleSearchGroup = async () => {
		try {
			const res = await dispatch(
				getGroup({ session: selectedSession, courseId: selectedCourse })
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSendNotification = async () => {
		if (!message) {
			toastr.error('Please give a valid message');
			return;
		}
		if (sendToGroup && groupSelected.length <= 0) {
			toastr.error('Please select a group');
			return;
		}
		if (!sendToGroup && selectedStudents.length <= 0) {
			toastr.error('Please select a student');
			return;
		}
		if (sendToGroup) {
			const promise = groupSelected.map(item =>
				dispatch(getStudentsByGroup({ groupId: item }))
			);
			try {
				const res = await Promise.all(promise);
				let students = [];
				let studnetsPhones = [];
				
				const arr = res.map(item => {
					const data = item?.payload?.data;
					data.map(el => {
						students.push(el._id);
						studnetsPhones.push(el.username);		
					});
				});
				const data = {
					message,
					type: 'notice',
					info: {
						title
					},
					students: studnetsPhones,
					sms: isSendSMS ? studnetsPhones : undefined,
					sendToGuardian: sendToGuardian
				};
				console.log('res', data);
				const response = await dispatch(createNotification({ data }));
				handleSaveHistory();
			} catch (error) {
				console.log(error);
			}
		} else {
			try {
				const data = {
					message,
					type: 'notice',
					info: {
						title
					},
					students: selectedStudents,
					sms: isSendSMS ? selectedStudents : undefined,
					sendToGuardian: sendToGuardian
					
				};
				const response = await dispatch(createNotification({ data }));
				handleSaveHistory();
			} catch (error) {
				console.log(error);
			}
		}
	};

	const onRequiredTypeChange = ({ requiredMark }) => {
		setRequiredMarkType(requiredMark);
	};

	const receiverTypeChange = value => {
		setSendToGroup(!sendToGroup);
	};
	const addStudentReciever = value => {
		setSeletedStudents(value);
	};

	const handleChangeSmsGateway = async () => {
		setGatewayLoading(true);
		const gateway = selectedGateway === 'metro' ? 'reve' : 'metro';
		try {
			const data = { gateway };
			await dispatch(changeSmsGateway({ data }));
			setGatewayLoading(false);
		} catch (error) {
			console.log(error);
			setGatewayLoading(false);
		}
	};

	const handleSaveHistory = () => {
		const notificationList = localStorage.getItem('historyNotification');
		const data = { title, message };
		if (notificationList) {
			const notifications = JSON.parse(notificationList);
			const payload = [data, ...notifications];
			localStorage.setItem('historyNotification', JSON.stringify(payload));
		} else {
			const payload = [data];
			localStorage.setItem('historyNotification', JSON.stringify(payload));
		}
		const temp = [data, ...historyNotificationList];
		setHistoryNotificationList(temp);
	};

	const handleSaveDraft = () => {
		const notificationList = localStorage.getItem('draftNotification');
		const data = { title, message };
		if (notificationList) {
			const notifications = JSON.parse(notificationList);
			const payload = [data, ...notifications];
			localStorage.setItem('draftNotification', JSON.stringify(payload));
		} else {
			const payload = [data];
			localStorage.setItem('draftNotification', JSON.stringify(payload));
		}
		const temp = [data, ...draftNotificationList];
		setDrafNotificationList(temp);
	};

	return (
		<div className='main-content-wrap'>
			<PageHeader
				className='site-page-header'
				backIcon={false}
				title='Notification'
			/>
			,
			<Row
				className='notification-panel-wrap'
				style={{ padding: '0 25px' }}
				gutter={[15, 15]}
			>
				<Col xs={24} md={12}>
					<Card style={{ maxWidth: '600px' }}>
						<Content>
							<Form
								className='notification-create-form'
								form={form}
								layout='vertical'
								onValuesChange={onRequiredTypeChange}
							>
								<Form.Item label='Title' required tooltip='Notification Title'>
									<Input
										value={title}
										onChange={e => setTitle(e.target.value)}
										placeholder='input placeholder'
									/>
								</Form.Item>
								<Form.Item
									label='Message'
									required
									tooltip='Notification Message'
								>
									<Input.TextArea
										value={message}
										onChange={e => setMessage(e.target.value)}
										autoSize={{ minRows: 4, maxRows: 6 }}
									/>
								</Form.Item>
								<Form.Item
									label='Send to'
									required
									tooltip='You can send notification to Groups or Individual Students'
								>
									<span style={{ marginRight: 15 }}>Receiver Type.</span>
									<Switch
										className='receiver-type'
										checkedChildren='Groups'
										unCheckedChildren='Students'
										defaultChecked
										onChange={receiverTypeChange}
									/>
								</Form.Item>
								<Form.Item
									label='Send to Guardian'
									required
									tooltip='You can send notification to Guardian of the students'
								>
									
									<Switch
										className='receiver-type'
										checkedChildren='Yes'
										unCheckedChildren='No'
										defaultChecked = {false}
										onChange={(e)=>{
											console.log('send to guardian')
											setSendToGuardian(e)
										
										}}
										
									/>
								</Form.Item>
								{sendToGroup && (
									<React.Fragment>
										<Row gutter={([8], [8])}>
											<Col span={10}>
												<Select
													onChange={value => setSelectedSession(value)}
													style={{ width: '100%' }}
													placeholder='Session'
												>
													{sessions.map((item, index) => (
														<Option key={index} value={item}>
															{item}
														</Option>
													))}
												</Select>
											</Col>
											<Col span={10}>
												<Select
													onChange={value => setSelectedCourse(value)}
													style={{ width: '100%' }}
													placeholder='Course'
												>
													{courseList.map((item, index) => (
														<Option key={index} value={item._id}>
															{item.name}
														</Option>
													))}
												</Select>
											</Col>
											<Col>
												<Button onClick={() => handleSearchGroup()}>
													Search
												</Button>
											</Col>
										</Row>
										<Form.Item>
											<Select
												style={{ marginTop: 10 }}
												mode='multiple'
												allowClear
												onChange={value => {
													setGroupSelected(value);
												}}
												placeholder='groups'
												// labelInValue
												showSearch
												optionFilterProp='children'
												filterOption={(input, option) =>
													option.props.children
														.toLowerCase()
														.indexOf(input.toLowerCase()) >= 0
												}
												defaultValue={[]}
											>
												{grouopList.map((item, index) => (
													<Option value={item._id} key={index}>
														{item.name}
													</Option>
												))}
											</Select>
										</Form.Item>
									</React.Fragment>
								)}
								{!sendToGroup && (
									<Form.Item required>
										<Select
											mode='tags'
											style={{ width: '100%', maxWidth: '552px' }}
											// autoClearSearchValue={false}
											placeholder='Student Username'
											onChange={addStudentReciever}
										/>
									</Form.Item>
								)}

								<Row style={{ marginTop: '120px' }}>
									<Col xs={24} md={12}>
										<Form.Item>
											<Switch
												checkedChildren={<CheckOutlined />}
												unCheckedChildren={<CloseOutlined />}
												defaultChecked
												onChange={checked => setSendSMS(checked)}
												style={{ marginRight: 15 }}
											/>
											<span>Also send SMS notification.</span>
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item style={{ textAlign: 'right' }}>
											<Space>
												<Button
													type='default'
													icon={<SaveOutlined />}
													onClick={() => handleSaveDraft()}
												>
													Save Draft
												</Button>
												<Button
													onClick={() => handleSendNotification()}
													type='primary'
													icon={<SendOutlined />}
												>
													Send
												</Button>
											</Space>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</Content>
					</Card>
					<Card title='Change SMS Gateway' style={{ marginTop: '1em' }}>
						<Switch
							checkedChildren={'Reve'}
							unCheckedChildren={'Metro'}
							checked={selectedGateway === 'reve'}
							onChange={() => handleChangeSmsGateway()}
							style={{ marginRight: 15 }}
							loading={isGatewayLoading}
						/>
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card style={{ width: 'auto' }}>
						<Content>
							<Tabs defaultActiveKey='1'>
								<TabPane tab='History' key='history'>
									<List
										bordered
										className='demo-loadmore-list'
										itemLayout='horizontal'
										dataSource={historyNotificationList}
										pagination={{
											onChange: page => {
												console.log(page);
											},
											pageSize: 6
										}}
										style={{ cursor: 'pointer' }}
										renderItem={item => (
											<List.Item
												onClick={() => {
													setTitle(item?.title);
													setMessage(item?.message);
												}}
											>
												<List.Item.Meta
													className='notification-panel-meta'
													title={item.title}
													description={
														<Paragraph
															ellipsis={{
																rows: 1,
																expandable: true,
																symbol: 'more'
															}}
														>
															{item.message}
														</Paragraph>
													}
													style={{ paddingRight: 15 }}
												/>
												{/* <div style={{ textAlign: 'center' }}>
													Reach: 0 / 100
												</div> */}
											</List.Item>
										)}
									/>
								</TabPane>
								<TabPane tab='Draft' key='draft'>
									<List
										bordered
										className='demo-loadmore-list'
										itemLayout='horizontal'
										dataSource={draftNotificationList}
										pagination={{
											onChange: page => {
												console.log(page);
											},
											pageSize: 6
										}}
										renderItem={item => (
											<List.Item
												actions={
													item.title && [
														<Button
															onClick={() => {
																setTitle(item?.title);
																setMessage(item?.message);
															}}
															type='primary'
															key='list-loadmore-edit'
														>
															Edit
														</Button>
													]
												}
											>
												<List.Item.Meta
													className='notification-panel-meta'
													title={item.title}
													description={
														<Paragraph
															ellipsis={{
																rows: 1,
																expandable: true,
																symbol: 'more'
															}}
														>
															{item.message}
														</Paragraph>
													}
													style={{ paddingRight: 15 }}
												/>
											</List.Item>
										)}
									/>
								</TabPane>
							</Tabs>
						</Content>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default NotificationPanel;
