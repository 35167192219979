import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { instance } from '../../constants/constString';
import toastr from 'toastr';

const initialState = {
	notificationList: [],
	selectedGateway: undefined,
	status: 'idle',
	error: null
};

export const createNotification = createAsyncThunk(
	'notification/create',
	async ({ data }) => {
		try {
			const response = await instance.post('/notification', data);
			if (response?.status === 200) {
				toastr.success('Notifications sent successfully to selected groups');
			}
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const getSmsGateway = createAsyncThunk(
	'notification/getSmsGateway',
	async () => {
		try {
			const response = await instance.get('/gateway/sms');
			return response.data;
		} catch (error) {
			return Promise.reject(error);
		}
	}
);

export const changeSmsGateway = createAsyncThunk(
	'notification/changeSmsGateway',
	async ({ data }) => {
		try {
			const response = await instance.put('/gateway/sms', data);
			return response.data;
		} catch (error) {
			if (error?.response?.data?.errors?.title) {
				toastr.error(error?.response?.data?.errors?.title);
			}
			return Promise.reject(error);
		}
	}
);

const slice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		getNotifications: (state, action) => {
			return state.notificationList;
		}
	},
	extraReducers: {
		[getSmsGateway.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getSmsGateway.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			const data = action?.payload?.data;
			state.selectedGateway = data?.gateway;
		},
		[getSmsGateway.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[changeSmsGateway.pending]: (state, action) => {
			state.status = 'loading';
		},
		[changeSmsGateway.fulfilled]: (state, action) => {
			state.status = 'succeeded';
			const data = action?.payload?.data;
			state.selectedGateway = data?.gateway;
		},
		[changeSmsGateway.rejected]: (state, action) => {
			state.status = 'failed';
		}
	}
});

export default slice.reducer;
