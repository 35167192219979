import React, { useEffect, useState } from 'react';
import { Row, Layout, Button, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Spinner from '../Common/Spinner';
import SubHeader from '../Common/SubHeader';
import CourseCard from './CourseCard';
import AddCourseModal from './AddCourseModal';
import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';

const { Content } = Layout;

const SubjectList = props => {
	const [isAddSubjectModalVisible, setAddSubjectModalVisible] = useState(false);
	const dispatch = useDispatch();
	const { courseId } = props.match.params;
	const subjectList = useSelector(state => state.courses.subjectList);
	const stateStatus = useSelector(state => state.courses.status);

	const selectedCourse = JSON.parse(localStorage.getItem('selectedCourse'));

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}
		fetchData();
	}, [courseId]);

	const toggleAddModal = () =>
		setAddSubjectModalVisible(!isAddSubjectModalVisible);

	if (stateStatus === 'loading') {
		return <Spinner />;
	}

	return (
		<React.Fragment>
			<AddCourseModal
				isVisible={isAddSubjectModalVisible}
				toggleModal={toggleAddModal.bind(this)}
				courseId={courseId}
				isSubject={true}
			/>

			<SubHeader
				headText='Subjects'
				subTitle={selectedCourse?.name}
				backIcon={true}
				backTo='/courses'
			/>
			<div
				className='site-layout-background'
				style={{
					padding: 15,
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<p>Add, view and edit chapters & topics all in one place</p>
				<Button
					onClick={() => setAddSubjectModalVisible(!isAddSubjectModalVisible)}
					type='primary'
					size='middle'
				>
					Add
				</Button>
			</div>

			<Content>
				<Row
					gutter={[16, { xs: 8, sm: 2, md: 16, lg: 16 }]}
					className='single-row-container'
				>
					{subjectList.map(item => (
						<Col xs={24} sm={24} md={6} lg={6}>
							<CourseCard data={item} isSubject={true} from='subject' />
						</Col>
					))}
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default SubjectList;
