import React from 'react';
import { Pie } from '@ant-design/charts';

const PieChart = props => {
	const { data, style, radius } = props;

	const config = {
		appendPadding: 10,
		data: data,
		angleField: 'value',
		colorField: 'type',
		radius: radius,
		label: {
			type: 'outer',
			content: '{percentage} {name}'
		},
		interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }]
	};

	return (
		<div className='pie-chart'>
			<Pie {...config} style={style} />
		</div>
	);
};

export default PieChart;
