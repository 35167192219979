import React from 'react';
import { Form, Button, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import toastr from 'toastr';

import { instance } from '../../constants/constString';

const ForgetPassword = props => {
	const [form] = Form.useForm();

	const handleSubmit = async values => {
		const { oldPassword, newPassword } = values;
		try {
			const res = await instance.patch('/admin/reset-passowrd', {
				oldPassword,
				newPassword
			});
			console.log(res);
			toastr.success('Password reseted successfully');
			return res.data;
		} catch (error) {
			console.log(error.response);
			return Promise.reject(error);
		}
	};

	return (
		<div className='body'>
			<div className='wrap'>
				<div className='logo' style={{ textAlign: 'center' }}>
					<h1>
						<strong>Forget Password</strong>
						<img src='/mystudy-horizontal.png' alt='Logo' />
					</h1>
				</div>
				<div className='form-wrap'>
					<Form className='login-form' form={form} onFinish={handleSubmit}>
						<Form.Item
							name='oldPassword'
							style={{ padding: 1 }}
							rules={[
								{ required: true, message: 'Please enter your old password' }
							]}
						>
							<Input.Password
								size='small'
								prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='Type old password'
							/>
						</Form.Item>
						<Form.Item
							name='newPassword'
							rules={[
								{ required: 'true', message: 'Please input your password' },
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || value.length >= 8) {
											return Promise.resolve();
										}
										return Promise.reject('Password must be 8 characters long');
									}
								})
							]}
							hasFeedback
						>
							<Input.Password
								size='small'
								prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='Type new password'
							/>
						</Form.Item>
						<Form.Item
							name='confirmPassword'
							dependencies={['newPassword']}
							rules={[
								{ required: true, message: 'Confirm Password' },
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('newPassword') === value) {
											return Promise.resolve();
										}
										return Promise.reject('Passwords dont match');
									}
								})
							]}
							hasFeedback
						>
							<Input.Password
								size='small'
								prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
								placeholder='Confirm new password'
							/>
						</Form.Item>
						<Form.Item noStyle>
							<Button
								htmlType='submit'
								type='primary'
								size='large'
								style={{ marginTop: 20 }}
								className='login-form-button'
							>
								Proceed
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default ForgetPassword;
