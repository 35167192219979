import React from 'react';
import { Modal, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { resetAdminPasswordRequest } from '../../stateManager/reducers/adminAuthSlice';
import { passwordValidator } from '../../constants/constFunction';

const ResetAdminPasswordModal = props => {
	const { isVisible } = props;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const status = useSelector(state => state.authAdmin.status);

	const handleSubmit = async values => {
		const { oldPassword, newPassword } = values;
		try {
			const data = { oldPassword, newPassword };
			const res = await dispatch(resetAdminPasswordRequest({ data }));
			props.toggleModal();
		} catch (error) {
			props.toggleModal();
		}
	};

	return (
		<Modal
			visible={isVisible}
			onOk={() => form.submit()}
			onCancel={() => props.toggleModal()}
			okText='Submit'
			title='Reset Password'
			okButtonProps={{ loading: status === 'loading' }}
		>
			<Form
				layout='vertical'
				form={form}
				preserve={false}
				onFinish={handleSubmit}
			>
				<Form.Item
					label='Old Password'
					name='oldPassword'
					rules={[{ required: true, message: 'Please enter old password' }]}
				>
					<Input.Password placeholder='Enter Old Password' />
				</Form.Item>
				<Form.Item
					label='New Password'
					name='newPassword'
					rules={[
						{ required: true, message: 'Please enter new password' },
						() => ({
							validator(rule, value) {
								if (!value || passwordValidator(value)) {
									return Promise.resolve();
								}
								return Promise.reject(
									'Password must be 8 charaters long and should contain at 1 uppercase, 1 lowercase and 1 number'
								);
							}
						})
					]}
					hasFeedback
				>
					<Input.Password placeholder='Enter New Password' />
				</Form.Item>
				<Form.Item
					label='Confirm Password'
					name='confirmPassword'
					rules={[
						{ required: true, message: 'Please enter new password' },
						({ getFieldValue }) => ({
							validator(rule, value) {
								if (!value || getFieldValue('newPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject("Passwords don't match");
							}
						})
					]}
					hasFeedback
				>
					<Input.Password placeholder='Enter New Password Again' />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default ResetAdminPasswordModal;
