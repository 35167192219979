import React, { useEffect, useState } from 'react'
import { Button, Modal, Select, Space, Tag } from 'antd'
import { assignChapterToMentor, getMentorChapters } from '../../stateManager/reducers/mentorSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'


const { Option } = Select

const AssignChapterModal = (props) => {
	// State for dropdowns
	const { isVisible, isEdit, user, updateUser, handleModalOpen } = props
	const [selectedChapters, setSelectedChapters]                  = useState([])
	const [selectedCourse, setSelectedCourse]                      = useState(null)
    const [selectedSubject, setSelectedSubject]                    = useState(null)
	const [searchKey, setSearchKey]                              = useState('')
	const [selectedSession, setSelectedSession]                  = useState(new Date().getFullYear().toString())
	const assignedChapters                                         = useSelector(state => state.mentor.mentorAssignedChapters)
	const courses                                                  = useSelector(state => state.mentor.courses)
	let mentorData                                               = useSelector(state => state.mentor.mentors)
	const dispatch                                                 = useDispatch()


	if (searchKey) {
		mentorData = mentorData.filter(item => {
			return item.firstName.toLowerCase().includes(searchKey.toLowerCase()) || 
			item.lastName.toLowerCase().includes(searchKey.toLowerCase()) || 
			item.username.toLowerCase().includes(searchKey.toLowerCase()) ||
			item?.adminId?.toLowerCase().includes(searchKey.toLowerCase())

		})
	}
	useEffect(() => {
		async function fetchData() {
			await dispatch(getMentorChapters(user._id))
		}

		user && fetchData()

	}, [user])

	useEffect(() => {

		setSelectedChapters(assignedChapters)

	}, [assignedChapters])

	// Handle first dropdown change
	const handleFirstDropdownChange = (value) => {
		const selectedCourse = courses.find((course) => course._id === value)
		setSelectedCourse(selectedCourse)
		console.log('selectedCourse', selectedCourse)
	}

	const submit = async () => {
		console.log('selectedSubjects', selectedChapters)
		//only subject id
		await dispatch(assignChapterToMentor({ mentorId: user._id, chapters: selectedChapters.map(item => item._id) }))
		handleModalOpen(false, false)
		setSelectedCourse(null)
		updateUser(undefined)
		setSelectedSubject(null)
		setSelectedChapters([])
		updateUser(undefined)
	}

	// Handle second dropdown change
	const handleSecondDropdownChange = (value) => {

        console.log('value', value)

		const selectedChapter = selectedSubject.chapters.find(
			(chapter) => chapter._id === value,
		)
		//check if subject already exists

		const isExist = selectedChapters.find((item) => item._id === selectedChapter._id)
		if (isExist) {
			return
		}
		setSelectedChapters([
			...selectedChapters, selectedChapter
		])
		// remove selected subject from dropdown
		// setSelectedCourse({
		// 	...selectedCourse,
		// 	subjects: selectedCourse.subjects.filter((subject) => subject._id !== value),
		// })
	}

	// Remove item from selected list
	const removeItem = (item) => {
		setSelectedChapters(selectedChapters.filter((i) => i._id !== item._id))
		// setSelectedCourse({
		// 	...selectedCourse,
		// 	subjects: [
		// 		...selectedCourse.subjects,
		// 		{
		// 			_id : item._id,
		// 			name: item.name,
		// 		},
		// 	],
		// })
	}

	return (
		<Modal
			open={isVisible}
			destroyOnClose
			centered
			onCancel={() => {
				updateUser(undefined)
				handleModalOpen(false, false)
				setSelectedCourse(null)
				setSelectedSubject(null)
				console.log('selectedChapters', selectedChapters)
				setSelectedChapters([])
				setSelectedSession(new Date().getFullYear().toString())
				setSearchKey('')
			}}
			onOk={() => submit()}
			okText={'Submit'}
			cancelButtonProps={{
				style: { display: 'none' },
			}}
			footer={null}
			bodyStyle={{
				background: '#F8FAFC',
				padding   : '68px 120px 60px',
			}}
			width={'60%'}
		>
			<Space size={48} direction={'vertical'} style={{ width: '100%' }}>
				<div style={{
					color     : '#EE473F',
					fontSize  : '32px',
					fontWeight: 600,
				}}>{'Assign Mentor Chapter'}</div>
				<Space size={24} direction={'vertical'} style={{ width: '100%' }}>
					<Select
						placeholder={'Mentor Name'}
						showSearch
						style={{ width: '100%' }}
						optionFilterProp=''
						size={'large'}
						value={user?._id}
						onSearch={(value) => {
							setSearchKey(value)
						}}
						filterOption={false}
						onChange={(value) => updateUser(mentorData?.find(mentor => mentor._id === value))}
						disabled={isEdit}
					>
						{
							mentorData.map((mentor) => (
								<Option key={mentor._id} value={mentor._id}>
									
									<div>{mentor.firstName + ' ' + mentor.lastName}, ID:  {mentor.adminId} </div>
									
								</Option>
							))
						}
					</Select>
					<Select
						placeholder={'Select Session'}
						style={{ width: '100%' }}
						size={'large'}
						value={selectedSession}
						onChange={(value) => {
							setSelectedCourse(null)
							setSelectedSession(value)
						}}>
						{
							['2026', '2025', '2024', '2023', '2022' ].map((item) => (
								<Option key={item} value={item}>
									{item}
								</Option>
							))
						}
					</Select>
					<Select
						placeholder={'Select Course'}
						style={{ width: '100%' }}
						size={'large'}
						onChange={(value) => {
                            const course = courses.find(course => course._id === value)
                            setSelectedCourse(course)
                        }}
					>
						{
							courses.filter(course => course.session === selectedSession).map((course) => (
								<Option key={course._id} value={course._id}>
									{course.name}
								</Option>
							))
						}
					</Select>
                    <Select
						placeholder={'Select Subject'}
						style={{ width: '100%' }}
						size={'large'}
						onChange={(value) => {
                            const subject = selectedCourse.subjects.find(subject => subject._id === value)
                            setSelectedSubject(subject)
                        }}
					>
						{
							selectedCourse?.subjects.map((subject) => (
								<Option key={subject._id} value={subject._id}>
									{subject.name}
								</Option>
							))
						}
					</Select>
					<div style={{
						border    : '1px solid #d9d9d9',
						width     : '100%',
						minHeight : '40px',
						background: 'white',
						display   : 'flex',
						alignItems: 'center',
						padding   : '8px 12px',
						flexWrap  : 'wrap',
						gap       : '8px',
					}}>
						{
							// selectedSubject && selectedSubject.chapters.length > 0 &&
								selectedSubject?.chapters.map(chapter => (
									<div
										key={chapter._id}
										style={{
											background  : '#F1F5F9',
											padding     : '8px 16px',
											fontSize    : '14px',
											border      : '1px solid #CBD5E1',
											borderRadius: '6px',
											color       : '#EE473F',
											cursor      : 'pointer',
										}}
										onClick={() => handleSecondDropdownChange(chapter._id)}
									>
										<PlusOutlined style={{ color: '#EE473F', paddingRight: '4px' }} />
										{chapter.name}
									</div>
								))
						}
					</div>

					<div style={{ display: 'flex', flexWrap: 'wrap', rowGap: '10px' }}>
						{selectedChapters.map((item) => (
							<Tag
								key={item._id}
								closable
								onClose={() => removeItem(item)}
								color={'#EE473F'}
								style={{
									padding     : '8px 16px',
									fontSize    : '14px',
									borderRadius: '6px',
									cursor      : 'pointer',
								}}
								closeIcon={<CloseOutlined style={{ paddingLeft: '6px', fontSize: '14px' }} />}
							>
								{item.name}
							</Tag>
						))}
					</div>
				</Space>
				<div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
					<Button
						danger={true}
						type={'primary'}
						style={{
							background  : '#EE473F',
							width       : '200px',
							height      : '48px',
							borderRadius: 6,
						}}
						disabled={user === undefined }
						onClick={() => submit()}
					>{'Assign Chapter'}</Button>
				</div>
			</Space>
		</Modal>
	)
}

export default AssignChapterModal
