import React from 'react';
import { Layout, Typography, Card, Button, Row, Col, Avatar, Modal, Popover } from 'antd';
import { bucket_url } from '../../constants/constString';
import { DeleteOutlined,  } from '@ant-design/icons';
import { updateStatus, deleteQuestion, deleteComment, lockQuestion, unlockQuestion } from '../../stateManager/reducers/mentorSlice';
import EditReply from './EditReply';
import likeFillIcon from '../../assets/images/icons/like-filled.svg';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


const QUESTION_STATUS = {
    "COMPLETED": "COMPLETED",
    "COMPLETE": "COMPLETE",
    "DECLINE": "DECLINE",
    "DECLINED": "DECLINED",
}

const PostComponent = (props) => {
    const { comment, showStatus, updateQuestionStatus, updateQuestion } = props;

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [fileToView, setFileToView] = React.useState(null);
    const userType = comment.userType;
    const user = JSON.parse(localStorage.getItem('user'));
    const [deletePopoverVisible, setDeletePopoverVisible] = React.useState(false);

    const dispatch = useDispatch();
    const history = useHistory();

    const isLocked = () => {
      // if question is exist in lockedQuestions and lockedAt greater than 10 minutes
    
      

      if(!comment)
        return;

      if(comment.locked !== undefined && comment.locked !== null){
    
        const lockedAt = new Date(comment.locked.lockedAt);
        const currentTime = new Date();
        const diff = (currentTime - lockedAt) / 1000;
        if (diff < 600) {
          return true;
        }
        else{
          return false;
        }
      }
      
      return false;
     }

    React.useEffect(() => {
        console.log('fileToView:', fileToView);
        console.log('isModalVisible:', isModalVisible);

    }, [fileToView]);

    const handleDelete = async () => {
      // Perform delete action here (e.g., call an API to delete an item)
      await dispatch(deleteQuestion(comment._id));
      setDeletePopoverVisible(false); // Close the popover after deleting the item
      //go to the previous page
      history.goBack();
    };

    const handleLockQuestion = async () => {

      const user = JSON.parse(localStorage.getItem('user'));

      // try{
      //   const res = await dispatch(lockQuestion({
      //     questionId: comment._id,
      //     mentorId: user.id,
  
      //   }));
      //   console.log('res:', res.payload.data);
      //   updateQuestion();
      // }
      // catch(error){
      //   console.log('error:', error);
      //   //updateQuestionLockStatus(false);
      // }

      console.log('isLocked:', comment);

      if(isLocked(comment)){
        const user = JSON.parse(localStorage.getItem('user'));
        if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
        await dispatch(unlockQuestion({ questionId: comment._id, mentorId: user }));
        }
      }
      else{
        await dispatch(lockQuestion({ questionId: comment._id, mentorId: user.id }));
      }

      updateQuestion();
     
    };
  
    const handleCancel = () => {
      
      setDeletePopoverVisible(false); // Close the popover if canceled
      // Close the popover if canceled
    };
    const content = (
      <div>
        <p>Are you sure you want to delete this question?</p>
        
        <Button type="primary" danger onClick={handleDelete} style={{ marginRight: '8px' }}>
          Yes, Delete
        </Button>
        <Button onClick={handleCancel}>Cancel</Button>
      </div>
    );
  

    const updateStatusHandler = async () => {
        let status;
        switch (comment.status) {
            case QUESTION_STATUS.COMPLETE:
                status = QUESTION_STATUS.COMPLETED;
                break;
            case QUESTION_STATUS.COMPLETED:
                status = QUESTION_STATUS.COMPLETE;
                break;
            case QUESTION_STATUS.DECLINE:
                status = QUESTION_STATUS.DECLINED;
                break;
            case QUESTION_STATUS.DECLINED:
                status = QUESTION_STATUS.COMPLETE;
                break;
            default:
                status = QUESTION_STATUS.COMPLETE;
        }

        try {
            await dispatch(updateStatus({ questionId: comment._id, status }));
           updateQuestion();
        } catch (error) {

        }
    }
    const showPreview = (file, type) => {
      setFileToView({
          mediaUrl: file,
          mediaType: type
        })
      setIsModalVisible(true);
  }
    const renderFilePreview = (file, type) => {
      if (type.startsWith('image/')) {
        return <div className="img-section" onClick={()=>showPreview(file, type)}><img src={bucket_url+ file} alt={file.name} className="image-item" /></div>;
      } else if (type ===  'application/pdf') {
        return (
          <div className='pdf-section'
          style={{
            cursor: 'pointer',
           
          }}
          onClick={() => {
            setFileToView({
              mediaUrl: file,
              mediaType: type,
            });
            setIsModalVisible(true);
          }}
        >
          {/* Instead of directly embedding <object> here, use a wrapper div */}
          <div
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <iframe title='pdf'
              className="pdf"
              src={bucket_url + file}
              width="200"
              height="100"
              style={{
                pointerEvents: 'none', // Disable pointer events for object tag to avoid click interference
              }}
            />
          </div>
        </div>)
      } else if (type.startsWith('audio/')) {
        return <div className="audio-section"> <audio controls src={bucket_url+ file} /></div>;
      }
      return null;
    };
    const createPostHeader = () => {
        if (comment.userType === "Admin") {
          return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-end', // This ensures all items in this flex container are vertically centered
                marginBottom: '24px',
                justifyContent: 'flex-end',
            }}>
                <div style={{
                    lineHeight: '10px', // Increased line height for better visual alignment
                    textAlign: 'right'
                }}>

                    <h3 className="user-name"><span style={{color: "#018CE8"}}>{`Teacher, `}</span><span style={{
                    color: "#475569",
                        fontStyle: 'underline',
                    }}>{`${comment.userId ? comment.userId.firstName : 'Guest'} ${comment.userId? comment.userId.lastName:'User'}`}</span></h3>
                    <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                </div>
               
            </div>
          );
        } else {
            return ( 
              <div  className="user-content">
                <div className="user-data">
                  <div>
                      <h3 className="user-name">{comment?.userId?.name || 'Guest'}</h3>
                      <h6 className="time">{new Date(comment.createdAt).toLocaleString()}</h6>
                  </div>
                </div>
                {comment.status && <div className="question-btn-section">
                  {
                     (comment.status !== "COMPLETED" && comment.status !== "DECLINED") && <Button warning onClick={()=>{handleLockQuestion();}} className='mark-as-read-btn' disabled={comment.lockable? false:true}>{
                      isLocked(comment) ? 'Locked' : 'Lock'
                      }</Button>
                    }
                   <Button  disabled={ disableStatusButton(comment)} onClick={()=>{updateStatusHandler();}} className='action-dropdown-btn'>{comment.status}</Button>
                  <Popover
                    content={content}
                    title="Confirm Delete"
                    trigger="click"
                    open={deletePopoverVisible}>
                      {
                         user.roles.includes('admin') && <Button danger onClick={()=>{
                          setDeletePopoverVisible(true);
                          }} className='question-delete-btn'>
                          Delete
                                  </Button>
                      }
                      
                  </Popover>

                </div>
                  }
             
            </div>);
        }
    }
    const mediaView = () => {
        return <Modal title="Media" open={isModalVisible} destroyOnClose 
       width={1000}
        footer={null}
        centered
        onCancel={() => {
            setFileToView(null);
            setIsModalVisible(false);
        }
        }
        >
                    <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10px',
            gap: '10px',
            flowDirection: 'row',
          }}>
          <Button icon={<LeftOutlined/>} onClick={() => {
            // show next image
            prevMedia();



        }}></Button>
                             <Button icon={<RightOutlined />} 
                             onClick={() => {nextMedia();}}
                             ></Button>
          </div>
            {
                 fileToView?.mediaType.startsWith('image/') ?
                 <img src={bucket_url+ fileToView?.mediaUrl} alt="media" width="100%" />:
                <iframe title='pdf' class="pdf" src={bucket_url+ fileToView?.mediaUrl}  style={{width: '100%', height: '90vh',}}> </iframe>
                
            }
           
        </Modal>
    }

    const nextMedia = () => {
      console.log('Next Image');
      const images = comment.media.filter(item=> item && item.mediaType.startsWith('image/') || item.mediaType.startsWith('application/pdf'));
      console.log('Images:', images);
      const currentIndex = images.findIndex(item => item.mediaUrl === fileToView.mediaUrl);
      if (currentIndex === images.length - 1) {
          setFileToView({
              mediaUrl: images[0].mediaUrl,
              mediaType: images[0].mediaType,
          });
      }
      else {
          setFileToView({
              mediaUrl: images[currentIndex + 1].mediaUrl,
              mediaType: images[currentIndex + 1].mediaType,
          });
      }


    }

    const prevMedia = () => {
      console.log('Next Image');
            const images = comment.media.filter(item=> item && item.mediaType.startsWith('image/') || item.mediaType.startsWith('application/pdf'));
            console.log('Images:', images);
            const currentIndex = images.findIndex(item => item.mediaUrl === fileToView.mediaUrl);
            if (currentIndex === 0) {
                setFileToView({
                    mediaUrl: images[images.length - 1].mediaUrl,
                    mediaType: images[images.length - 1].mediaType,
                });
            }
            else {
                setFileToView({
                    mediaUrl: images[currentIndex - 1].mediaUrl,
                    mediaType: images[currentIndex - 1].mediaType,
                });
            }
    }

    const disableLockButton = (comment) => {
      const user = JSON.parse(localStorage.getItem('user'));

      if(user.roles.includes('admin') || user.roles.includes('seniorMentor')){
        return false;
      }
     
      if(comment.isLocked){
        return false;
      }
      return true;
    }

    const disableStatusButton = (comment) => {

      if(user.roles.includes('admin')){
        return false;
      }
      
      if((comment.status === QUESTION_STATUS.COMPLETED) || (comment.status === QUESTION_STATUS.DECLINED)){
        return true;
      }
      return false;
    }

  return (
    <>
    <Card className={`question-card ${userType}`}>
      <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24}} className={`total-data ${userType}-data`}>

          {createPostHeader()}
          <p style={{ textAlign: comment.userType === "Admin" ? "right" : "left", }} className="question-text">{comment.reply}
          </p>
          {/* {comment.media.length > 0 && <p className="attachment-text">Attachment</p>} */}
          {comment.media.length > 0 && (
              
              <div  className="attachment-section" 
            >
                  <Row gutter={[20,20]}  className='image-parent media-row'> 
                    {comment.media.filter(item=> item.mediaType.startsWith('image/')).map((file, index) => (
                          
                        <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                        </Col>
                    ))}
                  </Row>
                  <Row gutter={[20,20]}  className='pdf-parent media-row'> 
                    {comment.media.filter(item=> item.mediaType.startsWith('application/pdf')).map((file, index) => (
                          
                          <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                          </Col>
                    ))}
                </Row>
                <Row gutter={[20,20]}  className='audio-parent media-row'> 
                  {comment.media.filter(item=> item.mediaType.startsWith('audio/')).map((file, index) => (
                          
                          <Col xs={{ span: 24 }} lg={{ span:8 }} key={index}>{renderFilePreview(file.mediaUrl, file.mediaType)}
                          </Col>
                  ))}
                </Row>
              </div>
          )}
          {
                comment.userType === "Admin" && (
                    <div  className='upvote-section'>
                        <Button className='like-btn' type="text">
                            
                             <img src={likeFillIcon} alt="like" style={{
                                width: '20px',
                                height: '20px',
                            }}/> 
                        </Button>
                        <span>{comment.upvotes} Upvotes</span>
                    </div>
                )
            }
            {
                comment.userType === "Student" && !showStatus && user.roles.includes('admin') && (
                    <div  className='delete-section'>
                        <Button className='like-btn' type="text" onClick={async ()=>{
                          console.log('comment:', comment);
                            await dispatch(deleteComment(comment._id));
                            
                        }}>
                            <DeleteOutlined />
                        </Button>
                       
                    </div>)
            }
            {
              // edit button
              comment.userType === "Admin" && !showStatus && user.roles.includes('admin') && (
                <div  className='edit-section'>
                    <EditReply comment={comment} />
                </div>)
            }
          
      </Col>
      </Row>
    </Card>
    {
      mediaView()
    }
    </>

  );
};

export default PostComponent;
